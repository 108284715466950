import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EFilterType } from 'shared/enums/EFilterType';
import { FormattedTag } from 'shared/models/FormattedTag';

export const isGeoTag = (tag: FormattedTag): boolean => {
  return (
    tag.filterName === EAdvancedFilterType.geo ||
    tag.filterName === EFilterType.region ||
    tag.filterName === EAdvancedFilterType.bsCenter ||
    tag.filterName === EAdvancedFilterType.coworking
  );
};
