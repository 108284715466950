export enum EFilterType {
  dealType = 'dealType',
  officeType = 'officeType',
  price = 'price',
  priceType = 'priceType',
  totalArea = 'totalArea',
  isByCommercialOwner = 'isByCommercialOwner',
  BusinessAppointments = 'businessAppointments',
  SpecialtyTypes = 'specialtyTypes',
  workplaceCount = 'workplaceCount',
  workplaceType = 'workplaceType',
  region = 'region',
}
