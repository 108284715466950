import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { includesBuildingType } from 'shared/utils/filters/includesBuildingType';
import { isSingleOfficeType } from 'shared/utils/filters/isSingleOfficeType';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Здание". Не имеет значение аренда или продажа
 */
export class AnyBuildingAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return [isSingleOfficeType, includesBuildingType].every(fn => fn(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.buildingClassType,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
