import { Coworking } from 'shared/types/filters/geo';

/**
 * @deprecated Функция является устаревшей с точки зрения своего узкого названия (по факту выполняет тогл для модулей
 *   без гео, так же типизация данной функции, используемые типы являются не корректными. Требуется переосмысление и
 *   стандартизация данной логики, в существующих частях кода допустимо её использование, но не в новых частях, в новом
 *   коде или в исправленной логике использовать не желательно
 */
export function toggleGeoValue<T extends Coworking>(filterValue: T, filtersField: T[] | undefined): T[] {
  if (!filtersField) {
    return [filterValue];
  }

  const filtersFieldIds = filtersField.map(filtersFieldItem => filtersFieldItem.id);
  const filterValueId = filterValue.id;

  return !filtersFieldIds.includes(filterValueId)
    ? [...filtersField, filterValue]
    : filtersField.filter(filtersFieldItem => filtersFieldItem.id !== filterValueId);
}
