import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IFastFiltersUserResponseSchema83 } from 'shared/repositories/monolith-python/entities/schemas/FastFiltersUserResponseSchema83';
import { IFastFiltersUserSchema } from 'shared/repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { useApplicationContext } from 'shared/utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchCianApiSiteV1GetUserDataByRequestService } from './internal/fetchService';
import { userCianApiSiteV1GetUserDataByRequestSelector, userIsAuthenticatedSelector } from './selectors';

export function useCianApiSiteV1GetUserDataByRequestQuery<TData = IFastFiltersUserResponseSchema83>(
  options?: UseQueryOptions<IFastFiltersUserResponseSchema83, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();

  return useQuery<IFastFiltersUserResponseSchema83, QueryError, TData, QueryKey>({
    enabled: false,
    queryFn: () => fetchCianApiSiteV1GetUserDataByRequestService(context),
    queryKey: getQueryKey(),
    retry: 1,
    ...options,
  });
}

export function useUserQuery(
  options?: Omit<
    UseQueryOptions<IFastFiltersUserResponseSchema83, QueryError, IFastFiltersUserSchema | null, QueryKey>,
    'select'
  >,
): UseQueryResult<IFastFiltersUserSchema | null, QueryError> {
  return useCianApiSiteV1GetUserDataByRequestQuery({
    select: userCianApiSiteV1GetUserDataByRequestSelector,
    ...options,
  });
}

export function useIsUserAuthenticatedQuery(
  options?: Omit<UseQueryOptions<IFastFiltersUserResponseSchema83, QueryError, boolean, QueryKey>, 'select'>,
): UseQueryResult<boolean, QueryError> {
  return useCianApiSiteV1GetUserDataByRequestQuery({
    select: userIsAuthenticatedSelector,
    ...options,
  });
}
