import { ErrorLogComponent } from '@cian/error-log-component';
import { UiKitRoot } from '@cian/ui-kit';
import { FC } from 'react';
import { Provider } from 'react-redux';

import { CommonFiltersWrapper } from 'shared/components/CommonFiltersWrapper';
import { FilterWrapper } from 'shared/components/FilterWrapper';
import { ServerSideStateProvider } from 'shared/components/ServerSideStateProvider';
import { TagsManager } from 'shared/components/TagsManager';
import { IServerSideState } from 'shared/services/ServerSideState';
import { ReduxStore } from 'shared/types/RootState';
import { IApplicationContext } from 'shared/types/applicationContext';
import { ApplicationContext } from 'shared/utils/applicationContext';

import { OnMetaRequestedNotificationCenterLoadable } from './internal/components/OnMetaRequestedNotificationCenter/OnMetaRequestedNotificationCenterLoadable';

export interface IApplicationProps {
  context: IApplicationContext;
  serverSideState: IServerSideState;
  reduxStore: ReduxStore;
}

export const Application: FC<IApplicationProps> = props => {
  const { reduxStore, context, serverSideState } = props;
  const { logger } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitRoot deviceType="desktop">
          <Provider store={reduxStore}>
            <ServerSideStateProvider serverSideState={serverSideState}>
              <CommonFiltersWrapper>
                <FilterWrapper />
                <OnMetaRequestedNotificationCenterLoadable />
                <TagsManager />
              </CommonFiltersWrapper>
            </ServerSideStateProvider>
          </Provider>
        </UiKitRoot>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};

Application.displayName = 'Application';
