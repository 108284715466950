import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IApiGeoGetDistrictsTreeResponse } from 'shared/repositories/monolith-cian-realty/api/geo/get-districts-tree';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { useCurrentLocation } from 'shared/utils/hooks/useCurrentLocation';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchApiGeoGetDistrictsTreeService } from './internal/fetchService';

export function useApiGeoGetDistrictsTreeQuery<TData = IApiGeoGetDistrictsTreeResponse>(
  options?: UseQueryOptions<IApiGeoGetDistrictsTreeResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();
  const currentLocation = useCurrentLocation();

  return useQuery<IApiGeoGetDistrictsTreeResponse, QueryError, TData, QueryKey>({
    queryFn: () => fetchApiGeoGetDistrictsTreeService(context, { locationId: currentLocation }),
    queryKey: getQueryKey(currentLocation),
    ...options,
  });
}
