import { Option } from 'shared/components/Select/models/Option';
import { mapBuildingTypeLabel } from 'shared/constants/filters/advanced/buildingType';
import { EOfficeType } from 'shared/types/filters';
import { EBuildingType } from 'shared/types/filters/advanced/buildingType';

const mapOfficeTypeWithBuildintType: Partial<Record<EOfficeType, EBuildingType[]>> = {
  [EOfficeType.Office]: [
    EBuildingType.BusinessCenter,
    EBuildingType.OfficeBuilding,
    EBuildingType.AdministrativeBuilding,
    EBuildingType.MultifunctionalComplex,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.House,
  ],
  [EOfficeType.TradeArea]: [
    EBuildingType.MultifunctionalComplex,
    EBuildingType.ShoppingCenter,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
  ],
  [EOfficeType.FreeAppointmentObject]: [
    EBuildingType.ShoppingCenter,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.House,
    EBuildingType.Other,
  ],
  [EOfficeType.PublicCatering]: [
    EBuildingType.ShoppingCenter,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.House,
    EBuildingType.Other,
  ],
  [EOfficeType.AutoService]: [
    EBuildingType.ShoppingCenter,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.House,
    EBuildingType.Other,
  ],
  [EOfficeType.DomesticServices]: [
    EBuildingType.ShoppingCenter,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.House,
    EBuildingType.Other,
  ],
  [EOfficeType.Warehouse]: [
    EBuildingType.AdministrativeBuilding,
    EBuildingType.MultifunctionalComplex,
    EBuildingType.Warehouse,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.Other,
  ],
  [EOfficeType.Manufacture]: [
    EBuildingType.AdministrativeBuilding,
    EBuildingType.MultifunctionalComplex,
    EBuildingType.Warehouse,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.Other,
  ],
  [EOfficeType.Building]: [
    EBuildingType.BusinessCenter,
    EBuildingType.OfficeBuilding,
    EBuildingType.AdministrativeBuilding,
    EBuildingType.MultifunctionalComplex,
    EBuildingType.ShoppingCenter,
    EBuildingType.Warehouse,
    EBuildingType.FAO,
    EBuildingType.ResidentialBuilding,
    EBuildingType.House,
    EBuildingType.Other,
  ],
};

export const getBuildingTypeOptions = (officeType: EOfficeType[]): Option[] => {
  const uniqueBuildingTypes = officeType.reduce((acc, item) => {
    const availableBuildingTypes = mapOfficeTypeWithBuildintType[item];

    if (!availableBuildingTypes) {
      return acc;
    }

    availableBuildingTypes.forEach(type => acc.add(type));

    return acc;
  }, new Set<EBuildingType>());

  return Array.from(uniqueBuildingTypes).map(type => new Option(type, mapBuildingTypeLabel[type]));
};
