import { IFilter } from 'shared/components/AvailableFilters';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';

import { SchemaBlock } from '../../types';

/**
 * @todo Удалить функцию-хэлпер
 * @description Данный функционал появился в задаче CD-187913, будет удалён в задаче CD-188067
 */
export function removeElectronicTradingFilters(components: SchemaBlock[]): SchemaBlock[] {
  return components.map(([title, filters]) => {
    const formattedFilters = filters.reduce<IFilter<EAdvancedFilterType>[]>((acc, filter) => {
      if (filter.key !== EAdvancedFilterType.electronicTrading) {
        acc.push(filter);
      }

      return acc;
    }, []);

    const schema: SchemaBlock = [title, formattedFilters];

    return schema;
  });
}
