import { FC } from 'react';

import { ITagProps } from '../Tag/types';

import styles from './TagsList.css';

interface ITagsProps {
  tags: React.ReactElement<ITagProps>[];
}

export const TagsList: FC<ITagsProps> = ({ tags }) => (
  <>
    {tags.map((tag, index) => {
      return (
        <div className={styles['tag']} key={`tag_${index}`}>
          {tag}
        </div>
      );
    })}
  </>
);
