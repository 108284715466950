import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';

import { Block } from '../models/Block';
import { AdvancedFiltersBlocksSchema } from '../services/AdvancedFiltersBlocksSchema';

export const ADVANCED_FILTERS_BLOCKS_SCHEMA = new AdvancedFiltersBlocksSchema(
  new Block([EAdvancedFilterType.publishPeriod, EAdvancedFilterType.undergroundTime]),
  new Block(
    [
      EAdvancedFilterType.placementType,
      EAdvancedFilterType.specialtyTypes,
      EAdvancedFilterType.floor,
      EAdvancedFilterType.floorTypes,
      EAdvancedFilterType.totalArea,
      EAdvancedFilterType.amenity,
      EAdvancedFilterType.coworkingAccess,
      EAdvancedFilterType.inputType,
      EAdvancedFilterType.parkingType,
      EAdvancedFilterType.accessSystem,
      EAdvancedFilterType.conditionType,
      EAdvancedFilterType.estateTypes,
      EAdvancedFilterType.furniture,
      EAdvancedFilterType.buildingCranageTypesType,
      EAdvancedFilterType.buildingGatesType,
      EAdvancedFilterType.buildingHeatingType,
      EAdvancedFilterType.buildingLiftTypesType,
    ],
    'Объект',
  ),
  new Block(
    [
      EAdvancedFilterType.buildingClassType,
      EAdvancedFilterType.buildingHouseLine,
      EAdvancedFilterType.buildingType,
      EAdvancedFilterType.houseYear,
      EAdvancedFilterType.floorn,
      EAdvancedFilterType.infrastructure,
    ],
    'Здание',
  ),
  new Block(
    [EAdvancedFilterType.electronicTrading, EAdvancedFilterType.contract, EAdvancedFilterType.commissionType],
    'Условия сделки',
  ),
  new Block(
    [
      EAdvancedFilterType.containWords,
      EAdvancedFilterType.excludeWords,
      EAdvancedFilterType.contact,
      EAdvancedFilterType.id,
    ],
    'Объявление',
  ),
);
