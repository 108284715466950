import { ArticleHeading3, ArticleHeading5 } from '@cian/ui-kit';
import { FC } from 'react';

import { filterTypeToFilterLabelMapper } from 'shared/utils/filterTypeToFilterLabelMapper';

import { AvailableAdvancedFiltersProps } from './types';

import styles from './styles.css';

export const AvailableAdvancedFilters: FC<AvailableAdvancedFiltersProps> = props => {
  const { filtersComponents } = props;

  return (
    <div className={styles['filters']}>
      {filtersComponents.map(([title, fields]) => (
        <div className={styles['filters-group']} key={fields[0].key}>
          {title && (
            <div className={styles['filters-group-title']}>
              <ArticleHeading3 textAlign="right">{title}</ArticleHeading3>
            </div>
          )}
          {fields.map(filter => {
            const filterLabel = filterTypeToFilterLabelMapper[filter.key];

            return (
              <div className={styles['filter-row']} key={filter.key}>
                <ArticleHeading5 textAlign="right">{filterLabel}</ArticleHeading5>
                {filter.component}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

AvailableAdvancedFilters.displayName = 'AvailableAdvancedFilters';
