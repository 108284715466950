import { Button } from '@cian/ui-kit';
import { IButtonProps } from '@cian/ui-kit/button/types';
import cn from 'clsx';
import { Ref, forwardRef } from 'react';

import { useWithCounter } from './hooks/useWithCounter';
import { FilterButtonProps } from './types';

import styles from './style.css';

export const FilterButton = forwardRef(function FilterButton(props: FilterButtonProps, ref: Ref<HTMLButtonElement>) {
  const { children, onClick, count, isActive } = props;

  const [isCounterVisible, setCounterRef] = useWithCounter(count, ref);

  return (
    <div
      className={cn(styles['root'], {
        [styles['with-ellipsis']]: count != null,
        [styles['with-counter']]: isCounterVisible,
        [styles['active']]: isActive,
      })}
    >
      <Button ref={setCounterRef} size="XS" theme="stroke_secondary" onClick={onClick}>
        {children as IButtonProps['children']}
      </Button>
      {isCounterVisible && <span className={styles['counter']}>{count}</span>}
    </div>
  );
});

FilterButton.displayName = 'FilterButton';
