import { combineReducers } from 'redux';

import { filtersReducer } from './filters/reducer';
import { offersCountTooltipReducer } from './offersCountTooltip/reducer';
import { rsReducer } from './rs/reducer';
import { saveSearchReducer } from './saveSearch/reducer';

export const rootReducer = combineReducers({
  filters: filtersReducer,
  offersCountTooltip: offersCountTooltipReducer,
  rs: rsReducer,
  saveSearch: saveSearchReducer,
});
