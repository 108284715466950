import { ReactNode } from 'react';

import { BuildingClassTypeFilter } from 'shared/components/filters/advanced/BuildingClassTypeFilter';
import { CommonTagsFilter } from 'shared/components/filters/advanced/CommonTagsFilter';
import { CommonTermFilter } from 'shared/components/filters/advanced/CommonTermFilter';
import { CommonTermsFilter } from 'shared/components/filters/advanced/CommonTermsFilter';
import { ContactFilter } from 'shared/components/filters/advanced/ContactFilter';
import { ContractTypeFilter } from 'shared/components/filters/advanced/ContractTypeFilter';
import { FloorFilter } from 'shared/components/filters/advanced/FloorFilter';
import { HouseYearFilter } from 'shared/components/filters/advanced/HouseYearFilter';
import { InfrastructureFilter } from 'shared/components/filters/advanced/InfrastructureFilter';
import { InputTypeFilterContainer } from 'shared/components/filters/advanced/InputTypeFilter';
import { MultipleCheckboxFilter } from 'shared/components/filters/advanced/MultipleCheckboxFilter';
import { OfferIdFilter } from 'shared/components/filters/advanced/OfferIdFilter';
import { ParkingTypeFilterContainer } from 'shared/components/filters/advanced/ParkingTypeFilter/container';
import { PublishPeriodFilter } from 'shared/components/filters/advanced/PublishDateFilter';
import { SpecialtyTypesFilter } from 'shared/components/filters/advanced/SpecialtyTypesFilter';
import { TotalAreaFilter } from 'shared/components/filters/advanced/TotalAreaFilter';
import { UndergroundTimeFilterContainer } from 'shared/components/filters/advanced/UndergroundTimeFilter';
import { WithoutAgentCall } from 'shared/components/filters/advanced/WithoutAgentCall';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';

export const filterTypeToAdvancedFilterComponentMapper: Record<EAdvancedFilterType, () => ReactNode> = {
  [EAdvancedFilterType.totalArea]: () => <TotalAreaFilter key={EAdvancedFilterType.totalArea} />,
  [EAdvancedFilterType.publishPeriod]: () => <PublishPeriodFilter key={EAdvancedFilterType.publishPeriod} />,
  [EAdvancedFilterType.specialtyTypes]: () => <SpecialtyTypesFilter key={EAdvancedFilterType.specialtyTypes} />,
  [EAdvancedFilterType.withPhoto]: () => null,
  [EAdvancedFilterType.undergroundTime]: () => (
    <UndergroundTimeFilterContainer key={EAdvancedFilterType.undergroundTime} />
  ),
  [EAdvancedFilterType.contract]: () => <ContractTypeFilter key={EAdvancedFilterType.contract} />,
  [EAdvancedFilterType.electronicTrading]: () => (
    <CommonTermFilter filterName={EAdvancedFilterType.electronicTrading} key={EAdvancedFilterType.electronicTrading} />
  ),
  [EAdvancedFilterType.buildingClassType]: () => (
    <BuildingClassTypeFilter key={EAdvancedFilterType.buildingClassType} />
  ),
  [EAdvancedFilterType.floor]: () => (
    <FloorFilter floorType={EAdvancedFilterType.floor} key={EAdvancedFilterType.floor} />
  ),
  [EAdvancedFilterType.floorn]: () => (
    <FloorFilter floorType={EAdvancedFilterType.floorn} key={EAdvancedFilterType.floorn} />
  ),
  [EAdvancedFilterType.inputType]: () => <InputTypeFilterContainer key={EAdvancedFilterType.inputType} />,
  [EAdvancedFilterType.furniture]: () => (
    <CommonTermFilter filterName={EAdvancedFilterType.furniture} key={EAdvancedFilterType.furniture} />
  ),
  [EAdvancedFilterType.buildingLiftTypesType]: () => (
    <CommonTermsFilter
      filterName={EAdvancedFilterType.buildingLiftTypesType}
      key={EAdvancedFilterType.buildingLiftTypesType}
    />
  ),
  [EAdvancedFilterType.buildingCranageTypesType]: () => (
    <CommonTermsFilter
      filterName={EAdvancedFilterType.buildingCranageTypesType}
      key={EAdvancedFilterType.buildingCranageTypesType}
    />
  ),
  [EAdvancedFilterType.buildingGatesType]: () => (
    <CommonTermsFilter filterName={EAdvancedFilterType.buildingGatesType} key={EAdvancedFilterType.buildingGatesType} />
  ),
  [EAdvancedFilterType.buildingHeatingType]: () => (
    <CommonTermsFilter
      filterName={EAdvancedFilterType.buildingHeatingType}
      key={EAdvancedFilterType.buildingHeatingType}
    />
  ),
  [EAdvancedFilterType.conditionType]: () => (
    <CommonTermsFilter filterName={EAdvancedFilterType.conditionType} key={EAdvancedFilterType.conditionType} />
  ),
  [EAdvancedFilterType.amenity]: () => (
    <MultipleCheckboxFilter filterName={EAdvancedFilterType.amenity} key={EAdvancedFilterType.amenity} />
  ),
  [EAdvancedFilterType.infrastructure]: () => <InfrastructureFilter key={EAdvancedFilterType.infrastructure} />,
  [EAdvancedFilterType.coworkingAccess]: () => (
    <CommonTermsFilter filterName={EAdvancedFilterType.coworkingAccess} key={EAdvancedFilterType.coworkingAccess} />
  ),
  [EAdvancedFilterType.buildingHouseLine]: () => (
    <CommonTermsFilter filterName={EAdvancedFilterType.buildingHouseLine} key={EAdvancedFilterType.buildingHouseLine} />
  ),
  [EAdvancedFilterType.houseYear]: () => <HouseYearFilter key={EAdvancedFilterType.houseYear} />,
  [EAdvancedFilterType.placementType]: () => (
    <CommonTermsFilter filterName={EAdvancedFilterType.placementType} key={EAdvancedFilterType.placementType} />
  ),
  [EAdvancedFilterType.parkingType]: () => <ParkingTypeFilterContainer key={EAdvancedFilterType.parkingType} />,
  [EAdvancedFilterType.containWords]: () => (
    <CommonTagsFilter
      filterName={EAdvancedFilterType.containWords}
      key={EAdvancedFilterType.containWords}
      placeholder="Например: высокий потолок"
    />
  ),
  [EAdvancedFilterType.excludeWords]: () => (
    <CommonTagsFilter
      filterName={EAdvancedFilterType.excludeWords}
      key={EAdvancedFilterType.excludeWords}
      placeholder="Например: переуступка"
    />
  ),
  [EAdvancedFilterType.contact]: () => <ContactFilter key={EAdvancedFilterType.contact} />,
  [EAdvancedFilterType.estateTypes]: () => (
    <CommonTermsFilter filterName={EAdvancedFilterType.estateTypes} key={EAdvancedFilterType.estateTypes} />
  ),
  [EAdvancedFilterType.id]: () => <OfferIdFilter key={EAdvancedFilterType.id} />,
  [EAdvancedFilterType.accessSystem]: () => (
    <CommonTermFilter filterName={EAdvancedFilterType.accessSystem} key={EAdvancedFilterType.accessSystem} />
  ),
  [EAdvancedFilterType.commissionType]: () => (
    <CommonTermFilter filterName={EAdvancedFilterType.commissionType} key={EAdvancedFilterType.commissionType}>
      <WithoutAgentCall />
    </CommonTermFilter>
  ),
  [EAdvancedFilterType.buildingType]: () => (
    <CommonTermsFilter filterName={EAdvancedFilterType.buildingType} key={EAdvancedFilterType.buildingType} />
  ),
  [EAdvancedFilterType.coworking]: () => null,
  [EAdvancedFilterType.bsCenter]: () => null,
  [EAdvancedFilterType.geo]: () => null,
  [EAdvancedFilterType.isSeparatedInputType]: () => null,
  [EAdvancedFilterType.floorTypes]: () => null,
  [EAdvancedFilterType.idUser]: () => null,
  [EAdvancedFilterType.withoutAgentCall]: () => null,
};
