import { FC, useRef } from 'react';

import { EButtonTarget } from 'shared/types/offersCountTooltip/offersCountTooltipTarget';
import { useAvailableFiltersCount } from 'shared/utils/hooks/useAvailableFiltersCount';
import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';

import { AdvancedFiltersButton } from './AdvancedFiltersButton';
import { AdvancedFiltersButtonContainerProps } from './types';

export const AdvancedFiltersButtonContainer: FC<AdvancedFiltersButtonContainerProps> = props => {
  const advancedFiltersCount = useAvailableFiltersCount();
  const fullfiled = advancedFiltersCount > 0;

  const { openedAtCurrentTarget } = useOffersCountTooltip(EButtonTarget.moreButton);

  const advancedFiltersButtonRef = useRef(null);

  return (
    <div ref={advancedFiltersButtonRef}>
      <AdvancedFiltersButton
        {...props}
        fullfiled={fullfiled}
        hostElement={advancedFiltersButtonRef.current}
        tooltipOpened={openedAtCurrentTarget}
      />
    </div>
  );
};

AdvancedFiltersButtonContainer.displayName = 'AdvancedFiltersButtonContainer';
