import { Option } from 'shared/components/Select/models/Option';
import { mapBuildingCranageTypesTypeLabel } from 'shared/constants/filters/mapBuildingCranageTypesTypeLabel';
import { EBuildingCranageType } from 'shared/types/filters/advanced/buildingCranageTypesType';

export const buildingCranageTypesTypeOptions = [
  new Option(EBuildingCranageType.Beam, mapBuildingCranageTypesTypeLabel[EBuildingCranageType.Beam]),
  new Option(EBuildingCranageType.Goat, mapBuildingCranageTypesTypeLabel[EBuildingCranageType.Goat]),
  new Option(EBuildingCranageType.Overhead, mapBuildingCranageTypesTypeLabel[EBuildingCranageType.Overhead]),
  new Option(EBuildingCranageType.Railway, mapBuildingCranageTypesTypeLabel[EBuildingCranageType.Railway]),
];
