import { FC, useMemo } from 'react';

import { useApplicationContext } from 'shared/utils/applicationContext';
import { useAvailableAdvancedFilters } from 'shared/utils/hooks/useAvailableAdvancedFilters';

import { AvailableAdvancedFilters } from './AvailableAdvancedFilters';
import { ADVANCED_FILTERS_BLOCKS_SCHEMA } from './internal/constants/schema';
import { removeElectronicTradingFilters } from './internal/utils/removeElectronicTradingFilters';

export const AvailableAdvancedFiltersContainer: FC = () => {
  const { config } = useApplicationContext();
  const availableAdvancedFilters = useAvailableAdvancedFilters();

  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187913, будет удалён в задаче CD-188067
   */
  const electronicTradingEnabled = config.getStrict<boolean>('Commercial.ElectronicTrading.Enabled');

  const filtersComponents = useMemo(() => {
    const components = ADVANCED_FILTERS_BLOCKS_SCHEMA.getSchema(availableAdvancedFilters);

    /**
     * @todo Удалить блок if и функцию-хэлпер
     * @description Данный функционал появился в задаче CD-187913, будет удалён в задаче CD-188067
     */
    if (!electronicTradingEnabled) {
      const componentsWithoutElectronicTradingFilters = removeElectronicTradingFilters(components);

      return componentsWithoutElectronicTradingFilters;
    }

    return components;
  }, [availableAdvancedFilters, electronicTradingEnabled]);

  return <AvailableAdvancedFilters filtersComponents={filtersComponents} />;
};
