import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';

import { setTargetOffersCountTooltip } from './actions';
import { OffersCountTooltipState } from './types';

const initialState: OffersCountTooltipState = {
  target: null,
};

export const offersCountTooltipReducer = createReducer(initialState).handleAction(
  setTargetOffersCountTooltip,
  (state, action) =>
    produce(state, draft => {
      draft.target = action.payload;
    }),
);
