import { createAction } from 'typesafe-actions';

export const changeBSCenterTitleAction = createAction('filters/BSCenterTitleChanged')<{
  id: number;
  name: string;
}>();

export const setBSCenterTitlesAction = createAction('filters/setBSCenterTitles')<
  {
    id: number;
    name: string;
  }[]
>();
