import { FC, useCallback } from 'react';

import {
  useIsUserAuthenticatedQuery,
  useUserQuery,
} from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-user-data-by-request/query';
import { useFetchSaveSearchMetaQuery } from 'shared/store/serverSide/search-offers-index/v2/get-meta/query';
import { dataFromFetchOffersSearchMetaSelector } from 'shared/store/serverSide/search-offers-index/v2/get-meta/selectors';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { useSaveSearchJsonQuery } from 'shared/utils/hooks/useSaveSearchJsonQuery';

import { SaveSearchModal } from './SaveSearchModal';
import { useInitPushManager } from './internal/hooks/useInitPushManager';
import { usePushManagerHandlers } from './internal/hooks/usePushManagerHandlers';
import { useTrackSubscription } from './internal/hooks/useTrackSubscription';
import { SaveSearchModalContainerProps, TrackSubscription } from './types';

export const SaveSearchModalContainer: FC<SaveSearchModalContainerProps> = props => {
  const { open, onClose } = props;

  const {
    logger,
    httpApi,
    custom: { subdomain },
  } = useApplicationContext();
  const jsonQuery = useSaveSearchJsonQuery();

  const {
    isFetching: isSaveSearchMetaFetching,
    isLoading: isSaveSearchMetaLoading,
    isError: isSaveSearchMetaError,
    data: meta,
    refetch: refetchMetaData,
  } = useFetchSaveSearchMetaQuery({ enabled: open, select: dataFromFetchOffersSearchMetaSelector });

  const {
    isFetching: isUserDataFetching,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    data: userData,
    refetch: refetchUserData,
  } = useUserQuery({ enabled: open });

  const { data: isAuthenticated } = useIsUserAuthenticatedQuery();

  const { pushManager, isSubscribed, handleSetSubscribed, pushPermission } = useInitPushManager(open);
  const { handlePushSubscribe, handleSubscribed } = usePushManagerHandlers(pushManager, handleSetSubscribed);
  const { trackSubscription } = useTrackSubscription(isSubscribed, pushPermission);

  const onSubscribe = useCallback<TrackSubscription>(
    parameters => {
      handleSubscribed();
      trackSubscription(parameters);
    },
    [handleSubscribed, trackSubscription],
  );

  const onErrorRetry = useCallback(() => {
    // Покрыто в функ. тестах
    /* istanbul ignore next */
    if (isSaveSearchMetaError) {
      refetchMetaData();
    }

    // Покрыто в функ. тестах
    /* istanbul ignore next */
    if (isUserDataError) {
      refetchUserData();
    }
  }, [isSaveSearchMetaError, isUserDataError, refetchMetaData, refetchUserData]);

  const isDataError = isUserDataError || isSaveSearchMetaError;
  const isDataLoading = isSaveSearchMetaFetching || isSaveSearchMetaLoading || isUserDataFetching || isUserDataLoading;

  return (
    <SaveSearchModal
      hideLicense={isAuthenticated}
      httpApi={httpApi}
      isDataError={isDataError}
      isDataLoading={isDataLoading}
      jsonQuery={jsonQuery}
      logger={logger}
      open={open}
      subdomain={subdomain || 'www'}
      // если уже не подписан или если не запретил пуши
      subscribeToPushAvailable={!isSubscribed && pushPermission !== 'denied'}
      title={meta?.h1}
      userEmail={userData?.email}
      onClose={onClose}
      onErrorRetry={onErrorRetry}
      onPushSubscribe={handlePushSubscribe}
      onSubscribed={onSubscribe}
    />
  );
};

SaveSearchModalContainer.displayName = 'SaveSearchModalContainer';
