import { Button } from '@cian/ui-kit';
import cn from 'clsx';
import { FC } from 'react';

import { OffersCountTooltip } from '../OffersCountTooltip';

import { AdvancedFiltersButtonProps } from './types';

import styles from './styles.css';

export const AdvancedFiltersButton: FC<AdvancedFiltersButtonProps> = props => {
  const { fullfiled, tooltipOpened, onClick, hostElement } = props;

  return (
    <div className={cn(styles['root'], { [styles['fullfiled']]: fullfiled })}>
      <OffersCountTooltip hostElement={hostElement} opened={tooltipOpened} portal={true}>
        <Button size="XS" theme="stroke_secondary" onClick={onClick}>
          Ещё фильтры
        </Button>
      </OffersCountTooltip>
    </div>
  );
};

AdvancedFiltersButton.displayName = 'AdvancedFiltersButton';
