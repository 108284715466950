import { ICianApiSiteV1GetRegionCitiesResponse } from 'shared/repositories/monolith-python/cian-api/site/v1/get-region-cities';

export function dataFromGetRegionCitiesSelector(
  state: ICianApiSiteV1GetRegionCitiesResponse,
): ICianApiSiteV1GetRegionCitiesResponse['data'] {
  return state.data;
}

export function citiesFromGetRegionCitiesSelector(
  state: ICianApiSiteV1GetRegionCitiesResponse,
): ICianApiSiteV1GetRegionCitiesResponse['data']['items'] {
  return dataFromGetRegionCitiesSelector(state).items;
}
