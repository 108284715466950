import { EOfficeType, OfficeType } from 'shared/types/filters';

export const OFFICE_TYPES: OfficeType[] = [
  { label: 'Офис', value: EOfficeType.Office },
  { label: 'Офис в коворкинге', value: EOfficeType.Coworking },
  { label: 'Рабочее место', value: EOfficeType.Workplace },
  { label: 'Торговая площадь', shortLabel: 'Торговую площадь', value: EOfficeType.TradeArea },
  { label: 'Склад', value: EOfficeType.Warehouse },
  { label: 'Помещение свободного назначения', shortLabel: 'Свобод. назнач.', value: EOfficeType.FreeAppointmentObject },
  { label: 'Общепит', value: EOfficeType.PublicCatering },
  { label: 'Производство', value: EOfficeType.Manufacture },
  { label: 'Автосервис', value: EOfficeType.AutoService },
  { label: 'Здание', value: EOfficeType.Building },
  { label: 'Бытовые услуги', value: EOfficeType.DomesticServices },
  {
    description: 'Помещение в собственности c арендатором на долгий срок',
    label: 'Арендный бизнес',
    value: EOfficeType.RentalBusiness,
  },
  {
    description: 'Помещение в собственности или аренде с юрлицом, имуществом и сотрудниками',
    label: 'Готовый бизнес',
    value: EOfficeType.ReadyBusiness,
  },
  { label: 'Коммерческая земля', shortLabel: 'Коммерч. землю', value: EOfficeType.CommercialLand },
];
