import type { ILogger } from '@cian/logger/shared';
import { GenericError, HttpBadStatusCodeError, HttpCancelError } from '@cian/peperrors/shared';

import { ILogRequestService } from '../ILogRequestService';

export class LogRequestService implements ILogRequestService {
  private readonly logger: ILogger;

  public constructor(logger: ILogger) {
    this.logger = logger;
  }

  public logFetchError<Error = unknown>(error: Error): void {
    let logError: keyof ILogger;

    if (error instanceof GenericError) {
      const level = error.details?.level;

      switch (level) {
        case 'debug':
        case 'warning':
        case 'error':
          logError = level;
          break;
      }
    }

    logError ??= 'error';

    this.getErrorLogger(logError)(error);
  }

  private getErrorLogger(logLevel: keyof ILogger) {
    return <Err = unknown>(error: Err): void => {
      if (error instanceof HttpCancelError) {
        this.logger.debug(error.message, {
          details: error.printDetails(),
          domain: error.domain,
          name: error.name,
          stack: error.stack,
          type: error.type,
        });
      } else if (error instanceof HttpBadStatusCodeError) {
        this.logger[logLevel](error.message, {
          details: error.printDetails(),
          domain: error.domain,
          name: error.name,
          stack: error.stack,
          statusCode: error.statusCode,
          type: error.type,
        });
      } else if (error instanceof GenericError) {
        this.logger[logLevel](error.message, {
          details: error.printDetails(),
          domain: error.domain,
          name: error.name,
          stack: error.stack,
          type: error.type,
        });
      } else {
        this.logger.error(error as Error, {
          description:
            'Неизвестная ошибка, нужно обязательно описать её в логгере приложения, либо в сервисе запроса не корректно выбрасывается ошибка, нужно фиксить!',
        });
      }
    };
  }
}
