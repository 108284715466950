import { ModalWindow } from '@cian/ui-kit';
import { IUndergroundMapApi, UndergroundMap } from '@cian/underground-map-widget';
import { IStation } from '@cian/underground-map-widget/es/types/widget';
import { FC, useCallback, useRef } from 'react';

import { EUndergroundTimeType } from 'shared/types/filters/advanced/undergroundTime';
import { Underground } from 'shared/types/filters/geo';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';

import { ModalFooter } from '../common/ModalFooter/ModalFooter';
import { ModalFooterWrapper } from '../common/ModalFooterWrapper';

import { MetroModalHeader } from './header';
import { UndergroundMapLineSelector } from './internal/components/UndergroundMapLineSelector';
import { UndergroundMapSection } from './internal/components/UndergroundMapSection';
import { useFetchGetUndergroundMapData } from './internal/hooks/useFetchGetUndergroundMapData';
import { MetroModalProps } from './types';

export const MetroModal: FC<MetroModalProps> = props => {
  const { open, onClose, region, selectedIds } = props;

  const undergroundMapApiRef = useRef<IUndergroundMapApi>(null);

  const { addUndergroundStations, removeUndergroundStations, changeUndergroundTimeTypeAction } = useFiltersActions();

  const fetchUndergroundMapData = useFetchGetUndergroundMapData();

  const onSelectMetroItem = useCallback<(items: IStation[]) => void>(
    items => {
      const undergroundItems: Underground[] = items.map(item => ({
        color: item.color,
        id: item.id,
        title: item.name,
        type: 'underground',
      }));

      addUndergroundStations(undergroundItems);
      changeUndergroundTimeTypeAction(EUndergroundTimeType.foot);
    },
    [addUndergroundStations, changeUndergroundTimeTypeAction],
  );

  const onRemoveMetroItem = useCallback<(items: IStation[]) => void>(
    items => {
      const undergroundStationsIds = items.map(item => item.id);

      removeUndergroundStations(undergroundStationsIds);
    },
    [removeUndergroundStations],
  );

  return (
    <ModalWindow
      fixed
      footer={
        <ModalFooterWrapper>
          <ModalFooter onShowClick={onClose} />
        </ModalFooterWrapper>
      }
      header={
        <MetroModalHeader
          fastSelectionButtons={
            <UndergroundMapLineSelector api={undergroundMapApiRef} locationId={region} selectedIds={selectedIds} />
          }
        />
      }
      open={open}
      size="M"
      width={1250}
      onClose={onClose}
    >
      <UndergroundMapSection>
        <UndergroundMap
          apiRef={undergroundMapApiRef}
          locationId={region}
          selectedStations={selectedIds}
          onDataRequested={fetchUndergroundMapData}
          onRemove={onRemoveMetroItem}
          onSelect={onSelectMetroItem}
        />
      </UndergroundMapSection>
    </ModalWindow>
  );
};

MetroModal.displayName = 'MetroModal';
