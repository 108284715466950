import { EWorkplaceType, WorkplaceType } from 'shared/types/filters/wokplaceType';

export const WORKPLACE_TYPES: WorkplaceType[] = [
  {
    description: 'Этим столом будете пользоваться только вы',
    label: 'Закреплённое рабочее место',
    shortLabel: 'Закреплённое',
    value: EWorkplaceType.FixedWorkSpace,
  },
  {
    description: 'Любое доступное место в общей зоне',
    label: 'Незакреплённое рабочее место',
    shortLabel: 'Незакреплённое',
    value: EWorkplaceType.FreeWorkSpace,
  },
];
