import { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';

import { RangeInputContainer } from 'shared/components/RangeInputContainer';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EFloorTypes } from 'shared/types/filters/advanced/floorTypes';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useValidateRangeFiltersActions } from 'shared/utils/hooks/useValidateRangeFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { FloorTypes } from './internal/components/FloorTypes';
import { FloorFilterContainerProps, Validator } from './types';

import styles from './styles.css';

export const FloorFilter = memoWithDisplayName<FloorFilterContainerProps>(props => {
  const { floorType } = props;

  const floor = useFilter(EAdvancedFilterType.floor);
  const floorn = useFilter(EAdvancedFilterType.floorn);
  const floorTypes = useFilter(EAdvancedFilterType.floorTypes);
  const isFirstFloorSelected =
    floorType === EAdvancedFilterType.floor && floorTypes?.includes(EFloorTypes.OnlyFirstFloor);

  const {
    changeFloorMinAction,
    changeFloorMaxAction,
    validateFloorFilterAction,
    changeFloornMinAction,
    changeFloornMaxAction,
  } = useFiltersActions();

  const validator = useCallback<Validator>(() => true, []);

  const { validateRangeFilterAction } = useValidateRangeFiltersActions();

  const onBlur = useCallback(() => {
    batch(() => {
      validateRangeFilterAction(floorType);
      validateFloorFilterAction(floorType);
    });
  }, [floorType, validateFloorFilterAction, validateRangeFilterAction]);

  /**
   * @todo вместо копипасты переиспользовать экшены для этажей
   * @link https://jira.cian.tech/browse/CD-132601
   */
  const floorObject = useMemo(() => {
    if (floorType === EAdvancedFilterType.floor) {
      return { actions: { onChangeMax: changeFloorMaxAction, onChangeMin: changeFloorMinAction }, field: floor };
    }

    return { actions: { onChangeMax: changeFloornMaxAction, onChangeMin: changeFloornMinAction }, field: floorn };
  }, [
    changeFloorMaxAction,
    changeFloorMinAction,
    changeFloornMaxAction,
    changeFloornMinAction,
    floor,
    floorType,
    floorn,
  ]);

  return (
    <div className={styles['container']}>
      <RangeInputContainer
        disabled={isFirstFloorSelected}
        maxWidth={168}
        validator={validator}
        valueMax={floorObject.field?.max}
        valueMin={floorObject.field?.min}
        onBlurMax={onBlur}
        onBlurMin={onBlur}
        onChangeMax={floorObject.actions.onChangeMax}
        onChangeMin={floorObject.actions.onChangeMin}
      />
      {floorType === EAdvancedFilterType.floor && <FloorTypes />}
    </div>
  );
}, 'FloorFilter');
