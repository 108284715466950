import { EOfficeType, Filters } from 'shared/types/filters';
import { ECategoryType } from 'shared/types/filters/categoryType';
import { isSale } from 'shared/utils/filters/isSale';

export function getJsonQueryCategory(filters: Filters): ECategoryType[] | undefined {
  if (!filters.officeType.includes(EOfficeType.CommercialLand)) {
    return undefined;
  }

  if (isSale(filters)) {
    return [ECategoryType.CommercialLandSale];
  }

  return [ECategoryType.CommercialLandRent];
}
