/* Старая кнопка */
import { mergeStyles } from '@cian/utils';
import { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';

import styles from './index.css';

export type TStyle = 'primary' | 'danger';
export type TSize = 'default' | 'small' | 'large' | 'xlarge';

type IThemesMap = {
  [key in TStyle]: {
    default: CSSProperties;
    light: CSSProperties;
    outline: CSSProperties;
  };
};

const themesMap: IThemesMap = {
  danger: require('./themes/danger.css'),
  primary: require('./themes/primary.css'),
};

export interface IButtonProps {
  className?: string;
  style?: CSSProperties;
  type?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  title?: string;
  children?: ReactNode;

  /**
   * Тема
   * @type {TStyle}
   * @memberof IButtonProps
   */
  theme: TStyle;

  /**
   * Режим отображения
   * @type {('light' | 'outline')}
   * @memberof IButtonProps
   */
  mode?: 'light' | 'outline';

  /**
   * Размер
   * @type {TSize}
   * @memberof IButtonProps
   */
  size?: TSize;
}

export const Button: FC<IButtonProps> = props => {
  const { theme, mode, size, ...sharedProps } = props;

  const mainStyle = mergeStyles(
    styles['main'],
    size === 'large' && styles['main--large'],
    size === 'xlarge' && styles['main--xlarge'],
    size === 'small' && styles['main--small'],
    mode === 'outline' && styles['outline'],
    mode === 'outline' && size === 'large' && styles['outline--large'],
    mode === 'outline' && size === 'xlarge' && styles['outline--xlarge'],
  );

  let themeStyle = themesMap[theme]['default'];

  if (mode) {
    if (mode === 'light') {
      themeStyle = themesMap[theme]['light'];
    } else if (mode === 'outline') {
      themeStyle = themesMap[theme]['outline'];
    }
  }

  return (
    <button
      {...sharedProps}
      {...(mergeStyles(
        mainStyle,
        themeStyle,
        props.className,
        props.style,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any)}
    />
  );
};
