export enum EConditionTypeLegacy {
  /** Офисная отделка */
  OfficeDecoration = 1,
  /** Под чистовую отделку */
  FullDecorationNeeded = 2,
  /** Требуется капитальный ремонт */
  MajorRepairNeeded = 3,
  /** Требуется коcметический ремонт */
  CosmeticRepairNeeded = 4,
  /** Типовой ремонт */
  RegularRepair = 5,
  /** Дизайнерский ремонт */
  DesignerRepair = 6,
}

export enum EConditionType {
  /** Офисная отделка */
  OfficeDecoration = 'officeDecoration',
  /** Под чистовую отделку */
  FullDecorationNeeded = 'fullDecorationNeeded',
  /** Требуется капитальный ремонт */
  MajorRepairNeeded = 'majorRepairNeeded',
  /** Требуется коcметический ремонт */
  CosmeticRepairNeeded = 'cosmeticRepairNeeded',
  /** Типовой ремонт */
  RegularRepair = 'regularRepair',
  /** Дизайнерский ремонт */
  DesignerRepair = 'designerRepair',
  /** С отделкой */
  WithDecoration = 'withDecoration',
}
