import { EFilterType } from 'shared/enums/EFilterType';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { hasTwoOfficeTypes } from 'shared/utils/filters/hasTwoOfficeTypes';
import { hasWorkplaceOfficeType } from 'shared/utils/filters/hasWorkplaceOfficeType';
import { isRent } from 'shared/utils/filters/isRent';

import { IFiltersService } from '../IFiltersService';

import { AbstractFiltersService } from './AbstractFiltersService';

export class RentWorkplaceAndCoworkingFiltersService
  extends AbstractFiltersService
  implements IFiltersService<EFilterType>
{
  public isAvailable(): boolean {
    return (
      isRent(this.filters) &&
      hasTwoOfficeTypes(this.filters) &&
      hasCoworkingOfficeType(this.filters) &&
      hasWorkplaceOfficeType(this.filters)
    );
  }

  public getFields(): EFilterType[] {
    return super.getFields().concat(EFilterType.workplaceType, EFilterType.workplaceCount, EFilterType.price);
  }
}
