export enum EBuildingType {
  BusinessCenter = 1,
  House = 2,
  AdministrativeBuilding = 3,
  MultifunctionalComplex = 4,
  Warehouse = 5,
  FAO = 6,
  Other = 7,
  ShoppingCenter = 8,
  ResidentialBuilding = 9,
  OfficeBuilding = 10,
}

export enum EBuildingTypeLegacy {
  BusinessCenter = 1,
  ShoppingEntertainmentCenter = 2,
  OldFund = 5,
  OfficeBuilding = 6,
  BusinessPark = 7,
  OfficeIndustrialComplex = 8,
  OfficeWarehouseComplex = 9,
  WarehouseComplex = 10,
  IndustrialWarehouseComplex = 11,
  LogisticsCenter = 12,
  Warehouse = 13,
  AdministrativeBuilding = 14,
  IndustrialPark = 15,
  LogisticsComplex = 16,
  ShoppingBusinessComplex = 17,
  MultifunctionalComplex = 18,
  OfficeResidentialComplex = 19,
  TradingOfficeComplex = 20,
  OfficeHotelComplex = 21,
  BusinessCenter2 = 22,
  Mansion = 23,
  Technopark = 24,
  BusinessQuarter = 25,
  StandaloneBuilding = 26,
  ResidentialHouse = 27,
  ResidentialComplex = 28,
  ShoppingCenter = 29,
  SpecializedShoppingCenter = 30,
  ShoppingCommunityCenter = 31,
  Outlet = 32,
  IndustrialComplex = 33,
  IndustrialSite = 34,
  ManufacturingFacility = 35,
  FreeAppointmentObject = 36,
  PropertyComplex = 37,
  ManufactureBuilding = 38,
  ModularBuilding = 39,
  FreeBuilding = 40,
  OfficeWarehouse = 41,
  OfficeCenter = 42,
  OfficeQuarter = 43,
  BusinessQuarter2 = 44,
  BusinessHouse = 45,
  TradingHouse = 46,
  Other = 100,
}
