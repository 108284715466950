import { IUndergroundMapDataV2 } from '@cian/underground-map-widget';
import type { QueryClient } from '@tanstack/react-query';

import { IApplicationContext } from 'shared/types/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetUndergroundMapDataServiceV2 } from './internal/fetchService';

export function fetchGetUndergroundMapDataV2(
  queryClient: QueryClient,
  dependencies: IApplicationContext,
  locationId: number,
): Promise<IUndergroundMapDataV2> {
  return queryClient.fetchQuery<IUndergroundMapDataV2, QueryError, IUndergroundMapDataV2, QueryKey>(
    getQueryKey(locationId),
    () => fetchGetUndergroundMapDataServiceV2(dependencies, locationId),
  );
}
