import { ErrorLogComponent } from '@cian/error-log-component';
import { ESuggestType, IItemClickParams } from '@cian/geosuggest-widget';
import { useBooleanState } from '@cian/react-utils';
import { IconSearch16 } from '@cian/ui-kit';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { batch } from 'react-redux';

import { geocode } from 'shared/services/geocode';
import { useSearchOffersMetaQueryLegacy } from 'shared/store/serverSide/search-offers-index/v2/get-meta/query';
import { EUndergroundTimeType } from 'shared/types/filters/advanced/undergroundTime';
import { EButtonTarget } from 'shared/types/offersCountTooltip/offersCountTooltipTarget';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { useFilters } from 'shared/utils/hooks/useFilters';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { GeoSuggest } from './GeoSuggest';
import { GeoSuggestRightAddon } from './GeoSuggestRightAddon/GeoSuggestRightAddon';
import { getGeoSuggestPlaceholder } from './helpers/getGeoSuggestPlaceholder';
import { normalizeGeoObject } from './helpers/normalizeGeoObject';
import { useGetSuggest } from './internal/hooks/useGetSuggest/useGetSuggest';

export const GeoSuggestContainer = memoWithDisplayName(() => {
  const context = useApplicationContext();
  const filters = useFilters();

  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState('');
  const {
    suggestions,
    yandexSuggestQuery: { isFetching: isLoadingYaSuggest },
    geoSuggestQuery: { isFetching: isLoadingSuggest },
  } = useGetSuggest(searchValue);

  const { refetch } = useSearchOffersMetaQueryLegacy();

  const {
    state: isGeocodeLoading,
    setTrue: setIsGeocodeLoading,
    setFalse: setIsGeocodeLoaded,
  } = useBooleanState(false);

  const {
    changeCoworkingAction,
    changeBSCenterAction,
    changeRegionAction,
    changeGeoAction,
    changeUndergroundTimeTypeAction,
  } = useFiltersActions();

  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const handleItemSelect = useCallback<(suggestion: IItemClickParams) => Promise<void>>(
    async suggestion => {
      setIsGeocodeLoading();
      setSearchValue('');

      const { id, title, group, regionId } = suggestion;

      if (id) {
        switch (group) {
          case ESuggestType.businessCenter:
          case ESuggestType.shoppingCenter:
            batch(() => {
              changeBSCenterAction({ id, title });
              setTargetOffersCountTooltip(EButtonTarget.findButton);
            });

            break;

          case ESuggestType.coworking:
            changeCoworkingAction({ id, title });
            setTargetOffersCountTooltip(EButtonTarget.findButton);

            break;

          case ESuggestType.district:
          case ESuggestType.road:
          case ESuggestType.railway:
            if (regionId && !filters.region?.includes(regionId)) {
              changeRegionAction([regionId]);

              refetch();
            }

            batch(() => {
              changeGeoAction(normalizeGeoObject(suggestion));
              setTargetOffersCountTooltip(EButtonTarget.findButton);
            });

            break;

          case ESuggestType.underground:
            if (regionId && !filters.region?.includes(regionId)) {
              changeRegionAction([regionId]);

              refetch();
            }

            batch(() => {
              changeUndergroundTimeTypeAction(EUndergroundTimeType.foot);
              changeGeoAction(normalizeGeoObject(suggestion));
              setTargetOffersCountTooltip(EButtonTarget.findButton);
            });

            break;

          case ESuggestType.city:
            if (regionId) {
              if (!filters.region?.includes(regionId)) {
                changeRegionAction([regionId]);

                refetch();
              }

              changeGeoAction(normalizeGeoObject(suggestion));
            } else {
              if (id) {
                if (!filters.region?.includes(id)) {
                  changeRegionAction([id]);

                  refetch();
                }
              } else {
                batch(() => {
                  changeGeoAction(normalizeGeoObject(suggestion));
                  setTargetOffersCountTooltip(EButtonTarget.findButton);
                });
              }
            }

            break;
        }
      } else {
        try {
          const geocodedItem = await geocode(context, { query: suggestion.title }, queryClient);

          setIsGeocodeLoaded();

          const isRegion =
            geocodedItem.geoValue.type === 'location' && geocodedItem.geoValue.id === geocodedItem.regionId;

          if (geocodedItem.regionId && (!filters.region?.includes(geocodedItem.regionId) || isRegion)) {
            changeRegionAction([geocodedItem.regionId]);

            refetch();
          } else {
            setTargetOffersCountTooltip(EButtonTarget.findButton);
          }

          if (!isRegion) {
            //@ts-expect-error, @todo привести типы
            changeGeoAction({
              ...geocodedItem.geoValue,
              title: suggestion.title.trim().replace(/([, ]*Россия$|^Россия[, ]*)/gi, ''),
            });
          }
        } catch (error) {
          context.logger.error(error as Error, {
            description: 'не удалось обработать саджест',
            domain: 'src/shared/components/GeoSuggest/container.tsx',
          });

          throw new Error('не удалось обработать саджест');
        }
      }
    },
    [
      setIsGeocodeLoading,
      changeCoworkingAction,
      setTargetOffersCountTooltip,
      filters.region,
      changeBSCenterAction,
      changeRegionAction,
      refetch,
      changeGeoAction,
      changeUndergroundTimeTypeAction,
      context,
      queryClient,
      setIsGeocodeLoaded,
    ],
  );

  const placeholder = getGeoSuggestPlaceholder(filters);

  return (
    <ErrorLogComponent logger={context.logger}>
      <GeoSuggest
        addonLeft={<IconSearch16 color="primary_100" />}
        addonRight={<GeoSuggestRightAddon />}
        isLoading={isLoadingSuggest || isLoadingYaSuggest || isGeocodeLoading}
        placeholder={placeholder}
        suggestions={suggestions}
        value={searchValue}
        onItemClick={handleItemSelect}
        onValueChange={setSearchValue}
      />
    </ErrorLogComponent>
  );
}, 'GeoSuggestContainer');
