import type { IExperimentResultSchema } from 'shared/repositories/ab-use/entities/entities/ExperimentResultSchema';
import type { IGetMultipleExperimentResponse } from 'shared/repositories/ab-use/v1/get-multiple-experiment';

export function getAbUseExperiment(
  multipleExperimentResponse: IGetMultipleExperimentResponse,
  experimentName: IExperimentResultSchema['experimentName'],
): IExperimentResultSchema | null {
  return (
    multipleExperimentResponse.result.find(abUseExperiment => abUseExperiment.experimentName === experimentName) ?? null
  );
}

export const getIsAbUseExperimentsActiveFactory =
  (experimentName: IExperimentResultSchema['experimentName']) =>
  (groupName: IExperimentResultSchema['groupName']) =>
  (multipleExperimentResponse: IGetMultipleExperimentResponse): boolean => {
    const abUseExperiment = getAbUseExperiment(multipleExperimentResponse, experimentName);

    return abUseExperiment?.groupName === groupName;
  };
