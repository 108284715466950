import { EFilterType } from 'shared/enums/EFilterType';
import {
  AnyAnyFiltersService,
  FiltersFacadeService,
  RentCoworkingFiltersService,
  RentWorkplaceAndCoworkingFiltersService,
  RentWorkplaceFiltersService,
  SaleBusinessFiltersService,
} from 'shared/services/FiltersService';
import { Filters } from 'shared/types/filters';

export function getAvailableFilters(filters: Filters): Array<EFilterType> {
  const currentFilterService = FiltersFacadeService.findCurrentFiltersService(
    () => new RentWorkplaceAndCoworkingFiltersService(filters),
    () => new SaleBusinessFiltersService(filters),
    () => new RentWorkplaceFiltersService(filters),
    () => new RentCoworkingFiltersService(filters),
    () => new AnyAnyFiltersService(filters),
  );

  return currentFilterService.getFields();
}
