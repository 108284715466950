import type { JsonQueryRangeValue } from 'shared/types/jsonQuery';

import type { IJsonQueryRange } from '../IJsonQueryElement';

export class JsonQueryRange implements IJsonQueryRange {
  public readonly type = 'range';
  public value: JsonQueryRangeValue;

  public constructor(value: JsonQueryRangeValue) {
    this.value = value;
  }
}
