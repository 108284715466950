import { createAction } from 'typesafe-actions';

import { RangeValueKeys } from 'shared/types/filters/rangeValue';
import { RangeValue } from 'shared/types/rangeValue';

type Payload = {
  fieldName: RangeValueKeys;
  rangeValueKey: keyof RangeValue;
  value: number;
  limit?: number;
};

export const changeRangeValueAction = createAction('filters/rangeValueChanged')<Payload>();
