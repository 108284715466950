import { FC } from 'react';

import { FindButtonContainer } from 'shared/components/FindButton/FindButtonContainer';
import { EButtonType } from 'shared/components/FindButton/types';

import { ClearButton } from './components/ClearButton';
import { AdvancedFiltersModalFooterProps } from './types';

import styles from './AdvancedFiltersModalFooter.css';

export const AdvancedFiltersModalFooter: FC<AdvancedFiltersModalFooterProps> = props => {
  const { label, loading, onShowClick, onClear } = props;

  const buttons = (
    <>
      <div className={styles['clear-button']}>
        <ClearButton onClick={onClear} />
      </div>
      <FindButtonContainer
        buttonType={EButtonType.AdvancedFilters}
        label={label}
        loading={loading}
        onClick={onShowClick}
      />
    </>
  );

  return (
    <div className={styles['container']} data-testid="advanced-filters-modal-footer">
      {buttons}
    </div>
  );
};

AdvancedFiltersModalFooter.displayName = 'AdvancedFiltersModalFooter';
