import { ActionCreatorsMapObject } from 'redux';

import { useBoundActions } from './useBoundActions';

export function boundActionsFactory<A, M extends ActionCreatorsMapObject<A>>(actionCreators: M): () => M;

export function boundActionsFactory<M extends ActionCreatorsMapObject, N extends ActionCreatorsMapObject>(
  actionCreators: M,
): () => N;

export function boundActionsFactory<A, M extends ActionCreatorsMapObject<A>>(actionCreators: M): () => M {
  return () => useBoundActions(actionCreators);
}
