import { useQueryClient } from '@tanstack/react-query';

import { FilterSerializer } from 'shared/models/FiltersSerializer/FilterSerializer';
import { EStrategyType } from 'shared/models/FiltersSerializer/strategies/types';
import { IJsonQuery } from 'shared/models/JsonQuery';

import { useFilters } from './useFilters';

export const useJsonQuery = (): IJsonQuery => {
  const filters = useFilters();
  const queryClient = useQueryClient();

  const filterSerializer = new FilterSerializer(filters, queryClient);

  return filterSerializer.serialize(EStrategyType.toJsonQuery);
};
