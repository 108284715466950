export type UndergroundTime = {
  type: EUndergroundTimeType;
  time: number;
};

export enum EUndergroundTimeType {
  none = '0',
  foot = '2',
  transport = '-2',
}
