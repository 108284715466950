import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { hasTradeAreaOfficeType } from 'shared/utils/filters/hasTradeAreaOfficeType';
import { isSingleOfficeType } from 'shared/utils/filters/isSingleOfficeType';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Торговая площадь". Не имеет значение аренда или продажа
 */
export class AnyTradeAreaAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return [isSingleOfficeType, hasTradeAreaOfficeType].every(fn => fn(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.placementType,
        EAdvancedFilterType.floor,
        EAdvancedFilterType.floorTypes,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.inputType,
        EAdvancedFilterType.isSeparatedInputType,
        EAdvancedFilterType.buildingHouseLine,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.infrastructure,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
