import { Checkbox } from '@cian/ui-kit';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const WithPhotoFilter = memoWithDisplayName(() => {
  const withPhoto = useFilter(EAdvancedFilterType.withPhoto);
  const { toggleWithPhotoAction } = useFiltersActions();

  return <Checkbox checked={!!withPhoto} label={`Только с\u00A0фото`} onChange={toggleWithPhotoAction} />;
}, 'WithPhotoFilter');

WithPhotoFilter.displayName = 'WithPhotoFilter';
