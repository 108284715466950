import { FC, useRef } from 'react';

import { EFilterType } from 'shared/enums/EFilterType';

import { FilterPopupWindow } from '../FilterPopupWindow';
import { OffersCountTooltip } from '../OffersCountTooltip';
import { Outside } from '../Outside';

import { getFilterLabelComponent } from './helpers/getFilterLabelComponent';
import { IPopupFilterProps } from './types';

export const PopupFilter: FC<IPopupFilterProps> = props => {
  const {
    placeholder,
    opened,
    popupContent,
    onPopupToggle,
    label,
    count,
    filterType,
    isTooltipOpened,
    isHighlightedActiveState = true,
    labelView,
    onContentClick,
  } = props;

  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);

  const FilterLabelView = getFilterLabelComponent(labelView);

  const isActive = isHighlightedActiveState && opened;
  const tooltipPlacement = filterType === EFilterType.dealType ? 'bottom-start' : 'bottom';

  return (
    <Outside active={opened} insideRefs={[popupContentRef]} onOutside={onPopupToggle}>
      <div data-testid={`filter-${filterType}`}>
        <OffersCountTooltip opened={isTooltipOpened} placement={tooltipPlacement}>
          <FilterLabelView count={count} isActive={isActive} ref={filterButtonRef} onClick={onPopupToggle}>
            {label ?? placeholder}
          </FilterLabelView>
        </OffersCountTooltip>

        <FilterPopupWindow anchorRef={filterButtonRef} opened={opened}>
          <div data-testid="filter-popup-content" ref={popupContentRef} onClick={onContentClick}>
            {popupContent}
          </div>
        </FilterPopupWindow>
      </div>
    </Outside>
  );
};

PopupFilter.displayName = 'PopupFilter';
