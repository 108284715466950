/** Тип сделки */
export enum EDealType {
  /** Аренда **/
  Rent = 1,
  /** Покупка **/
  Sale = 2,
}

export type DealType = {
  label: string;
  value: EDealType;
};
