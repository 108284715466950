import { CheckboxButton } from '@cian/ui-kit';
import { IUndergroundMapApi } from '@cian/underground-map-widget';
import { FC, RefObject, useMemo } from 'react';

import { useApplicationContext } from 'shared/utils/applicationContext';

import styles from './styles.css';

export interface IStationData {
  name: string;
  id: number;
  color?: string;
}

export type UndergroundShortcut = {
  text: string;
  ids: number[];
  label: string;
};

export type UndergroundShortcuts = Record<string, Array<UndergroundShortcut>>;

export interface IUndergroundJSONDataPath {
  [locationId: string]: string;
}

export interface IUndergroundMapLineSelectorProps {
  api: RefObject<IUndergroundMapApi>;
  locationId: number;
  selectedIds: Array<number>;
}

export function includesAll(hostArray: number[], searchArray: number[]): boolean {
  const searchSet = new Set<number>(hostArray);

  return !!searchArray.length && !!searchSet.size && searchArray.every(el => searchSet.has(el));
}

export const UndergroundMapLineSelector: FC<IUndergroundMapLineSelectorProps> = props => {
  const { api, locationId, selectedIds } = props;

  const { config } = useApplicationContext();

  const metroData = config.get<UndergroundShortcuts>('undergroundMapShortcuts');

  const shortcuts = useMemo(() => metroData && metroData[`${locationId}`], [metroData, locationId]);

  const handleToggleStationsFactory = (ids: number[]) => (): void => {
    const selectedStations = new Set<number>(selectedIds);

    const checked = [ids.length, selectedStations.size].every(Boolean) && ids.every(el => selectedStations.has(el));

    if (checked) {
      api.current?.onRemove(ids);
    } else {
      api.current?.onSelect(ids);
    }
  };

  if (!shortcuts?.length) {
    return null;
  }

  return (
    <div className={styles['checkbox-group']}>
      {shortcuts.map((shortcut, index) => (
        <CheckboxButton
          checked={includesAll(selectedIds, shortcut.ids)}
          key={index}
          label={shortcut.text}
          onChange={handleToggleStationsFactory(shortcut.ids)}
        >
          {shortcut.text}
        </CheckboxButton>
      ))}
    </div>
  );
};
