import { IOption } from '../IOption';

export class Option<Value = number> implements IOption<Value> {
  public readonly value: Value;
  public readonly label: string;
  public readonly shortLabel?: string;
  public readonly description?: string;

  public constructor(value: Value, label: string, shortLabel?: string, description?: string) {
    this.value = value;
    this.label = label;

    if (description) {
      this.description = description;
    }

    if (shortLabel) {
      this.shortLabel = shortLabel;
    }
  }

  public checkIsActive(values: Set<Value>): boolean {
    return values.has(this.value);
  }

  public getIsSearchValueInLabel(searchValue: string): boolean {
    return !searchValue || new RegExp(searchValue, 'i').test(this.label);
  }
}
