export enum EQueryKeys {
  AbUse__V1_GetMultipleExperiment = 'ab-use__/v1/get-multiple-experiment/',
  Announcements__V1_GetBusinessPossibleAppointments = 'announcements__/v1/get-business-possible-appointments/',
  Announcements__V1_GetPossibleAppointments = 'announcements__/v1/get-possible-appointments/',
  SearchOffersIndex__V2_GetCount = 'search-offers-index__/v2/get-meta??count??/',
  SearchOffersIndex__V2_GetMeta = 'search-offers-index__/v2/get-meta/',
  SearchOffersIndex__V2_GetMeta_Legacy = 'search-offers-index__/v2/get-meta__legacy/',
  Coworking__V1_GetCoworkingsNamesByIds = 'coworking__/v1/get-coworkings-names-by-ids/',
  GeoSuggest__V2_Suggest = 'geo-suggest__/v2/suggest/',
  Map__V1_GetUndergroundMapData = 'map__/v1/get-underground-map-data/',
  Map__V2_GetUndergroundMapData = 'map__/v2/get-underground-map-data/',
  MonolithCianRealty__Api_Geo_GeocodeCached = 'monolith-cian-realty__/api/geo/geocode-cached/',
  MonolithCianRealty__Api_Geo_GeocodedForSearch = 'monolith-cian-realty__/api/geo/geocoded-for-search/',
  MonolithCianRealty__Api_Geo_GetDistrictsTree = 'monolith-cian-realty__/api/geo/get-districts-tree/',
  MonolithPython__CianApi_Site_V1_GetBscentersByIds = 'monolith-python__/cian-api/site/v1/get-bscenters-by-ids/',
  MonolithPython__CianApi_Site_V1_GetRegion = 'monolith-python__/cian-api/site/v1/get-region/',
  MonolithPython__CianApi_Site_V1_GetRegionCities = 'monolith-python__/cian-api/site/v1/get-region-cities/',
  MonolithPython__CianApi_Site_V1_GetRegions = 'monolith-python__/cian-api/site/v1/get-regions/',
  MonolithPython__CianApi_Site_V1_GetUserDataByRequest = 'monolith-python__/cian-api/site/v1/get-user-data-by-request/',
  MonolithPython__CianApi_Site_V1_QueryToGeoTags = 'monolith-python__/cian-api/site/v1/query-to-geo-tags/',
  MonolithPython__CianApi_Site_V1_SearchRegionsCities = 'monolith-python__/cian-api/site/v1/search-regions-cities/',
  Yandex__ApiMaps_2_1 = 'enterprise.api-maps.yandex.ru/2.1/',
}
