/* eslint-disable @typescript-eslint/no-explicit-any */

/* TODO: отрефакторить модалку, много логики взято со старых фильтров */
import { useBooleanState } from '@cian/react-utils';
import { ModalWindow } from '@cian/ui-kit';
import { FC, ReactNode } from 'react';

import { IGetRegionResponseSchema } from 'shared/repositories/monolith-python/entities/schemas/GetRegionResponseSchema';
import { useGetCurrentRegionQuery } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-region/query';
import { dataFromGetRegionSelector } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-region/selectors';
import { useGetRegionCities } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-region-cities/query';
import { citiesFromGetRegionCitiesSelector } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-region-cities/selectors';
import { useGetRegionsQuery } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-regions/query';
import { regionsFromGetRegionsSelector } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-regions/selectors';
import { useCurrentLocation } from 'shared/utils/hooks/useCurrentLocation';
import { useFilters } from 'shared/utils/hooks/useFilters';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';

import { ModalFooterWrapper } from '../common/ModalFooterWrapper';
import { ModalGeoSuggest } from '../common/ModalGeoSuggest';

import { RegionModalFooterView } from './RegionModalFooterView';
import { Areas } from './areas';
import { Breadcrumbs } from './breadcrumb';
import { Capitals } from './capitals';
import { RegionModalProps } from './types';

import styles from './styles.css';

export const RegionModal: FC<RegionModalProps> = props => {
  const { open, onClose } = props;

  const { data: region } = useGetCurrentRegionQuery({ select: dataFromGetRegionSelector });
  const { data: cities } = useGetRegionCities({ select: citiesFromGetRegionCitiesSelector });
  const { data: regions } = useGetRegionsQuery({ select: regionsFromGetRegionsSelector });
  const { state: isShowAllRegions, setTrue: showAllRegions, setFalse: hideAllRegions } = useBooleanState(false);

  const filters = useFilters();

  const currentLocation = useCurrentLocation();
  const { changeRegionAction } = useFiltersActions();

  const onCloseModal = (): void => {
    hideAllRegions();
    onClose();
  };

  const onSelectItem = (item: any): void => {
    hideAllRegions();
    changeRegionAction([item.id]);
  };

  const onRegionSelected = (item: any): void => {
    hideAllRegions();

    if (item.id) {
      changeRegionAction([item.id]);

      return;
    }

    if (item === 'moscow_mo') {
      changeRegionAction([1, 4593]);

      return;
    }

    if (item === 'spb_lo') {
      changeRegionAction([2, 4588]);

      return;
    }
  };

  const onCountrySelected = (): void => showAllRegions();
  const onSelectGeoItem = (): void => hideAllRegions();

  if (!cities?.length || !regions?.length || !region) {
    return null;
  }

  const currentRegion = regions.find(
    (regionItem: IGetRegionResponseSchema) => (region.parentId ?? region.id) === regionItem.id,
  );
  const currentRegionName = currentRegion?.displayName;

  const renderCities = (): ReactNode => {
    return (
      <Areas
        areas={filters.region.length > 1 ? [] : cities}
        selectedCity={currentLocation}
        selectedRegion={filters.region}
        onRegionSelected={(): void => {}}
        onSelected={onSelectItem}
      />
    );
  };

  const isMoscow_mo = filters.region.includes(1) && filters.region.includes(4593);
  const isSpb_lo = filters.region.includes(2) && filters.region.includes(4588);

  /**
   * @todo Отрефакторить/Удалить костыль с 'moscow_mo' и 'spb_lo'
   * @description Данный функционал появился в задаче CD-132830, будет удалён в задаче CD-133396
   */
  const selectedRegion = isMoscow_mo
    ? 'moscow_mo'
    : isSpb_lo
    ? 'spb_lo'
    : { displayName: region.displayName, id: region?.parentId ?? region?.id };

  const renderRegions = (): ReactNode => {
    return <Areas areas={regions} onRegionSelected={(): void => {}} onSelected={onRegionSelected} />;
  };

  const renderBreadcrumbs = (): ReactNode => {
    /**
     * @todo Отрефакторить/Удалить костыль с 'moscow_mo' и 'spb_lo'
     * @description Данный функционал появился в задаче CD-132830, будет удалён в задаче CD-133396
     */
    const selectedRegionLocal = isMoscow_mo ? 'moscow_mo' : isSpb_lo ? 'spb_lo' : region?.parentId ?? region?.id;

    return (
      <Breadcrumbs
        selectedCity={undefined}
        selectedRegion={isShowAllRegions ? undefined : selectedRegionLocal}
        selectedRegionName={isShowAllRegions ? undefined : currentRegionName}
        onCountrySelected={onCountrySelected}
        onRegionSelected={onRegionSelected}
      />
    );
  };

  return (
    <ModalWindow
      fixed
      footer={
        <ModalFooterWrapper>
          <RegionModalFooterView showPlaceholder={isShowAllRegions} onShowClick={onClose} />
        </ModalFooterWrapper>
      }
      header={
        <div>
          <div className={styles['geo-container']}>
            <ModalGeoSuggest onSelectItem={onSelectGeoItem} />
          </div>
          {renderBreadcrumbs()}
        </div>
      }
      open={open}
      size="M"
      width={1200}
      onClose={onCloseModal}
    >
      <div className={styles['cities-container']}>
        <Capitals
          regions={regions}
          selectedCity={isShowAllRegions ? undefined : currentLocation}
          selectedRegion={isShowAllRegions ? undefined : selectedRegion}
          onRegionSelected={onRegionSelected}
        />

        {isShowAllRegions ? renderRegions() : renderCities()}
      </div>
    </ModalWindow>
  );
};
