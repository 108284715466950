import { Checkbox } from '@cian/ui-kit';
import { useMemo } from 'react';

import { IOption } from 'shared/components/Select/models/Option';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { amenityOptions } from './constants/amenityOptions';
import { infrastructureOptions } from './constants/infrastructureOptions';
import { HandleChange, MultipleCheckboxFilterProps } from './types';

import styles from './styles.css';

export const OPTIONS_BY_FILTER = {
  [EAdvancedFilterType.amenity]: amenityOptions,
  [EAdvancedFilterType.infrastructure]: infrastructureOptions,
};

export const MultipleCheckboxFilter = memoWithDisplayName<MultipleCheckboxFilterProps>(props => {
  const { filterName } = props;

  const filterValue: Array<number> | undefined = useFilter(filterName);

  const { toggleTermsFilterValue } = useFiltersActions();

  const options: IOption[] = useMemo(() => OPTIONS_BY_FILTER[filterName], [filterName]);

  const handleChange: HandleChange = option => () => toggleTermsFilterValue({ name: filterName, value: option.value });

  return (
    <div className={styles['container']}>
      {options.map(option => (
        <Checkbox
          checked={!!filterValue?.includes(option.value)}
          key={`option_${option.value}_${option.label}`}
          label={option.label}
          onChange={handleChange(option)}
        />
      ))}
    </div>
  );
}, 'MultipleCheckboxFilter');
