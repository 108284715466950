import { useCallback } from 'react';

import { useSearchOffersMetaQueryLegacy } from 'shared/store/serverSide/search-offers-index/v2/get-meta/query';
import { dataFromFetchOffersSearchMetaSelector } from 'shared/store/serverSide/search-offers-index/v2/get-meta/selectors';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { FindButton } from './FindButton';
import { FindButtonContainerProps } from './types';
import { trackSearchButtonClick } from './utils/tracks';

export const FindButtonContainer = memoWithDisplayName<FindButtonContainerProps>(props => {
  const { loading, label, theme = 'fill_primary', onClick, buttonType } = props;

  const { refetch } = useSearchOffersMetaQueryLegacy({ select: dataFromFetchOffersSearchMetaSelector });

  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const onButtonClick = useCallback(() => {
    setTargetOffersCountTooltip(null);
    refetch();
    trackSearchButtonClick(buttonType);

    if (onClick) {
      onClick();
    }
  }, [setTargetOffersCountTooltip, refetch, buttonType, onClick]);

  return <FindButton label={label} loading={loading} theme={theme} onClick={onButtonClick} />;
}, 'FindButtonContainer');
