import { EBuildingClassType } from 'shared/types/filters/buildingClassType';

export const builidingClassTypeMap = {
  [EBuildingClassType.A]: 'A',
  [EBuildingClassType.APlus]: 'A+',
  [EBuildingClassType.B]: 'B',
  [EBuildingClassType.BPlus]: 'B+',
  [EBuildingClassType.C]: 'C',
  [EBuildingClassType.CPlus]: 'C+',
  [EBuildingClassType.D]: 'D',
  [EBuildingClassType.BMinus]: 'B-',
};

export const BUILDING_CLASS_TYPES = [
  EBuildingClassType.A,
  EBuildingClassType.B,
  EBuildingClassType.C,
  EBuildingClassType.D,
];

export const BUILDING_A_CLASS_TYPES = [EBuildingClassType.A, EBuildingClassType.B, EBuildingClassType.C];
