import { REGIONS_WITH_UNDERGROUND } from 'shared/constants/filters/regions';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { Filters } from 'shared/types/filters';

import { IFiltersService } from '../../IFiltersService';

export abstract class AbstractAdvancedFiltersService implements IFiltersService<EAdvancedFilterType> {
  public readonly filters: Filters;

  public abstract isAvailable(): boolean;

  public constructor(filters: Filters) {
    this.filters = filters;
  }

  public getFields(): EAdvancedFilterType[] {
    const fields = [
      EAdvancedFilterType.publishPeriod,
      EAdvancedFilterType.withPhoto,
      EAdvancedFilterType.undergroundTime,
      EAdvancedFilterType.geo,
      EAdvancedFilterType.coworking,
      EAdvancedFilterType.bsCenter,
      EAdvancedFilterType.idUser,
      EAdvancedFilterType.containWords,
      EAdvancedFilterType.excludeWords,
      EAdvancedFilterType.contact,
      EAdvancedFilterType.id,
    ];

    if (this.filters.region.some(reg => !REGIONS_WITH_UNDERGROUND.includes(reg))) {
      return fields.filter(field => field !== EAdvancedFilterType.undergroundTime);
    }

    return fields;
  }
}
