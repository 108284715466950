import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IGetMetaResponse } from 'shared/repositories/search-offers-index/v2/get-meta';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { useJsonQuery } from 'shared/utils/hooks/useJsonQuery';
import { useSaveSearchJsonQuery } from 'shared/utils/hooks/useSaveSearchJsonQuery';

import { QueryError, QueryKey, QueryKeyLegacy, getQueryKey, getQueryKeyLegacy } from './internal/config';
import { fetchOffersSearchMetaService } from './internal/fetchService';

/**
 * @description используется для получения данных для Сохраненного поиска
 */
export function useFetchSaveSearchMetaQuery<TData = IGetMetaResponse>(
  options?: UseQueryOptions<IGetMetaResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();
  const jsonQuery = useSaveSearchJsonQuery();

  return useQuery<IGetMetaResponse, QueryError, TData, QueryKey>({
    enabled: false,
    queryFn: () => fetchOffersSearchMetaService(context, { jsonQuery }),
    queryKey: getQueryKey(),
    retry: 1,
    ...options,
  });
}

/**
 * @deprecated Будет удалено в рамках COMM-1094, когда будем отказываться от использования меты
 */
export function useSearchOffersMetaQueryLegacy<TData = IGetMetaResponse>(
  options?: UseQueryOptions<IGetMetaResponse, QueryError, TData, QueryKeyLegacy>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();
  const jsonQuery = useJsonQuery();

  return useQuery<IGetMetaResponse, QueryError, TData, QueryKeyLegacy>({
    queryFn: () => fetchOffersSearchMetaService(context, { jsonQuery }),
    queryKey: getQueryKeyLegacy(),
    ...options,
    enabled: false,
  });
}
