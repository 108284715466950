export enum EOfficeType {
  Office = 1,
  TradeArea = 2,
  Warehouse = 3,
  PublicCatering = 4,
  FreeAppointmentObject = 5,
  Manufacture = 7,
  AutoService = 9,
  Business = 10,
  Building = 11,
  DomesticServices = 12,
  Coworking = 13,
  CommercialLand = 14,
  Workplace = 15,
  RentalBusiness = 16,
  ReadyBusiness = 17,
}

export type OfficeType = {
  label: string;
  value: EOfficeType;
  shortLabel?: string;
  description?: string;
};
