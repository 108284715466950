import { useCallback, useRef } from 'react';

import { OffersCountTooltip } from 'shared/components/OffersCountTooltip';
import { ELabelView } from 'shared/components/PopupFilterControlled/types';
import { RangeInputContainer } from 'shared/components/RangeInputContainer';
import { EFilterType } from 'shared/enums/EFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { PopupFilterControlled } from '../../PopupFilterControlled';
import { PriceTypeFilter } from '../PriceTypeFilter';

import { getPriceLabel } from './internal/helpers/getPriceLabel';

import styles from './PriceFilter.css';

export const PriceFilter = memoWithDisplayName(() => {
  const price = useFilter(EFilterType.price);
  const { changePriceMinAction, changePriceMaxAction } = useFiltersActions();
  const { openedAtCurrentTarget } = useOffersCountTooltip(EFilterType.price);
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const containerRef = useRef(null);

  const handleChangeMin = useCallback(
    (value: number) => {
      setTargetOffersCountTooltip(EFilterType.price);
      changePriceMinAction(value);
    },
    [changePriceMinAction, setTargetOffersCountTooltip],
  );

  const handleChangeMax = useCallback(
    (value: number) => {
      setTargetOffersCountTooltip(EFilterType.price);
      changePriceMaxAction(value);
    },
    [changePriceMaxAction, setTargetOffersCountTooltip],
  );

  const content = (
    <OffersCountTooltip hostElement={containerRef.current} opened={openedAtCurrentTarget} placement="right">
      <div className={styles['container']}>
        <RangeInputContainer
          isFormattedInput
          postfix={'\u20bd'}
          valueMax={price?.max}
          valueMin={price?.min}
          onChangeMax={handleChangeMax}
          onChangeMin={handleChangeMin}
        />
        <div className={styles['postfix']}>
          <PriceTypeFilter />
        </div>
      </div>
    </OffersCountTooltip>
  );

  const priceLabel = getPriceLabel(price?.min ?? null, price?.max ?? null, `\u20bd`);

  return (
    <div ref={containerRef}>
      <PopupFilterControlled
        filterType={EFilterType.price}
        label={priceLabel}
        labelView={ELabelView.Button}
        placeholder="Цена"
        popupContent={content}
      />
    </div>
  );
}, 'PriceFilter');

PriceFilter.displayName = 'PriceFilter';
