import { KeyboardEvent, useCallback } from 'react';

export const useTagOnKeyDown = (callback: () => void): ((e: KeyboardEvent<HTMLDivElement>) => void) =>
  useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        callback();
      }
    },
    [callback],
  );
