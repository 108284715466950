import { FilterLink } from 'shared/components/FilterLink';
import { useResidentialLink } from 'shared/utils/hooks/useResidentialLink';

import styles from './styles.css';

export const OfficeTypePostfix: React.FC = () => {
  const residentialLink = useResidentialLink();

  return (
    <div className={styles['container']}>
      <FilterLink href={residentialLink}>Жилая</FilterLink>
    </div>
  );
};

OfficeTypePostfix.displayName = 'OfficeTypePostfix';
