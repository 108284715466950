export enum EAdvancedFilterType {
  totalArea = 'totalArea',
  withPhoto = 'withPhoto',
  publishPeriod = 'publishPeriod',
  specialtyTypes = 'specialtyTypes',
  undergroundTime = 'undergroundTime',
  coworking = 'coworking',
  bsCenter = 'bsCenter',
  geo = 'geo',
  contract = 'contract',
  buildingClassType = 'buildingClassType',
  floor = 'floor',
  floorn = 'floorn',
  inputType = 'inputType',
  furniture = 'furniture',
  isSeparatedInputType = 'isSeparatedInputType',
  buildingLiftTypesType = 'buildingLiftTypesType',
  buildingCranageTypesType = 'buildingCranageTypesType',
  buildingGatesType = 'buildingGatesType',
  buildingHeatingType = 'buildingHeatingType',
  conditionType = 'conditionType',
  amenity = 'amenity',
  coworkingAccess = 'coworkingAccess',
  buildingHouseLine = 'buildingHouseLine',
  floorTypes = 'floorTypes',
  placementType = 'placementType',
  houseYear = 'houseYear',
  parkingType = 'parkingType',
  infrastructure = 'infrastructure',
  idUser = 'idUser',
  containWords = 'containWords',
  excludeWords = 'excludeWords',
  contact = 'contact',
  estateTypes = 'estateTypes',
  id = 'id',
  accessSystem = 'accessSystem',
  commissionType = 'commissionType',
  withoutAgentCall = 'withoutAgentCall',
  buildingType = 'buildingType',
  electronicTrading = 'electronicTrading',
}
