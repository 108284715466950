import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import {
  AnyAnyAdvancedFiltersService,
  AnyBuildingAdvancedFiltersService,
  AnyBuildingAndBusinessAdvancedFiltersService,
  AnyFAOAndOtherAdvancedFiltersService,
  AnyManufactureAdvancedFiltersService,
  AnyOfficeAdvancedFiltersService,
  AnyOfficeAndManyOtherAdvancedFiltersService,
  AnyOfficeTradeAreaAdvancedFiltersService,
  AnyTradeAreaAdvancedFiltersService,
  AnyWarehouseAdvancedFiltersService,
  AnyWarehouseOrManufactureAndOtherAdvancedFiltersService,
  FiltersFacadeService,
  RentCommercialLandAdvancedFiltersService,
  RentCoworkingAdvancedFiltersService,
  SaleBusinessAdvancedFiltersService,
  SaleCommercialLandAdvancedFiltersService,
} from 'shared/services/FiltersService';
import { Filters } from 'shared/types/filters';

export function getAvailableAdvancedFilters(filters: Filters): Array<EAdvancedFilterType> {
  const currentFilterService = FiltersFacadeService.findCurrentFiltersService(
    () => new SaleBusinessAdvancedFiltersService(filters),
    () => new SaleCommercialLandAdvancedFiltersService(filters),
    () => new RentCoworkingAdvancedFiltersService(filters),
    () => new RentCommercialLandAdvancedFiltersService(filters),
    () => new AnyBuildingAdvancedFiltersService(filters),
    () => new AnyManufactureAdvancedFiltersService(filters),
    () => new AnyWarehouseAdvancedFiltersService(filters),
    () => new AnyTradeAreaAdvancedFiltersService(filters),
    () => new AnyOfficeAdvancedFiltersService(filters),
    () => new AnyBuildingAndBusinessAdvancedFiltersService(filters),
    () => new AnyFAOAndOtherAdvancedFiltersService(filters),
    () => new AnyOfficeTradeAreaAdvancedFiltersService(filters),
    () => new AnyOfficeAndManyOtherAdvancedFiltersService(filters),
    () => new AnyWarehouseOrManufactureAndOtherAdvancedFiltersService(filters),
    () => new AnyAnyAdvancedFiltersService(filters),
  );

  return currentFilterService.getFields();
}
