import { DehydratedState, QueryClient, dehydrate, hydrate } from '@tanstack/react-query';

import type { IServerSideState } from '../IServerSideState';

export class ServerSideState implements IServerSideState {
  public static readonly key = 'serverSideState';

  public readonly queryClient: QueryClient;

  public constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public hydrate(dehydratedState: unknown): void {
    hydrate(this.queryClient, dehydratedState);
  }

  public dehydrate(): DehydratedState {
    return dehydrate(this.queryClient);
  }
}
