import { ModalWindow } from '@cian/ui-kit';
import { FC } from 'react';

import { useApiGeoGetDistrictsTreeQuery } from 'shared/store/serverSide/monolith-cian-realty/api/geo/get-districts-tree/query';
import { useCurrentLocation } from 'shared/utils/hooks/useCurrentLocation';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useSelectedDistrictsIds } from 'shared/utils/hooks/useSelectedDistrictsIds';

import { ModalFooter } from '../common/ModalFooter/ModalFooter';
import { ModalFooterWrapper } from '../common/ModalFooterWrapper';

import { DistrictsModalHeader } from './DistrictsModalHeader';
import { DistrictsLayout } from './layout';
import { DistrictsModalProps } from './types';

export const DistrictsModal: FC<DistrictsModalProps> = props => {
  const { open, onClose } = props;

  const { data: districts } = useApiGeoGetDistrictsTreeQuery();

  const selectedDistricts = useSelectedDistrictsIds();
  const currentLocation = useCurrentLocation();

  const { changeGeoAction } = useFiltersActions();

  const onSelectItem = (id: number, title?: string): void => {
    changeGeoAction({ id, title, type: 'district' });
  };

  return (
    <ModalWindow
      fixed
      footer={
        <ModalFooterWrapper>
          <ModalFooter onShowClick={onClose} />
        </ModalFooterWrapper>
      }
      header={<DistrictsModalHeader />}
      open={open}
      size="M"
      width={1200}
      onClose={onClose}
    >
      <DistrictsLayout
        areas={districts}
        selectedDistricts={selectedDistricts || []}
        selectedLocation={currentLocation}
        onRemoved={onSelectItem}
        onSelected={onSelectItem}
      />
    </ModalWindow>
  );
};

DistrictsModal.displayName = 'DistrictsModal';
