import { IJsonQuery } from 'shared/models/JsonQuery';
import { Filters } from 'shared/types/filters';

export interface IStrategy {
  formatFilters(filters: Filters): IJsonQuery | string;
}

export enum EStrategyType {
  toJsonQuery = 'toJsonQuery',
  toQueryString = 'toQueryString',
}
