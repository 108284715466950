import type { HttpBadStatusCodeError, UnknownError } from '@cian/peperrors/shared';

import { EQueryKeys } from 'shared/store/serverSide/EQueryKeys';

export type QueryError = HttpBadStatusCodeError | UnknownError;

export type QueryKey = [EQueryKeys.MonolithPython__CianApi_Site_V1_SearchRegionsCities, string];
export const getQueryKey = (query: QueryKey[1]): QueryKey => [
  EQueryKeys.MonolithPython__CianApi_Site_V1_SearchRegionsCities,
  query,
];
