import { CommercialFiltersEventsLog } from 'shared/services/CommercialFiltersEventsLog';
import { BrowserServerSideState } from 'shared/services/ServerSideState';
import { projectNameSelector } from 'shared/store/data/rs/selectors/dump/projectNameSelector';

import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';

import { createContext } from './createContext';

export function runApp(): void {
  const context = createContext();

  const store = createApplicationState(context);

  CommercialFiltersEventsLog.init();

  const state = store.getState();

  const projectName = projectNameSelector(state);

  const browserServerSideState = new BrowserServerSideState(context);

  renderApplication({ context, projectName, serverSideState: browserServerSideState, store });
}
