import { QueryClientProvider } from '@tanstack/react-query';
import { FC, ReactNode } from 'react';

import { IServerSideState } from 'shared/services/ServerSideState';

import { ReactQueryDevtools } from './internal/components/ReactQueryDevtools';

type ServerSideStateProviderProps = {
  serverSideState: IServerSideState;
  children: ReactNode;
};

export const ServerSideStateProvider: FC<ServerSideStateProviderProps> = props => {
  const {
    serverSideState: { queryClient },
    children,
  } = props;

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  );
};
