import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EOfficeType, Filters } from 'shared/types/filters';
import { includesManufactureType } from 'shared/utils/filters/includesManufactureType';
import { includesWarehouseOfficeType } from 'shared/utils/filters/includesWarehouseOfficeType';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Производство или склад + другой тип". Не имеет значение аренда или продажа
 */
export class AnyWarehouseOrManufactureAndOtherAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  private isWarehouseOrManufactureAndOtherService = (filters: Filters): boolean =>
    (includesWarehouseOfficeType(this.filters) || includesManufactureType(this.filters)) &&
    filters.officeType.every(officeType =>
      [
        EOfficeType.Office,
        EOfficeType.FreeAppointmentObject,
        EOfficeType.PublicCatering,
        EOfficeType.AutoService,
        EOfficeType.DomesticServices,
        EOfficeType.Warehouse,
        EOfficeType.Manufacture,
        EOfficeType.Building,
        EOfficeType.TradeArea,
      ].includes(officeType),
    );

  public isAvailable(): boolean {
    return this.isWarehouseOrManufactureAndOtherService(this.filters);
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.floor,
        EAdvancedFilterType.floorTypes,
        EAdvancedFilterType.floorn,
        EAdvancedFilterType.buildingClassType,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.buildingLiftTypesType,
        EAdvancedFilterType.buildingCranageTypesType,
        EAdvancedFilterType.buildingGatesType,
        EAdvancedFilterType.buildingHeatingType,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.inputType,
        EAdvancedFilterType.isSeparatedInputType,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.infrastructure,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
