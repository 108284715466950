import { EConditionType } from 'shared/types/filters/advanced/conditionType';

export const mapConditionTypeIndustry: Record<EConditionType, string> = {
  [EConditionType.OfficeDecoration]: 'Офисная отделка',
  [EConditionType.FullDecorationNeeded]: 'Под чистовую отделку',
  [EConditionType.MajorRepairNeeded]: 'Требуется капитальный ремонт',
  [EConditionType.CosmeticRepairNeeded]: 'Требуется коcметический ремонт',
  [EConditionType.RegularRepair]: 'Типовой ремонт',
  [EConditionType.DesignerRepair]: 'Дизайнерский ремонт',
  [EConditionType.WithDecoration]: 'С отделкой',
};
