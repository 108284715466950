import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';

import { setSaveSearchJsonQuery } from './actions';
import { SaveSearchState } from './types';

const initialState: SaveSearchState = {
  jsonQuery: {
    _type: 'commercialsale',
    engine_version: {
      type: 'term',
      value: 2,
    },
    region: {
      type: 'terms',
      value: [1],
    },
  },
};

export const saveSearchReducer = createReducer(initialState).handleAction(setSaveSearchJsonQuery, (state, action) =>
  produce(state, draft => {
    draft.jsonQuery = action.payload;
  }),
);
