import { useCallback, useMemo } from 'react';

import { ALL_AVAILABLE_PARKING_TYPES } from 'shared/constants/filters/allAvailableParkingTypes';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EParkingType } from 'shared/types/filters/advanced/parkingType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { parkingTypeOptions } from '../CommonTermsFilter/internal/constants/parkingTypeOptions';

import { ParkingTypeFilter } from './ParkingTypeFilter';
import { HandleCheckboxChange, HandleFilterChange } from './types';

export const ParkingTypeFilterContainer = memoWithDisplayName(() => {
  const { setParkingType } = useFiltersActions();

  const onFilterChange = useCallback<HandleFilterChange>(
    event => {
      setParkingType(Number(event.target.value));
    },
    [setParkingType],
  );

  const onCheckboxChange = useCallback<HandleCheckboxChange>(
    (_, checked) => {
      if (checked) {
        setParkingType(EParkingType.AllTypes);

        return;
      }

      setParkingType(undefined);
    },
    [setParkingType],
  );

  const parkingTypesFromStore = useFilter(EAdvancedFilterType.parkingType);
  const values = useMemo(() => {
    if (parkingTypesFromStore?.includes(EParkingType.AllTypes)) {
      return ALL_AVAILABLE_PARKING_TYPES;
    }

    return parkingTypesFromStore ?? [];
  }, [parkingTypesFromStore]);

  return (
    <ParkingTypeFilter
      checked={!!values.length}
      options={parkingTypeOptions}
      values={values}
      onCheckboxChange={onCheckboxChange}
      onFilterChange={onFilterChange}
    />
  );
}, 'ParkingTypeFilterContainer');
