import { useCallback } from 'react';

import { RangeInputContainer } from 'shared/components/RangeInputContainer';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useValidateRangeFiltersActions } from 'shared/utils/hooks/useValidateRangeFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const HouseYearFilter = memoWithDisplayName(() => {
  const houseYear = useFilter(EAdvancedFilterType.houseYear);
  const { changeRangeValueAction, validateHouseYearFilterAction } = useFiltersActions();
  const { validateRangeFilterAction } = useValidateRangeFiltersActions();

  const onBlur = useCallback(() => {
    validateRangeFilterAction(EAdvancedFilterType.houseYear);
    validateHouseYearFilterAction('min');
    validateHouseYearFilterAction('max');
  }, [validateRangeFilterAction, validateHouseYearFilterAction]);

  const onRangeChange = useCallback(
    (rangeValueKey: 'min' | 'max') =>
      (value: number): void => {
        changeRangeValueAction({ fieldName: EAdvancedFilterType.houseYear, limit: 9999, rangeValueKey, value });
      },
    [changeRangeValueAction],
  );

  return (
    <RangeInputContainer
      maxWidth={168}
      valueMax={houseYear?.max}
      valueMin={houseYear?.min}
      onBlurMax={onBlur}
      onBlurMin={onBlur}
      onChangeMax={onRangeChange('max')}
      onChangeMin={onRangeChange('min')}
    />
  );
}, 'TotalAreaFilter');
