import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { ICianApiSiteV1GetRegionResponse } from 'shared/repositories/monolith-python/cian-api/site/v1/get-region';
import { fetchGetRegionService } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-region/internal/fetchService';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { useFilters } from 'shared/utils/hooks/useFilters';

import { QueryError, QueryKey, getQueryKey } from './internal/config';

export function useGetCurrentRegionQuery<TData = ICianApiSiteV1GetRegionResponse>(
  options?: UseQueryOptions<ICianApiSiteV1GetRegionResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();
  const { region } = useFilters();

  return useQuery<ICianApiSiteV1GetRegionResponse, QueryError, TData, QueryKey>({
    keepPreviousData: true,
    queryFn: () => fetchGetRegionService(context, { regionId: region[0] }),
    queryKey: getQueryKey(region[0]),
    ...options,
  });
}
