import {
  CheckboxGroup,
  EBehavior,
  NestedCheckboxGroupsDesktop,
  SearchField,
  flatCheckboxGroupsToItems,
} from '@cian/nested-checkbox-groups';
import { useCallback, useMemo, useState } from 'react';
import { batch } from 'react-redux';

import { FilterButton } from 'shared/components/FilterButton';
import { ELabelView, PopupFilterControlled } from 'shared/components/PopupFilterControlled';
import { Option } from 'shared/components/Select/models/Option';
import { Options } from 'shared/components/Select/models/Options';
import { EFilterType } from 'shared/enums/EFilterType';
import { useBusinessAppointmentsSelector } from 'shared/utils/hooks/useBusinessAppointmentsSelector';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';
import { capitalize } from 'shared/utils/string';

import { renderNestedCheckboxFactory } from './internal/helpers/renderNestedCheckboxFactory';
import { renderTitleCheckboxFactory } from './internal/helpers/renderTitleCheckboxFactory';
import { useBusinessAppointmentsCheckboxGroupsSelector } from './internal/hooks/useBusinessAppointmentsCheckboxGroupsSelector';
import { useBusinessAppointmentsFilterBoundActions } from './internal/hooks/useBusinessAppointmentsFilterBoundActions';
import { useBusinessAppointmentsFilterTooltipObserver } from './internal/hooks/useBusinessAppointmentsFilterTooltipObserver';
import { useGetAvailableBusinessAppointmentsItems } from './internal/hooks/useGetAvailableBusinessAppointmentsItems';
import { HandleNestedCheckboxClick, HandleSearchValueChange, HandleTitleCheckboxClick } from './types';

import styles from './BusinessAppointmentsFilter.css';

const placeholder = 'Категория';

export const BusinessAppointmentsFilter = memoWithDisplayName(() => {
  const values = useBusinessAppointmentsSelector();
  const businessAppointmentsCheckboxGroups = useBusinessAppointmentsCheckboxGroupsSelector();
  const availableBusinessAppointmentsItems = useGetAvailableBusinessAppointmentsItems();

  const [searchValue, setSearchValue] = useState('');

  const groups = useMemo<Array<CheckboxGroup>>(
    () =>
      businessAppointmentsCheckboxGroups.map(group => ({
        items: group.items.map(item => ({ label: item.name, value: item.id })),
        label: group.name,
        value: group.groupType,
      })),
    [businessAppointmentsCheckboxGroups],
  );

  const boundActions = useBusinessAppointmentsFilterBoundActions();

  const { setLastChangedElement, lastChangedElementRef, nestedCheckboxGroupsContainerRef, lastChangedElement } =
    useBusinessAppointmentsFilterTooltipObserver();

  const label = useMemo<string>(() => {
    const options = new Options(
      flatCheckboxGroupsToItems(groups).map(item => new Option(item.value, item.label)),
      placeholder,
    );

    return capitalize(options.getLabel(new Set(availableBusinessAppointmentsItems?.availableIds)));
  }, [availableBusinessAppointmentsItems?.availableIds, groups]);

  const handleNestedCheckboxClick = useCallback<HandleNestedCheckboxClick>(
    checkboxGroupsItemChangeModel => {
      setLastChangedElement([
        checkboxGroupsItemChangeModel.checkboxGroupItemChangeModel.checkboxGroupChangeModel.group.value,
        checkboxGroupsItemChangeModel.checkboxGroupItemChangeModel.item.value,
      ]);

      batch(() => {
        boundActions.setTargetOffersCountTooltip(EFilterType.BusinessAppointments);
        boundActions.setBusinessAppointmentsAction(
          checkboxGroupsItemChangeModel.checkboxGroupItemChangeModel.checkboxGroupChangeModel.allValues,
        );
      });
    },
    [boundActions, setLastChangedElement],
  );

  const handleTitleCheckboxClick = useCallback<HandleTitleCheckboxClick>(
    checkboxGroupsChangeModel => {
      setLastChangedElement([checkboxGroupsChangeModel.checkboxGroupChangeModel.group.value, null]);

      batch(() => {
        boundActions.setTargetOffersCountTooltip(EFilterType.BusinessAppointments);
        boundActions.setBusinessAppointmentsAction(checkboxGroupsChangeModel.checkboxGroupChangeModel.allValues);
      });
    },
    [boundActions, setLastChangedElement],
  );

  const handleSearchValueChange = useCallback<HandleSearchValueChange>((_event, value) => setSearchValue(value), []);

  const renderNestedCheckbox = useMemo(
    () => renderNestedCheckboxFactory(lastChangedElement, lastChangedElementRef),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastChangedElement],
  );

  const renderTitleCheckbox = useMemo(
    () => renderTitleCheckboxFactory(lastChangedElement, lastChangedElementRef),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastChangedElement],
  );

  if (!availableBusinessAppointmentsItems) {
    return <FilterButton onClick={(): void => {}}>{placeholder}</FilterButton>;
  }

  return (
    <PopupFilterControlled
      count={availableBusinessAppointmentsItems.availableIds.length}
      filterType={EFilterType.BusinessAppointments}
      label={label}
      labelView={ELabelView.Button}
      popupContent={
        <div className={styles['container']}>
          <div className={styles['search-field-wrapper']}>
            <SearchField value={searchValue} onChange={handleSearchValueChange} />
          </div>
          <div className={styles['nested-checkbox-groups']} ref={nestedCheckboxGroupsContainerRef}>
            <NestedCheckboxGroupsDesktop
              behavior={EBehavior.Multiply}
              groups={groups}
              renderNestedCheckbox={renderNestedCheckbox}
              renderTitleCheckbox={renderTitleCheckbox}
              searchValue={searchValue}
              values={values}
              onNestedCheckboxClick={handleNestedCheckboxClick}
              onTitleCheckboxClick={handleTitleCheckboxClick}
            />
          </div>
        </div>
      }
    />
  );
}, 'BusinessAppointmentsFilter');
