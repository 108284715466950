import { ca } from '@cian/analytics';

import { EButtonType } from '../types';

export const trackSearchButtonClick = (buttonType: EButtonType): void => {
  ca('eventSite', {
    action: 'click',
    category: buttonType,
    label: '',
    name: 'oldevent',
    page: {
      pageType: 'Listing',
    },
  });
};
