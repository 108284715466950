import { EFilterType } from 'shared/enums/EFilterType';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { isRent } from 'shared/utils/filters/isRent';
import { isSingleOfficeType } from 'shared/utils/filters/isSingleOfficeType';

import { IFiltersService } from '../IFiltersService';

import { AbstractFiltersService } from './AbstractFiltersService';

export class RentCoworkingFiltersService extends AbstractFiltersService implements IFiltersService<EFilterType> {
  public isAvailable(): boolean {
    return isRent(this.filters) && isSingleOfficeType(this.filters) && hasCoworkingOfficeType(this.filters);
  }

  public getFields(): EFilterType[] {
    return super.getFields().concat(EFilterType.workplaceCount, EFilterType.price);
  }
}
