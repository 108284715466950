import {
  IGetMultipleExperimentRequest,
  IGetMultipleExperimentResponse,
  IGetMultipleExperimentResponse200,
  IGetMultipleExperimentResponse400,
  createGetMultipleExperimentModel,
  fetchGetMultipleExperiment,
} from 'shared/repositories/ab-use/v1/get-multiple-experiment';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import type { IApplicationContext } from 'shared/types/applicationContext';

export function fetchGetMultipleExperimentService(
  applicationContext: IApplicationContext,
  parameters: IGetMultipleExperimentRequest,
): Promise<IGetMultipleExperimentResponse> {
  const { httpApi, logger } = applicationContext;

  const fetchAssertsService = new FetchAssertsService(
    createGetMultipleExperimentModel(parameters),
    'src/shared/store/serverSide/ab-use/v1/get-multiple-experiment/internal/fetchService.ts',
    logger,
  );

  return fetchGetMultipleExperiment<IGetMultipleExperimentResponse200, IGetMultipleExperimentResponse400>({
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        case 400:
          throw fetchAssertsService.assert400StatusCodeError(result);
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
