import { QueryClient } from '@tanstack/react-query';

import { EFilterType } from 'shared/enums/EFilterType';
import { Filters } from 'shared/types/filters';
import { getAvailableAdvancedFilters } from 'shared/utils/filters/getAvailableAdvancedFilters';
import { getAvailableFilters } from 'shared/utils/filters/getAvailableFilters';

import { IJsonQuery } from '../JsonQuery';

import { MetaStrategyContext } from './strategies/MetaStrategyContext';
import { EStrategyType } from './strategies/types';

export class FilterSerializer {
  private filters: Filters;
  private readonly queryClient: QueryClient;

  public constructor(filters: Filters, queryClient: QueryClient) {
    this.filters = filters;
    this.queryClient = queryClient;
  }

  public serialize<S extends EStrategyType>(strategy: S): S extends EStrategyType.toJsonQuery ? IJsonQuery : string;
  public serialize(strategy: EStrategyType): IJsonQuery | string {
    this.validateFilters();

    const metaStrategy = new MetaStrategyContext(strategy, this.queryClient);

    return metaStrategy.formatFilters(this.filters);
  }

  private validateFilters(): void {
    const availableFilters = getAvailableFilters(this.filters);
    const availableAdvancedFilters = getAvailableAdvancedFilters(this.filters);

    const allAvailableFilters = [...availableFilters, ...availableAdvancedFilters];

    const entriesWithAvailableKeys = Object.entries(this.filters).filter(([key]) => {
      return key === 'offerType' || key === 'engineVersion' || allAvailableFilters.includes(key as EFilterType);
    });

    this.filters = Object.fromEntries(entriesWithAvailableKeys) as Filters;
  }
}
