import {
  IApiGeoGetDistrictsTreeRequest,
  IApiGeoGetDistrictsTreeResponse,
  IApiGeoGetDistrictsTreeResponse200,
  createApiGeoGetDistrictsTreeModel,
  fetchApiGeoGetDistrictsTree,
} from 'shared/repositories/monolith-cian-realty/api/geo/get-districts-tree';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export async function fetchApiGeoGetDistrictsTreeService(
  dependencies: IApplicationContext,
  parameters: IApiGeoGetDistrictsTreeRequest,
): Promise<IApiGeoGetDistrictsTreeResponse> {
  const {
    httpApi,
    custom: { subdomain },
    logger,
  } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createApiGeoGetDistrictsTreeModel(parameters),
    'src/shared/store/serverSide/monolith-cian-realty/api/geo/get-districts-tree/internal/fetchService.ts',
    logger,
  );

  return fetchApiGeoGetDistrictsTree<IApiGeoGetDistrictsTreeResponse200>({
    config: { subdomain },
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
