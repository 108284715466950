import { Option } from 'shared/components/Select/models/Option';
import { amenityMapLabel } from 'shared/constants/filters/amenity';
import { EAmenityType } from 'shared/types/filters/advanced/amenities';

export const amenityOptions: Option<EAmenityType>[] = [
  new Option(EAmenityType.Wifi, amenityMapLabel[EAmenityType.Wifi]),
  new Option(EAmenityType.BikeParking, amenityMapLabel[EAmenityType.BikeParking]),
  new Option(EAmenityType.Internet, amenityMapLabel[EAmenityType.Internet]),
  new Option(EAmenityType.RestZone, amenityMapLabel[EAmenityType.RestZone]),
  new Option(EAmenityType.Cafe, amenityMapLabel[EAmenityType.Cafe]),
  new Option(EAmenityType.Cleaning, amenityMapLabel[EAmenityType.Cleaning]),
  new Option(EAmenityType.Kitchen, amenityMapLabel[EAmenityType.Kitchen]),
  new Option(EAmenityType.Furniture, amenityMapLabel[EAmenityType.Furniture]),
  new Option(EAmenityType.Security, amenityMapLabel[EAmenityType.Security]),
  new Option(EAmenityType.Parking, amenityMapLabel[EAmenityType.Parking]),
  new Option(EAmenityType.MeetingRoom, amenityMapLabel[EAmenityType.MeetingRoom]),
  new Option(EAmenityType.Printer, amenityMapLabel[EAmenityType.Printer]),
  new Option(EAmenityType.Reception, amenityMapLabel[EAmenityType.Reception]),
  new Option(EAmenityType.Modern, amenityMapLabel[EAmenityType.Modern]),
  new Option(EAmenityType.Gym, amenityMapLabel[EAmenityType.Gym]),
  new Option(EAmenityType.PhoneBooth, amenityMapLabel[EAmenityType.PhoneBooth]),
  new Option(EAmenityType.TechSupport, amenityMapLabel[EAmenityType.TechSupport]),
  new Option(EAmenityType.Events, amenityMapLabel[EAmenityType.Events]),
  new Option(EAmenityType.Fax, amenityMapLabel[EAmenityType.Fax]),
  new Option(EAmenityType.Flipchart, amenityMapLabel[EAmenityType.Flipchart]),
  new Option(EAmenityType.Fruits, amenityMapLabel[EAmenityType.Fruits]),
  new Option(EAmenityType.Tea, amenityMapLabel[EAmenityType.Tea]),
  new Option(EAmenityType.Lockers, amenityMapLabel[EAmenityType.Lockers]),
  new Option(EAmenityType.LegalAddress, amenityMapLabel[EAmenityType.LegalAddress]),
];
