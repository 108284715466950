export enum EInfrastructureItem {
  CarWash = 1,
  CarService,
  Pharmacy,
  ClothesStudio,
  ATM,
  Pool,
  Buffet,
  WarehouseComplex,
  Hotel,
  Cafe,
  Cinema,
  ConferenceRoom,
  MedicalCenter,
  Minimarket,
  NotaryOffice,
  Department,
  Park,
  Restaurant,
  BeautyShop,
  Warehouse,
  Canteen,
  Supermarket,
  ShoppingArea,
  FitnessCentre,
  Studio,
  CentralReception,
}
