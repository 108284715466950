import { Checkbox, TooltipDesktop, UIText2 } from '@cian/ui-kit';
import { Faq16 } from '@cian/ui-kit/prev';

import { RenderOption } from 'shared/components/Select';

import styles from './styles.css';

export const renderOption: RenderOption = props => {
  const { checked, label, description } = props;

  return (
    <div className={styles['option-item']}>
      <Checkbox checked={checked} label={label} />
      {description && (
        <TooltipDesktop
          placement="top-end"
          title={
            <div className={styles['tooltip-content']}>
              <UIText2 color="white_100" textAlign="left">
                {description}
              </UIText2>
            </div>
          }
        >
          <Faq16 />
        </TooltipDesktop>
      )}
    </div>
  );
};
