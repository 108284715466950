/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-161150, будет удалён в задаче CD-161151
 * Удалить если эксперимент будет не удачным
 * Оставить, если будет удачным, написать тесты
 */

import { useBooleanState } from '@cian/react-utils';
import { useCallback, useEffect } from 'react';

import { trackMotivationTooltipClosed, trackMotivationTooltipShown } from '../tracking';

const itemKey = 'form_owner_tooltip_info_shown';

export function useFormOwnerMotivationTooltipState(): [boolean, VoidFunction] {
  const { state, setTrue, setFalse } = useBooleanState();

  useEffect(() => {
    const item = sessionStorage.getItem(itemKey);

    if (item == null) {
      sessionStorage.setItem(itemKey, '1');

      trackMotivationTooltipShown();

      setTrue();
    }
  });

  const hide = useCallback<VoidFunction>(() => {
    trackMotivationTooltipClosed();

    setFalse();
  }, [setFalse]);

  return [state, hide];
}
