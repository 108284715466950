import { ReactNode } from 'react';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';

import { IFilter } from '../IFilter';

export class AdvancedFilter implements IFilter<EAdvancedFilterType> {
  public readonly key: EAdvancedFilterType;
  public readonly component: ReactNode;

  public constructor(key: EAdvancedFilterType, component: ReactNode) {
    this.key = key;
    this.component = component;
  }
}
