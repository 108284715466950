import { Children, forwardRef } from 'react';

import { ITagProps } from '../Tag/types';
import { TagsList } from '../TagsList';

import styles from './Tags.css';

interface ITagsProps {
  forwardedRef: React.Ref<HTMLDivElement>;
  children: React.ReactNode;
}

const TagsComponent: React.FC<ITagsProps> = ({ forwardedRef, children }) => {
  const tags = Children.toArray(children) as React.ReactElement<ITagProps>[];

  return (
    <div className={styles['container']} ref={forwardedRef}>
      <TagsList tags={tags} />
    </div>
  );
};

export const Tags = forwardRef<HTMLDivElement, Omit<React.PropsWithChildren<ITagsProps>, 'forwardedRef'>>(
  (props, ref) => <TagsComponent forwardedRef={ref} {...props} />,
);

Tags.displayName = 'Tags';
