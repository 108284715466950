import { RenderTitleCheckboxProps } from '@cian/nested-checkbox-groups';
import { ReactNode, RefObject } from 'react';

import { OffersCountTooltip } from 'shared/components/OffersCountTooltip';

type RenderTitleCheckboxFactory = (
  lastChangedElement: [null | string, null | number],
  ref: RefObject<HTMLDivElement>,
) => (props: RenderTitleCheckboxProps) => ReactNode;

// eslint-disable-next-line react/display-name
export const renderTitleCheckboxFactory: RenderTitleCheckboxFactory = (lastChangedElement, ref) => props => {
  const { group, children } = props;

  const isLastChangedElement = group.value === lastChangedElement[0] && lastChangedElement[1] == null;

  return (
    <OffersCountTooltip portal opened={isLastChangedElement} placement="right">
      <div ref={isLastChangedElement ? ref : undefined}>{children}</div>
    </OffersCountTooltip>
  );
};
