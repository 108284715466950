import { IInputProps, INumberInputProps, Input, NumberInput } from '@cian/ui-kit';

export const inputFactory = (formattedNumber: boolean = false) => {
  return function InnerInput(props: INumberInputProps | IInputProps): JSX.Element {
    if (formattedNumber) {
      return (
        <NumberInput
          data-testid="number-input"
          {...(props as INumberInputProps)}
          value={Number(props.value) || undefined}
        />
      );
    }

    return <Input data-testid="input" type="text" {...(props as IInputProps)} />;
  };
};
