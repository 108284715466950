import { ArticleParagraph2 } from '@cian/ui-kit';
import cn from 'clsx';
import { Ref, forwardRef } from 'react';

import { FilterButtonProps } from '../FilterButton/types';

import styles from './FilterTextLabel.css';

export const FilterTextLabel = forwardRef(function FilterTextLabel(
  props: FilterButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const { children, onClick, isActive } = props;

  return (
    <div className={cn(styles['container'], { [styles['active']]: isActive })}>
      <ArticleParagraph2 color="primary_100" ref={ref} onClick={onClick}>
        {children}
      </ArticleParagraph2>
    </div>
  );
});

FilterTextLabel.displayName = 'FilterTextLabel';
