import { useSelector } from 'react-redux';

import { IJsonQuery } from 'shared/models/JsonQuery';
import { jsonQuerySaveSearchSelector } from 'shared/store/data/saveSearch/selectors/dump/jsonQuerySaveSearchSelector';

export const useSaveSearchJsonQuery = (): IJsonQuery => {
  const jsonQuery = useSelector(jsonQuerySaveSearchSelector);

  return jsonQuery;
};
