import cn from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';

import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';

import { OffersCountTooltip } from '../OffersCountTooltip';

import { defaultOptionRenderer } from './renderers';
import { HandleOptionChange, SelectProps } from './types';

import styles from './Select.css';

export const Select: FC<SelectProps> = props => {
  const {
    options,
    values,
    onOptionChange,
    isLastElementCanBeSelected = true,
    withScroll,
    withTooltip,
    postfix,
    elementsWithBorder,
    maxHeight = 345,
    isFullfiledWhenEmpty,
    renderOption = defaultOptionRenderer,
  } = props;

  const initialTooltipIndex = options.list.findIndex(value => value.checkIsActive(values));

  const [tooltipIndex, setTooltipIndex] = useState<number | null>(initialTooltipIndex);

  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();
  const { opened } = useOffersCountTooltip();
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const listElement = ref.current;
    const handleScroll: VoidFunction = () => setTargetOffersCountTooltip(null);

    listElement?.addEventListener('scroll', handleScroll, false);

    return () => {
      listElement?.removeEventListener('scroll', handleScroll, false);
    };
  }, [setTargetOffersCountTooltip]);

  const handleOptionChange: HandleOptionChange = (option, index) => (): void => {
    if (!isLastElementCanBeSelected && values.size <= 1 && values.has(option.value)) {
      return;
    }

    setTooltipIndex(index);
    onOptionChange(option);
  };

  const listItems = options.list.reduce((accumulator, option, index) => {
    const isOffersCountPopupOpened = tooltipIndex === index && opened;

    const isValueChecked =
      option.checkIsActive(values) ||
      (!!isFullfiledWhenEmpty && !values.size && !elementsWithBorder?.has(option.value));

    if (withTooltip) {
      accumulator.push(
        <OffersCountTooltip
          hostElement={props.hostElement}
          key={option.value}
          opened={isOffersCountPopupOpened}
          placement="right"
        >
          <li
            className={cn(styles['list-item'], { [styles['separated']]: elementsWithBorder?.has(option.value) })}
            data-testid="list-item"
            onClick={handleOptionChange(option, index)}
          >
            {renderOption({ checked: isValueChecked, description: option.description, label: option.label })}
          </li>
        </OffersCountTooltip>,
      );
    } else {
      accumulator.push(
        <li
          className={cn(styles['list-item'], { [styles['separated']]: elementsWithBorder?.has(option.value) })}
          data-testid="list-item"
          key={option.value}
          onClick={handleOptionChange(option, index)}
        >
          {renderOption({ checked: isValueChecked, description: option.description, label: option.label })}
        </li>,
      );
    }

    return accumulator;
  }, Array.of<JSX.Element>());

  return (
    <div className={styles['container']}>
      {!!listItems.length && (
        <ul
          className={cn(styles['list'], { [styles['with-scroll']]: withScroll })}
          data-testid="select-list"
          ref={ref}
          style={{ maxHeight: withScroll ? maxHeight : undefined }}
        >
          {listItems}
        </ul>
      )}
      {postfix && <div className={styles['postfix']}>{postfix}</div>}
    </div>
  );
};

Select.displayName = 'Select';
