import cn from 'clsx';
import { FC } from 'react';

import { ModalFooterWrapperProps } from './types';

import styles from './styles.css';

export const ModalFooterWrapper: FC<ModalFooterWrapperProps> = props => {
  const { children, withBorder } = props;

  return <div className={cn(styles['footer'], { [styles['with-border']]: withBorder })}>{children}</div>;
};

ModalFooterWrapper.displayName = 'ModalFooterWrapper';
