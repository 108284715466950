// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TGetPossibleAppointmentsModel,
  IMappers,
  TGetPossibleAppointmentsResponse,
  IGetPossibleAppointmentsResponse,
} from './types';

export const defaultConfig: TGetPossibleAppointmentsModel = {
  apiType: 'public',
  assertStatusCodes: [200],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'announcements',
  pathApi: '/v1/get-possible-appointments/',
} as TGetPossibleAppointmentsModel;

export function createGetPossibleAppointmentsModel(): TGetPossibleAppointmentsModel {
  return {
    ...defaultConfig,
  };
}

export interface IGetPossibleAppointmentsOptions<TResponse200> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
}

export async function fetchGetPossibleAppointments<TResponse200>({
  httpApi,
  config,
  mappers,
}: IGetPossibleAppointmentsOptions<TResponse200>): Promise<TGetPossibleAppointmentsResponse | TResponse200> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetPossibleAppointmentsModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetPossibleAppointmentsResponse);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetPossibleAppointmentsResponse;
}
