import { Option } from 'shared/components/Select/models/Option';
import { infrastructureLabelMap } from 'shared/constants/filters/advanced/infractructure';
import { EInfrastructureItem } from 'shared/types/filters/advanced/infrastructure';
export const infrastructureOptions = [
  new Option(EInfrastructureItem.CarWash, infrastructureLabelMap[EInfrastructureItem.CarWash]),
  new Option(EInfrastructureItem.CarService, infrastructureLabelMap[EInfrastructureItem.CarService]),
  new Option(EInfrastructureItem.Pharmacy, infrastructureLabelMap[EInfrastructureItem.Pharmacy]),
  new Option(EInfrastructureItem.ClothesStudio, infrastructureLabelMap[EInfrastructureItem.ClothesStudio]),
  new Option(EInfrastructureItem.ATM, infrastructureLabelMap[EInfrastructureItem.ATM]),
  new Option(EInfrastructureItem.Pool, infrastructureLabelMap[EInfrastructureItem.Pool]),
  new Option(EInfrastructureItem.Buffet, infrastructureLabelMap[EInfrastructureItem.Buffet]),
  new Option(EInfrastructureItem.WarehouseComplex, infrastructureLabelMap[EInfrastructureItem.WarehouseComplex]),
  new Option(EInfrastructureItem.Hotel, infrastructureLabelMap[EInfrastructureItem.Hotel]),
  new Option(EInfrastructureItem.Cafe, infrastructureLabelMap[EInfrastructureItem.Cafe]),
  new Option(EInfrastructureItem.Cinema, infrastructureLabelMap[EInfrastructureItem.Cinema]),
  new Option(EInfrastructureItem.ConferenceRoom, infrastructureLabelMap[EInfrastructureItem.ConferenceRoom]),
  new Option(EInfrastructureItem.MedicalCenter, infrastructureLabelMap[EInfrastructureItem.MedicalCenter]),
  new Option(EInfrastructureItem.Minimarket, infrastructureLabelMap[EInfrastructureItem.Minimarket]),
  new Option(EInfrastructureItem.NotaryOffice, infrastructureLabelMap[EInfrastructureItem.NotaryOffice]),
  new Option(EInfrastructureItem.Department, infrastructureLabelMap[EInfrastructureItem.Department]),
  new Option(EInfrastructureItem.Park, infrastructureLabelMap[EInfrastructureItem.Park]),
  new Option(EInfrastructureItem.Restaurant, infrastructureLabelMap[EInfrastructureItem.Restaurant]),
  new Option(EInfrastructureItem.BeautyShop, infrastructureLabelMap[EInfrastructureItem.BeautyShop]),
  new Option(EInfrastructureItem.Warehouse, infrastructureLabelMap[EInfrastructureItem.Warehouse]),
  new Option(EInfrastructureItem.Canteen, infrastructureLabelMap[EInfrastructureItem.Canteen]),
  new Option(EInfrastructureItem.Supermarket, infrastructureLabelMap[EInfrastructureItem.Supermarket]),
  new Option(EInfrastructureItem.ShoppingArea, infrastructureLabelMap[EInfrastructureItem.ShoppingArea]),
  new Option(EInfrastructureItem.FitnessCentre, infrastructureLabelMap[EInfrastructureItem.FitnessCentre]),
  new Option(EInfrastructureItem.Studio, infrastructureLabelMap[EInfrastructureItem.Studio]),
  new Option(EInfrastructureItem.CentralReception, infrastructureLabelMap[EInfrastructureItem.CentralReception]),
];
