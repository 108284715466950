import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { includesOfficeType } from 'shared/utils/filters/includesOfficeType';
import { isSingleOfficeType } from 'shared/utils/filters/isSingleOfficeType';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Офис". Не имеет значение аренда или продажа
 */
export class AnyOfficeAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return isSingleOfficeType(this.filters) && includesOfficeType(this.filters);
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.floor,
        EAdvancedFilterType.floorTypes,
        EAdvancedFilterType.floorn,
        EAdvancedFilterType.buildingClassType,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.furniture,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.infrastructure,
        EAdvancedFilterType.accessSystem,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
