import { EBuildingType } from 'shared/types/filters/advanced/buildingType';

export const mapBuildingTypeLabel: Record<EBuildingType, string> = {
  [EBuildingType.BusinessCenter]: 'Бизнес-центр',
  [EBuildingType.House]: 'Особняк',
  [EBuildingType.AdministrativeBuilding]: 'Административное здание',
  [EBuildingType.MultifunctionalComplex]: 'Многофункциональный комплекс',
  [EBuildingType.Warehouse]: 'Склад или логистический центр',
  [EBuildingType.FAO]: 'Помещение свободного назначения',
  [EBuildingType.Other]: 'Прочее',
  [EBuildingType.ShoppingCenter]: 'Площадь в торговом центре',
  [EBuildingType.ResidentialBuilding]: 'Жилое здание',
  [EBuildingType.OfficeBuilding]: 'Офисное здание',
};
