import { IFiltersService } from '../IFiltersService';

export class FiltersFacadeService<F extends string> implements IFiltersService<F> {
  public static findCurrentFiltersService<F extends string>(
    ...getFiltersServiceList: Array<() => IFiltersService<F>>
  ): IFiltersService<F> {
    for (const getFiltersService of getFiltersServiceList) {
      const filtersService = getFiltersService();

      if (filtersService.isAvailable()) {
        return new FiltersFacadeService(filtersService);
      }
    }

    throw new Error('Не найден релевантный сервис фильтров');
  }

  private filtersService: IFiltersService<F>;

  private constructor(filtersService: IFiltersService<F>) {
    this.filtersService = filtersService;
  }

  public isAvailable(): boolean {
    return this.filtersService.isAvailable();
  }

  public getFields(): F[] {
    return this.filtersService.getFields();
  }
}
