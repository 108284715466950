import type { IJsonQueryTerm } from '../IJsonQueryElement';

export class JsonQueryTerm<K> implements IJsonQueryTerm<K> {
  public readonly type = 'term';
  public value: K;

  public constructor(value: K) {
    this.value = value;
  }
}
