import { OFFICE_TYPES } from 'shared/constants/filters/officeTypes';
import { EOfficeType } from 'shared/types/filters';

export const filterOfficeTypeByDealType = (exludedList: EOfficeType[]): EOfficeType[] =>
  OFFICE_TYPES.reduce((acc, item) => {
    if (!exludedList.includes(item.value)) {
      acc.push(item.value);
    }

    return acc;
  }, Array.of<EOfficeType>());
