import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { ICianApiSiteV1GetRegionCitiesResponse } from 'shared/repositories/monolith-python/cian-api/site/v1/get-region-cities';
import { useApplicationContext } from 'shared/utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchSearchRegionsCitiesService } from './internal/fetchService';

export function useSearchRegionsCitiesQuery<TData = ICianApiSiteV1GetRegionCitiesResponse>(
  query: string,
  options?: UseQueryOptions<ICianApiSiteV1GetRegionCitiesResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();
  const isQuerySearchable = query.length > 2;

  return useQuery<ICianApiSiteV1GetRegionCitiesResponse, QueryError, TData, QueryKey>({
    enabled: isQuerySearchable,
    queryFn: () => fetchSearchRegionsCitiesService(context, { text: query }),
    queryKey: getQueryKey(query),
    ...options,
  });
}
