import { PopupManager, PushManager } from '@cian/push-manager';
import { useCallback } from 'react';

import { useApplicationContext } from 'shared/utils/applicationContext';

export interface IUsePushManagerHandlersResult {
  handlePushSubscribe: () => Promise<void>;
  handleSubscribed: VoidFunction;
}

export function usePushManagerHandlers(
  pushManager: React.MutableRefObject<PushManager | undefined>,
  handleSetSubscribed: (isSubscribed: boolean) => void,
): IUsePushManagerHandlersResult {
  const {
    httpApi,
    config,
    custom: { subdomain },
  } = useApplicationContext();

  const applicationServerKey = config.get<string>('applicationServerKey') || '';

  const handleSubscribed = useCallback(() => {
    if (window.__reloadHeader__) {
      window.__reloadHeader__();
    }
  }, []);

  const handlePushSubscribe = useCallback(async () => {
    if (subdomain !== 'www') {
      if (pushManager.current) {
        pushManager.current.showPopup({
          baseUrl: 'web-push-subscribe-popup',
          trackingLabel: 'From_cat_save',
        });
      }
    } else if (applicationServerKey) {
      const popup = new PopupManager({
        applicationServerKey,
        httpApi,
        workerSrc: '/push-worker.js',
      });

      try {
        await popup.register();
      } catch (error) {
        return;
      }

      try {
        await popup.subscribe();

        handleSetSubscribed(true);
      } catch (error) {
        return;
      }
    }
  }, [applicationServerKey, handleSetSubscribed, httpApi, pushManager, subdomain]);

  return { handlePushSubscribe, handleSubscribed };
}
