import { EPriceType, PriceType } from 'shared/types/filters/priceType';

export const PRICE_TYPES_RENT: PriceType[] = [
  { label: 'За месяц', value: EPriceType.Total },
  { label: 'За м\u00B2 в год', value: EPriceType.SM },
];

export const PRICE_TYPES_SALE: PriceType[] = [
  { label: 'За всё', value: EPriceType.Total },
  { label: 'За м\u00B2', value: EPriceType.SM },
];

export const PRICE_TYPES_COWORKING: PriceType[] = [
  { label: 'За всё', value: EPriceType.Total },
  { label: 'За рабочее место', value: EPriceType.SM },
];

export const PRICE_TYPES_COMMERCIAL_LAND_SALE: PriceType[] = [
  { label: 'За всё', value: EPriceType.Total },
  { label: 'За сотку', value: EPriceType.SM },
];

export const PRICE_TYPES_COMMERCIAL_LAND_RENT: PriceType[] = [
  { label: 'За месяц', value: EPriceType.Total },
  { label: 'За сотку в год', value: EPriceType.SM },
];
