import { ReactNode } from 'react';

import { EFilterType } from 'shared/enums/EFilterType';

import { IFilter } from '../IFilter';

export class Filter implements IFilter<EFilterType> {
  public readonly key: EFilterType;
  public readonly component: ReactNode;

  public constructor(key: EFilterType, component: ReactNode) {
    this.key = key;
    this.component = component;
  }
}
