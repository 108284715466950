import { Option } from 'shared/components/Select/models/Option';
import { mapParkingTypeLabel } from 'shared/constants/filters/mapParkingTypeLabel';
import { EParkingType } from 'shared/types/filters/advanced/parkingType';

export const parkingTypeOptions = [
  new Option(EParkingType.Ground, mapParkingTypeLabel[EParkingType.Ground]),
  new Option(EParkingType.Multilevel, mapParkingTypeLabel[EParkingType.Multilevel]),
  new Option(EParkingType.Underground, mapParkingTypeLabel[EParkingType.Underground]),
  new Option(EParkingType.Rooftop, mapParkingTypeLabel[EParkingType.Rooftop]),
];
