import { EContractType } from 'shared/types/filters/contractType';

export const contractTypeMap = {
  [EContractType.ObjSale]: 'Продажа объекта',
  [EContractType.PermTransfer]: 'Переуступка прав аренды',
  [EContractType.DirectRent]: 'Прямая аренда',
  [EContractType.SubRent]: 'Субаренда',
};

export const SALE = [EContractType.ObjSale, EContractType.PermTransfer];
export const RENT = [EContractType.DirectRent, EContractType.SubRent];
