import { IGetBusinessPossibleAppointmentItem } from 'shared/repositories/announcements/entities/AnnouncementReferences/GetBusinessPossibleAppointmentItem';
import { IGetBusinessPossibleAppointmentsResponse } from 'shared/repositories/announcements/v1/get-business-possible-appointments';
import { EOfficeType } from 'shared/types/filters';

import { getIsBusinessCategory } from './getIsBusinessCategory';

export type GetAvailableBusinessAppointmentsItemsResult = {
  availableIds: Array<number>;
  availableItems: Array<IGetBusinessPossibleAppointmentItem>;
};

export function getAvailableBusinessAppointmentsItems(
  businessPossibleAppointments: IGetBusinessPossibleAppointmentsResponse,
  officeTypes: Array<EOfficeType>,
  ids: Array<number>,
): GetAvailableBusinessAppointmentsItemsResult {
  const { rentalBusiness, readyBusiness } = businessPossibleAppointments;

  const businessAppointmentsCollection = new Set<number>(ids);
  const availableIds = new Set<number>();
  const availableItems = Array.of<IGetBusinessPossibleAppointmentItem>();

  const { isRentalBusiness, isReadyBusiness } = getIsBusinessCategory(officeTypes);

  if (isReadyBusiness) {
    readyBusiness.groups.forEach(group => {
      group.items.forEach(item => {
        if (businessAppointmentsCollection.has(item.id) && !availableIds.has(item.id)) {
          availableIds.add(item.id);
          availableItems.push(item);
        }
      });
    });
  }

  if (isRentalBusiness) {
    rentalBusiness.items.forEach(item => {
      if (businessAppointmentsCollection.has(item.id) && !availableIds.has(item.id)) {
        availableIds.add(item.id);
        availableItems.push(item);
      }
    });
  }

  return {
    availableIds: Array.from(availableIds),
    availableItems,
  };
}
