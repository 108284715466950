import { TRADE_AREA_GROUP } from 'shared/constants/filters/conditionType';
import { Filters } from 'shared/types/filters';
import { EConditionType } from 'shared/types/filters/advanced/conditionType';
import { getConditionTypeFields } from 'shared/utils/filters/getConditionTypeFields';
import { includesOfficeType } from 'shared/utils/filters/includesOfficeType';
import { isSingleOfficeType } from 'shared/utils/filters/isSingleOfficeType';

export const changeConditionTypesByOfficeTypes = (state: Filters): EConditionType[] | undefined => {
  const fields = getConditionTypeFields(state.officeType);

  return state.conditionType?.reduce((acc, item) => {
    const field = ((): EConditionType[] => {
      if ([EConditionType.OfficeDecoration, EConditionType.DesignerRepair].includes(item)) {
        return [EConditionType.WithDecoration];
      }

      if (item === EConditionType.RegularRepair) {
        if (isSingleOfficeType(state) && includesOfficeType(state)) {
          return [EConditionType.FullDecorationNeeded];
        }

        if (state.officeType.length && state.officeType.every(type => TRADE_AREA_GROUP.includes(type))) {
          return [EConditionType.RegularRepair, EConditionType.FullDecorationNeeded];
        }
      }

      if (item === EConditionType.WithDecoration) {
        if (isSingleOfficeType(state) && includesOfficeType(state)) {
          return [EConditionType.OfficeDecoration];
        }

        if (state.officeType.length && state.officeType.every(type => TRADE_AREA_GROUP.includes(type))) {
          return [EConditionType.DesignerRepair];
        }
      }

      if (item === EConditionType.FullDecorationNeeded) {
        return [EConditionType.RegularRepair];
      }

      return [item];
    })();

    if (field.every(f => fields.includes(f) && !acc.includes(f))) {
      acc.push(...field);
    }

    return acc;
  }, Array.of<EConditionType>());
};
