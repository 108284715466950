import { ca } from '@cian/analytics';

export function trackSaveSearchModalOpen(): void {
  ca('eventSite', {
    action: 'Open',
    category: 'subscription',
    label: 'From_cat_save',
    name: 'oldevent',
    page: {
      pageType: 'Listing',
    },
  });
}
