import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IBoundedBySchema } from 'shared/repositories/monolith-python/entities/search_catalog_item/BoundedBySchema';
import { useGetCurrentRegionQuery } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-region/query';
import { useApplicationContext } from 'shared/utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchYandexSuggestService } from './internal/fetchService';
import { FetchGetGeoSuggestServiceParameters } from './types';

type UseYandexSuggestQueryOptions<TData> = UseQueryOptions<YMaps.ISuggestResult[], QueryError, TData, QueryKey>;

export function useYandexSuggestQuery<TData = YMaps.ISuggestResult[]>(
  query: string,
  parameters: FetchGetGeoSuggestServiceParameters,
  options?: UseYandexSuggestQueryOptions<TData>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();

  return useQuery<YMaps.ISuggestResult[], QueryError, TData, QueryKey>({
    queryFn: () => fetchYandexSuggestService(context, parameters),
    queryKey: getQueryKey(query),
    ...options,
  });
}

type UseYandexSuggestQueryWithBoundedByOptions<TData> = UseYandexSuggestQueryOptions<TData>;

export function useYandexSuggestQueryWithBoundedBy<TData = YMaps.ISuggestResult[]>(
  query: string,
  options: UseYandexSuggestQueryWithBoundedByOptions<TData> = {},
): UseQueryResult<TData, QueryError> {
  const { data: boundedBy, status } = useGetCurrentRegionQuery({
    select: data => data.data.boundedBy && mapBoundsToYandexBBox(data.data.boundedBy),
  });

  return useYandexSuggestQuery(
    query,
    { boundedBy, query },
    { ...options, enabled: status === 'success' && !!options.enabled },
  );
}

function mapBoundsToYandexBBox(boundedBySchema: IBoundedBySchema): [YMaps.TCoord, YMaps.TCoord] {
  const {
    upperCorner: { lat: latMax, lng: lngMax },
    lowerCorner: { lat: latMin, lng: lngMin },
  } = boundedBySchema;

  return [
    [latMin, lngMin],
    [latMax, lngMax],
  ];
}
