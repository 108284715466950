import { EAmenityType } from 'shared/types/filters/advanced/amenities';

export const amenityMapLabel: Record<EAmenityType, string> = {
  [EAmenityType.Wifi]: 'Wi-Fi',
  [EAmenityType.BikeParking]: 'Велопарковка',
  [EAmenityType.Internet]: 'Высокоскоростной интернет',
  [EAmenityType.RestZone]: 'Зоны отдыха',
  [EAmenityType.Cafe]: 'Кафе',
  [EAmenityType.Cleaning]: 'Клининг',
  [EAmenityType.Kitchen]: 'Кухня',
  [EAmenityType.Furniture]: 'Мебель',
  [EAmenityType.Security]: 'Охрана',
  [EAmenityType.Parking]: 'Парковка',
  [EAmenityType.MeetingRoom]: 'Переговорные комнаты',
  [EAmenityType.Printer]: 'Принтер и сканер',
  [EAmenityType.Reception]: 'Ресепшн',
  [EAmenityType.Modern]: 'Современный ремонт',
  [EAmenityType.Gym]: 'Спорт-зал',
  [EAmenityType.PhoneBooth]: 'Телефонные будки',
  [EAmenityType.TechSupport]: 'Техническая поддержка',
  [EAmenityType.Events]: 'Участие в мероприятиях',
  [EAmenityType.Fax]: 'Факс',
  [EAmenityType.Flipchart]: 'Флипчарт',
  [EAmenityType.Fruits]: 'Фрукты и закуски',
  [EAmenityType.Tea]: 'Чай/кофе',
  [EAmenityType.Lockers]: 'Шкафчики',
  [EAmenityType.LegalAddress]: 'Юридический адрес',
};
