import { ActionCreatorsMapObject } from 'redux';

import { setTargetOffersCountTooltip } from 'shared/store/data/offersCountTooltip/actions';

import { useBoundActions } from './useBoundActions';

const actions = {
  setTargetOffersCountTooltip,
};

/**
 * @deprecated
 * @returns {ActionCreatorsMapObject<typeof actions>}
 */
export function useOffersCountTooltipActions(): ActionCreatorsMapObject<typeof actions> {
  return useBoundActions(actions);
}
