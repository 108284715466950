import { hydrate } from 'react-dom';

import { Application } from 'shared/containers/App';
import { IServerSideState } from 'shared/services/ServerSideState';
import { ReduxStore } from 'shared/types/RootState';
import { IApplicationContext } from 'shared/types/applicationContext';

interface IDependencies {
  store: ReduxStore;
  context: IApplicationContext;
  projectName: string;
  serverSideState: IServerSideState;
}

export function renderApplication(dependencies: IDependencies): void {
  const { store, context, projectName, serverSideState } = dependencies;

  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  hydrate(<Application context={context} reduxStore={store} serverSideState={serverSideState} />, rootElement);
}
