import type { IUndergroundMapDataV1 } from '@cian/underground-map-widget';

import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export function fetchGetUndergroundMapDataServiceV1(
  dependencies: IApplicationContext,
  locationId: number,
): Promise<IUndergroundMapDataV1> {
  const { logger } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    { locationId },
    'src/shared/store/serverSide/map/v1/get-underground-map-data/internal/fetchService.ts',
    logger,
  );

  return import(`@cian/metro/json/${locationId}.json`).then<IUndergroundMapDataV1>(
    (result: { default: IUndergroundMapDataV1 }) => result.default,
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
