import { FC, useCallback } from 'react';

import { Tag } from 'shared/components/TagsManager/internal/components/Tag';
import { setTargetOffersCountTooltip } from 'shared/store/data/offersCountTooltip/actions';
import { Underground } from 'shared/types/filters/geo';
import { EButtonTarget } from 'shared/types/offersCountTooltip/offersCountTooltipTarget';
import { boundActionsFactory } from 'shared/utils/hooks/useBoundActions';
import { useTags } from 'shared/utils/hooks/useTags';
import { useTagsActions } from 'shared/utils/hooks/useTagsActions';

import { TagsCollapsible } from './internal/components/TagsCollapsible';
import { UndergroundIcon } from './internal/components/UndergroundIcon';
import { isGeoTag } from './internal/heplers/isGeoTag';
import { isIdUserTag } from './internal/heplers/isIdUserTag';
import { HandleClose } from './types';

import styles from './style.css';

const useBoundActions = boundActionsFactory({
  setTargetOffersCountTooltip,
});

export const TagsManager: FC = () => {
  const tags = useTags();

  const boundActions = useBoundActions();

  const { removeTag, removeAllTags } = useTagsActions();

  const handleClose: HandleClose = tag => () => {
    removeTag(tag);

    boundActions.setTargetOffersCountTooltip(
      isGeoTag(tag) || isIdUserTag(tag) ? EButtonTarget.findButton : EButtonTarget.moreButton,
    );
  };

  const handleClear = useCallback<VoidFunction>(() => {
    removeAllTags();

    boundActions.setTargetOffersCountTooltip(EButtonTarget.moreButton);
  }, [boundActions, removeAllTags]);

  if (!tags.length) {
    return null;
  }

  return (
    <TagsCollapsible onClear={handleClear}>
      {tags.map(tag => {
        const tagValue = tag.value as Underground;
        const tagColor = tagValue.color;

        return (
          <Tag key={`tag_${tag.id}`} onClose={handleClose(tag)}>
            {tagColor && (
              <span className={styles['icon-wrapper']}>
                <UndergroundIcon color={tagColor} />
              </span>
            )}
            {tag.name}
          </Tag>
        );
      })}
    </TagsCollapsible>
  );
};
TagsManager.displayName = 'TagsManagerContainer';
