import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { includesWarehouseOfficeType } from 'shared/utils/filters/includesWarehouseOfficeType';
import { isSingleOfficeType } from 'shared/utils/filters/isSingleOfficeType';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Склад". Не имеет значение аренда или продажа
 */
export class AnyWarehouseAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return [isSingleOfficeType, includesWarehouseOfficeType].every(fn => fn(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.floor,
        EAdvancedFilterType.floorTypes,
        EAdvancedFilterType.buildingClassType,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.buildingHeatingType,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.infrastructure,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
