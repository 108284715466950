import { ITagOption, IconPlus16, Input, InputAdornmentButton, Tags } from '@cian/ui-kit';
import clsx from 'clsx';
import { FC, FormEvent, useCallback, useRef, useState } from 'react';

import { Option } from '../Select/models/Option';

import styles from './InputWithTags.css';

interface IInputWithTagsProps {
  id: string;
  placeholder?: string;
  maxLength?: number;
  options: ITagOption[];
  onAdd(value: ITagOption): void;
  onRemove(value: ITagOption): void;
}

export const InputWithTags: FC<IInputWithTagsProps> = ({
  id,
  placeholder,
  maxLength = 30,
  options,
  onAdd,
  onRemove,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>('');

  const handleChange = useCallback<(event: FormEvent<HTMLInputElement>) => void>(
    event => {
      setValue(event.currentTarget.value);
    },
    [setValue],
  );

  const handleAdd = useCallback(() => {
    const trimmedValue = value.trim();

    if (!trimmedValue || options.some(v => v.value === trimmedValue)) {
      return;
    }

    onAdd(new Option(trimmedValue, trimmedValue));
    setValue('');

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [options, value, onAdd, setValue]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleAdd();
      }
    },
    [handleAdd],
  );

  return (
    <div>
      <div className={clsx(!!options && styles['input-container--with-tags'])} id={`${id}_input`}>
        <Input
          maxLength={maxLength}
          placeholder={placeholder}
          ref={inputRef}
          rightAdornment={
            <InputAdornmentButton
              aria-controls={`${id}_input`}
              aria-keyshortcuts="Enter"
              aria-label="Добавить тег"
              title="Добавить тег"
              onClick={handleAdd}
            >
              <IconPlus16 color="current_color" />
            </InputAdornmentButton>
          }
          value={value}
          width={260}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      {options && (
        <span data-testid={`${id}_tags`}>
          <Tags options={options} onClose={onRemove}></Tags>
        </span>
      )}
    </div>
  );
};
