import { Button } from '@cian/ui-kit';
import { FC } from 'react';

export const RegionModalFooterPlaceholder: FC = () => {
  return (
    <Button disabled size="XS">
      Выберите регион
    </Button>
  );
};

RegionModalFooterPlaceholder.displayName = 'RegionModalFooterPlaceholder';
