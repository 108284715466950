import { FlattenType } from 'shared/types/FlattenType';
import { Filters } from 'shared/types/filters';

export class FormattedTag<K extends keyof Filters = keyof Filters> {
  public readonly id: string;
  public readonly name: string;
  public readonly value: FlattenType<Filters[K]>;
  public readonly filterName: K;
  public readonly color?: string;

  public constructor(id: string, name: string, filterName: K, value: FlattenType<Filters[K]>, color?: string) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.filterName = filterName;
    if (color) {
      this.color = color;
    }
  }
}
