import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { ICianApiSiteV1GetRegionsResponse } from 'shared/repositories/monolith-python/cian-api/site/v1/get-regions';
import { useApplicationContext } from 'shared/utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetRegionsService } from './internal/fetchService';

export function useGetRegionsQuery<TData = ICianApiSiteV1GetRegionsResponse>(
  options?: UseQueryOptions<ICianApiSiteV1GetRegionsResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();

  return useQuery<ICianApiSiteV1GetRegionsResponse, QueryError, TData, QueryKey>({
    queryFn: () => fetchGetRegionsService(context),
    queryKey: getQueryKey(),
    ...options,
  });
}
