import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { filtersSelector } from 'shared/store/data/filters/selectors/root/filtersSelector';
import { RootState } from 'shared/types/RootState';
import { Filters } from 'shared/types/filters';

export const useFilter = <T extends keyof Filters>(filterName: T): Filters[T] => {
  const selector = useCallback((state: RootState) => filtersSelector(state)[filterName], [filterName]);

  return useSelector(selector);
};
