import { useSelector } from 'react-redux';

import { filtersSelector } from 'shared/store/data/filters/selectors/root/filtersSelector';
import { Filters } from 'shared/types/filters';

export function useFilters(): Filters {
  const filters = useSelector(filtersSelector);

  return filters;
}
