import { Filters } from 'shared/types/filters';

export function getJsonQueryCoworkingId(filters: Filters): number[] | undefined {
  const { coworking } = filters;

  if (!coworking) {
    return undefined;
  }

  return coworking.map(coworkingItem => coworkingItem.id);
}
