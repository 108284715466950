import { Button } from '@cian/ui-kit';
import { FC } from 'react';

import { FindButtonProps } from './types';

export const FindButton: FC<FindButtonProps> = props => {
  const { loading, theme, label } = props;

  return (
    <Button loading={loading} size="XS" theme={theme} onClick={props.onClick}>
      {label}
    </Button>
  );
};

FindButton.displayName = 'FindButton';
