import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { ISuggestionsDataSchema } from 'shared/repositories/geo-suggest/entities/entities/SuggestionsDataSchema';
import { EDealType, EOfferType, ESource, ISuggestResponse } from 'shared/repositories/geo-suggest/v2/suggest';
import { EDealType as FDealType } from 'shared/types/filters';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { useCurrentLocation } from 'shared/utils/hooks/useCurrentLocation';
import { useFilters } from 'shared/utils/hooks/useFilters';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetGeoSuggestService } from './internal/fetchService';

const DEAL_TYPE_SUGGEST_MAP: { [key: number]: EDealType } = {
  [FDealType.Sale]: EDealType.Sale,
  [FDealType.Rent]: EDealType.Rent,
};

export function useGetGeoSuggestQuery<TData = ISuggestionsDataSchema>(
  query: string,
  options?: UseQueryOptions<ISuggestResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();
  const filters = useFilters();
  const currentLocation = useCurrentLocation();

  return useQuery<ISuggestResponse, QueryError, TData, QueryKey>({
    queryFn: () =>
      fetchGetGeoSuggestService(context, {
        dealType: DEAL_TYPE_SUGGEST_MAP[filters.dealType],
        isCoworkingSearch: hasCoworkingOfficeType(filters),
        offerType: EOfferType.Commercial,
        query,
        regionId: currentLocation,
        source: ESource.Serp,
      }),
    queryKey: getQueryKey(query),
    ...options,
  });
}
