import { FC } from 'react';

import { EFilterType } from 'shared/enums/EFilterType';
import { filterTypeToFilterComponentMapper } from 'shared/utils/filterTypeToFilterComponentMapper';
import { useAvailableFilters } from 'shared/utils/hooks/useAvailableFilters';

import { AvailableFilters } from './AvailableFilters';
import { Filter, IFilter } from './internal/models/Filter';
import { AvailableFiltersContainerProps } from './types';

const FILTERS_AFTER_CHILDREN = [EFilterType.isByCommercialOwner];

export const AvailableFiltersContainer: FC<AvailableFiltersContainerProps> = props => {
  const availableFilters = useAvailableFilters();
  const filters = availableFilters.reduce(
    (acc, filterName) => {
      const filterComponent = filterTypeToFilterComponentMapper[filterName]();

      if (filterComponent) {
        const columnIndex = FILTERS_AFTER_CHILDREN.includes(filterName) ? 1 : 0;
        acc[columnIndex].push(new Filter(filterName, filterComponent));
      }

      return acc;
    },
    [[], []] as [IFilter<EFilterType>[], IFilter<EFilterType>[]],
  );

  return <AvailableFilters filtersByColumns={filters} {...props} />;
};

AvailableFiltersContainer.displayName = 'AvailableFiltersContainer';
