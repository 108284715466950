import { QueryClient } from '@tanstack/react-query';

import { EFilterType } from 'shared/enums/EFilterType';
import { getBusinessPossibleAppointments } from 'shared/store/serverSide/announcements/v1/get-business-possible-appointments/load';
import { Filters } from 'shared/types/filters';
import { getAvailableFilters } from 'shared/utils/filters/getAvailableFilters';
import { getAvailableBusinessAppointmentsItems } from 'shared/utils/getAvailableBusinessAppointmentsItems';

export function getJsonQuerySpecialtyTypes(filters: Filters, queryClient: QueryClient): number[] | undefined {
  const { specialtyTypes, officeType } = filters;

  const availableFilters = getAvailableFilters(filters);

  if (availableFilters.includes(EFilterType.BusinessAppointments)) {
    const businessPossibleAppointments = getBusinessPossibleAppointments(queryClient);

    if (!businessPossibleAppointments) {
      return filters.businessAppointments;
    }

    const { availableIds } = getAvailableBusinessAppointmentsItems(
      businessPossibleAppointments,
      officeType,
      filters.businessAppointments,
    );

    return availableIds;
  }

  return specialtyTypes?.map(specialtyType => specialtyType.id);
}
