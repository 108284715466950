import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { isCommercialLand } from 'shared/utils/filters/isCommercialLand';
import { isSale } from 'shared/utils/filters/isSale';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Коммерческая земля". Работает только для "Продажа"
 */
export class SaleCommercialLandAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return [isSale, isCommercialLand].every(fn => fn(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.electronicTrading,
      )
      .filter(field => field !== EAdvancedFilterType.undergroundTime);
  }
}
