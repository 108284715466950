import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EOfficeType } from 'shared/types/filters';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "ПСН, общепит, автосервис, бытовые услуги". Не имеет значение аренда или продажа
 */
export class AnyOfficeAndManyOtherAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return this.filters.officeType.every(officeType =>
      [
        EOfficeType.Office,
        EOfficeType.TradeArea,
        EOfficeType.FreeAppointmentObject,
        EOfficeType.Warehouse,
        EOfficeType.Manufacture,
        EOfficeType.PublicCatering,
      ].includes(officeType),
    );
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.specialtyTypes,
        EAdvancedFilterType.floor,
        EAdvancedFilterType.floorTypes,
        EAdvancedFilterType.floorn,
        EAdvancedFilterType.buildingClassType,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.inputType,
        EAdvancedFilterType.isSeparatedInputType,
        EAdvancedFilterType.furniture,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.infrastructure,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
