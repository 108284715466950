import { IOption, Option } from 'shared/components/Select/models/Option';
import { IOptions, Options } from 'shared/components/Select/models/Options';

type Filter = {
  value?: number;
  label?: string;
  description?: string;
  shortLabel?: string;
};

export function getFilterOptions<T extends Filter>(filters: T[], placeholder: string): IOptions {
  return new Options(
    filters.reduce((accumulator: IOption[], filter: T) => {
      if (filter.value != null && filter.label) {
        accumulator.push(new Option(filter.value, filter.label, filter.shortLabel, filter.description));
      }

      return accumulator;
    }, Array.of<IOption>()),
    placeholder,
  );
}
