import { stringify } from 'qs';
const ymapsPromise: { [key: string]: Promise<YMaps.IYMaps> } = {};

const SCRIPT_URL = 'https://enterprise.api-maps.yandex.ru/2.1/';

export async function loadYMapsApi(apiKey: string, suggestApiKey: string, modules?: string[]): Promise<YMaps.IYMaps> {
  if (!ymapsPromise[apiKey]) {
    ymapsPromise[apiKey] = new Promise((resolve, reject) => {
      if (window._cianYMaps && window._cianYMaps[apiKey]) {
        resolve(window._cianYMaps[apiKey]);

        return;
      }

      const srcParams = stringify(
        {
          apikey: encodeURIComponent(apiKey),
          lang: 'ru-RU',
          load: 'package.system',
          ns: `_cianYMaps.${encodeURIComponent(apiKey)}`,
          suggest_apikey: encodeURIComponent(suggestApiKey),
        },
        { addQueryPrefix: true },
      );

      const htmlScriptElement = window.document.createElement('script');
      htmlScriptElement.src = `${SCRIPT_URL}${srcParams}`;

      htmlScriptElement.async = true;
      htmlScriptElement.defer = true;
      htmlScriptElement.onload = (): void => {
        if (window._cianYMaps && window._cianYMaps[apiKey]) {
          resolve(window._cianYMaps[apiKey]);
        } else {
          reject(new Error('Unable to load Yandex Maps API (_cianYMaps unavailable)'));
        }
      };
      htmlScriptElement.onerror = (): void => {
        window.document.body.removeChild(htmlScriptElement);
        delete ymapsPromise[apiKey];

        reject(new Error('Unable to load Yandex Maps API'));
      };
      window.document.body.appendChild(htmlScriptElement);
    });
  }

  const ymaps = await ymapsPromise[apiKey];

  return new Promise<YMaps.IYMaps>((resolve, reject) => {
    ymaps.ready({
      errorCallback(error) {
        reject(error);
      },
      require: modules,
      successCallback() {
        resolve(ymaps);
      },
    });
  });
}
