import { RadioButtonGroup } from '@cian/ui-kit';
import { useCallback, useMemo } from 'react';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { accessSystemOptions } from './internal/constants/accessSystemOptions';
import { commisionTypeOptions } from './internal/constants/commisionTypeOptions';
import { electronicTradingOptions } from './internal/constants/electronicTradingOptions';
import { furnitureOptions } from './internal/constants/furnitureOptions';
import { NEUTRAL_OPTION } from './internal/constants/neutralOption';
import { CommonTermFilterProps } from './types';

import styles from './CommonTermFilter.css';

export const OPTIONS_BY_FILTER = {
  [EAdvancedFilterType.furniture]: furnitureOptions,
  [EAdvancedFilterType.accessSystem]: accessSystemOptions,
  [EAdvancedFilterType.commissionType]: commisionTypeOptions,
  [EAdvancedFilterType.electronicTrading]: electronicTradingOptions,
};

export const CommonTermFilter = memoWithDisplayName<CommonTermFilterProps>(props => {
  const { filterName, children } = props;

  const filterValue = useFilter(filterName);

  const { setTermFilterValue } = useFiltersActions();

  const onFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermFilterValue({ name: filterName, value: Number(event.target.value) });
    },
    [filterName, setTermFilterValue],
  );

  const options = useMemo(() => [NEUTRAL_OPTION, ...OPTIONS_BY_FILTER[filterName]], [filterName]);

  return (
    <div className={styles['container']}>
      <RadioButtonGroup spliced options={options} value={filterValue ?? 0} onChange={onFilterChange} />
      {children}
    </div>
  );
}, 'CommonTermsFilter');
