// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IModelResponse, IModel } from '@cian/http-api';
import type { ISuggestV2ResponseSchema } from '../../entities/entities/SuggestV2ResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export interface ISuggestRequest {
  /**  Запрос пользователя  **/
  query: string;
  /**  id региона  **/
  regionId: number;
  /**  Тип недвижимости  **/
  offerType: EOfferType;
  /**  Тип сделки  **/
  dealType?: EDealType;
  /**  Откуда пришел запрос  **/
  source?: ESource;
  /**  Идет ли поиск коворкингов  **/
  isCoworkingSearch?: boolean;
  /** Идет ли поиск для посутки  **/
  isDailyRent?: boolean;
}
export enum EOfferType {
  /** Коммерческая **/
  'Commercial' = 'commercial',
  /** Квартиры **/
  'Flat' = 'flat',
  /** Новостройки **/
  'Newobject' = 'newobject',
  /** Загородка **/
  'Suburban' = 'suburban',
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Покупка **/
  'Sale' = 'sale',
}
export enum ESource {
  /** Из коммерческой недвижимости **/
  'IapMap' = 'iapMap',
  /** С главной **/
  'Mainpage' = 'mainpage',
  /** С карты **/
  'Map' = 'map',
  /** С мобильного сайта **/
  'MobileWebsite' = 'mobileWebsite',
  /** Страница акций в новостройках **/
  'NewbuildingPromosPage' = 'newbuildingPromosPage',
  /** С поиска **/
  'Serp' = 'serp',
}

export type ISuggestResponse = ISuggestV2ResponseSchema;

export interface ISuggestResponse200 extends IModelResponse<ISuggestResponse> {
  statusCode: 200;
}

export type ISuggestResponseError = IErrorResponseSchema;

export interface ISuggestResponse400 extends IModelResponse<ISuggestResponseError> {
  statusCode: 400;
}

export type TSuggestResponse = ISuggestResponse200 | ISuggestResponse400;

export type TSuggestModel = IModel<ISuggestRequest, TSuggestResponse>;

export interface IMappers<TResponse200, TResponse400> {
  200(response: ISuggestResponse): TResponse200;
  400(response: ISuggestResponseError): TResponse400;
}
