import type { FetchQueryOptions, QueryClient } from '@tanstack/react-query';

import { IGetBusinessPossibleAppointmentsResponse } from 'shared/repositories/announcements/v1/get-business-possible-appointments';
import { IApplicationContext } from 'shared/types/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetBusinessPossibleAppointmentsService } from './internal/fetchService';

export async function prefetchGetBusinessPossibleAppointments<TData = IGetBusinessPossibleAppointmentsResponse>(
  queryClient: QueryClient,
  dependencies: IApplicationContext,
  options?: FetchQueryOptions<IGetBusinessPossibleAppointmentsResponse, QueryError, TData, QueryKey>,
): Promise<void> {
  return queryClient.prefetchQuery<IGetBusinessPossibleAppointmentsResponse, QueryError, TData, QueryKey>(
    getQueryKey(),
    () => fetchGetBusinessPossibleAppointmentsService(dependencies),
    options,
  );
}

export function getBusinessPossibleAppointments(
  queryClient: QueryClient,
): IGetBusinessPossibleAppointmentsResponse | undefined {
  return queryClient.getQueryData<IGetBusinessPossibleAppointmentsResponse>(getQueryKey());
}
