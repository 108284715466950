import type { FetchQueryOptions, QueryClient } from '@tanstack/react-query';

import {
  IApiGeoGeocodeCachedRequest,
  IApiGeoGeocodeCachedResponse,
} from 'shared/repositories/monolith-cian-realty/api/geo/geocode-cached';
import { IApplicationContext } from 'shared/types/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGeocodeCachedService } from './internal/fetchService';

export class FetchGeocodeCachedLoadService {
  private readonly queryClient: QueryClient;

  public constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public async fetchQuery<TData = IApiGeoGeocodeCachedResponse>(
    dependencies: IApplicationContext,
    parameters: IApiGeoGeocodeCachedRequest,
    options?: FetchQueryOptions<IApiGeoGeocodeCachedResponse, QueryError, TData, QueryKey>,
  ): Promise<TData> {
    return this.queryClient.fetchQuery<IApiGeoGeocodeCachedResponse, QueryError, TData, QueryKey>({
      queryFn: () => fetchGeocodeCachedService(dependencies, parameters),
      queryKey: getQueryKey(),
      ...options,
    });
  }

  public getQueryData(): IApiGeoGeocodeCachedResponse | undefined {
    return this.queryClient.getQueryData<IApiGeoGeocodeCachedResponse>(getQueryKey());
  }
}
