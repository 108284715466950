import { Filters } from 'shared/types/filters';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { hasWorkplaceOfficeType } from 'shared/utils/filters/hasWorkplaceOfficeType';

export function getJsonQueryCoworkingOfferType(filters: Filters): number[] | undefined {
  const value = [];
  if (hasCoworkingOfficeType(filters)) {
    value.push(1);
  }
  if (filters.workplaceType && hasWorkplaceOfficeType(filters)) {
    value.push(...filters.workplaceType);
  }

  if (hasWorkplaceOfficeType(filters) || hasCoworkingOfficeType(filters)) {
    return value;
  }

  return undefined;
}
