import { Option } from 'shared/components/Select/models/Option';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EUndergroundTimeType } from 'shared/types/filters/advanced/undergroundTime';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { UndergroundTimeFilter } from './UndergroundTimeFilter';

export const OPTIONS = [
  new Option(EUndergroundTimeType.none, 'Неважно'),
  new Option(EUndergroundTimeType.foot, 'Пешком'),
  new Option(EUndergroundTimeType.transport, 'Транспортом'),
];

export const UndergroundTimeFilterContainer = memoWithDisplayName(() => {
  const { type = EUndergroundTimeType.none, time } = useFilter(EAdvancedFilterType.undergroundTime) || {};
  const { changeUndergroundTimeAction, changeUndergroundTimeTypeAction } = useFiltersActions();

  return (
    <UndergroundTimeFilter
      options={OPTIONS}
      time={time}
      type={type}
      onUndergroundTimeChange={changeUndergroundTimeAction}
      onUndergroundTimeTypeChange={changeUndergroundTimeTypeAction}
    />
  );
}, 'UndergroundTimeFilterContainer');
