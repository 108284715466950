import { useMemo } from 'react';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { InputTypeFilter } from './InputTypeFilter';
import { INPUT_TYPE_OPTIONS } from './constants';

export const InputTypeFilterContainer = memoWithDisplayName(() => {
  const inputType = useFilter(EAdvancedFilterType.inputType);
  const selectedInputType = useMemo(() => inputType || [], [inputType]);

  const isSeparatedInputType = useFilter(EAdvancedFilterType.isSeparatedInputType);
  const selectedIsSeparatedInputType = useMemo(() => isSeparatedInputType || false, [isSeparatedInputType]);

  const { changeInputTypeAction, toggleIsSeparatedInputTypeAction } = useFiltersActions();

  return (
    <InputTypeFilter
      inputType={selectedInputType}
      isSeparatedInputType={selectedIsSeparatedInputType}
      options={INPUT_TYPE_OPTIONS}
      onInputTypeChange={changeInputTypeAction}
      onIsSeparatedInputTypeChange={toggleIsSeparatedInputTypeAction}
    />
  );
}, 'InputTypeFilterContainer');
