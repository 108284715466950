import { EFilterType } from 'shared/enums/EFilterType';
import { isOnlyBusinessCategory } from 'shared/utils/filters/isOnlyBusinessCategory';
import { isSale } from 'shared/utils/filters/isSale';

import { IFiltersService } from '../IFiltersService';

import { AbstractFiltersService } from './AbstractFiltersService';

export class SaleBusinessFiltersService extends AbstractFiltersService implements IFiltersService<EFilterType> {
  public isAvailable(): boolean {
    if (!this.filters.officeType.length) {
      return false;
    }

    return [isSale, isOnlyBusinessCategory].every(fn => fn(this.filters));
  }

  public getFields(): EFilterType[] {
    return super.getFields().concat(EFilterType.BusinessAppointments, EFilterType.price, EFilterType.SpecialtyTypes);
  }
}
