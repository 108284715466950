import { Button, IconHeartOff16 } from '@cian/ui-kit';
import { FC } from 'react';

import { ISaveSearchButtonProps } from './types';

export const SaveSearchButton: FC<ISaveSearchButtonProps> = props => {
  const { handleClick } = props;

  return (
    <Button
      beforeIcon={<IconHeartOff16 color="primary_100" />}
      size="XS"
      theme="fill_white_primary"
      onClick={handleClick}
    >
      Сохранить поиск
    </Button>
  );
};
