import {
  IGetBusinessPossibleAppointmentsResponse,
  IGetBusinessPossibleAppointmentsResponse200,
  createGetBusinessPossibleAppointmentsModel,
  fetchGetBusinessPossibleAppointments,
} from 'shared/repositories/announcements/v1/get-business-possible-appointments';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export async function fetchGetBusinessPossibleAppointmentsService(
  dependencies: IApplicationContext,
): Promise<IGetBusinessPossibleAppointmentsResponse> {
  const { httpApi, logger } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createGetBusinessPossibleAppointmentsModel(),
    'src/shared/store/serverSide/announcements/v1/get-business-possible-appointments/internal/fetchService.ts',
    logger,
  );

  return fetchGetBusinessPossibleAppointments<IGetBusinessPossibleAppointmentsResponse200>({
    httpApi,
  }).then(
    result => result.response,
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
