import { Filters } from 'shared/types/filters';
import { GeoValue } from 'shared/types/filters/geo';

export const getJsonQueryGeo = (filters: Filters): GeoValue[] | undefined => {
  if (!filters.geo?.length) {
    return undefined;
  }

  return [...filters.geo];
};
