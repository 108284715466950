import { FC } from 'react';

import { ModalFooter } from '../../common/ModalFooter/ModalFooter';
import { RegionModalFooterPlaceholder } from '../RegionModalFooterPlaceholder';

import { RegionModalFooterViewProps } from './types';

export const RegionModalFooterView: FC<RegionModalFooterViewProps> = props => {
  const { showPlaceholder, onShowClick } = props;

  return showPlaceholder ? <RegionModalFooterPlaceholder /> : <ModalFooter onShowClick={onShowClick} />;
};

RegionModalFooterView.displayName = 'RegionModalFooterView';
