import type { UnknownError } from '@cian/peperrors/shared';

import { EQueryKeys } from 'shared/store/serverSide/EQueryKeys';

export type QueryError = UnknownError;

export type QueryKey = [EQueryKeys.MonolithCianRealty__Api_Geo_GetDistrictsTree, number];
export const getQueryKey = (locationId: QueryKey[1]): QueryKey => [
  EQueryKeys.MonolithCianRealty__Api_Geo_GetDistrictsTree,
  locationId,
];
