import type { IUndergroundMapDataV1 } from '@cian/underground-map-widget';
import type { QueryClient } from '@tanstack/react-query';

import type { IApplicationContext } from 'shared/types/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetUndergroundMapDataServiceV1 } from './internal/fetchService';

export function fetchGetUndergroundMapDataV1(
  queryClient: QueryClient,
  context: IApplicationContext,
  locationId: number,
): Promise<IUndergroundMapDataV1> {
  return queryClient.fetchQuery<IUndergroundMapDataV1, QueryError, IUndergroundMapDataV1, QueryKey>(
    getQueryKey(locationId),
    () => fetchGetUndergroundMapDataServiceV1(context, locationId),
  );
}
