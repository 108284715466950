import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { filtersSelector } from 'shared/store/data/filters/selectors/root/filtersSelector';

import { getAvailableAdvancedFilters } from '../filters/getAvailableAdvancedFilters';

export function useAvailableAdvancedFilters(): EAdvancedFilterType[] {
  const filters = useSelector(filtersSelector);

  return useMemo(() => getAvailableAdvancedFilters(filters), [filters]);
}
