import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';

import { RangeValue } from 'shared/types/rangeValue';

export function getRangeLabel(range?: RangeValue, postfix?: string): string | undefined {
  if (!range) {
    return undefined;
  }

  const { max, min } = range;

  if (min && !max) {
    return `от ${numberToPrettyString(min)} ${postfix}`;
  }

  if (!min && max) {
    return `до ${numberToPrettyString(max)} ${postfix}`;
  }

  if (min && max) {
    return `${numberToPrettyString(min)} - ${numberToPrettyString(max)} ${postfix}`;
  }

  return undefined;
}
