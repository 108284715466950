import { Option } from 'shared/components/Select/models/Option';
import { mapConditionTypeIndustry } from 'shared/constants/filters/mapConditionTypeIndustry';
import { EOfficeType } from 'shared/types/filters';
import { EConditionType } from 'shared/types/filters/advanced/conditionType';
import { getConditionTypeFields } from 'shared/utils/filters/getConditionTypeFields';

export const getConditionTypeOptions = (officeTypes: EOfficeType[]): Option<EConditionType>[] => {
  const availableFields = getConditionTypeFields(officeTypes);

  return availableFields.map(field => new Option(field, mapConditionTypeIndustry[field]));
};
