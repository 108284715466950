import type { HttpBadStatusCodeError, UnknownError } from '@cian/peperrors/shared';

import { EQueryKeys } from 'shared/store/serverSide/EQueryKeys';

export type QueryError = HttpBadStatusCodeError | UnknownError;

/**
 * @deprecated Будет удалено в рамках COMM-1094, когда будем отказываться от использования меты
 */
export type QueryKeyLegacy = [EQueryKeys.SearchOffersIndex__V2_GetMeta_Legacy];
/**
 * @deprecated Будет удалено в рамках COMM-1094, когда будем отказываться от использования меты
 */
export const getQueryKeyLegacy = (): QueryKeyLegacy => [EQueryKeys.SearchOffersIndex__V2_GetMeta_Legacy];

export type QueryKey = [EQueryKeys.SearchOffersIndex__V2_GetMeta];
export const getQueryKey = (): QueryKey => [EQueryKeys.SearchOffersIndex__V2_GetMeta];
