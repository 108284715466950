import { WAREHOUSE_GROUP } from 'shared/constants/filters/conditionType';
import { EOfficeType } from 'shared/types/filters';
import { EConditionType, EConditionTypeLegacy } from 'shared/types/filters/advanced/conditionType';

export const getLegacyConditionTypeForOffice = (
  conditionType: EConditionType[],
): EConditionTypeLegacy[] | undefined => {
  const legacyConditionType = conditionType.reduce((acc, type) => {
    if (type === EConditionType.WithDecoration || type === EConditionType.OfficeDecoration) {
      acc.push(EConditionTypeLegacy.OfficeDecoration);
    }

    if (type === EConditionType.RegularRepair || type === EConditionType.FullDecorationNeeded) {
      acc.push(EConditionTypeLegacy.FullDecorationNeeded);
    }

    if (type === EConditionType.MajorRepairNeeded) {
      acc.push(EConditionTypeLegacy.MajorRepairNeeded);
    }

    if (type === EConditionType.CosmeticRepairNeeded) {
      acc.push(EConditionTypeLegacy.CosmeticRepairNeeded);
    }

    return acc;
  }, Array.of<EConditionTypeLegacy>());

  if (legacyConditionType.length) {
    return legacyConditionType;
  }

  return undefined;
};

export const getLegacyConditionTypeForTradeAreaGroup = (
  conditionType: EConditionType[],
  officeType: EOfficeType[],
): EConditionTypeLegacy[] | undefined => {
  const legacyConditionType = conditionType.reduce((acc, type) => {
    if (type === EConditionType.WithDecoration || type === EConditionType.DesignerRepair) {
      acc.push(EConditionTypeLegacy.DesignerRepair);
    }

    if (type === EConditionType.RegularRepair) {
      acc.push(EConditionTypeLegacy.RegularRepair);

      if (
        !officeType.length ||
        officeType.some(typeItem => typeItem === EOfficeType.Office || WAREHOUSE_GROUP.includes(typeItem))
      ) {
        acc.push(EConditionTypeLegacy.FullDecorationNeeded);
      }
    }

    if (type === EConditionType.FullDecorationNeeded) {
      acc.push(EConditionTypeLegacy.FullDecorationNeeded);
    }

    if (type === EConditionType.MajorRepairNeeded) {
      acc.push(EConditionTypeLegacy.MajorRepairNeeded);
    }

    if (type === EConditionType.CosmeticRepairNeeded) {
      acc.push(EConditionTypeLegacy.CosmeticRepairNeeded);
    }

    return acc;
  }, Array.of<EConditionTypeLegacy>());

  if (legacyConditionType.length) {
    return legacyConditionType;
  }

  return undefined;
};

export const getLegacyConditionTypeForWarehousGroup = (
  conditionType: EConditionType[],
): EConditionTypeLegacy[] | undefined => {
  const legacyConditionType = conditionType.reduce((acc, type) => {
    if (type === EConditionType.RegularRepair) {
      acc.push(EConditionTypeLegacy.RegularRepair);
    }

    if (type === EConditionType.MajorRepairNeeded) {
      acc.push(EConditionTypeLegacy.MajorRepairNeeded);
    }

    if (type === EConditionType.CosmeticRepairNeeded) {
      acc.push(EConditionTypeLegacy.CosmeticRepairNeeded);
    }

    return acc;
  }, Array.of<EConditionTypeLegacy>());

  if (legacyConditionType.length) {
    return legacyConditionType;
  }

  return undefined;
};
