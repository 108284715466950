/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-161150, будет удалён в задаче CD-161151
 * Удалить если эксперимент будет не удачным
 * Оставить, если будет удачным, написать тесты
 */

import { ca } from '@cian/analytics';

export function trackMotivationTooltipShown(): void {
  ca('eventSite', {
    action: 'owner_filter_tooltip_view',
    category: 'Listing',
    name: 'oldevent',
    page: { pageType: 'Listing' },
  });
}

export function trackMotivationTooltipClosed(): void {
  ca('eventSite', {
    action: 'owner_filter_tooltip_close',
    category: 'Listing',
    name: 'oldevent',
    page: { pageType: 'Listing' },
  });
}
