import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { isCommercialLand } from 'shared/utils/filters/isCommercialLand';
import { isRent } from 'shared/utils/filters/isRent';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Коммерческая земля". Работает только для "Аренда"
 */
export class RentCommercialLandAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return [isRent, isCommercialLand].every(fn => fn(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .filter(field => field !== EAdvancedFilterType.undergroundTime)
      .concat(
        EAdvancedFilterType.contract,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
