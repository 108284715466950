import { useSelector } from 'react-redux';

import { offersCountTooltipTargetSelector } from 'shared/store/data/offersCountTooltip/selectors/dump/offersCountTooltipOpenedSelector copy';
import { OffersCountTooltipTarget } from 'shared/types/offersCountTooltip/offersCountTooltipTarget';

export function useOffersCountTooltip(tooltipTarget?: OffersCountTooltipTarget): {
  opened: boolean;
  openedAtCurrentTarget: boolean;
} {
  const target = useSelector(offersCountTooltipTargetSelector);

  return {
    opened: target !== null,
    openedAtCurrentTarget: !!target && target === tooltipTarget,
  };
}
