import { commarize, deunit, getUnit, round } from 'shared/utils/number';

const SHRUG = '¯\\_(ツ)_/¯';

export function getPriceLabel(min: number | null, max: number | null, postfix?: string): string | undefined {
  let priceMin: string | null = null;
  const priceMax: string | null = max !== null ? commarize(max) : null;

  if (min !== null && max !== null) {
    if (min === 0 && max === 0) {
      return SHRUG;
    }

    const minUnit = getUnit(min);
    const maxUnit = getUnit(max);
    const minWithUnit = min > max || minUnit !== maxUnit;

    const minDeunited = deunit(min, minUnit);

    if (minDeunited >= 100 && maxUnit - minUnit === 1) {
      priceMin = round(minDeunited / 1000, 1)
        .toString()
        .replace(/\./g, ',');
    } else {
      priceMin = commarize(min, !minWithUnit);
    }
  } else if (min !== null) {
    priceMin = commarize(min);
  }

  const formattedPrice = ((): string | undefined => {
    if (priceMin && !priceMax) {
      return `от ${priceMin}`;
    } else if (!priceMin && priceMax) {
      return `до ${priceMax}`;
    } else if (priceMin && priceMax) {
      return `${priceMin} - ${priceMax}`;
    }

    return undefined;
  })();

  if (formattedPrice) {
    return `${formattedPrice}${postfix ? ` ${postfix}` : ''}`;
  }

  return undefined;
}
