import { QueryClient } from '@tanstack/react-query';

import type { IServerSideState } from '../IServerSideState';

import { ServerSideState } from './ServerSideState';

export class NodeServerSideState extends ServerSideState implements IServerSideState {
  public constructor() {
    const queryClient = new QueryClient();

    super(queryClient);
  }
}
