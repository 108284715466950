import { Button } from '@cian/ui-kit';
import { FC } from 'react';

import { ClearButtonProps } from './types';

export const ClearButton: FC<ClearButtonProps> = props => {
  return (
    <Button size="XS" theme="fill_white_primary" onClick={props.onClick}>
      Сбросить фильтры
    </Button>
  );
};
