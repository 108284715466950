import { createSelector } from 'reselect';

import { GEO_FILTERS } from 'shared/constants/filters/geoFilters';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { getAvailableAdvancedFilters } from 'shared/utils/filters/getAvailableAdvancedFilters';

import { filtersSelector } from '../root/filtersSelector';

export const advancedFiltersCountSelector = createSelector(filtersSelector, filters => {
  const availableAdvancedFilters = getAvailableAdvancedFilters(filters);

  const availableAdvancedFiltersInsideModal = availableAdvancedFilters.filter(
    item => !GEO_FILTERS.includes(item) && item !== EAdvancedFilterType.idUser,
  );

  return availableAdvancedFiltersInsideModal.reduce((count, item) => {
    const filter = filters[item];

    if (filter) {
      if (Array.isArray(filter)) {
        return filter.length ? count + 1 : count;
      }

      return count + 1;
    }

    return count;
  }, 0);
});
