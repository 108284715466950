import { useCallback, useRef } from 'react';

import { OffersCountTooltip } from 'shared/components/OffersCountTooltip';
import { ELabelView } from 'shared/components/PopupFilterControlled/types';
import { RangeInputContainer } from 'shared/components/RangeInputContainer';
import { EFilterType } from 'shared/enums/EFilterType';
import { getWorkplaceCountLabel } from 'shared/utils/getWorkplaceCountLabel';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { PopupFilterControlled } from '../../PopupFilterControlled';

import styles from './WorkplaceCountFilter.css';

export const WorkplaceCountFilter = memoWithDisplayName(() => {
  const workplaceCount = useFilter(EFilterType.workplaceCount);

  const { changeWorkplaceCountMinAction, changeWorkplaceCountMaxAction } = useFiltersActions();

  const { openedAtCurrentTarget } = useOffersCountTooltip(EFilterType.workplaceCount);
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const containerRef = useRef(null);

  const handleChangeMin = useCallback(
    (value: number) => {
      setTargetOffersCountTooltip(EFilterType.workplaceCount);
      changeWorkplaceCountMinAction(value);
    },
    [setTargetOffersCountTooltip, changeWorkplaceCountMinAction],
  );

  const handleChangeMax = useCallback(
    (value: number) => {
      setTargetOffersCountTooltip(EFilterType.workplaceCount);
      changeWorkplaceCountMaxAction(value);
    },
    [setTargetOffersCountTooltip, changeWorkplaceCountMaxAction],
  );

  const content = (
    <OffersCountTooltip hostElement={containerRef.current} opened={openedAtCurrentTarget} placement="right">
      <div className={styles['container']}>
        <RangeInputContainer
          maxWidth={176}
          valueMax={workplaceCount?.max}
          valueMin={workplaceCount?.min}
          onChangeMax={handleChangeMax}
          onChangeMin={handleChangeMin}
        />
      </div>
    </OffersCountTooltip>
  );

  return (
    <div ref={containerRef}>
      <PopupFilterControlled
        filterType={EFilterType.workplaceCount}
        label={getWorkplaceCountLabel(workplaceCount)}
        labelView={ELabelView.Button}
        placeholder="Кол-во мест"
        popupContent={content}
      />
    </div>
  );
}, 'WorkplaceCountFilter');
