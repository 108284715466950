import { IItemClickParams, ILocationSuggest } from '@cian/geosuggest-widget';
import { FC, useState } from 'react';

import { ISearchCatalogItemSchema } from 'shared/repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';
import { useSearchRegionsCitiesQuery } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/search-regions-cities/query';
import { regionsCitiesFromSearchRegionsCitiesSelector } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/search-regions-cities/selectors';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';

import { GeoSuggest } from '../../GeoSuggest';

import { ModalGeoSuggestProps } from './types';

export const ModalGeoSuggest: FC<ModalGeoSuggestProps> = props => {
  const [searchValue, setSearchValue] = useState('');

  const { data: regionsCities, isLoading } = useSearchRegionsCitiesQuery(searchValue, {
    select: regionsCitiesFromSearchRegionsCitiesSelector,
  });

  const { changeRegionAction } = useFiltersActions();

  /**
   * @todo Вынести в модель
   * @description Данный блок появился в задаче CD-131027, будет удалён в задаче CD-133404
   */
  const suggestData = regionsCities
    ? regionsCities.map((suggestion: ISearchCatalogItemSchema) => ({
        fullName: suggestion.displayName,
        id: suggestion.id,
        name: suggestion.displayName,
        regionId: suggestion.parentId,
      }))
    : null;

  const onSelectItem = (suggestion: IItemClickParams): void => {
    props.onSelectItem();

    if (suggestion.id) {
      changeRegionAction([suggestion.id]);
    }
  };

  return (
    <GeoSuggest
      isLoading={isLoading}
      placeholder="Введите регион или город"
      suggestions={
        suggestData
          ? {
              sortOrder: ['cities'],
              /**
               * @todo Разобраться с приведением типов
               * @description Данный блок появился в задаче CD-131027, будет удалён в задаче CD-133404
               */
              suggestions: suggestData.length ? { cities: suggestData as ILocationSuggest[] } : {},
            }
          : null
      }
      value={searchValue}
      onItemClick={onSelectItem}
      onValueChange={setSearchValue}
    />
  );
};

ModalGeoSuggest.displayName = 'ModalGeoSuggest';
