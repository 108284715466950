import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { ICianApiSiteV1GetRegionCitiesResponse } from 'shared/repositories/monolith-python/cian-api/site/v1/get-region-cities';
import { useGetCurrentRegionQuery } from 'shared/store/serverSide/monolith-python/cian-api/site/v1/get-region/query';
import { useApplicationContext } from 'shared/utils/applicationContext';

import { dataFromGetRegionSelector } from '../get-region/selectors';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetRegionCitiesService } from './internal/fetchService';

export function useGetRegionCities<TData = ICianApiSiteV1GetRegionCitiesResponse>(
  options?: UseQueryOptions<ICianApiSiteV1GetRegionCitiesResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();

  const { data: currentRegion } = useGetCurrentRegionQuery({ select: dataFromGetRegionSelector });

  const parentId = currentRegion?.parentId;
  const id = currentRegion?.id;

  const currentRegionId = parentId ?? id;

  return useQuery<ICianApiSiteV1GetRegionCitiesResponse, QueryError, TData, QueryKey>({
    enabled: !!currentRegionId,
    keepPreviousData: true,
    queryFn: () => fetchGetRegionCitiesService(context, { regionId: currentRegionId as number }),
    queryKey: getQueryKey(currentRegionId),
    ...options,
  });
}
