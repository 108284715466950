// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ICianApiSiteV1GetRegionCitiesRequest,
  TCianApiSiteV1GetRegionCitiesModel,
  IMappers,
  TCianApiSiteV1GetRegionCitiesResponse,
  ICianApiSiteV1GetRegionCitiesResponse,
  ICianApiSiteV1GetRegionCitiesResponseError,
} from './types';

export const defaultConfig: TCianApiSiteV1GetRegionCitiesModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-region-cities/',
} as TCianApiSiteV1GetRegionCitiesModel;

export function createCianApiSiteV1GetRegionCitiesModel(
  parameters: ICianApiSiteV1GetRegionCitiesRequest,
): TCianApiSiteV1GetRegionCitiesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICianApiSiteV1GetRegionCitiesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICianApiSiteV1GetRegionCitiesRequest;
}

export async function fetchCianApiSiteV1GetRegionCities<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1GetRegionCitiesOptions<TResponse200, TResponse400>): Promise<
  TCianApiSiteV1GetRegionCitiesResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCianApiSiteV1GetRegionCitiesModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICianApiSiteV1GetRegionCitiesResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ICianApiSiteV1GetRegionCitiesResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCianApiSiteV1GetRegionCitiesResponse;
}
