import { IMaskedInputProps, MaskedInput } from '@cian/ui-kit';
import { useCallback } from 'react';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const OfferIdFilter = memoWithDisplayName(() => {
  const offerId = useFilter(EAdvancedFilterType.id);
  const { setTermFilterValue } = useFiltersActions();

  const onChange = useCallback<NonNullable<IMaskedInputProps['onChange']>>(
    (value: string) => {
      setTermFilterValue({ name: EAdvancedFilterType.id, value: Number(value) });
    },
    [setTermFilterValue],
  );

  return <MaskedInput lazy mask="00000000000000" value={String(offerId)} width={260} onChange={onChange} />;
}, 'OfferIdFilter');
