import { ArticleParagraph2 } from '@cian/ui-kit';

import { RenderOption } from 'shared/components/Select/types';
import { SelectOption } from 'shared/components/SelectOption';

import styles from './styles.css';

export const singleOptionRenderer: RenderOption = props => {
  const { checked, label, description } = props;

  return (
    <>
      <SelectOption checked={checked} label={label} />
      {description && (
        <div className={styles['description']}>
          <ArticleParagraph2 color={'black_60'}>{description}</ArticleParagraph2>
        </div>
      )}
    </>
  );
};
