import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';

export const filterTypeToFilterLabelMapper: Partial<Record<EAdvancedFilterType, string>> = {
  [EAdvancedFilterType.publishPeriod]: 'Дата публикации',
  [EAdvancedFilterType.totalArea]: 'Площадь',
  [EAdvancedFilterType.specialtyTypes]: 'Назначение',
  [EAdvancedFilterType.undergroundTime]: 'Расстояние до метро',
  [EAdvancedFilterType.furniture]: 'Мебель',
  [EAdvancedFilterType.contract]: 'Тип договора',
  [EAdvancedFilterType.buildingClassType]: 'Класс',
  [EAdvancedFilterType.floor]: 'Этаж',
  [EAdvancedFilterType.floorn]: 'Этажей в здании',
  [EAdvancedFilterType.inputType]: 'Вход',
  [EAdvancedFilterType.buildingLiftTypesType]: 'Лифт',
  [EAdvancedFilterType.buildingCranageTypesType]: 'Кран',
  [EAdvancedFilterType.buildingGatesType]: 'Ворота',
  [EAdvancedFilterType.buildingHeatingType]: 'Отопление',
  [EAdvancedFilterType.conditionType]: 'Состояние',
  [EAdvancedFilterType.amenity]: 'Включено в\u00A0стоимость',
  [EAdvancedFilterType.coworkingAccess]: 'Доступ',
  [EAdvancedFilterType.buildingHouseLine]: 'Линия домов',
  [EAdvancedFilterType.houseYear]: 'Год постройки',
  [EAdvancedFilterType.placementType]: 'Тип помещения',
  [EAdvancedFilterType.parkingType]: 'Парковка',
  [EAdvancedFilterType.infrastructure]: 'Инфраструктура',
  [EAdvancedFilterType.containWords]: 'Ключевые слова',
  [EAdvancedFilterType.excludeWords]: 'Исключить слова',
  [EAdvancedFilterType.contact]: 'Номер телефона',
  [EAdvancedFilterType.estateTypes]: 'Недвижимость',
  [EAdvancedFilterType.id]: 'Номер объявления',
  [EAdvancedFilterType.accessSystem]: 'Пропускной режим',
  [EAdvancedFilterType.commissionType]: 'Комиссия',
  [EAdvancedFilterType.buildingType]: 'Тип здания',
  [EAdvancedFilterType.electronicTrading]: 'Торги и залоговая недвижимость',
};
