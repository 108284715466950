import { EFilterType } from 'shared/enums/EFilterType';
import { EDealType, EOfficeType, Filters } from 'shared/types/filters';
import { EPriceType } from 'shared/types/filters/priceType';

export const DEFAULT_FILTERS: Filters = {
  [EFilterType.dealType]: EDealType.Rent,
  [EFilterType.officeType]: [EOfficeType.Office],
  [EFilterType.priceType]: EPriceType.Total,
  [EFilterType.region]: [1],
  [EFilterType.BusinessAppointments]: Array.of<number>(),
};
