import { useToggleState } from '@cian/react-utils';
import { FC, useCallback } from 'react';

import { EButtonTarget } from 'shared/types/offersCountTooltip/offersCountTooltipTarget';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';

import { AvailableAdvancedFiltersContainer } from '../AvailableAdvancedFilters';

import { AdvancedFiltersModal } from './AdvancedFiltersModal';
import { AdvancedFiltersModalContainerProps } from './types';

export const AdvancedFiltersModalContainer: FC<AdvancedFiltersModalContainerProps> = props => {
  const { state: isAdvancedFiltersModalOpened, toggle: toggleAdvancedFiltersModalOpened } = useToggleState();
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const setAdvancedFiltersModalOpened = useCallback(() => {
    toggleAdvancedFiltersModalOpened();

    setTargetOffersCountTooltip(null);
  }, [setTargetOffersCountTooltip, toggleAdvancedFiltersModalOpened]);

  const setAdvancedFiltersModalClosed = useCallback(() => {
    toggleAdvancedFiltersModalOpened();

    setTargetOffersCountTooltip(EButtonTarget.moreButton);
  }, [setTargetOffersCountTooltip, toggleAdvancedFiltersModalOpened]);

  return (
    <>
      {props.children(isAdvancedFiltersModalOpened, setAdvancedFiltersModalOpened)}
      <AdvancedFiltersModal
        open={isAdvancedFiltersModalOpened}
        onClose={setAdvancedFiltersModalClosed}
        onShowClick={toggleAdvancedFiltersModalOpened}
      >
        <AvailableAdvancedFiltersContainer />
      </AdvancedFiltersModal>
    </>
  );
};

AdvancedFiltersModalContainer.displayName = 'AdvancedFiltersModalContainer';
