// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TCianApiSiteV1GetRegionsModel,
  IMappers,
  TCianApiSiteV1GetRegionsResponse,
  ICianApiSiteV1GetRegionsResponse,
  ICianApiSiteV1GetRegionsResponseError,
} from './types';

export const defaultConfig: TCianApiSiteV1GetRegionsModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-regions/',
} as TCianApiSiteV1GetRegionsModel;

export function createCianApiSiteV1GetRegionsModel(): TCianApiSiteV1GetRegionsModel {
  return {
    ...defaultConfig,
  };
}

export interface ICianApiSiteV1GetRegionsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

export async function fetchCianApiSiteV1GetRegions<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
}: ICianApiSiteV1GetRegionsOptions<TResponse200, TResponse400>): Promise<
  TCianApiSiteV1GetRegionsResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createCianApiSiteV1GetRegionsModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICianApiSiteV1GetRegionsResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ICianApiSiteV1GetRegionsResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCianApiSiteV1GetRegionsResponse;
}
