import { EFilterType } from 'shared/enums/EFilterType';

import { IFiltersService } from '../IFiltersService';

import { AbstractFiltersService } from './AbstractFiltersService';

export class AnyAnyFiltersService extends AbstractFiltersService implements IFiltersService<EFilterType> {
  public isAvailable(): boolean {
    return true;
  }

  public getFields(): EFilterType[] {
    return super.getFields().concat(EFilterType.price, EFilterType.totalArea, EFilterType.isByCommercialOwner);
  }
}
