import { EOfficeType, Filters } from 'shared/types/filters';
import { hasBusinessOfficeType } from 'shared/utils/filters/hasBusinessOfficeType';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { hasWorkplaceOfficeType } from 'shared/utils/filters/hasWorkplaceOfficeType';
import { isCommercialLand } from 'shared/utils/filters/isCommercialLand';

export function getJsonQueryOfficeType(filters: Filters): EOfficeType[] | undefined {
  const { officeType } = filters;

  if (isCommercialLand(filters) || !officeType.length) {
    return undefined;
  }

  if (hasCoworkingOfficeType(filters) || hasWorkplaceOfficeType(filters)) {
    return [EOfficeType.Office];
  }

  if (hasBusinessOfficeType(filters)) {
    const officeTypesWithoutBusinessTypes = officeType.filter(
      type => ![EOfficeType.RentalBusiness, EOfficeType.ReadyBusiness].includes(type),
    );

    return [...officeTypesWithoutBusinessTypes, EOfficeType.Business];
  }

  return officeType;
}
