import { useCallback, useRef } from 'react';

import { PopupFilterControlled } from 'shared/components/PopupFilterControlled';
import { ELabelView } from 'shared/components/PopupFilterControlled/types';
import { Select } from 'shared/components/Select';
import { IOption } from 'shared/components/Select/models/Option';
import { WORKPLACE_TYPES } from 'shared/constants/filters/workplaceTypes';
import { EFilterType } from 'shared/enums/EFilterType';
import { getFilterOptions } from 'shared/utils/getFilterOptions';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const WorkplaceTypeFilter = memoWithDisplayName(() => {
  const workplaceType = useFilter(EFilterType.workplaceType);
  const { changeWorkplaceTypeAction } = useFiltersActions();

  const values = new Set(workplaceType);
  const options = getFilterOptions(WORKPLACE_TYPES, 'Тип рабочего места');
  const label = options.getLabel(values);

  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const containerRef = useRef(null);

  const handleOptionChange = useCallback<(workplaceTypeParam: IOption) => void>(
    workplaceTypeParam => {
      setTargetOffersCountTooltip(EFilterType.workplaceType);
      changeWorkplaceTypeAction(workplaceTypeParam.value);
    },
    [changeWorkplaceTypeAction, setTargetOffersCountTooltip],
  );

  const content = (
    <Select
      withTooltip
      hostElement={containerRef.current}
      options={options}
      values={values}
      onOptionChange={handleOptionChange}
    />
  );

  return (
    <div ref={containerRef}>
      <PopupFilterControlled
        filterType={EFilterType.workplaceType}
        label={label}
        labelView={ELabelView.Button}
        placeholder="Тип рабочего места"
        popupContent={content}
      />
    </div>
  );
}, 'WorkplaceTypeFilter');
