import { Select } from '@cian/ui-kit';

import { PUBLISH_PERIOD_OPTIONS } from 'shared/constants/filters/publishPeriods';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { WithPhotoFilter } from '../WithPhotoFilter';

import styles from './PublishPeriodFilter.css';

export const PublishPeriodFilter = memoWithDisplayName(() => {
  const publishPeriod = useFilter(EAdvancedFilterType.publishPeriod);
  const { changePublishPeriodAction } = useFiltersActions();

  return (
    <div className={styles['container']}>
      <Select
        maxWidth={150}
        options={PUBLISH_PERIOD_OPTIONS}
        size="XS"
        value={publishPeriod}
        onChange={changePublishPeriodAction}
      />
      <div className={styles['additional-filter']}>
        <WithPhotoFilter />
      </div>
    </div>
  );
}, 'PublishPeriodFilter');
