import { useMemo } from 'react';

import { EFilterType } from 'shared/enums/EFilterType';
import { IReadyBusinessGroupItem } from 'shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { useGetBusinessPossibleAppointmentsQuery } from 'shared/store/serverSide/announcements/v1/get-business-possible-appointments/query';
import { getIsBusinessCategory } from 'shared/utils/getIsBusinessCategory';
import { useFilter } from 'shared/utils/hooks/useFilter';

export function useBusinessAppointmentsCheckboxGroupsSelector(): Array<IReadyBusinessGroupItem> {
  const { data: businessPossibleAppointments } = useGetBusinessPossibleAppointmentsQuery();
  const officeType = useFilter(EFilterType.officeType);

  return useMemo(() => {
    const readyBusinessGroupItems = Array.of<IReadyBusinessGroupItem>();

    if (!businessPossibleAppointments) {
      return readyBusinessGroupItems;
    }

    const { isRentalBusiness, isReadyBusiness } = getIsBusinessCategory(officeType);

    if (isRentalBusiness) {
      readyBusinessGroupItems.push({
        groupType: 'rentalBusiness',
        items: businessPossibleAppointments.rentalBusiness.items,
        name: 'Арендный бизнес',
      });
    }

    if (isReadyBusiness) {
      readyBusinessGroupItems.push(...businessPossibleAppointments.readyBusiness.groups);
    }

    return readyBusinessGroupItems;
  }, [businessPossibleAppointments, officeType]);
}
