import { TRADE_AREA_GROUP, WAREHOUSE_GROUP } from 'shared/constants/filters/conditionType';
import { EOfficeType } from 'shared/types/filters';
import { EConditionType } from 'shared/types/filters/advanced/conditionType';

export const getConditionTypeFields = (officeTypes: EOfficeType[]): EConditionType[] => {
  if (!officeTypes.length) {
    return [
      EConditionType.WithDecoration,
      EConditionType.RegularRepair,
      EConditionType.MajorRepairNeeded,
      EConditionType.CosmeticRepairNeeded,
    ];
  }

  if (officeTypes.length === 1 && officeTypes.includes(EOfficeType.Office)) {
    return [
      EConditionType.OfficeDecoration,
      EConditionType.FullDecorationNeeded,
      EConditionType.MajorRepairNeeded,
      EConditionType.CosmeticRepairNeeded,
    ];
  }

  if (officeTypes.every(field => TRADE_AREA_GROUP.includes(field))) {
    return [
      EConditionType.RegularRepair,
      EConditionType.DesignerRepair,
      EConditionType.FullDecorationNeeded,
      EConditionType.MajorRepairNeeded,
      EConditionType.CosmeticRepairNeeded,
    ];
  }

  if (officeTypes.every(field => WAREHOUSE_GROUP.includes(field))) {
    return [EConditionType.RegularRepair, EConditionType.MajorRepairNeeded, EConditionType.CosmeticRepairNeeded];
  }

  return [
    EConditionType.WithDecoration,
    EConditionType.RegularRepair,
    EConditionType.MajorRepairNeeded,
    EConditionType.CosmeticRepairNeeded,
  ];
};
