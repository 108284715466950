export enum EBusinessType {
  RentalBusiness = 1,
  ReadyBusiness = 2,
  All = 3,
}

export type BusinessType = {
  label: string;
  value: EBusinessType;
  shortLabel?: string;
};
