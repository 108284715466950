import { useCallback } from 'react';

import { ELabelView } from 'shared/components/PopupFilterControlled/types';
import { Select } from 'shared/components/Select';
import { Option } from 'shared/components/Select/models/Option';
import { singleOptionRenderer } from 'shared/components/Select/renderers';
import { EFilterType } from 'shared/enums/EFilterType';
import { EPriceType } from 'shared/types/filters/priceType';
import { getAvailablePriceTypes } from 'shared/utils/getAvailablePriceTypes';
import { getFilterOptions } from 'shared/utils/getFilterOptions';
import { useFilters } from 'shared/utils/hooks/useFilters';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { PopupFilterControlled } from '../../PopupFilterControlled';

export const PriceTypeFilter = memoWithDisplayName(() => {
  const filters = useFilters();
  const { changePriceTypeAction } = useFiltersActions();
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const values = new Set([filters.priceType ?? EPriceType.Total]);
  const availablePriceTypes = getAvailablePriceTypes(filters);
  const options = getFilterOptions(availablePriceTypes, 'Тип цены');
  const label = options.getLabel(values).toLowerCase();

  const handleOptionChange = useCallback(
    (value: Option<number>) => {
      setTargetOffersCountTooltip(EFilterType.price);
      changePriceTypeAction(value);
    },
    [setTargetOffersCountTooltip, changePriceTypeAction],
  );

  const content = (
    <Select options={options} renderOption={singleOptionRenderer} values={values} onOptionChange={handleOptionChange} />
  );

  return (
    <PopupFilterControlled
      closeAfterClick
      filterType={EFilterType.priceType}
      isHighlightedActiveState={false}
      label={label}
      labelView={ELabelView.Text}
      placeholder="Тип цены"
      popupContent={content}
    />
  );
}, 'PriceTypeFilter');
