import { EOfficeType } from '../types/filters';

export function getIsBusinessCategory(
  officeType: Array<EOfficeType>,
): Record<`is${Capitalize<'all' | 'readyBusiness' | 'rentalBusiness'>}`, boolean> {
  let isRentalBusiness = false;
  let isReadyBusiness = false;

  for (const officeTypeElement of officeType) {
    if (officeTypeElement === EOfficeType.RentalBusiness) {
      isRentalBusiness = true;
    } else if (officeTypeElement === EOfficeType.ReadyBusiness) {
      isReadyBusiness = true;
    }
  }

  return {
    isAll: isReadyBusiness && isRentalBusiness,
    isReadyBusiness,
    isRentalBusiness,
  };
}
