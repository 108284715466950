import { IMaskedInputProps, MaskedInput } from '@cian/ui-kit';
import { useCallback } from 'react';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const ContactFilter = memoWithDisplayName(() => {
  const contactPhone = useFilter(EAdvancedFilterType.contact);
  const { setTermFilterValue } = useFiltersActions();

  const handleChangeContactPhone = useCallback<NonNullable<IMaskedInputProps['onChange']>>(
    value => {
      setTermFilterValue({ name: EAdvancedFilterType.contact, value: value.replace(/-|\s|\+7|_/g, '') });
    },
    [setTermFilterValue],
  );

  return (
    <MaskedInput lazy mask="+7 000 000-00-00" value={contactPhone} width={260} onChange={handleChangeContactPhone} />
  );
}, 'ContactFilter');
