export enum EWorkplaceType {
  /**
   * @field
   * @description Закреплённое рабочее место
   */
  FixedWorkSpace = 2,
  /**
   * @field
   * @description Незакреплённое рабочее место
   */
  FreeWorkSpace = 3,
}

export type WorkplaceType = {
  label: string;
  value: EWorkplaceType;
  description: string;
  shortLabel?: string;
};
