/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-161150, будет удалён в задаче CD-161151
 */

import { FROM_OWNER_TOOLTIP_EXPERIMENT } from 'shared/store/serverSide/ab-use/v1/get-multiple-experiment/external/experiments';
import { useAbUseExperimentsQuery } from 'shared/store/serverSide/ab-use/v1/get-multiple-experiment/query';
import { getIsAbUseExperimentsActiveFactory } from 'shared/utils/abUseExperiments';
import { useApplicationContext } from 'shared/utils/applicationContext';

const isAbUseExperimentsActive = getIsAbUseExperimentsActiveFactory(FROM_OWNER_TOOLTIP_EXPERIMENT)('B');

export function useFromOwnerTooltipExperimentActive(): boolean {
  const { data: isFromOwnerExperimentActive } = useAbUseExperimentsQuery({ select: isAbUseExperimentsActive });
  const { config } = useApplicationContext();

  return !!isFromOwnerExperimentActive && !!config.get<boolean>('Commercial.FromOwnerTooltipExperiment.Enabled');
}
