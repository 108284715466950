import { IGlobalEventsLogs, getGlobalEventsLogs } from '@cian/events-log';
import { ConfigurationError } from '@cian/peperrors/shared';

import type { UpdateFiltersData } from 'shared/types/UpdateFiltersData';

import type { ICommercialFiltersEventsLog } from '../ICommercialFiltersEventsLog';

export class CommercialFiltersEventsLog implements ICommercialFiltersEventsLog {
  public static events: ICommercialFiltersEventsLog | null;

  private static readonly COMMERCIAL_FILTERS = 'commercial_filters';

  public static init(): ICommercialFiltersEventsLog {
    if (!CommercialFiltersEventsLog.events) {
      CommercialFiltersEventsLog.events = new CommercialFiltersEventsLog();
    }

    return CommercialFiltersEventsLog.events;
  }

  public static getEvents(): ICommercialFiltersEventsLog {
    if (!CommercialFiltersEventsLog.events) {
      throw new ConfigurationError({
        domain: 'events.getEvents',
        message: 'Events must be created before accessing it. Forgot to call init() before accessing events?',
      });
    }

    return CommercialFiltersEventsLog.events;
  }

  private readonly globalLog: IGlobalEventsLogs;

  public constructor() {
    this.globalLog = getGlobalEventsLogs();
  }

  public fireUpdateFiltersEvent = (data: UpdateFiltersData): void => {
    // @ts-expect-error, после выпила фильтров из серпа будет не актуально
    this.globalLog.produce(CommercialFiltersEventsLog.COMMERCIAL_FILTERS, { type: 'FILTERS_UPDATED', value: data });
  };

  public saveSearchButtonOpenEvent = (): void => {
    this.globalLog.produce(CommercialFiltersEventsLog.COMMERCIAL_FILTERS, {
      type: 'SAVE_SEARCH_BUTTON_OPENED',
      value: null,
    });
  };
}
