import {
  ICianApiSiteV1GetUserDataByRequestResponse200,
  createCianApiSiteV1GetUserDataByRequestModel,
  fetchCianApiSiteV1GetUserDataByRequest,
} from 'shared/repositories/monolith-python/cian-api/site/v1/get-user-data-by-request';
import { IFastFiltersUserResponseSchema83 } from 'shared/repositories/monolith-python/entities/schemas/FastFiltersUserResponseSchema83';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export function fetchCianApiSiteV1GetUserDataByRequestService(
  applicationContext: IApplicationContext,
): Promise<IFastFiltersUserResponseSchema83> {
  const {
    httpApi,
    custom: { subdomain },
    logger,
  } = applicationContext;

  const fetchAssertsService = new FetchAssertsService(
    createCianApiSiteV1GetUserDataByRequestModel(),
    'src/shared/store/serverSide/monolith-python/cian-api/site/v1/get-user-data-by-request/internal/fetchService.ts',
    logger,
  );

  return fetchCianApiSiteV1GetUserDataByRequest<ICianApiSiteV1GetUserDataByRequestResponse200>({
    config: { subdomain },
    httpApi,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
