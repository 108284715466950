import { EInputType, GeoSuggest as GeoSuggestWidget } from '@cian/geosuggest-widget';
import { Spinner } from '@cian/ui-kit';
import cn from 'clsx';
import { FC } from 'react';

import { GeoSuggestProps } from './types';

import styles from './styles.css';

export const GeoSuggest: FC<GeoSuggestProps> = props => {
  const isEmptyResults = props.suggestions && Object.keys(props.suggestions.suggestions).length === 0;

  return (
    <div className={styles['container']}>
      <div className={styles['addon-left']}>{props.addonLeft}</div>
      <GeoSuggestWidget
        error={isEmptyResults ? { text: 'Укажите другой адрес', title: 'Ничего не найдено' } : undefined}
        inputStyle={cn(styles['input'], {
          [styles['with-addons']]: props.addonLeft,
        })}
        inputType={EInputType.search}
        placeholder={props.placeholder}
        suggestData={props.suggestions ?? undefined}
        value={props.value}
        onItemClick={props.onItemClick}
        onValueChange={props.onValueChange}
      />
      <div className={styles['addon-right']}>
        {props.isLoading && (
          <div className={styles['spinner']}>
            <Spinner color="gray_icons_100" size={16} />
          </div>
        )}
        {props.addonRight}
      </div>
    </div>
  );
};

GeoSuggest.displayName = 'GeoSuggest';
