import { useForkRef } from '@cian/react-utils';
import { Ref, useEffect, useRef, useState } from 'react';

const isHtmlElement = (el: ChildNode | null | undefined): el is HTMLElement => el?.nodeType === 1;

export const useWithCounter = <T extends HTMLElement>(
  count: number | undefined,
  ref: Ref<T>,
): [boolean, ((value: T) => void) | null] => {
  const [isCounterVisible, setCounterVisible] = useState(false);
  const overflowedElementRef = useRef<T>(null);

  useEffect(() => {
    const firstChild = overflowedElementRef.current?.firstChild;

    if (!isHtmlElement(firstChild)) {
      return;
    }

    const isTextOverflowed = firstChild.offsetWidth < firstChild.scrollWidth;

    setCounterVisible(isTextOverflowed);
  }, [overflowedElementRef, count]);

  const setMultiplyRef = useForkRef(ref, overflowedElementRef);

  return [isCounterVisible, setMultiplyRef];
};
