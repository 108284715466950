import { ActionCreatorsMapObject } from 'redux';

import { validateRangeFilterAction } from 'shared/store/data/filters/actions';

import { useBoundActions } from './useBoundActions';

const actions = {
  validateRangeFilterAction,
};

export function useValidateRangeFiltersActions(): ActionCreatorsMapObject<typeof actions> {
  return useBoundActions(actions);
}
