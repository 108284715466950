import { EBuildingClassType } from 'shared/types/filters/buildingClassType';

export const getBuildingClassTypes = (buildingClassTypes: EBuildingClassType[]): EBuildingClassType[] => {
  const newBuildingClassTypes = [...buildingClassTypes];

  if (newBuildingClassTypes.includes(EBuildingClassType.A)) {
    newBuildingClassTypes.push(EBuildingClassType.APlus);
  }

  if (newBuildingClassTypes.includes(EBuildingClassType.B)) {
    newBuildingClassTypes.push(EBuildingClassType.BMinus, EBuildingClassType.BPlus);
  }

  if (newBuildingClassTypes.includes(EBuildingClassType.C)) {
    newBuildingClassTypes.push(EBuildingClassType.CPlus);
  }

  return newBuildingClassTypes;
};
