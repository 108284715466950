import { toggleFilterValue } from './toggleFilterValue';

export function toggleOrDeleteFilterValue<T>(filterValue: T, filtersField: T[] | undefined): T[] | undefined {
  const toggledValue = toggleFilterValue(filterValue, filtersField);

  if (toggledValue.length) {
    return toggledValue;
  }

  return undefined;
}
