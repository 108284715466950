import { useSelector } from 'react-redux';

import { geoSelector } from 'shared/store/data/filters/selectors/dump/geoSelector';
import { Underground } from 'shared/types/filters/geo';

export function useSelectedUndergroundIds(): number[] {
  const geo = useSelector(geoSelector);

  const undergrounds: Underground[] | undefined = geo?.filter(geoItem => geoItem.type === 'underground') as
    | Underground[]
    | undefined;

  return undergrounds?.map(geoItem => geoItem.id) ?? [];
}
