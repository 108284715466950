import { useCallback } from 'react';

import { PopupFilterControlled } from 'shared/components/PopupFilterControlled';
import { ELabelView } from 'shared/components/PopupFilterControlled/types';
import { Select } from 'shared/components/Select';
import { IOption } from 'shared/components/Select/models/Option';
import { singleOptionRenderer } from 'shared/components/Select/renderers';
import { DEAL_TYPES } from 'shared/constants/filters/dealTypes';
import { EFilterType } from 'shared/enums/EFilterType';
import { getFilterOptions } from 'shared/utils/getFilterOptions';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const DealTypeFilter = memoWithDisplayName(() => {
  const dealType = useFilter(EFilterType.dealType);
  const { changeDealTypeAction } = useFiltersActions();
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const values = new Set([dealType]);
  const options = getFilterOptions(DEAL_TYPES, 'Тип сделки');
  const label = options.getLabel(values);

  const handleOptionChange = useCallback<(dealTypeParam: IOption) => void>(
    dealTypeParam => {
      setTargetOffersCountTooltip(EFilterType.dealType);
      changeDealTypeAction(dealTypeParam.value);
    },
    [changeDealTypeAction, setTargetOffersCountTooltip],
  );

  const content = (
    <Select options={options} renderOption={singleOptionRenderer} values={values} onOptionChange={handleOptionChange} />
  );

  return (
    <PopupFilterControlled
      closeAfterClick
      filterType={EFilterType.dealType}
      label={label}
      labelView={ELabelView.Button}
      popupContent={content}
    />
  );
}, 'DealTypeFilter');

DealTypeFilter.displayName = 'DealTypeFilter';
