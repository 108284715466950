// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IGeoLocationCatalogLink {
  /** Id geo-объекта из наших баз. **/
  id: number;
  /** Тип гео-объекта. **/
  objectType: EObjectType;
}
export enum EObjectType {
  /** Страна **/
  'Country' = 'Country',
  /** Локация **/
  'Location' = 'Location',
  /** Улица **/
  'Street' = 'Street',
  /** Шоссе **/
  'Road' = 'Road',
  /** Район **/
  'District' = 'District',
  /** Метро **/
  'Underground' = 'Underground',
  /** Дом **/
  'House' = 'House',
  /** Въезд на шоссе **/
  'EntryIntoRoad' = 'EntryIntoRoad',
  /** ЖК - новостройки **/
  'NewObject' = 'NewObject',
}
