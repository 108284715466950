import type { IJsonQueryTerms } from '../IJsonQueryElement';

export class JsonQueryTerms<K> implements IJsonQueryTerms<K> {
  public readonly type = 'terms';
  public value: K[];

  public constructor(value: K[]) {
    this.value = value;
  }
}
