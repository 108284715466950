import { ReactNode } from 'react';

import { EFilterType } from 'shared/enums/EFilterType';

export interface IPopupFilterControlledProps {
  placeholder?: string;
  popupContent: ReactNode;
  label?: string;
  count?: number;
  filterType: EFilterType | null;
  isHighlightedActiveState?: boolean;
  labelView: ELabelView;
  closeAfterClick?: boolean;
}

export enum ELabelView {
  Button = 'button',
  Text = 'text',
  Select = 'select',
}
