import { ICianApiSiteV1GetRegionsResponse } from 'shared/repositories/monolith-python/cian-api/site/v1/get-regions';

export function dataFromGetRegionsSelector(
  state: ICianApiSiteV1GetRegionsResponse,
): ICianApiSiteV1GetRegionsResponse['data'] {
  return state.data;
}

export function regionsFromGetRegionsSelector(
  state: ICianApiSiteV1GetRegionsResponse,
): ICianApiSiteV1GetRegionsResponse['data']['items'] {
  return dataFromGetRegionsSelector(state).items;
}
