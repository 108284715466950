import * as filterActions from 'shared/store/data/filters/actions';

import { boundActionsFactory } from './useBoundActions';

export type FilterActions = typeof filterActions;

/**
 * @deprecated
 * @returns {ActionCreatorsMapObject<typeof filterActions>}
 */
export function useFiltersActions(): FilterActions {
  return boundActionsFactory(filterActions)();
}
