import {
  ICianApiSiteV1GetRegionRequest,
  ICianApiSiteV1GetRegionResponse,
  ICianApiSiteV1GetRegionResponse200,
  ICianApiSiteV1GetRegionResponse400,
  createCianApiSiteV1GetRegionModel,
  fetchCianApiSiteV1GetRegion,
} from 'shared/repositories/monolith-python/cian-api/site/v1/get-region';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export async function fetchGetRegionService(
  dependencies: IApplicationContext,
  parameters: ICianApiSiteV1GetRegionRequest,
): Promise<ICianApiSiteV1GetRegionResponse> {
  const {
    httpApi,
    custom: { subdomain },
    logger,
  } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createCianApiSiteV1GetRegionModel(parameters),
    'src/shared/store/serverSide/monolith-python/cian-api/site/v1/get-region/internal/fetchService.ts',
    logger,
  );

  return fetchCianApiSiteV1GetRegion<ICianApiSiteV1GetRegionResponse200, ICianApiSiteV1GetRegionResponse400>({
    config: { subdomain },
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        case 400:
          throw fetchAssertsService.assert400StatusCodeError({ response: result.response.data });
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
