import { Filters } from 'shared/types/filters';
import { EPriceType } from 'shared/types/filters/priceType';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { hasWorkplaceOfficeType } from 'shared/utils/filters/hasWorkplaceOfficeType';

export function getJsonQueryPriceSM(filters: Filters): boolean | undefined {
  if (filters.priceType === EPriceType.SM && !(hasWorkplaceOfficeType(filters) || hasCoworkingOfficeType(filters))) {
    return true;
  }

  return undefined;
}
