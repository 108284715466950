import { CloseS12 } from '@cian/ui-kit/prev';
import { FC } from 'react';

import { useTagOnKeyDown } from '../../hooks';

import { ITagProps } from './types';

import styles from './styles.css';

export const Tag: FC<ITagProps> = props => {
  const { children, onClose } = props;

  const onKeyDown = useTagOnKeyDown(onClose);

  return (
    <div className={styles['container']} tabIndex={0} onKeyDown={onKeyDown}>
      <div className={styles['content']}>{children}</div>
      <button className={styles['close-button']} type="button" onClick={onClose}>
        <CloseS12 color="gray_icons_100" />
      </button>
    </div>
  );
};

Tag.displayName = 'Tag';
