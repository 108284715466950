import { FC, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { RangeInput } from '../RangeInput';

import { ChangeValue, IRangeInputContainerProps } from './types';

const defaultValidator: IRangeInputContainerProps['validator'] = (valueMin, valueMax) =>
  !(
    (typeof valueMax !== 'undefined' && Number(valueMax) < 0) ||
    (typeof valueMin !== 'undefined' && Number(valueMin) < 0) ||
    Number(valueMin) > Number(valueMax)
  );

export const RangeInputContainer: FC<IRangeInputContainerProps> = props => {
  const {
    disabled,
    isFormattedInput,
    postfix,
    valueMin,
    valueMax,
    maxWidth,
    onBlurMax,
    onBlurMin,
    onChangeMin,
    onChangeMax,
    validator = defaultValidator,
  } = props;
  /**
   * @todo подумать над тем, где правильнее организовать валидацию данных, но точно не тут
   * @description будет пофикшено тут https://jira.cian.tech/browse/CD-132123
   * */
  const [isValid, setIsValid] = useState(() => validator(valueMin, valueMax));
  const setDebouncedIsValid = useDebouncedCallback(setIsValid, 1500);

  const handleChangeMin = useCallback<ChangeValue>(
    valueRaw => {
      if (typeof valueRaw === 'number' || valueRaw == null) {
        onChangeMin?.(valueRaw || undefined);

        return;
      }

      if (valueRaw.target.value.match(/^-?\d*\.?\d*$/)) {
        onChangeMin?.(valueRaw.target.value);
      }
    },
    [onChangeMin],
  );

  const handleChangeMax = useCallback<ChangeValue>(
    valueRaw => {
      if (typeof valueRaw === 'number' || valueRaw == null) {
        onChangeMax?.(valueRaw || undefined);

        return;
      }

      if (valueRaw.target.value.match(/^-?\d*\.?\d*$/)) {
        onChangeMax?.(valueRaw.target.value);
      }
    },
    [onChangeMax],
  );

  const handleBlurMax = useCallback<NonNullable<IRangeInputContainerProps['onBlurMax']>>(
    event => {
      if (onBlurMax) {
        onBlurMax(event);
      }
    },
    [onBlurMax],
  );

  const handleBlurMin = useCallback<NonNullable<IRangeInputContainerProps['onBlurMax']>>(
    event => {
      if (onBlurMin) {
        onBlurMin(event);
      }
    },
    [onBlurMin],
  );

  useEffect(() => {
    setIsValid(true);
    setDebouncedIsValid(validator(valueMin, valueMax));
  }, [validator, setDebouncedIsValid, valueMax, valueMin]);

  return (
    <RangeInput
      disabled={disabled}
      isFormattedInput={isFormattedInput}
      isInvalid={!isValid}
      maxWidth={maxWidth}
      postfix={postfix}
      valueMax={valueMax}
      valueMin={valueMin}
      onBlurMax={handleBlurMax}
      onBlurMin={handleBlurMin}
      onChangeMax={handleChangeMax}
      onChangeMin={handleChangeMin}
    />
  );
};

RangeInputContainer.displayName = 'RangeInputContainer';
