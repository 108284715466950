import { JsonQueryRangeValue } from 'shared/types/jsonQuery';
import { RangeValue } from 'shared/types/rangeValue';

export function getJsonQueryRangeFromRange(range: RangeValue | undefined): JsonQueryRangeValue | undefined {
  if (!range) {
    return undefined;
  }

  return { gte: range.min, lte: range.max };
}
