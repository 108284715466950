import type { HttpBadStatusCodeError, UnknownError } from '@cian/peperrors/shared';

import { EQueryKeys } from 'shared/store/serverSide/EQueryKeys';

export type QueryError = HttpBadStatusCodeError | UnknownError;

export type QueryKey = [EQueryKeys.MonolithPython__CianApi_Site_V1_GetRegion, number];
export const getQueryKey = (region: QueryKey[1]): QueryKey => [
  EQueryKeys.MonolithPython__CianApi_Site_V1_GetRegion,
  region,
];
