/* eslint-disable max-lines */
import { produce } from 'immer';
import { assoc, assocPath } from 'ramda';
import { createReducer } from 'typesafe-actions';

import { NEUTRAL_OPTION_VALUE } from 'shared/components/filters/advanced/CommonTermFilter/internal/constants/neutralOption';
import { YEAR_MIN_VALUE } from 'shared/constants/filters/advanced/yearMinValue';
import { ALL_AVAILABLE_PARKING_TYPES } from 'shared/constants/filters/allAvailableParkingTypes';
import { DEFAULT_FILTERS } from 'shared/constants/filters/defaultFilters';
import { MAX_FLOOR_COUNT } from 'shared/constants/filters/floor';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { Filters } from 'shared/types/filters';
import { EParkingType } from 'shared/types/filters/advanced/parkingType';
import { EUndergroundTimeType } from 'shared/types/filters/advanced/undergroundTime';
import { BsCenter, Coworking, GeoValue } from 'shared/types/filters/geo';
import { RangeValue } from 'shared/types/rangeValue';
import { changeConditionTypesByOfficeTypes } from 'shared/utils/filters/changeConditionTypesByOfficeTypes';
import { changeOfficeType } from 'shared/utils/filters/changeOfficeType';
import { getAvailableAdvancedFilters } from 'shared/utils/filters/getAvailableAdvancedFilters';
import { includeOfficeTypeByDealType } from 'shared/utils/filters/includeFiltersByDealType';
import { toggleFilterValue } from 'shared/utils/filters/toggleFilterValue';
import { toggleGeoValue } from 'shared/utils/filters/toggleGeoValue';
import { toggleOrDeleteFilterValue } from 'shared/utils/filters/toggleOrDeleteFilterValue';

import * as actions from './actions';

const initialState: Filters = { ...DEFAULT_FILTERS };

export const filtersReducer = createReducer(initialState)
  .handleAction(actions.changeDealTypeAction, (state, action) =>
    produce(state, draft => {
      draft.dealType = action.payload;
      draft.officeType = includeOfficeTypeByDealType(state.officeType, action.payload);

      delete draft.contract;
      delete draft.buildingClassType;
    }),
  )
  .handleAction(actions.changeOfficeTypeAction, (state, action) =>
    produce(state, draft => {
      draft.officeType = changeOfficeType(action.payload, state.officeType);

      if (state.conditionType) {
        const conditionType = changeConditionTypesByOfficeTypes(draft);

        draft.conditionType = conditionType?.length ? conditionType : undefined;
      }
    }),
  )
  .handleAction(actions.toggleIsByCommercialOwnerAction, state =>
    produce(state, draft => {
      draft.isByCommercialOwner = !state.isByCommercialOwner;
    }),
  )
  .handleAction(actions.setSpecialtyTypesAction, (state, action) =>
    produce(state, draft => {
      draft.specialtyTypes = action.payload;
    }),
  )
  .handleAction(actions.changeSpecialtyTypesAction, (state, action) =>
    produce(state, draft => {
      draft.specialtyTypes = toggleGeoValue(action.payload, state.specialtyTypes);
    }),
  )
  .handleAction(actions.changeWorkplaceTypeAction, (state, action) =>
    produce(state, draft => {
      const newWorkplaceTypeState = toggleFilterValue(action.payload, state.workplaceType);

      if (state.workplaceType?.length === 1 && !newWorkplaceTypeState.length) {
        return;
      }

      draft.workplaceType = newWorkplaceTypeState;
    }),
  )
  .handleAction(actions.changePriceTypeAction, (state, action) =>
    produce(state, draft => {
      draft.priceType = action.payload;
    }),
  )
  .handleAction([actions.changeRegionAction, actions.setRegionAction], (state, action) =>
    produce(state, draft => {
      draft.region = action.payload;

      if (action.type === 'filters/regionChanged') {
        delete draft.coworking;
        delete draft.bsCenter;
        delete draft.geo;
        delete draft.undergroundTime;
      }
    }),
  )
  .handleAction(actions.changeCoworkingAction, (state, action) =>
    produce(state, draft => {
      draft.coworking = toggleGeoValue<Coworking>(action.payload, state.coworking);
    }),
  )
  .handleAction(actions.changeBSCenterAction, (state, action) =>
    produce(state, draft => {
      draft.bsCenter = toggleGeoValue<BsCenter>(action.payload, state.bsCenter);
    }),
  )
  .handleAction(actions.changeGeoAction, (state, action) =>
    produce(state, draft => {
      draft.geo = toggleGeoValue<GeoValue>(action.payload, state.geo);
    }),
  )
  .handleAction(actions.addUndergroundStations, (state, action) =>
    produce(state, draft => {
      const filtersField = state.geo ?? [];

      draft.geo = [...filtersField, ...action.payload];
    }),
  )
  .handleAction(actions.removeUndergroundStations, (state, action) =>
    produce(state, draft => {
      draft.geo = draft.geo?.filter(filtersFieldItem => !action.payload.includes(filtersFieldItem.id));
    }),
  )
  .handleAction(actions.clearGeoAction, state =>
    produce(state, draft => {
      delete draft.geo;
    }),
  )
  .handleAction(actions.setGeoTitleAction, (state, action) =>
    produce(state, draft => {
      action.payload.forEach(geoTitle => {
        const geoValueIndex = state.geo?.findIndex(item => item.id === geoTitle.id);

        if (!draft.geo || geoValueIndex === undefined || !state.geo) {
          return;
        }

        if (!draft.geo.length) {
          return;
        }

        draft.geo[geoValueIndex] = {
          ...state.geo[geoValueIndex],
          ...(geoTitle.color && { color: geoTitle.color }),
          title: geoTitle.title,
        };
      });
    }),
  )
  .handleAction(actions.setBSCenterTitlesAction, (state, action) =>
    produce(state, draft => {
      action.payload.forEach(bsCenterTitle => {
        const BsCenterIndex = state.bsCenter?.findIndex(item => item.id === bsCenterTitle.id);

        if (!draft.bsCenter || BsCenterIndex === undefined || !state.bsCenter) {
          return;
        }

        if (!draft.bsCenter.length) {
          return;
        }

        draft.bsCenter[BsCenterIndex] = {
          ...state.bsCenter[BsCenterIndex],
          title: bsCenterTitle.name,
        };
      });
    }),
  )
  .handleAction(actions.setSpecialtyTypeTitlesAction, (state, action) =>
    produce(state, draft => {
      action.payload.forEach(specialty => {
        const specialtyTypeIndex = draft.specialtyTypes?.findIndex(item => item.id === specialty.id);

        if (!draft.specialtyTypes || specialtyTypeIndex === undefined || !state.specialtyTypes) {
          return;
        }

        draft.specialtyTypes[specialtyTypeIndex] = {
          ...state.specialtyTypes[specialtyTypeIndex],
          title: specialty.title,
        };
      });
    }),
  )
  .handleAction(actions.setCoworkingTitlesAction, (state, action) =>
    produce(state, draft => {
      action.payload.forEach(coworkingTitle => {
        const coworkingIndex = state.coworking?.findIndex(item => item.id === coworkingTitle.id);

        if (!draft.coworking || coworkingIndex === undefined || !state.coworking) {
          return;
        }

        if (!draft.coworking.length) {
          return;
        }

        draft.coworking[coworkingIndex] = {
          ...state.coworking[coworkingIndex],
          title: coworkingTitle.name,
        };
      });
    }),
  )
  .handleAction(actions.toggleWithPhotoAction, state =>
    produce(state, draft => {
      draft.withPhoto = !state.withPhoto;
    }),
  )
  .handleAction(actions.changePublishPeriodAction, (state, action) =>
    produce(state, draft => {
      draft.publishPeriod = action.payload;
    }),
  )
  .handleAction(actions.validateRangeFilterAction, (state, action) =>
    produce(state, draft => {
      const filtersField = state[action.payload] as RangeValue;

      if (filtersField?.min != null && filtersField?.max != null && filtersField?.min > filtersField.max) {
        const draftFiltersField = { ...filtersField, max: filtersField.min };

        return { ...draft, [action.payload]: draftFiltersField };
      }

      return;
    }),
  )
  .handleAction(actions.setInitialFilters, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .handleAction([actions.clearAdvancedFiltersAction, actions.removeAllTags], state => {
    const availableAdvancedFilters = getAvailableAdvancedFilters(state);

    const cleanedAvailableFilters = availableAdvancedFilters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter]: undefined,
      }),
      {} as Partial<Filters>,
    );

    return {
      ...state,
      ...cleanedAvailableFilters,
    };
  })
  .handleAction(actions.removeTag, (state, action) => {
    const { filterName, value } = action.payload;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterByTag = state[filterName] as any;
    // тут не отчищается гео нормально
    if (Array.isArray(filterByTag)) {
      const filtered = filterByTag.filter(tag => tag !== value);

      return assoc(filterName, filtered.length ? filtered : undefined, state) as unknown as Filters;
    }

    return assoc(filterName, undefined, state) as unknown as Filters;
  })
  .handleAction(actions.changeUndergroundTimeTypeAction, (state, action) =>
    produce(state, draft => {
      draft.undergroundTime = {
        time: state.undergroundTime?.time || 45,
        type: action.payload,
      };

      if (draft.undergroundTime?.type === EUndergroundTimeType.none) {
        draft.undergroundTime = undefined;
      }
    }),
  )
  .handleAction(actions.changeUndergroundTimeAction, (state, action) =>
    assocPath([EAdvancedFilterType.undergroundTime, 'time'], action.payload, state),
  )
  .handleAction(actions.changeContractAction, (state, action) =>
    produce(state, draft => {
      draft.contract = toggleOrDeleteFilterValue(action.payload, state.contract);
    }),
  )
  .handleAction(actions.changeBuildingClassTypesAction, (state, action) =>
    produce(state, draft => {
      draft.buildingClassType = toggleOrDeleteFilterValue(action.payload, state.buildingClassType);
    }),
  )
  .handleAction(actions.changeFloorMinAction, (state, action) =>
    produce(state, draft => {
      const floorMin = action.payload;
      if (floorMin > MAX_FLOOR_COUNT) {
        return;
      }

      draft.floor = { ...state.floor, min: floorMin };

      if (draft.floor.min == null && draft.floor.max == null) {
        delete draft.floor;
      }
    }),
  )
  .handleAction(actions.changeFloorMaxAction, (state, action) =>
    produce(state, draft => {
      const floorMax = action.payload;

      if (floorMax > MAX_FLOOR_COUNT) {
        return;
      }

      draft.floor = { ...state.floor, max: floorMax };

      if (draft.floor.min == null && draft.floor.max == null) {
        delete draft.floor;
      }
    }),
  )
  .handleAction(actions.changeFloornMinAction, (state, action) =>
    produce(state, draft => {
      const floornMin = action.payload;
      if (floornMin > MAX_FLOOR_COUNT) {
        return;
      }

      draft.floorn = { ...state.floorn, min: floornMin };

      if (draft.floorn.min == null && draft.floorn.max == null) {
        delete draft.floorn;
      }
    }),
  )
  .handleAction(actions.changeFloornMaxAction, (state, action) =>
    produce(state, draft => {
      const floornMax = action.payload;

      if (floornMax > MAX_FLOOR_COUNT) {
        return;
      }

      draft.floorn = { ...state.floorn, max: floornMax };

      if (draft.floorn.min == null && draft.floorn.max == null) {
        delete draft.floorn;
      }
    }),
  )
  .handleAction(actions.validateFloorFilterAction, (state, action) =>
    produce(state, draft => {
      const floorField = draft[action.payload];

      if (!floorField) {
        return;
      }

      draft[action.payload] = {
        max: floorField.max !== 0 ? floorField.max : 1,
        min: floorField.min !== 0 ? floorField.min : 1,
      };
    }),
  )
  .handleAction(actions.validateHouseYearFilterAction, (state, action) =>
    produce(state, draft => {
      const rangeField = draft.houseYear?.[action.payload];
      if (draft.houseYear === undefined || rangeField === undefined) {
        return;
      }

      draft.houseYear[action.payload] = rangeField > YEAR_MIN_VALUE ? rangeField : YEAR_MIN_VALUE;
    }),
  )
  .handleAction(actions.changeInputTypeAction, (state, action) =>
    produce(state, draft => {
      draft.inputType = toggleOrDeleteFilterValue(action.payload, draft.inputType);
    }),
  )
  .handleAction(actions.toggleIsSeparatedInputTypeAction, state =>
    produce(state, draft => {
      draft.isSeparatedInputType = !state.isSeparatedInputType;
    }),
  )
  .handleAction(actions.toggleTermsFilterValue, (state, action) => ({
    ...state,
    [action.payload.name]: toggleOrDeleteFilterValue(action.payload.value, state[action.payload.name]),
  }))
  .handleAction(actions.setTermFilterValue, (state, action) => {
    const { name, value } = action.payload;

    if (value === NEUTRAL_OPTION_VALUE) {
      return assoc(name, undefined, state) as unknown as Filters;
    }

    return {
      ...state,
      [name]: value,
    };
  })
  .handleAction(actions.changeRangeValueAction, (state, action) =>
    produce(state, draft => {
      const { fieldName, rangeValueKey, value, limit } = action.payload;

      if (limit !== undefined && value > limit) {
        return;
      }

      draft[fieldName] = {
        ...state[fieldName],
        [rangeValueKey]: value,
      };

      if (draft[fieldName]?.min == null && draft[fieldName]?.max == null) {
        delete draft[fieldName];
      }
    }),
  )
  .handleAction(actions.setBusinessAppointmentsAction, (state, action) =>
    produce(state, draft => {
      draft.businessAppointments = action.payload;
    }),
  )
  .handleAction(actions.setParkingType, (state, action) =>
    produce(state, draft => {
      if (action.payload == null) {
        delete draft.parkingType;

        return;
      }

      if (state.parkingType?.includes(EParkingType.AllTypes) && action.payload !== EParkingType.AllTypes) {
        draft.parkingType = ALL_AVAILABLE_PARKING_TYPES.filter(type => type !== action.payload);

        return;
      }

      draft.parkingType = toggleOrDeleteFilterValue(action.payload, state.parkingType);
    }),
  );
