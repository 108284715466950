import { Filters } from 'shared/types/filters';
import { isRent } from 'shared/utils/filters/isRent';

export function getResidentialLink(filters: Filters): string {
  const dealType = isRent(filters) ? 'rent' : 'sale';

  return [
    '/cat.php?engine_version=2&offer_type=flat',
    `deal_type=${dealType}`,
    filters.region ? `region=${filters.region}` : null,
    isRent(filters) ? `type=4` : null,
  ]
    .filter(Boolean)
    .join('&');
}
