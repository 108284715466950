import { IMultiSuggestResult } from '@cian/geosuggest-widget';

import { useGetGeoSuggestQuery } from 'shared/store/serverSide/geo-suggest/v2/suggest/query';
import { useYandexSuggestQueryWithBoundedBy } from 'shared/store/serverSide/yandex/api-maps/2.1/query';

import { prepareSuggestions } from './mappers';

type UseGetSuggestResult = {
  geoSuggestQuery: ReturnType<typeof useGetGeoSuggestQuery>;
  yandexSuggestQuery: ReturnType<typeof useYandexSuggestQueryWithBoundedBy>;
  suggestions: IMultiSuggestResult | null;
};

export function useGetSuggest(query: string): UseGetSuggestResult {
  const queryEnabled = query.length >= 3;

  const geoSuggestQuery = useGetGeoSuggestQuery(query, {
    enabled: queryEnabled,
    select: data => data.data,
  });

  const yandexSuggestQuery = useYandexSuggestQueryWithBoundedBy(query, {
    enabled: queryEnabled,
  });

  const suggestions =
    geoSuggestQuery.data && yandexSuggestQuery.data
      ? (prepareSuggestions(geoSuggestQuery.data, yandexSuggestQuery.data) as IMultiSuggestResult)
      : null;

  return {
    geoSuggestQuery,
    suggestions,
    yandexSuggestQuery,
  };
}
