import { getAvailableOfficeTypes } from 'shared/components/filters/OfficeTypeFilter/internal/helpers';
import { EDealType, EOfficeType } from 'shared/types/filters';

export const includeOfficeTypeByDealType = (officeType: EOfficeType[], dealType: EDealType): EOfficeType[] => {
  const availableOfficeTypes = getAvailableOfficeTypes(dealType);
  const filteredOfficeType = officeType.filter(type => availableOfficeTypes.includes(type));

  if (!filteredOfficeType.length) {
    return [EOfficeType.Office];
  }

  return filteredOfficeType;
};
