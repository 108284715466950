import { TButtonTheme } from '@cian/ui-kit/button/types';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type FindButtonProps = {
  label: string;
  loading?: boolean;
  theme?: TButtonTheme;
  onClick: VoidFunction;
};

export type FindButtonContainerProps = PartialBy<FindButtonProps, 'onClick'> & {
  buttonType: EButtonType;
};

export enum EButtonType {
  Main = 'FastFilters',
  Popup = 'FastFilters_popup',
  AdvancedFilters = 'Filters_more_param',
}
