/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* TODO: отрефакторить компонент, скопирован с серпа */
import { Component } from 'react';

import { Button } from 'shared/components/GeoSuggest/DistrictsModal/button';

import styles from './index.css';

export interface IBreadcrumbsProps {
  selectedCity: any | undefined;
  selectedRegion: any | 'moscow_mo' | 'spb_lo' | undefined;
  onCountrySelected(): void;
  onRegionSelected(region: any | 'moscow_mo' | 'spb_lo'): void;
  selectedRegionName: any;
}

enum REGIONS {
  Moscow = 1,
  Petersburg = 2,
  MoscowArea = 4593,
  LeningradArea = 4588,
}

export class Breadcrumbs extends Component<IBreadcrumbsProps> {
  public render() {
    const { selectedRegion, selectedCity } = this.props;

    const fakeRegion = selectedRegion && this.getFakeRegion(selectedRegion);

    const isCapital =
      selectedRegion &&
      selectedRegion !== 'moscow_mo' &&
      selectedRegion !== 'spb_lo' &&
      (selectedRegion === 1 || selectedRegion === 2);

    return (
      <div className={styles['breadcrumb-container']}>
        <div className={styles['container']}>
          <div className={styles['breadcrumb']} onClick={(): any => this.props.onCountrySelected()}>
            Россия
          </div>
          {fakeRegion}
          {selectedRegion && !isCapital && (
            <div className={styles['breadcrumb-wrapper']}>
              <div className={styles['arrow']} />
              {selectedCity ? (
                <div className={styles['breadcrumb']} onClick={(): any => this.props.onRegionSelected(selectedRegion)}>
                  {this.getName(selectedRegion, this.props.selectedRegionName)}
                </div>
              ) : (
                <Button
                  className={styles['end-button']}
                  theme="primary"
                  onClick={() => {
                    if (fakeRegion && selectedRegion !== 'moscow_mo' && selectedRegion !== 'spb_lo') {
                      this.props.onRegionSelected(
                        selectedRegion === REGIONS.Moscow || selectedRegion === REGIONS.MoscowArea
                          ? 'moscow_mo'
                          : 'spb_lo',
                      );
                    } else {
                      this.props.onCountrySelected();
                    }
                  }}
                >
                  {this.getName(selectedRegion, this.props.selectedRegionName)}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  private getName = (selectedRegion: any | 'moscow_mo' | 'spb_lo', selectedRegionName: any): any => {
    if (selectedRegion === 'moscow_mo') {
      return 'Москва и МО';
    } else if (selectedRegion === 'spb_lo') {
      return 'Санкт-Петербург и ЛО';
    }

    return selectedRegionName;
  };

  private getFakeRegion(selectedRegion: any | 'moscow_mo' | 'spb_lo') {
    if (selectedRegion !== 'moscow_mo' && selectedRegion !== 'spb_lo') {
      if (
        // tslint:disable-next-line:prefer-switch
        selectedRegion === REGIONS.MoscowArea ||
        selectedRegion === REGIONS.Moscow ||
        selectedRegion === REGIONS.Petersburg ||
        selectedRegion === REGIONS.LeningradArea
      ) {
        return (
          <div className={styles['breadcrumb-wrapper']}>
            <div className={styles['arrow']} />
            <div
              className={styles['breadcrumb']}
              onClick={(): any =>
                this.props.onRegionSelected(
                  selectedRegion === REGIONS.Moscow || selectedRegion === REGIONS.MoscowArea ? 'moscow_mo' : 'spb_lo',
                )
              }
            >
              {selectedRegion === REGIONS.Moscow || selectedRegion === REGIONS.MoscowArea
                ? 'Москва и МО'
                : 'Санкт Петербург и ЛО'}
            </div>
          </div>
        );
      }
    }

    return '';
  }
}
