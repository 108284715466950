import { EBuildingType, EBuildingTypeLegacy } from 'shared/types/filters/advanced/buildingType';

export const getJsonQueryBuildingType = (buildingTypes: EBuildingType[]): EBuildingTypeLegacy[] => {
  return buildingTypes.reduce((acc, item) => {
    if (item === EBuildingType.BusinessCenter) {
      acc.push(
        EBuildingTypeLegacy.BusinessCenter,
        EBuildingTypeLegacy.BusinessHouse,
        EBuildingTypeLegacy.BusinessQuarter2,
        EBuildingTypeLegacy.OfficeCenter,
        EBuildingTypeLegacy.TradingOfficeComplex,
        EBuildingTypeLegacy.OfficeQuarter,
      );
    }

    if (item === EBuildingType.House) {
      acc.push(EBuildingTypeLegacy.Mansion);
    }

    if (item === EBuildingType.AdministrativeBuilding) {
      acc.push(EBuildingTypeLegacy.AdministrativeBuilding);
    }

    if (item === EBuildingType.MultifunctionalComplex) {
      acc.push(
        EBuildingTypeLegacy.BusinessCenter2,
        EBuildingTypeLegacy.BusinessPark,
        EBuildingTypeLegacy.BusinessQuarter,
        EBuildingTypeLegacy.IndustrialComplex,
        EBuildingTypeLegacy.IndustrialPark,
        EBuildingTypeLegacy.IndustrialWarehouseComplex,
        EBuildingTypeLegacy.MultifunctionalComplex,
        EBuildingTypeLegacy.OfficeHotelComplex,
        EBuildingTypeLegacy.OfficeResidentialComplex,
        EBuildingTypeLegacy.OfficeBuilding,
        EBuildingTypeLegacy.OfficeWarehouseComplex,
        EBuildingTypeLegacy.OfficeCenter,
        EBuildingTypeLegacy.TradingOfficeComplex,
        EBuildingTypeLegacy.OfficeIndustrialComplex,
        EBuildingTypeLegacy.ResidentialComplex,
        EBuildingTypeLegacy.ResidentialHouse,
        EBuildingTypeLegacy.ShoppingBusinessComplex,
        EBuildingTypeLegacy.StandaloneBuilding,
        EBuildingTypeLegacy.Technopark,
        EBuildingTypeLegacy.TradingOfficeComplex,
        EBuildingTypeLegacy.OldFund,
      );
    }

    if (item === EBuildingType.Warehouse) {
      acc.push(
        EBuildingTypeLegacy.LogisticsCenter,
        EBuildingTypeLegacy.LogisticsComplex,
        EBuildingTypeLegacy.IndustrialPark,
        EBuildingTypeLegacy.OfficeWarehouseComplex,
        EBuildingTypeLegacy.Warehouse,
        EBuildingTypeLegacy.WarehouseComplex,
      );
    }

    if (item === EBuildingType.FAO) {
      acc.push(EBuildingTypeLegacy.FreeAppointmentObject);
    }

    if (item === EBuildingType.Other) {
      acc.push(
        EBuildingTypeLegacy.IndustrialSite,
        EBuildingTypeLegacy.ManufacturingFacility,
        EBuildingTypeLegacy.OldFund,
        EBuildingTypeLegacy.Other,
      );
    }

    if (item === EBuildingType.ShoppingCenter) {
      acc.push(
        EBuildingTypeLegacy.Outlet,
        EBuildingTypeLegacy.ShoppingCommunityCenter,
        EBuildingTypeLegacy.ShoppingEntertainmentCenter,
        EBuildingTypeLegacy.ShoppingCenter,
        EBuildingTypeLegacy.SpecializedShoppingCenter,
        EBuildingTypeLegacy.TradingHouse,
      );
    }

    if (item === EBuildingType.ResidentialBuilding) {
      acc.push(EBuildingTypeLegacy.ResidentialHouse);
    }

    if (item === EBuildingType.OfficeBuilding) {
      acc.push(EBuildingTypeLegacy.OfficeBuilding);
    }

    return acc;
  }, Array.of<EBuildingTypeLegacy>());
};
