import { Button } from '@cian/ui-kit';
import { forwardRef } from 'react';

import { SwitchButtonProps } from './types';

import img from './img.svg';

export const SwitchButton = forwardRef<HTMLButtonElement, SwitchButtonProps>(function SwitchButton(props, ref) {
  const { isCollapsed, onClick } = props;

  const icon = isCollapsed ? <img src={img} /> : undefined;
  const label = !isCollapsed ? 'Свернуть' : '';

  return (
    <Button beforeIcon={icon} data-name="SwitchButton" ref={ref} size="XS" theme="fill_secondary" onClick={onClick}>
      {label}
    </Button>
  );
});

SwitchButton.displayName = 'SwitchButton';
