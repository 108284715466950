import { QueryClient } from '@tanstack/react-query';

import { IJsonQuery } from 'shared/models/JsonQuery';
import { Filters } from 'shared/types/filters';
import { mapFiltersToJsonQuery } from 'shared/utils/mapFiltersToJsonQuery';

import { IStrategy } from './types';

export class JsonQueryStrategy implements IStrategy {
  private readonly queryClient: QueryClient;

  public constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public formatFilters(filters: Filters): IJsonQuery {
    return mapFiltersToJsonQuery(filters, this.queryClient);
  }
}
