import { useMemo } from 'react';

import { EFilterType } from 'shared/enums/EFilterType';
import { useGetBusinessPossibleAppointmentsQuery } from 'shared/store/serverSide/announcements/v1/get-business-possible-appointments/query';
import {
  GetAvailableBusinessAppointmentsItemsResult,
  getAvailableBusinessAppointmentsItems,
} from 'shared/utils/getAvailableBusinessAppointmentsItems';
import { useBusinessAppointmentsSelector } from 'shared/utils/hooks/useBusinessAppointmentsSelector';
import { useFilter } from 'shared/utils/hooks/useFilter';

export function useGetAvailableBusinessAppointmentsItems(): GetAvailableBusinessAppointmentsItemsResult | null {
  const { data: businessPossibleAppointments } = useGetBusinessPossibleAppointmentsQuery();
  const ids = useBusinessAppointmentsSelector();
  const officeType = useFilter(EFilterType.officeType);

  return useMemo(() => {
    if (!businessPossibleAppointments) {
      return null;
    }

    return getAvailableBusinessAppointmentsItems(businessPossibleAppointments, officeType, ids);
  }, [businessPossibleAppointments, ids, officeType]);
}
