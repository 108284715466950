import type { HttpBadStatusCodeError, UnknownError } from '@cian/peperrors/shared';

import { IGetMetaRequest } from 'shared/repositories/search-offers-index/v2/get-meta';
import { EQueryKeys } from 'shared/store/serverSide/EQueryKeys';

export type QueryError = HttpBadStatusCodeError | UnknownError;

export type QueryKey = [EQueryKeys.SearchOffersIndex__V2_GetCount, IGetMetaRequest];
export const getQueryKey = (parameters: QueryKey[1]): QueryKey => [
  EQueryKeys.SearchOffersIndex__V2_GetCount,
  parameters,
];
