import { createAction } from 'typesafe-actions';

import { FlattenType } from 'shared/types/FlattenType';
import { Filters } from 'shared/types/filters';
import { TermsTypeKeys } from 'shared/types/filters/advanced/termsFilterType';

export type TermsFilterPayload<F extends TermsTypeKeys = TermsTypeKeys> = {
  name: F;
  value: FlattenType<Filters[F]>;
};

export const toggleTermsFilterValue = createAction('filters/termsFilterChanged')<TermsFilterPayload>();
