import { useCallback } from 'react';

import { RangeInputContainer } from 'shared/components/RangeInputContainer';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EAreaMeasure } from 'shared/enums/EAreaMeasure';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useValidateRangeFiltersActions } from 'shared/utils/hooks/useValidateRangeFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const TotalAreaFilter = memoWithDisplayName(() => {
  const totalArea = useFilter(EAdvancedFilterType.totalArea);
  const { changeTotalAreaMaxAction, changeTotalAreaMinAction } = useFiltersActions();
  const { validateRangeFilterAction } = useValidateRangeFiltersActions();

  const onBlur = useCallback(() => {
    validateRangeFilterAction(EAdvancedFilterType.totalArea);
  }, [validateRangeFilterAction]);

  return (
    <RangeInputContainer
      isFormattedInput
      maxWidth={248}
      postfix={EAreaMeasure.SquareMeters}
      valueMax={totalArea?.max}
      valueMin={totalArea?.min}
      onBlurMax={onBlur}
      onBlurMin={onBlur}
      onChangeMax={changeTotalAreaMaxAction}
      onChangeMin={changeTotalAreaMinAction}
    />
  );
}, 'TotalAreaFilter');
