import { FC } from 'react';

import { UndergroundTimeFilterContainer } from 'shared/components/filters/advanced/UndergroundTimeFilter';

import { MetroModalHeaderProps } from './types';

import styles from './index.css';

export const MetroModalHeader: FC<MetroModalHeaderProps> = props => {
  return (
    <div className={styles['header']}>
      <UndergroundTimeFilterContainer />
      <div className={styles['buttons']}>{props.fastSelectionButtons}</div>
    </div>
  );
};

MetroModalHeader.displayName = 'MetroModalHeader';
