import { useMemo } from 'react';

import { Option } from 'shared/components/Select/models/Option';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EFilterType } from 'shared/enums/EFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';

import { buildingCranageTypesTypeOptions } from '../constants/buildingCranageTypesTypeOptions';
import { buildingGatesTypeOptions } from '../constants/buildingGatesTypeOptions';
import { buildingHeatingTypeOptions } from '../constants/buildingHeatingTypeOptions';
import { buildingHouseLineOptions } from '../constants/buildingHouseLineOptions';
import { buildingLiftTypesTypeOptions } from '../constants/buildingLiftTypesTypeOptions';
import { coworkingAccessOptions } from '../constants/coworkingAccessOptions';
import { estateTypeOptions } from '../constants/estateTypeOptions';
import { placementTypeOptions } from '../constants/placementTypeOptions';
import { getBuildingTypeOptions } from '../utils/getBuildingTypeOptions';
import { getConditionTypeOptions } from '../utils/getConditionTypeOptions';

const OPTIONS_BY_FILTER = {
  [EAdvancedFilterType.buildingLiftTypesType]: (): Option[] => buildingLiftTypesTypeOptions,
  [EAdvancedFilterType.buildingCranageTypesType]: (): Option[] => buildingCranageTypesTypeOptions,
  [EAdvancedFilterType.buildingGatesType]: (): Option[] => buildingGatesTypeOptions,
  [EAdvancedFilterType.buildingHeatingType]: (): Option[] => buildingHeatingTypeOptions,
  [EAdvancedFilterType.buildingHouseLine]: (): Option[] => buildingHouseLineOptions,
  [EAdvancedFilterType.conditionType]: getConditionTypeOptions,
  [EAdvancedFilterType.coworkingAccess]: (): Option[] => coworkingAccessOptions,
  [EAdvancedFilterType.placementType]: (): Option[] => placementTypeOptions,
  [EAdvancedFilterType.estateTypes]: (): Option[] => estateTypeOptions,
  [EAdvancedFilterType.buildingType]: getBuildingTypeOptions,
};

export type FilterName = keyof typeof OPTIONS_BY_FILTER;

export const useOptions = (filterName: FilterName): Option<number | string>[] => {
  const officeType = useFilter(EFilterType.officeType);

  const options = OPTIONS_BY_FILTER[filterName];

  return useMemo(() => options(officeType), [officeType, options]);
};
