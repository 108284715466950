import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';

import { IBlock } from '../IBlock';

export class Block implements IBlock {
  public readonly title?: string;
  public readonly fields: EAdvancedFilterType[];

  public constructor(fields: EAdvancedFilterType[], title?: string) {
    this.fields = fields;

    if (title) {
      this.title = title;
    }
  }
}
