import { useToggleState } from '@cian/react-utils';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CommercialFiltersEventsLog } from 'shared/services/CommercialFiltersEventsLog';
import { filtersSaveSearchEnabledSelector } from 'shared/store/data/rs/selectors/dump/filtersSaveSearchEnabledSelector';

import { SaveSearchModalContainer } from '../SaveSearchModal/container';

import { SaveSearchButton } from './SaveSearchButton';
import { trackSaveSearchModalOpen } from './tracking';

export const SaveSearchButtonContainer: FC = () => {
  /**
   * @todo Удалить переменную, когда будет удален фича-тогл
   * @description Данный функционал появился в задаче CD-163541, будет удалён в задаче CD-163575
   */
  const isFiltersSaveSearchEnabled = useSelector(filtersSaveSearchEnabledSelector);

  const { state: isSaveSearchModalVisible, toggle: toggleSaveSearchModalVisibility } = useToggleState();

  const handleClick = useCallback(() => {
    if (isFiltersSaveSearchEnabled) {
      toggleSaveSearchModalVisibility();
      trackSaveSearchModalOpen();
    } else {
      CommercialFiltersEventsLog.getEvents().saveSearchButtonOpenEvent();
    }
  }, [isFiltersSaveSearchEnabled, toggleSaveSearchModalVisibility]);

  return (
    <>
      <SaveSearchButton handleClick={handleClick} />
      <SaveSearchModalContainer open={isSaveSearchModalVisible} onClose={toggleSaveSearchModalVisibility} />
    </>
  );
};

SaveSearchButtonContainer.displayName = 'SaveSearchButtonContainer';
