import type { IUndergroundMapDataV2 } from '@cian/underground-map-widget';

import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';
import { getHttpRequestUri } from 'shared/utils/getHttpRequestUri';

export type UndergroundJSONDataPath = {
  [locationId: string]: string;
};

export async function fetchGetUndergroundMapDataServiceV2(
  dependencies: IApplicationContext,
  locationId: number,
): Promise<IUndergroundMapDataV2> {
  const { config, httpApi, logger } = dependencies;

  const undergroundMapData = config.get<UndergroundJSONDataPath>('undergroundMapDataV2');

  const fetchAssertsService = new FetchAssertsService(
    { locationId, rs: { undergroundMapData } },
    'src/shared/store/serverSide/map/v2/get-underground-map-data/internal/fetchService.ts',
    logger,
  );

  let stringUrl: string | null = null;

  for (const key in undergroundMapData) {
    if (locationId === +key) {
      stringUrl = undergroundMapData[locationId];

      break;
    }
  }

  if (!stringUrl) {
    throw fetchAssertsService.assertValidationError(
      'Не удалось найти "locationId" из доступных ключей в "undergroundMapData"',
    );
  }

  return httpApi.httpRequest({ uri: getHttpRequestUri(stringUrl), withCredentials: false }).then(
    result => {
      switch (result.responseStatusCode) {
        case 200:
          return JSON.parse(result.responseBody) as IUndergroundMapDataV2;
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(result.responseStatusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
