import { QueryClient } from '@tanstack/react-query';

import { JsonQueryBuilder } from 'shared/services/JsonQueryBuilder/implementations/JsonQueryBuilder';
import { Filters } from 'shared/types/filters';

import { IJsonQuery } from '../models/JsonQuery';

export function mapFiltersToJsonQuery(filters: Filters, queryClient: QueryClient): IJsonQuery {
  const jsonQueryBuilder = new JsonQueryBuilder(filters, queryClient);

  jsonQueryBuilder
    .addOfficeType()
    .addPrice()
    .addPriceType()
    .addTotalArea()
    .addPriceSM()
    .addSite()
    .addIsByCommercialOwner()
    .addSpecialtyTypes()
    .addReadyBusinessTypes()
    .addWorkplaceCount()
    .addCoworkingOfferType()
    .addPublishPeriod()
    .addWithPhoto()
    .addContract()
    .addCategory()
    .addBuildingClassTypeWarehouse()
    .addBuildingClassType()
    .addFloor()
    .addFloorn()
    .addFootMin()
    .addGeo()
    .addBSCenter()
    .addCoworkingId()
    .addInputType()
    .addOnlyFoot()
    .addBuildingCranageTypesType()
    .addBuildingHeatingType()
    .addBuildingGatesType()
    .addBuildingLiftTypesType()
    .addConditionType()
    .addAmenity()
    .addAllDayAccess()
    .addAllWeekAccess()
    .addBuildingHouseLine()
    .addFloorTypes()
    .addPlacementType()
    .addFurnitureType()
    .addHouseYear()
    .addParkingType()
    .addInfrastructure()
    .addId()
    .addIdUser()
    .addContainedWords()
    .addExcludedWords()
    .addContact()
    .addEstateType()
    .addAccessSystem()
    .addCommissionType()
    .addWithoutAgentCall()
    .addBuildingType()
    .addElectronicTrading();

  return jsonQueryBuilder.build();
}
