import { ForwardRefExoticComponent, RefAttributes } from 'react';

import { FilterButton } from 'shared/components/FilterButton';
import { FilterButtonProps } from 'shared/components/FilterButton/types';
import { FilterSelectButton } from 'shared/components/FilterSelectButton';
import { FilterTextLabel } from 'shared/components/FilterTextLabel';
import { ELabelView } from 'shared/components/PopupFilterControlled/types';

export function getFilterLabelComponent(
  view: ELabelView,
): ForwardRefExoticComponent<FilterButtonProps & RefAttributes<HTMLButtonElement>> {
  switch (view) {
    case ELabelView.Text:
      return FilterTextLabel;
    case ELabelView.Select:
      return FilterSelectButton;
    case ELabelView.Button:
    default:
      return FilterButton;
  }
}
