import { useSelector } from 'react-redux';

import { geoSelector } from 'shared/store/data/filters/selectors/dump/geoSelector';

import { useFilters } from './useFilters';

export function useCurrentLocation(): number {
  const geo = useSelector(geoSelector);
  const filters = useFilters();

  const cities = geo?.filter(geoItem => geoItem.type === 'city');
  const locations = geo?.filter(geoItem => geoItem.type === 'location');

  if (cities && cities.length) {
    return cities[cities.length - 1].id;
  }

  if (locations && locations.length) {
    return locations[locations.length - 1].id;
  }

  return filters.region[0];
}
