import { loadYMapsApi } from 'shared/packages/YandexMaps/browser';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

import { FetchGetGeoSuggestServiceParameters } from '../types';

export async function fetchYandexSuggestService(
  dependencies: IApplicationContext,
  parameters: FetchGetGeoSuggestServiceParameters,
): Promise<YMaps.ISuggestResult[]> {
  const { logger, config } = dependencies;
  const { boundedBy, query } = parameters;

  const queryWithoutMotherland = query.trim().replace(/([, ]*Россия$|^Россия[, ]*)/gi, '');
  const yandexApiKey = config.getStrict<string>('commercial.MapApiKey');
  const suggestApiKey = config.getStrict<string>('commercial.SuggestApiKey');

  const fetchAssertsService = new FetchAssertsService(
    { suggestApiKey, yandexApiKey },
    'src/shared/store/serverSide/geo-suggest/v2/suggest/internal/fetchService.ts',
    logger,
  );

  return loadYMapsApi(yandexApiKey, suggestApiKey, ['suggest'])
    .then(ymaps => ymaps.suggest(`Россия, ${queryWithoutMotherland}`, { boundedBy }))
    .catch(error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    });
}
