import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import { ITagProps } from '@cian/ui-kit/tags/internal/components/Tag/types';
import { useRef } from 'react';
import { createPortal } from 'react-dom';

import styles from './Measure.css';

interface IMeasureProps {
  children: React.ReactElement<ITagProps> | React.ReactElement<ITagProps>[];
  width: number;
  onMeasurementsChange(count: number, widthLeft: number): void;
}

export const Measure: React.FC<IMeasureProps> = ({ width, children, onMeasurementsChange }) => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const tagsHash = JSON.stringify(children, circularReplacer());

  const measureOneLineTags = (): [number, number] => {
    if (!componentRef.current) {
      return [0, 0];
    }

    const tagElements = Array.from(componentRef.current.children);

    let visibleTags = 0;
    let widthLeft = width;
    for (let i = 0; i < tagElements.length; i++) {
      const tagElement = tagElements[i];
      const tagElementStyles = getComputedStyle(tagElement);
      const tagElementMargin = parseInt(tagElementStyles.marginRight, 10) || 0;
      const tagWidth = tagElement.getBoundingClientRect().width + tagElementMargin;

      if (widthLeft >= tagWidth) {
        visibleTags++;
        widthLeft -= tagWidth;
      } else {
        break;
      }
    }

    return [visibleTags, widthLeft];
  };

  useIsomorphicLayoutEffect(() => {
    onMeasurementsChange(...measureOneLineTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsHash, width]);

  return createPortal(
    <div className={styles['container']} ref={componentRef} style={{ width: `${width}px` }}>
      {children}
    </div>,
    document.body,
  );
};

function circularReplacer(): (key: string, value: object) => object | undefined {
  const seen = new WeakSet();

  return (key: string, value: object): object | undefined => {
    if (key.startsWith('_')) {
      return undefined;
    }

    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return undefined;
      }

      seen.add(value);
    }

    return value;
  };
}
