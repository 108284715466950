export enum EAmenityType {
  Wifi = 1,
  BikeParking = 2,
  Internet = 3,
  RestZone = 4,
  Cafe = 5,
  Cleaning = 6,
  Kitchen = 7,
  Furniture = 8,
  Security = 9,
  Parking = 10,
  MeetingRoom = 11,
  Printer = 12,
  Reception = 13,
  Modern = 14,
  Gym = 15,
  PhoneBooth = 16,
  TechSupport = 17,
  Events = 18,
  Fax = 19,
  Flipchart = 20,
  Fruits = 21,
  Tea = 22,
  Lockers = 23,
  LegalAddress = 24,
}
