import { CombinedState, createStore } from 'redux';

import { rootReducer } from 'shared/store/data/rootReducer';
import { RootAction } from 'shared/types/RootAction';
import { ReduxStore, RootInitialState, RootState } from 'shared/types/RootState';

import { getStoreEnhancers } from './getStoreEnhancers';

export function getReduxStore(initialState: RootInitialState | undefined, debugEnabled: boolean): ReduxStore {
  return createStore<CombinedState<RootState>, RootAction, {}, {}>(
    rootReducer,
    initialState,
    getStoreEnhancers(debugEnabled),
  );
}
