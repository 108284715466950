/* eslint-disable max-lines */

import { QueryClient } from '@tanstack/react-query';

import { infrastructureItemToJsonQueryFieldMap } from 'shared/constants/filters/advanced/infractructure';
import { ALL_AVAILABLE_PARKING_TYPES } from 'shared/constants/filters/allAvailableParkingTypes';
import { TRADE_AREA_GROUP, WAREHOUSE_GROUP } from 'shared/constants/filters/conditionType';
import { IJsonQuery } from 'shared/models/JsonQuery';
import { JsonQuery } from 'shared/models/JsonQuery/implementations/JsonQuery';
import { JsonQueryGeo, JsonQueryRange, JsonQueryTerm, JsonQueryTerms } from 'shared/models/JsonQueryElement';
import { EOfficeType, Filters } from 'shared/types/filters';
import { EAccessSystem } from 'shared/types/filters/advanced/accessSystem';
import { ECoworkingAccess } from 'shared/types/filters/advanced/coworkingAccess';
import { EFurnitureType } from 'shared/types/filters/advanced/furnitureType';
import { EParkingType } from 'shared/types/filters/advanced/parkingType';
import {
  getLegacyConditionTypeForOffice,
  getLegacyConditionTypeForTradeAreaGroup,
  getLegacyConditionTypeForWarehousGroup,
} from 'shared/utils/filters/getConditionTypeRevertMappers';
import { hasBusinessOfficeType } from 'shared/utils/filters/hasBusinessOfficeType';
import { hasTradeAreaOfficeType } from 'shared/utils/filters/hasTradeAreaOfficeType';
import { includesBuildingType } from 'shared/utils/filters/includesBuildingType';
import { includesManufactureType } from 'shared/utils/filters/includesManufactureType';
import { includesOfficeType } from 'shared/utils/filters/includesOfficeType';
import { includesWarehouseOfficeType } from 'shared/utils/filters/includesWarehouseOfficeType';
import { isCommercialLand } from 'shared/utils/filters/isCommercialLand';
import { parseOfficeTypesToReadyBusinessTypes } from 'shared/utils/filters/parseReadyBusinessTypes';
import { getBuildingClassTypes } from 'shared/utils/getBuildingClassTypes';
import { getJsonQueryBsCenter } from 'shared/utils/getJsonQueryBsCenter';
import { getJsonQueryBuildingType } from 'shared/utils/getJsonQueryBuildingType';
import { getJsonQueryCategory } from 'shared/utils/getJsonQueryCategory';
import { getJsonQueryCoworkingId } from 'shared/utils/getJsonQueryCoworkingId';
import { getJsonQueryCoworkingOfferType } from 'shared/utils/getJsonQueryCoworkingOfferType';
import { getJsonQueryGeo } from 'shared/utils/getJsonQueryGeo';
import { getJsonQueryInputType } from 'shared/utils/getJsonQueryInputType';
import { getJsonQueryOfficeType } from 'shared/utils/getJsonQueryOfficeType';
import { getJsonQueryPriceSM } from 'shared/utils/getJsonQueryPriceSM';
import { getJsonQueryPriceType } from 'shared/utils/getJsonQueryPriceType';
import { getJsonQueryRangeFromRange } from 'shared/utils/getJsonQueryRangeFromRange';
import { getJsonQuerySpecialtyTypes } from 'shared/utils/getJsonQuerySpecialtyTypes';

import { IJsonQueryBuilder } from '../IJsonQueryBuilder';

export class JsonQueryBuilder implements IJsonQueryBuilder {
  public readonly jsonQuery: IJsonQuery;
  public readonly filters: Filters;

  private readonly queryClient: QueryClient;

  public constructor(filters: Filters, queryClient: QueryClient) {
    this.filters = filters;
    this.jsonQuery = new JsonQuery(filters.dealType, filters.region);
    this.queryClient = queryClient;
  }

  private setTerm<V extends keyof IJsonQuery, K>(jsonQueryProperty: V) {
    return (termValue: K): IJsonQuery => {
      // @ts-expect-error Expression produces a union type that is too complex to represent
      this.jsonQuery[jsonQueryProperty] = new JsonQueryTerm(termValue);

      return this.jsonQuery;
    };
  }

  private setTerms<V extends keyof IJsonQuery, K>(jsonQueryProperty: V) {
    return (termsValue: K): IJsonQuery => {
      // @ts-expect-error Expression produces a union type that is too complex to represent
      this.jsonQuery[jsonQueryProperty] = new JsonQueryTerms(termsValue);

      return this.jsonQuery;
    };
  }

  private setRange<V extends keyof IJsonQuery, K>(jsonQueryProperty: V) {
    return (rangeValue: K): IJsonQuery => {
      // @ts-expect-error Expression produces a union type that is too complex to represent
      this.jsonQuery[jsonQueryProperty] = new JsonQueryRange(rangeValue);

      return this.jsonQuery;
    };
  }

  private setGeo<V extends keyof IJsonQuery, K>(jsonQueryProperty: V) {
    return (geoValue: K): IJsonQuery => {
      // @ts-expect-error Expression produces a union type that is too complex to represent
      this.jsonQuery[jsonQueryProperty] = new JsonQueryGeo(geoValue);

      return this.jsonQuery;
    };
  }

  public addOfficeType(): this {
    const officeType = getJsonQueryOfficeType(this.filters);
    if (officeType) {
      this.setTerms('office_type')(officeType);
    }

    return this;
  }

  public addPrice(): this {
    const price = getJsonQueryRangeFromRange(this.filters.price);
    if (price) {
      this.setRange('price')(price);
    }

    return this;
  }

  public addCategory(): this {
    const category = getJsonQueryCategory(this.filters);
    if (category) {
      this.setTerms('category')(category);
    }

    return this;
  }

  public addPriceType(): this {
    const priceType = getJsonQueryPriceType(this.filters);

    if (priceType) {
      this.setTerm('price_type')(priceType);
    }

    return this;
  }

  public addTotalArea(): this {
    const totalArea = getJsonQueryRangeFromRange(this.filters.totalArea);

    if (!isCommercialLand(this.filters) && totalArea) {
      this.setRange('total_area')(totalArea);
    }

    return this;
  }

  public addPriceSM(): this {
    const priceSM = getJsonQueryPriceSM(this.filters);

    if (priceSM) {
      this.setTerm('price_sm')(priceSM);
    }

    return this;
  }

  public addSite(): this {
    const site = getJsonQueryRangeFromRange(this.filters.totalArea);

    if (isCommercialLand(this.filters) && site) {
      this.setRange('site')(site);
    }

    return this;
  }

  public addIsByCommercialOwner(): this {
    if (this.filters.isByCommercialOwner) {
      this.setTerm('is_by_commercial_owner')(this.filters.isByCommercialOwner);
    }

    return this;
  }

  public addSpecialtyTypes(): this {
    const specialtyTypes = getJsonQuerySpecialtyTypes(this.filters, this.queryClient);

    if (specialtyTypes && specialtyTypes.length) {
      this.setTerms('specialty_types')(specialtyTypes);
    }

    return this;
  }

  public addReadyBusinessTypes(): this {
    if (hasBusinessOfficeType(this.filters)) {
      const businessTypes = this.filters.officeType.filter(type =>
        [EOfficeType.ReadyBusiness, EOfficeType.RentalBusiness].includes(type),
      );
      const readyBusinessTypes = parseOfficeTypesToReadyBusinessTypes(businessTypes);

      this.setTerms('ready_business_types')(readyBusinessTypes);
    }

    return this;
  }

  public addCoworkingOfferType(): this {
    const coworkingOfferType = getJsonQueryCoworkingOfferType(this.filters);

    if (coworkingOfferType) {
      this.setTerms('coworking_offer_type')(coworkingOfferType);
    }

    return this;
  }

  public addPublishPeriod(): this {
    const publishPeriod = this.filters.publishPeriod;

    if (publishPeriod) {
      this.setTerm('publish_period')(publishPeriod);
    }

    return this;
  }
  public addWithPhoto(): this {
    const withPhoto = this.filters.withPhoto;

    if (withPhoto) {
      this.setTerm('wp')(withPhoto);
    }

    return this;
  }
  public addContract(): this {
    const contract = this.filters.contract;

    if (contract) {
      this.setTerms('contract')(contract);
    }

    return this;
  }

  public addBuildingClassTypeWarehouse(): this {
    const buildingClassTypeWarehouse = this.filters.buildingClassType;

    if (
      [includesManufactureType, includesWarehouseOfficeType].some(fn => fn(this.filters)) &&
      buildingClassTypeWarehouse
    ) {
      this.setTerms('building_class_type__warehouse')(getBuildingClassTypes(buildingClassTypeWarehouse));
    }

    return this;
  }

  public addBuildingClassType(): this {
    const buildingClassType = this.filters.buildingClassType;

    if (
      [includesOfficeType, hasTradeAreaOfficeType, includesBuildingType].some(fn => fn(this.filters)) &&
      buildingClassType
    ) {
      this.setTerms('building_class_type')(getBuildingClassTypes(buildingClassType));
    }

    return this;
  }

  public addWorkplaceCount(): this {
    const workplaceCount = getJsonQueryRangeFromRange(this.filters.workplaceCount);
    if (workplaceCount) {
      this.setRange('workplace_count')(workplaceCount);
    }

    return this;
  }

  public addFloor(): this {
    const floor = getJsonQueryRangeFromRange(this.filters.floor);
    if (floor) {
      this.setRange('floor')(floor);
    }

    return this;
  }

  public addFloorn(): this {
    const floorn = getJsonQueryRangeFromRange(this.filters.floorn);
    if (floorn) {
      this.setRange('floorn')(floorn);
    }

    return this;
  }

  public addFootMin(): this {
    const undergroundTime = this.filters.undergroundTime;
    const time = getJsonQueryRangeFromRange({ max: undergroundTime?.time });
    if (undergroundTime && time) {
      this.setRange('foot_min')(time);
    }

    return this;
  }

  public addGeo(): this {
    const geo = getJsonQueryGeo(this.filters);
    if (geo) {
      this.setGeo('geo')(geo);
    }

    return this;
  }

  public addBSCenter(): this {
    const bsCenter = getJsonQueryBsCenter(this.filters);

    if (bsCenter) {
      this.setTerms('bs_center')(bsCenter);
    }

    return this;
  }

  public addCoworkingId(): this {
    const coworkingId = getJsonQueryCoworkingId(this.filters);

    if (coworkingId) {
      this.setTerms('coworking_id')(coworkingId);
    }

    return this;
  }

  public addInputType(): this {
    const inputType = getJsonQueryInputType(this.filters);

    if (inputType) {
      this.setTerms('input_type')(inputType);
    }

    return this;
  }

  public addOnlyFoot(): this {
    const undergroundTime = this.filters.undergroundTime;
    const type = undergroundTime?.type;

    if (type) {
      this.setTerm('only_foot')(type);
    }

    return this;
  }

  public addBuildingCranageTypesType(): this {
    const buildingCranageTypesType = this.filters.buildingCranageTypesType;

    if (buildingCranageTypesType) {
      this.setTerms('building_cranage_types_type')(buildingCranageTypesType);
    }

    return this;
  }

  public addBuildingGatesType(): this {
    const buildingGatesType = this.filters.buildingGatesType;

    if (buildingGatesType) {
      this.setTerms('building_gates_type')(buildingGatesType);
    }

    return this;
  }

  public addBuildingHeatingType(): this {
    const buildingHeatingType = this.filters.buildingHeatingType;

    if (buildingHeatingType) {
      this.setTerms('building_heating_Type')(buildingHeatingType);
    }

    return this;
  }

  public addBuildingLiftTypesType(): this {
    const buildingLiftTypesType = this.filters.buildingLiftTypesType;

    if (buildingLiftTypesType) {
      this.setTerms('building_lift_types_type')(buildingLiftTypesType);
    }

    return this;
  }

  public addConditionType(): JsonQueryBuilder {
    const { conditionType, officeType } = this.filters;

    if (conditionType) {
      if (!officeType.length || officeType.includes(EOfficeType.Office)) {
        const legacyConditionType = getLegacyConditionTypeForOffice(conditionType);
        this.setTerms('condition_type')(legacyConditionType);
      }

      if (!officeType.length || officeType.some(type => TRADE_AREA_GROUP.includes(type))) {
        const legacyConditionType = getLegacyConditionTypeForTradeAreaGroup(conditionType, officeType);
        this.setTerms('condition_type__free_appointment_object')(legacyConditionType);
      }

      if (!officeType.length || officeType.some(type => WAREHOUSE_GROUP.includes(type))) {
        const legacyConditionType = getLegacyConditionTypeForWarehousGroup(conditionType);
        this.setTerms('condition_type__industry')(legacyConditionType);
      }
    }

    return this;
  }

  public addAmenity(): this {
    const amenity = this.filters.amenity;

    if (amenity) {
      this.setTerms('amenity')(amenity);
    }

    return this;
  }

  public addAllDayAccess(): this {
    const coworkingAccess = this.filters.coworkingAccess;

    if (coworkingAccess?.some(item => item === ECoworkingAccess.AllDayAccess)) {
      this.setTerm('all_day_access')(ECoworkingAccess.AllDayAccess);
    }

    return this;
  }

  public addAllWeekAccess(): this {
    const coworkingAccess = this.filters.coworkingAccess;

    if (coworkingAccess?.some(item => item === ECoworkingAccess.AllWeekAccess)) {
      this.setTerm('all_week_access')(1);
    }

    return this;
  }

  public addBuildingHouseLine(): this {
    const buildingHouseLine = this.filters.buildingHouseLine;

    if (buildingHouseLine) {
      this.setTerms('building_house_line_type__shopping_area')(buildingHouseLine);
    }

    return this;
  }

  public addFloorTypes(): this {
    const floorTypes = this.filters.floorTypes;

    if (floorTypes) {
      this.setTerms('floor_types')(floorTypes);
    }

    return this;
  }

  public addPlacementType(): this {
    const placementType = this.filters.placementType;

    if (placementType) {
      this.setTerms('placement_type')(placementType);
    }

    return this;
  }

  public addFurnitureType(): this {
    const furniture = this.filters.furniture;

    if (furniture) {
      this.setTerm('has_furniture')(furniture === EFurnitureType.WithFurniture);
    }

    return this;
  }
  public addHouseYear(): this {
    const houseYear = getJsonQueryRangeFromRange(this.filters.houseYear);
    if (houseYear) {
      this.setRange('house_year')(houseYear);
    }

    return this;
  }

  public addParkingType(): this {
    const parkingType = this.filters.parkingType;

    if (parkingType) {
      this.setTerms('parking_type')(
        parkingType.includes(EParkingType.AllTypes) ? ALL_AVAILABLE_PARKING_TYPES : parkingType,
      );
    }

    return this;
  }

  public addInfrastructure(): this {
    const infrastructureItems = this.filters.infrastructure;

    infrastructureItems?.forEach(item => {
      if (infrastructureItemToJsonQueryFieldMap[item]) {
        this.setTerm(infrastructureItemToJsonQueryFieldMap[item] as keyof IJsonQuery)(true);
      }
    });

    return this;
  }

  public addId(): this {
    const id = this.filters.id;

    if (id) {
      this.setTerm('id')(id);
    }

    return this;
  }

  public addIdUser(): this {
    const idUser = this.filters.idUser;

    if (idUser) {
      this.setTerm('id_user')(idUser);
    }

    return this;
  }

  public addContainedWords(): this {
    const containWords = this.filters.containWords;

    if (containWords) {
      this.setTerms('description_include')(containWords);
    }

    return this;
  }

  public addExcludedWords(): this {
    const excludeWords = this.filters.excludeWords;

    if (excludeWords) {
      this.setTerms('description_exclude')(excludeWords);
    }

    return this;
  }

  public addContact(): this {
    const { contact } = this.filters;

    if (contact) {
      this.setTerm('contact')(contact);
    }

    return this;
  }

  public addEstateType(): this {
    const estateType = this.filters.estateTypes;

    if (estateType) {
      this.setTerms('estate_type')(estateType);
    }

    return this;
  }

  public addAccessSystem(): this {
    const { accessSystem } = this.filters;

    if (accessSystem) {
      this.setTerm('enter')(accessSystem === EAccessSystem.WithAccessSystem);
    }

    return this;
  }

  public addCommissionType(): JsonQueryBuilder {
    const { commissionType } = this.filters;

    if (commissionType) {
      this.setTerm('commission_type')(commissionType - 1);
    }

    return this;
  }

  public addWithoutAgentCall(): JsonQueryBuilder {
    const { withoutAgentCall } = this.filters;

    if (withoutAgentCall) {
      this.setTerm('cl')(!withoutAgentCall);
    }

    return this;
  }

  public addBuildingType(): JsonQueryBuilder {
    const { buildingType, officeType } = this.filters;

    if (buildingType) {
      const isOnlyBuilding = officeType.length === 1 && officeType.includes(EOfficeType.Building);

      this.setTerms(isOnlyBuilding ? 'building_type__building' : 'building_type2')(
        getJsonQueryBuildingType(buildingType),
      );
    }

    return this;
  }

  public addElectronicTrading(): this {
    const electronicTrading = this.filters.electronicTrading;

    if (electronicTrading) {
      this.setTerm('electronic_trading')(electronicTrading);
    }

    return this;
  }

  public build(): JsonQuery {
    return this.jsonQuery;
  }
}
