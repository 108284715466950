import { FC, ReactNode } from 'react';

import styles from './UndergroundMapSection.css';

type UndergroundMapSectionProps = {
  children: ReactNode;
};

export const UndergroundMapSection: FC<UndergroundMapSectionProps> = props => {
  const { children } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['inner']}>{children}</div>
    </div>
  );
};
