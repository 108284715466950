import type { FetchQueryOptions, QueryClient } from '@tanstack/react-query';

import {
  IApiGeoGeocodedForSearchRequest,
  IApiGeoGeocodedForSearchResponse,
} from 'shared/repositories/monolith-cian-realty/api/geo/geocoded-for-search';
import { IApplicationContext } from 'shared/types/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGeocodedForSearchService } from './internal/fetchService';

export class FetchGeocodedForSearchLoadService {
  private readonly queryClient: QueryClient;

  public constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public async fetchQuery<TData = IApiGeoGeocodedForSearchResponse>(
    dependencies: IApplicationContext,
    parameters: IApiGeoGeocodedForSearchRequest,
    options?: FetchQueryOptions<IApiGeoGeocodedForSearchResponse, QueryError, TData, QueryKey>,
  ): Promise<TData> {
    return this.queryClient.fetchQuery<IApiGeoGeocodedForSearchResponse, QueryError, TData, QueryKey>({
      queryFn: () => fetchGeocodedForSearchService(dependencies, parameters),
      queryKey: getQueryKey(),
      ...options,
    });
  }

  public getQueryData(): IApiGeoGeocodedForSearchResponse | undefined {
    return this.queryClient.getQueryData<IApiGeoGeocodedForSearchResponse>(getQueryKey());
  }
}
