import { createReduxStore } from 'shared/store/createReduxStore';
import { ReduxStore, RootInitialState } from 'shared/types/RootState';
import { IApplicationContext } from 'shared/types/applicationContext';
import { setCoworkingTotalAreaFilterEnabled } from 'shared/utils/rs/Commercial.NewFilters.CoworkingTotalAreaFilter.Enabled';

export function createApplicationState(context: IApplicationContext): ReduxStore {
  const { config } = context;

  const initialState = config.getStrict<RootInitialState>('initialState');

  /**
   * @todo удалить строчку с кодом
   * @description данная функциональность была добавлена в задаче CD-135759, будет удалена в CD-136055
   * @link https://jira.cian.tech/browse/CD-136055
   */
  setCoworkingTotalAreaFilterEnabled(!!config.get('Commercial.NewFilters.CoworkingTotalAreaFilter.Enabled'));

  return createReduxStore(initialState, true);
}
