import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import { Button } from '@cian/ui-kit';
import { ResizeObserver } from '@juggle/resize-observer';
import { Children, useCallback, useEffect, useRef, useState } from 'react';

import { SwitchButton } from '../SwitchButton';
import { ITagProps } from '../Tag/types';
import { Tags } from '../Tags';
import { TagsList } from '../TagsList';

import { Measure } from './components/Measure';

import styles from './styles.css';

interface ITagsCollapsibleProps {
  children: React.ReactElement<ITagProps> | React.ReactElement<ITagProps>[];
  onClear?(): void;
}

export const TagsCollapsible: React.FC<ITagsCollapsibleProps> = props => {
  const { children, onClear } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [visibleTagsCount, setVisibleTagsCount] = useState<number | null>(null);

  const componentRef = useRef<HTMLDivElement | null>(null);
  const buttonsRef = useRef<HTMLDivElement | null>(null);
  const timeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [componentWidth, setComponentWidth] = useState<number | null>(null);
  const tags = Children.toArray(children) as React.ReactElement<ITagProps>[];
  const tagsCount = useRef<number>(tags.length);
  tagsCount.current = tags.length;
  const shouldCollapse = useRef<boolean>(true);
  shouldCollapse.current = visibleTagsCount === null || tagsCount.current > visibleTagsCount;

  const computeWidth = (): void => {
    let buttonsWidth = 0;
    if (buttonsRef.current) {
      buttonsWidth = Math.ceil(buttonsRef.current.getBoundingClientRect().width);
    }

    if (componentRef.current) {
      const { width } = componentRef.current.getBoundingClientRect();
      setComponentWidth(Math.floor(width) - buttonsWidth);
    }
  };

  const handleMeasurementsChange = useCallback((oneLineTagsRaw: number) => {
    setVisibleTagsCount(oneLineTagsRaw);
  }, []);

  const resizeHandler = (): void => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }

    timeoutHandler.current = setTimeout(() => {
      computeWidth();
    }, 100);
  };

  const resizeObserver = useRef(new ResizeObserver(resizeHandler));

  useEffect(() => {
    if (resizeObserver.current && componentRef.current) {
      resizeObserver.current.observe(componentRef.current);
    }

    return (): void => {
      if (resizeObserver.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.current.disconnect();
      }

      if (timeoutHandler.current) {
        clearTimeout(timeoutHandler.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIsomorphicLayoutEffect(() => {
    computeWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  let visibleTags = tags;
  if (isCollapsed && shouldCollapse.current && visibleTagsCount !== null) {
    visibleTags = tags.slice(0, visibleTagsCount);
  }

  return (
    <>
      {!!componentWidth && (
        <Measure width={componentWidth} onMeasurementsChange={handleMeasurementsChange}>
          <TagsList tags={tags} />
        </Measure>
      )}
      <Tags ref={componentRef}>
        {visibleTags}
        <div className={styles['buttons']} ref={buttonsRef}>
          {shouldCollapse.current && <SwitchButton isCollapsed={isCollapsed} onClick={handleButtonClick} />}
          {tags.length > 1 && (
            <Button size="XS" theme="fill_secondary" onClick={onClear}>
              Очистить
            </Button>
          )}
        </div>
      </Tags>
    </>
  );
};
