import { useCallback, useMemo, useRef } from 'react';

import { PopupFilterControlled } from 'shared/components/PopupFilterControlled';
import { ELabelView } from 'shared/components/PopupFilterControlled/types';
import { Select } from 'shared/components/Select';
import { IOption } from 'shared/components/Select/models/Option';
import { OFFICE_TYPES } from 'shared/constants/filters/officeTypes';
import { EFilterType } from 'shared/enums/EFilterType';
import { EOfficeType } from 'shared/types/filters';
import { getFilterOptions } from 'shared/utils/getFilterOptions';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';
import { capitalize } from 'shared/utils/string';

import { OfficeTypePostfix } from './internal/components/OfficeTypePostfix';
import { getAvailableOfficeTypes } from './internal/helpers';
import { renderOption } from './internal/helpers/renderers';

export const OfficeTypeFilter = memoWithDisplayName(() => {
  const dealType = useFilter(EFilterType.dealType);
  const availableOfficeTypes = getAvailableOfficeTypes(dealType);
  const options = getFilterOptions(OFFICE_TYPES, 'Коммерческая');
  const filteredOptions = options.filter(option => availableOfficeTypes.includes(option.value));

  const officeType = useFilter(EFilterType.officeType);
  const values = useMemo(() => new Set(officeType), [officeType]);
  const label = filteredOptions.getLabel(values);

  const { changeOfficeTypeAction } = useFiltersActions();
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const valuesCount = useMemo(() => {
    if (!values.size || filteredOptions.getIsEveryValuesIncluded(values)) {
      return undefined;
    }

    return values.size;
  }, [filteredOptions, values]);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleOptionChange = useCallback<(officeTypeParam: IOption) => void>(
    officeTypeParam => {
      setTargetOffersCountTooltip(EFilterType.officeType);
      changeOfficeTypeAction(officeTypeParam.value);
    },
    [changeOfficeTypeAction, setTargetOffersCountTooltip],
  );

  const content = (
    <Select
      isFullfiledWhenEmpty
      withTooltip
      elementsWithBorder={new Set([EOfficeType.CommercialLand, EOfficeType.Coworking, EOfficeType.Workplace])}
      hostElement={containerRef.current}
      isLastElementCanBeSelected={false}
      options={filteredOptions}
      postfix={<OfficeTypePostfix />}
      renderOption={renderOption}
      values={values}
      onOptionChange={handleOptionChange}
    />
  );

  return (
    <div ref={containerRef}>
      <PopupFilterControlled
        count={valuesCount}
        filterType={EFilterType.officeType}
        label={capitalize(label)}
        labelView={ELabelView.Button}
        placeholder="Тестовый заголовок для фильтра"
        popupContent={content}
      />
    </div>
  );
}, 'OfficeTypeFilter');
