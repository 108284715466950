export enum EPublishPeriod {
  Any = 0,
  Today = -2,
  Hour = 3600,
  Day = 86400,
  Week = 604800,
  TenDays = 864000,
  Month = 2592000,
}

export type PublishPeriod = {
  label: string;
  value: EPublishPeriod;
};
