import {
  IApiGeoGeocodedForSearchRequest,
  IApiGeoGeocodedForSearchResponse,
  IApiGeoGeocodedForSearchResponse200,
  createApiGeoGeocodedForSearchModel,
  fetchApiGeoGeocodedForSearch,
} from 'shared/repositories/monolith-cian-realty/api/geo/geocoded-for-search';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export async function fetchGeocodedForSearchService(
  dependencies: IApplicationContext,
  parameters: IApiGeoGeocodedForSearchRequest,
): Promise<IApiGeoGeocodedForSearchResponse> {
  const {
    httpApi,
    custom: { subdomain },
    logger,
  } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createApiGeoGeocodedForSearchModel(parameters),
    'src/shared/store/serverSide/monolith-cian-realty/api/geo/geocoded-for-search/internal/fetchService.ts',
    logger,
  );

  return fetchApiGeoGeocodedForSearch<IApiGeoGeocodedForSearchResponse200>({
    config: { subdomain },
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
