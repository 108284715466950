import {
  IJsonQueryGeo,
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  JsonQueryTerm,
  JsonQueryTerms,
} from 'shared/models/JsonQueryElement';
import { EBusinessType, EDealType, EOfficeType } from 'shared/types/filters';
import { EAmenityType } from 'shared/types/filters/advanced/amenities';
import { EBuildingCranageType } from 'shared/types/filters/advanced/buildingCranageTypesType';
import { EBuildingGatesType } from 'shared/types/filters/advanced/buildingGatesType';
import { EBuildingHeatingType } from 'shared/types/filters/advanced/buildingHeatingType';
import { EBuildingLiftType } from 'shared/types/filters/advanced/buildingLiftTypesType';
import { EBuidlingHouseLine } from 'shared/types/filters/advanced/buildlingHouseLine';
import { EConditionType } from 'shared/types/filters/advanced/conditionType';
import { ECoworkingAccess } from 'shared/types/filters/advanced/coworkingAccess';
import { EFloorTypes } from 'shared/types/filters/advanced/floorTypes';
import { EPlacementType } from 'shared/types/filters/advanced/placementType';
import { EPublishPeriod } from 'shared/types/filters/advanced/publishPeriods';
import { EUndergroundTimeType } from 'shared/types/filters/advanced/undergroundTime';
import { EBuildingClassType } from 'shared/types/filters/buildingClassType';
import { ECategoryType } from 'shared/types/filters/categoryType';
import { EContractType } from 'shared/types/filters/contractType';
import { EInputType } from 'shared/types/filters/inputType';
import { EWorkplaceType } from 'shared/types/filters/wokplaceType';
import { JsonQueryType } from 'shared/types/jsonQuery';
import { getJsonQueryTypeFromDealType } from 'shared/utils/getJsonQueryTypeFromDealType';

import { IJsonQuery } from '../IJsonQuery';

export class JsonQuery implements IJsonQuery {
  public _type: JsonQueryType;
  public readonly engine_version: IJsonQueryTerm<number> = new JsonQueryTerm(2);
  public office_type?: IJsonQueryTerms<EOfficeType>;
  public price?: IJsonQueryRange;
  public total_area?: IJsonQueryRange;
  public site?: IJsonQueryRange;
  public is_by_commercial_owner?: IJsonQueryTerm<true>;
  public category?: IJsonQueryTerms<ECategoryType>;
  public specialty_types?: IJsonQueryTerms<number>;
  public region: IJsonQueryTerms<number>;
  public workplace_count?: IJsonQueryRange;
  public coworking_offer_type?: IJsonQueryTerms<EWorkplaceType>;
  public building_class_type?: IJsonQueryTerms<EBuildingClassType>;
  public building_class_type__warehouse?: IJsonQueryTerms<EBuildingClassType>;
  public price_type?: IJsonQueryTerm<'workplace'>;
  public price_sm?: IJsonQueryTerm<true>;
  public publish_period?: IJsonQueryTerm<EPublishPeriod>;
  public floor?: IJsonQueryRange;
  public floorn?: IJsonQueryRange;
  public contract?: IJsonQueryTerms<EContractType>;
  public input_type?: IJsonQueryTerms<EInputType>;
  public building_cranage_types_type?: IJsonQueryTerms<EBuildingCranageType>;
  public building_gates_type?: IJsonQueryTerms<EBuildingGatesType>;
  public building_heating_Type?: IJsonQueryTerms<EBuildingHeatingType>;
  public building_lift_types_type?: IJsonQueryTerms<EBuildingLiftType>;
  public condition_type?: IJsonQueryTerms<EConditionType>;
  public condition_type__free_appointment_object?: JsonQueryTerms<EConditionType>;
  public condition_type__industry?: JsonQueryTerms<EConditionType>;
  public amenity?: IJsonQueryTerms<EAmenityType>;
  public all_day_access?: IJsonQueryTerm<ECoworkingAccess.AllDayAccess>;
  public all_week_access?: IJsonQueryTerm<ECoworkingAccess.AllWeekAccess>;
  public building_house_line_type__shopping_area?: IJsonQueryTerms<EBuidlingHouseLine>;
  public floor_types?: IJsonQueryTerms<EFloorTypes>;
  public placement_type?: IJsonQueryTerms<EPlacementType>;
  public wp?: IJsonQueryTerm<true>;
  public geo?: IJsonQueryGeo;
  public bs_center?: IJsonQueryTerms<number>;
  public coworking_id?: IJsonQueryTerms<number>;
  public only_foot?: IJsonQueryTerm<EUndergroundTimeType>;
  public foot_min?: IJsonQueryRange;
  public has_furniture?: IJsonQueryTerm<true>;
  public id?: IJsonQueryTerm<number>;
  public id_user?: IJsonQueryTerm<number>;
  public house_year?: IJsonQueryRange;
  public parking_type?: IJsonQueryTerms<number>;
  public infrastructure_has_car_wash?: IJsonQueryTerm<true>;
  public infrastructure_has_car_service?: IJsonQueryTerm<true>;
  public infrastructure_has_pharmacy?: IJsonQueryTerm<true>;
  public infrastructure_has_clothes_studio?: IJsonQueryTerm<true>;
  public infrastructure_has_atm?: IJsonQueryTerm<true>;
  public infrastructure_has_pool?: IJsonQueryTerm<true>;
  public infrastructure_has_buffet?: IJsonQueryTerm<true>;
  public infrastructure_has_exhibition_and_warehouse_complex?: IJsonQueryTerm<true>;
  public infrastructure_has_hotel?: IJsonQueryTerm<true>;
  public infrastructure_has_cafe?: IJsonQueryTerm<true>;
  public infrastructure_has_cinema?: IJsonQueryTerm<true>;
  public infrastructure_has_conference_room?: IJsonQueryTerm<true>;
  public infrastructure_has_medical_center?: IJsonQueryTerm<true>;
  public infrastructure_has_minimarket?: IJsonQueryTerm<true>;
  public infrastructure_has_notary_office?: IJsonQueryTerm<true>;
  public infrastructure_has_bank_department?: IJsonQueryTerm<true>;
  public infrastructure_has_park?: IJsonQueryTerm<true>;
  public infrastructure_has_restaurant?: IJsonQueryTerm<true>;
  public infrastructure_has_beauty_shop?: IJsonQueryTerm<true>;
  public infrastructure_has_warehouse?: IJsonQueryTerm<true>;
  public infrastructure_has_canteen?: IJsonQueryTerm<true>;
  public infrastructure_has_supermarket?: IJsonQueryTerm<true>;
  public infrastructure_has_shopping_area?: IJsonQueryTerm<true>;
  public infrastructure_has_fitness_centre?: IJsonQueryTerm<true>;
  public infrastructure_has_studio?: IJsonQueryTerm<true>;
  public infrastructure_has_central_reception?: IJsonQueryTerm<true>;
  public description_include?: IJsonQueryTerms<string>;
  public description_exclude?: IJsonQueryTerms<string>;
  public contact?: IJsonQueryTerm<string>;
  public estate_type?: IJsonQueryTerms<number>;
  public enter?: IJsonQueryTerm<boolean>;
  public ready_business_types?: IJsonQueryTerms<EBusinessType>;
  public commission_type?: IJsonQueryTerm<number>;
  public cl?: IJsonQueryTerm<false>;
  public building_type2?: IJsonQueryTerms<number>;
  public building_type__building?: IJsonQueryTerms<number>;

  public constructor(dealType: EDealType, region: number[]) {
    this._type = getJsonQueryTypeFromDealType(dealType);
    this.region = new JsonQueryTerms(region);
  }
}
