import { EFilterType } from 'shared/enums/EFilterType';
import { Filters } from 'shared/types/filters';

import { IFiltersService } from '../IFiltersService';

export abstract class AbstractFiltersService implements IFiltersService<EFilterType> {
  public readonly filters: Filters;

  public abstract isAvailable(): boolean;

  public constructor(filters: Filters) {
    this.filters = filters;
  }

  public getFields(): EFilterType[] {
    return [EFilterType.dealType, EFilterType.officeType, EFilterType.priceType, EFilterType.region];
  }
}
