import { InputGroup } from '@cian/ui-kit';
import { useMemo } from 'react';

import { RangeInputAdornment } from '../RangeInputAdornment';

import { inputFactory } from './internal/components/inputFactory';
import { IRangeInputProps } from './types';

export const RangeInput: React.FC<IRangeInputProps> = props => {
  const {
    disabled,
    isInvalid,
    postfix,
    isFormattedInput,
    // HACK при передачи undefined в Input, он не сбрасывает свое значение в рантайме, только при перемаунте
    valueMin = '',
    valueMax = '',
    maxWidth,
    onBlurMax,
    onBlurMin,
    onChangeMin,
    onChangeMax,
  } = props;

  const rightAdornment = postfix && <RangeInputAdornment postfix={postfix} />;

  const InputComponent = useMemo(() => inputFactory(isFormattedInput), [isFormattedInput]);

  return (
    <InputGroup maxWidth={maxWidth} spliced={false}>
      <InputComponent
        data-testid="min-input"
        disabled={disabled}
        invalid={isInvalid}
        placeholder="от"
        rightAdornment={rightAdornment}
        value={valueMin.toString()}
        onBlur={onBlurMin}
        onChange={onChangeMin}
      />
      <InputComponent
        data-testid="max-input"
        disabled={disabled}
        invalid={isInvalid}
        placeholder="до"
        rightAdornment={rightAdornment}
        value={valueMax.toString()}
        onBlur={onBlurMax}
        onChange={onChangeMax}
      />
    </InputGroup>
  );
};

RangeInput.displayName = 'RangeInput';
