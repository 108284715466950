import {
  EBehavior,
  NestedCheckboxGroupsWithSearchDesktop,
  convertCheckboxGroupItemsToCheckboxGroup,
  flatCheckboxGroupsToItems,
} from '@cian/nested-checkbox-groups';
import { useCallback, useMemo } from 'react';

import { FilterSelectButton } from 'shared/components/FilterSelectButton';
import { ELabelView, PopupFilterControlled } from 'shared/components/PopupFilterControlled';
import { Option } from 'shared/components/Select/models/Option';
import { Options } from 'shared/components/Select/models/Options';
import { IGetPossibleAppointmentItem } from 'shared/repositories/announcements/entities/AnnouncementReferences/GetPossibleAppointmentItem';
import { useGetPossibleAppointmentsQuery } from 'shared/store/serverSide/announcements/v1/get-possible-appointments/query';
import { useSpecialtyTypesIds } from 'shared/utils/hooks/useSpecialtyTypesIds';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';
import { capitalize } from 'shared/utils/string';

import { itemsFromFetchGetSpecialtiesSelector } from '../../../../store/serverSide/announcements/v1/get-possible-appointments/selectors';

import { useSpecialtyTypesFilterBoundActions } from './internal/hooks/useSpecialtyTypesFilterBoundActions';
import { HandleNestedCheckboxClick, HandleTitleCheckboxClick } from './types';

import styles from './SpecialtyTypesFilter.css';

const placeholder = 'Выберите назначение';

const defaultCheckedSpecialtiesIds = Array.of<number>();
const defaultSpecialties = Array.of<IGetPossibleAppointmentItem>();

export const SpecialtyTypesFilter = memoWithDisplayName(() => {
  const { data: specialties = defaultSpecialties } = useGetPossibleAppointmentsQuery({
    select: itemsFromFetchGetSpecialtiesSelector,
  });
  const values = useSpecialtyTypesIds() ?? defaultCheckedSpecialtiesIds;

  const groups = useMemo(
    () => [
      convertCheckboxGroupItemsToCheckboxGroup(
        specialties.map(specialty => ({ label: specialty.name, value: specialty.id })),
        '',
        'specialties',
      ),
    ],
    [specialties],
  );

  const boundActions = useSpecialtyTypesFilterBoundActions();

  const handleNestedCheckboxClick = useCallback<HandleNestedCheckboxClick>(
    checkboxGroupItemChangeModel => {
      const specialtyTypes = checkboxGroupItemChangeModel.allItems.map(item => ({ id: item.value, title: item.label }));

      boundActions.setSpecialtyTypesAction(specialtyTypes);
    },
    [boundActions],
  );

  const handleTitleCheckboxClick = useCallback<HandleTitleCheckboxClick>(
    checkboxGroupChangeModel => {
      const specialtyTypes = checkboxGroupChangeModel.allItems.map(item => ({ id: item.value, title: item.label }));

      boundActions.setSpecialtyTypesAction(specialtyTypes);
    },
    [boundActions],
  );

  const options = useMemo(
    () =>
      new Options(
        flatCheckboxGroupsToItems(groups).map(item => new Option(item.value, item.label)),
        placeholder,
      ),
    [groups],
  );

  const label = useMemo(() => capitalize(options.getLabel(new Set(values))), [options, values]);

  if (!groups.length) {
    return <FilterSelectButton onClick={(): void => {}}>{placeholder}</FilterSelectButton>;
  }

  return (
    <PopupFilterControlled
      count={values.length}
      filterType={null}
      label={label}
      labelView={ELabelView.Select}
      popupContent={
        <NestedCheckboxGroupsWithSearchDesktop
          behavior={EBehavior.Multiply}
          classNameContainer={styles['container']}
          classNameNestedCheckboxGroups={styles['nested-checkbox-groups']}
          groups={groups}
          values={values}
          onNestedCheckboxClick={handleNestedCheckboxClick}
          onTitleCheckboxClick={handleTitleCheckboxClick}
        />
      }
    />
  );
}, 'SpecialtyTypesFilter');
