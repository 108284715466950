import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { hasWorkplaceOfficeType } from 'shared/utils/filters/hasWorkplaceOfficeType';
import { isRent } from 'shared/utils/filters/isRent';
import { getCoworkingTotalAreaFilterEnabled } from 'shared/utils/rs/Commercial.NewFilters.CoworkingTotalAreaFilter.Enabled';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Коворкинг". Работает только для "Аренда"
 */
export class RentCoworkingAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return isRent(this.filters) && (hasCoworkingOfficeType(this.filters) || hasWorkplaceOfficeType(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return (
      super
        .getFields()
        .concat(
          EAdvancedFilterType.floor,
          EAdvancedFilterType.floorTypes,
          EAdvancedFilterType.totalArea,
          EAdvancedFilterType.amenity,
          EAdvancedFilterType.coworkingAccess,
          // Эл. торги НЕ применимы для коворкингов!
        )
        /**
         * @todo удалить фильтрацию полей
         * @description данная функциональность была добавлена в задаче CD-135759, будет удалена в CD-136055
         * @link https://jira.cian.tech/browse/CD-136055
         */
        .filter(field => {
          if (field === EAdvancedFilterType.totalArea) {
            return getCoworkingTotalAreaFilterEnabled();
          }

          return true;
        })
    );
  }
}
