import { createAction } from 'typesafe-actions';

import { Filters } from 'shared/types/filters';
import { TermTypeKeys } from 'shared/types/filters/advanced/termFilterType';

export type TermFilterPayload<F extends TermTypeKeys = TermTypeKeys> = {
  name: F;
  value: Filters[F];
};

export const setTermFilterValue = createAction('filters/termFilterChanged')<TermFilterPayload>();
