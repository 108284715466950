import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IGetMultipleExperimentResponse } from 'shared/repositories/ab-use/v1/get-multiple-experiment';
import { useApplicationContext } from 'shared/utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { EXPERIMENTS } from './internal/experiments';
import { fetchGetMultipleExperimentService } from './internal/fetchService';

export function useAbUseExperimentsQuery<TData = IGetMultipleExperimentResponse>(
  options?: UseQueryOptions<IGetMultipleExperimentResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();

  return useQuery<IGetMultipleExperimentResponse, QueryError, TData, QueryKey>({
    enabled: !!EXPERIMENTS.length,
    initialData: { result: [] },
    queryFn: () => fetchGetMultipleExperimentService(context, { experimentsNames: EXPERIMENTS, ignoreNotFound: true }),
    queryKey: getQueryKey(),
    ...options,
  });
}
