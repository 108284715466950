import { FC, NamedExoticComponent, memo } from 'react';

export function memoWithDisplayName<P extends object>(
  Component: FC<P>,
  displayName: string,
  propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean,
): NamedExoticComponent<P> {
  Component.displayName = displayName;

  return memo<P>(Component, propsAreEqual);
}
