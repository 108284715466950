import { AvailableFiltersProps } from './types';

import styles from './styles.css';

export const AvailableFilters: React.FC<AvailableFiltersProps> = props => {
  const { filtersByColumns, children } = props;

  return (
    <div className={styles['filters']}>
      {filtersByColumns[0].map(filter => (
        <div className={styles['filter']} key={filter.key}>
          {filter.component}
        </div>
      ))}
      {children && (
        <div className={styles['filter']} key="children">
          {children}
        </div>
      )}
      {filtersByColumns[1].map(filter => (
        <div className={styles['filter']} key={filter.key}>
          {filter.component}
        </div>
      ))}
    </div>
  );
};

AvailableFilters.displayName = 'AvailableFilters';
