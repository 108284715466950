import { EBusinessType, EOfficeType } from 'shared/types/filters';

export const parseReadyBusinessTypes = (readyBusinessTypes: string | string[] | undefined): EOfficeType[] => {
  if (!readyBusinessTypes || !Array.isArray(readyBusinessTypes)) {
    return [EOfficeType.RentalBusiness, EOfficeType.ReadyBusiness];
  }

  return readyBusinessTypes.reduce((acc, item) => {
    const parsedItem: EBusinessType = Number(item);

    switch (parsedItem) {
      case EBusinessType.ReadyBusiness:
        acc.push(EOfficeType.ReadyBusiness);
        break;

      case EBusinessType.RentalBusiness:
        acc.push(EOfficeType.RentalBusiness);
        break;
    }

    return acc;
  }, Array.of<EOfficeType>());
};

export const parseOfficeTypesToReadyBusinessTypes = (officeTypes: EOfficeType[]): EBusinessType[] => {
  return officeTypes.reduce((acc, item) => {
    switch (item) {
      case EOfficeType.ReadyBusiness:
        acc.push(EBusinessType.ReadyBusiness);
        break;

      case EOfficeType.RentalBusiness:
        acc.push(EBusinessType.RentalBusiness);
        break;
    }

    return acc;
  }, Array.of<EBusinessType>());
};
