import { InputAdornment } from '@cian/ui-kit';
import { FC } from 'react';

import { IRangeInputAdornmentProps } from './types';

import styles from './RangeInputAdornment.css';

export const RangeInputAdornment: FC<IRangeInputAdornmentProps> = props => {
  return (
    <InputAdornment>
      <span className={styles['postfix']} data-testid="input-adornment">
        {props.postfix}
      </span>
    </InputAdornment>
  );
};

RangeInputAdornment.displayName = 'RangeInputAdornment';
