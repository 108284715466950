import {
  ISuggestRequest,
  ISuggestResponse,
  ISuggestResponse200,
  ISuggestResponse400,
  createSuggestModel,
  fetchSuggest,
} from 'shared/repositories/geo-suggest/v2/suggest';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export async function fetchGetGeoSuggestService(
  dependencies: IApplicationContext,
  parameters: ISuggestRequest,
): Promise<ISuggestResponse> {
  const { httpApi, logger } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createSuggestModel(parameters),
    'src/shared/store/serverSide/geo-suggest/v2/suggest/internal/fetchService.ts',
    logger,
  );

  return fetchSuggest<ISuggestResponse200, ISuggestResponse400>({
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        case 400:
          throw fetchAssertsService.assert400StatusCodeError(result);
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
