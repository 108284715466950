import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';

export type LastChangedElement = [null | string, null | number];

export type UseSpecialtyTypesTooltipObservableData = {
  lastChangedElement: LastChangedElement;
  lastChangedElementRef: RefObject<HTMLDivElement>;
  nestedCheckboxGroupsContainerRef: RefObject<HTMLDivElement>;
  setLastChangedElement: Dispatch<SetStateAction<LastChangedElement>>;
};

export function useBusinessAppointmentsFilterTooltipObserver(): UseSpecialtyTypesTooltipObservableData {
  const nestedCheckboxGroupsContainerRef = useRef<HTMLDivElement>(null);
  const lastChangedElementRef = useRef<HTMLDivElement>(null);
  const [lastChangedElement, setLastChangedElement] = useState<LastChangedElement>([null, null]);

  useEffect(() => {
    if (lastChangedElementRef.current) {
      const observer = new IntersectionObserver(
        entries => {
          const [group, item] = lastChangedElement;

          if (!entries[0].isIntersecting && (group || item)) {
            setLastChangedElement([null, null]);
          }
        },
        {
          root: nestedCheckboxGroupsContainerRef.current,
          rootMargin: '0px 0px 0px 0px',
          threshold: 0.5,
        },
      );

      observer.observe(lastChangedElementRef.current);

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [lastChangedElement]);

  return {
    lastChangedElement,
    lastChangedElementRef,
    nestedCheckboxGroupsContainerRef,
    setLastChangedElement,
  };
}
