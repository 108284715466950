import { UnknownError } from '@cian/peperrors/shared';

import { EQueryKeys } from 'shared/store/serverSide/EQueryKeys';

export type QueryError = UnknownError;

export type QueryKey = [queryKey: EQueryKeys.Map__V2_GetUndergroundMapData, locationId: number];
export const getQueryKey = (locationId: QueryKey[1]): QueryKey => [
  EQueryKeys.Map__V2_GetUndergroundMapData,
  locationId,
];
