// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TGetBusinessPossibleAppointmentsModel,
  IMappers,
  TGetBusinessPossibleAppointmentsResponse,
  IGetBusinessPossibleAppointmentsResponse,
} from './types';

export const defaultConfig: TGetBusinessPossibleAppointmentsModel = {
  apiType: 'public',
  assertStatusCodes: [200],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'announcements',
  pathApi: '/v1/get-business-possible-appointments/',
} as TGetBusinessPossibleAppointmentsModel;

export function createGetBusinessPossibleAppointmentsModel(): TGetBusinessPossibleAppointmentsModel {
  return {
    ...defaultConfig,
  };
}

export interface IGetBusinessPossibleAppointmentsOptions<TResponse200> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
}

export async function fetchGetBusinessPossibleAppointments<TResponse200>({
  httpApi,
  config,
  mappers,
}: IGetBusinessPossibleAppointmentsOptions<TResponse200>): Promise<
  TGetBusinessPossibleAppointmentsResponse | TResponse200
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetBusinessPossibleAppointmentsModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBusinessPossibleAppointmentsResponse);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetBusinessPossibleAppointmentsResponse;
}
