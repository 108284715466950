import { ICianApiSiteV1SearchRegionsCitiesResponse } from 'shared/repositories/monolith-python/cian-api/site/v1/search-regions-cities';

export function dataFromSearchRegionsCitiesSelector(
  state: ICianApiSiteV1SearchRegionsCitiesResponse,
): ICianApiSiteV1SearchRegionsCitiesResponse['data'] {
  return state.data;
}

export function regionsCitiesFromSearchRegionsCitiesSelector(
  state: ICianApiSiteV1SearchRegionsCitiesResponse,
): ICianApiSiteV1SearchRegionsCitiesResponse['data']['items'] {
  return dataFromSearchRegionsCitiesSelector(state).items;
}
