import { Filters } from 'shared/types/filters';
import { EInputType } from 'shared/types/filters/inputType';

export function getJsonQueryInputType(filters: Filters): EInputType[] | undefined {
  const { inputType, isSeparatedInputType } = filters;

  const jsonQueryInputType = ((): EInputType[] | undefined => {
    if (isSeparatedInputType) {
      if (!inputType) {
        return [EInputType.separatedFromStreet, EInputType.separatedFromYard];
      }

      return inputType.filter(inputTypeItem =>
        [EInputType.separatedFromStreet, EInputType.separatedFromYard].includes(inputTypeItem),
      );
    }

    return inputType?.reduce((acc, inputTypeItem) => {
      acc.push(inputTypeItem);

      if (inputTypeItem === EInputType.separatedFromStreet) {
        acc.push(EInputType.fromStreet);
      }

      if (inputTypeItem === EInputType.separatedFromYard) {
        acc.push(EInputType.fromYard);
      }

      return acc;
    }, Array.of<EInputType>());
  })();

  if (!jsonQueryInputType || !jsonQueryInputType.length) {
    return undefined;
  }

  return jsonQueryInputType;
}
