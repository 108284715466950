import { IContext } from '@cian/save-search-widget';
import { Button, ModalWindow, Spinner, UIText1 } from '@cian/ui-kit';
import loadable from '@loadable/component';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { WidgetErrorBoundary } from './internal/components/WidgetErrorBoundary';
import { WidgetFallback } from './internal/components/WidgetFallback';
import { SaveSearchModalProps } from './types';

import * as styles from './SaveSearchModal.css';

const SaveSearchWidgetLoadable = loadable.lib(() => import('@cian/save-search-widget'), {
  fallback: <WidgetFallback />,
});

export const SaveSearchModal: FC<SaveSearchModalProps> = ({
  logger,
  open: isOpen,
  title,
  userEmail,
  subdomain,
  httpApi,
  jsonQuery,
  hideLicense,
  subscribeToPushAvailable,
  onClose,
  onPushSubscribe,
  onSubscribed,
  isDataLoading,
  isDataError,
  onErrorRetry,
}) => {
  const widgetContext = useRef<IContext | null>(null);

  const handleRetry = useCallback(() => {
    SaveSearchWidgetLoadable.load();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      widgetContext.current = null;
    }
  }, [isOpen]);

  const content = useMemo(() => {
    if (isDataLoading) {
      return (
        <div className={styles['loading-container']} data-testid="save-search-modal-content-loading">
          <Spinner size={32} />
        </div>
      );
    }

    if (isDataError) {
      return (
        <div data-testid="save-search-modal-content-error">
          <UIText1>Возникла ошибка, попробуйте ещё раз.</UIText1>
          <div className={styles['retry-button-wrapper']}>
            <Button size="XS" onClick={onErrorRetry}>
              Повторить
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div data-testid="save-search-modal-content-widget">
        <WidgetErrorBoundary logger={logger} onRetry={handleRetry}>
          <SaveSearchWidgetLoadable>
            {({ createContext, Provider, SaveSearch, EFrequency, ESource }): JSX.Element => {
              if (!widgetContext.current) {
                widgetContext.current = createContext({
                  httpApi,
                  logger,
                  subdomain,
                });
              }

              return (
                <Provider context={widgetContext.current}>
                  <SaveSearch
                    defaultEmail={userEmail}
                    defaultFrequency={EFrequency.Hourly}
                    defaultSubscribeToNews={true}
                    defaultSubscribeToPush={true}
                    defaultTitle={title || undefined}
                    hideLicense={hideLicense}
                    jsonQuery={jsonQuery}
                    source={ESource.Listing}
                    subscribeToPushAvailable={subscribeToPushAvailable}
                    onPushSubscribe={onPushSubscribe}
                    onSubscribed={onSubscribed}
                  />
                </Provider>
              );
            }}
          </SaveSearchWidgetLoadable>
        </WidgetErrorBoundary>
      </div>
    );
  }, [
    handleRetry,
    hideLicense,
    httpApi,
    isDataError,
    isDataLoading,
    jsonQuery,
    logger,
    onPushSubscribe,
    onSubscribed,
    subdomain,
    subscribeToPushAvailable,
    title,
    userEmail,
    onErrorRetry,
  ]);

  return (
    <ModalWindow
      content={<div className={styles['container']}>{content}</div>}
      open={isOpen}
      title="Сохранение поиска"
      onClose={onClose}
    />
  );
};
