import { EDealType, EOfficeType } from 'shared/types/filters';

import { filterOfficeTypeByDealType } from '../helpers/filterOfficeTypeByDealType';

import { EXCLUDED_RENT_OFFICE_TYPES } from './excludedRentOfficeTypes';
import { EXCLUDED_SALE_OFFICE_TYPES } from './excludedSaleOfficeTypes';

export const MAP_OFFICE_TYPE_WITH_DEAL_TYPE: Record<EDealType, EOfficeType[]> = {
  [EDealType.Rent]: filterOfficeTypeByDealType(EXCLUDED_RENT_OFFICE_TYPES),
  [EDealType.Sale]: filterOfficeTypeByDealType(EXCLUDED_SALE_OFFICE_TYPES),
};
