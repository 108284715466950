import { CustomTooltip } from '@cian/ui-kit';
import { FC } from 'react';

import { IFilterPopupWindowProps } from './types';

export const FilterPopupWindow: FC<IFilterPopupWindowProps> = props => {
  const { opened, anchorRef, children } = props;

  if (!opened) {
    return null;
  }

  return (
    <CustomTooltip
      anchorRef={anchorRef}
      content={children}
      maxWidth={350}
      open={opened}
      placement="bottom-start"
      portal={false}
    />
  );
};

FilterPopupWindow.displayName = 'FilterPopupWindow';
