import { Filters } from 'shared/types/filters';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { hasTradeAreaOfficeType } from 'shared/utils/filters/hasTradeAreaOfficeType';
import { hasWorkplaceOfficeType } from 'shared/utils/filters/hasWorkplaceOfficeType';
import { includesOfficeType } from 'shared/utils/filters/includesOfficeType';
import { isSingleOfficeType } from 'shared/utils/filters/isSingleOfficeType';

export function getGeoSuggestPlaceholder(filters: Filters): string {
  if (
    hasCoworkingOfficeType(filters) ||
    hasWorkplaceOfficeType(filters) ||
    (isSingleOfficeType(filters) && includesOfficeType(filters))
  ) {
    return 'Адрес или БЦ';
  }

  if (hasTradeAreaOfficeType(filters) && isSingleOfficeType(filters)) {
    return 'Адрес или ТЦ';
  }

  return 'Адрес';
}
