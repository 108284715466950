import { Checkbox } from '@cian/ui-kit';

import { mapFloorTypeLabel } from 'shared/constants/filters/advanced/floorTypes';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EFloorTypes } from 'shared/types/filters/advanced/floorTypes';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';

import { HandleChange } from './types';

export const FloorTypes: React.FC = () => {
  const floorTypes = useFilter(EAdvancedFilterType.floorTypes);
  const { toggleTermsFilterValue } = useFiltersActions();

  const handleChange: HandleChange = floorType => () =>
    toggleTermsFilterValue({ name: EAdvancedFilterType.floorTypes, value: floorType });

  return (
    <>
      <Checkbox
        checked={floorTypes?.includes(EFloorTypes.OnlyFirstFloor)}
        label={mapFloorTypeLabel[EFloorTypes.OnlyFirstFloor]}
        onChange={handleChange(EFloorTypes.OnlyFirstFloor)}
      />
      <Checkbox
        checked={floorTypes?.includes(EFloorTypes.NotBasement)}
        label={mapFloorTypeLabel[EFloorTypes.NotBasement]}
        onChange={handleChange(EFloorTypes.NotBasement)}
      />
    </>
  );
};
