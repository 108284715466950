import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';

import { IGetMetaResponse } from 'shared/repositories/search-offers-index/v2/get-meta';
import { useApplicationContext } from 'shared/utils/applicationContext';
import { useJsonQuery } from 'shared/utils/hooks/useJsonQuery';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchPublicDesktopV1FiltersGetCountService } from './internal/fetchService';

export function useGetCountQuery(
  options?: UseQueryOptions<IGetMetaResponse, QueryError, number, QueryKey>,
): UseQueryResult<number, QueryError> {
  const context = useApplicationContext();
  const jsonQuery = useJsonQuery();

  const [debouncedJsonQuery] = useDebounce(jsonQuery, 300);

  return useQuery<IGetMetaResponse, QueryError, number, QueryKey>({
    keepPreviousData: true,
    queryFn: () => fetchPublicDesktopV1FiltersGetCountService(context, { jsonQuery }),
    queryKey: getQueryKey({ jsonQuery: debouncedJsonQuery }),
    select: data => data.data.count,
    ...options,
  });
}
