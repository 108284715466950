import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EFilterType } from 'shared/enums/EFilterType';
import { filtersSelector } from 'shared/store/data/filters/selectors/root/filtersSelector';
import { getAvailableFilters } from 'shared/utils/filters/getAvailableFilters';

export function useAvailableFilters(): EFilterType[] {
  const filters = useSelector(filtersSelector);

  return useMemo(() => getAvailableFilters(filters), [filters]);
}
