export enum EBuildingClassType {
  A = 1,
  APlus = 2,
  B = 3,
  BPlus = 4,
  C = 5,
  CPlus = 6,
  D = 7,
  BMinus = 8,
}
