import { ITagOption } from '@cian/ui-kit/tags/types/ITagOption';
import { useCallback, useMemo } from 'react';

import { InputWithTags } from 'shared/components/InputWithTags';
import { Option } from 'shared/components/Select/models/Option';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { CommonTagsFilterProps } from './types';

export const CommonTagsFilter = memoWithDisplayName<CommonTagsFilterProps>(props => {
  const { filterName, placeholder } = props;
  const filterValue = useFilter(filterName);
  const options = useMemo(() => filterValue?.map(value => new Option(value, value)) || [], [filterValue]);

  const { toggleTermsFilterValue } = useFiltersActions();
  const handler = useCallback(
    (tag: ITagOption): void => {
      toggleTermsFilterValue({ name: filterName, value: tag.value });
    },
    [filterName, toggleTermsFilterValue],
  );

  return (
    <InputWithTags
      id={`${filterName}Tags`}
      options={options}
      placeholder={placeholder}
      onAdd={handler}
      onRemove={handler}
    />
  );
}, 'CommonTagsFilter');
