import { CheckboxButtonGroup } from '@cian/ui-kit';
import { useMemo } from 'react';

import { Option } from 'shared/components/Select/models/Option';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EFilterType } from 'shared/enums/EFilterType';
import { EOfficeType } from 'shared/types/filters';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { BUILDING_A_CLASS_TYPES, BUILDING_CLASS_TYPES, builidingClassTypeMap } from './constants';

export const BuildingClassTypeFilter = memoWithDisplayName(() => {
  const officeTypes = useFilter(EFilterType.officeType);
  const options = useMemo(() => {
    const fields =
      officeTypes.length && officeTypes.every(type => type === EOfficeType.Office)
        ? BUILDING_A_CLASS_TYPES
        : BUILDING_CLASS_TYPES;

    return fields.map(field => new Option(field, builidingClassTypeMap[field]));
  }, [officeTypes]);

  const buildingClassType = useFilter(EAdvancedFilterType.buildingClassType);
  const { changeBuildingClassTypesAction } = useFiltersActions();

  return <CheckboxButtonGroup options={options} value={buildingClassType} onChange={changeBuildingClassTypesAction} />;
}, 'BuildingClassTypeFilter');
