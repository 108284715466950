import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { isOnlyBusinessCategory } from 'shared/utils/filters/isOnlyBusinessCategory';
import { isSale } from 'shared/utils/filters/isSale';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Готовый бизнес". Работает только для "Продажа"
 */
export class SaleBusinessAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    if (!this.filters.officeType.length) {
      return false;
    }

    return [isSale, isOnlyBusinessCategory].every(fn => fn(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return super.getFields().concat(
      EAdvancedFilterType.floor,
      EAdvancedFilterType.floorTypes,
      EAdvancedFilterType.conditionType,
      EAdvancedFilterType.contract,
      EAdvancedFilterType.estateTypes,
      EAdvancedFilterType.furniture,
      EAdvancedFilterType.parkingType,
      EAdvancedFilterType.commissionType,
      EAdvancedFilterType.withoutAgentCall,
      // Эл. торги НЕ применимы для готового бизнеса!
    );
  }
}
