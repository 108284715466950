import { CheckboxButtonGroup } from '@cian/ui-kit';
import { useMemo } from 'react';

import { Option } from 'shared/components/Select/models/Option';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EFilterType } from 'shared/enums/EFilterType';
import { EDealType } from 'shared/types/filters';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { RENT, SALE, contractTypeMap } from './constants';

export const ContractTypeFilter = memoWithDisplayName(() => {
  const dealType = useFilter(EFilterType.dealType);
  const options = useMemo(() => {
    const fields = dealType === EDealType.Sale ? SALE : RENT;

    return fields.map(field => new Option(field, contractTypeMap[field]));
  }, [dealType]);

  const contractType = useFilter(EAdvancedFilterType.contract);
  const { changeContractAction } = useFiltersActions();

  return <CheckboxButtonGroup options={options} value={contractType} onChange={changeContractAction} />;
}, 'ContractTypeFilter');
