import { FC, useEffect } from 'react';

import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';

import { AdvancedFiltersButton } from '../AdvancedFiltersButton';
import { AdvancedFiltersModalContainer } from '../AdvancedFiltersModal';
import { AvailableFiltersContainer } from '../AvailableFilters';
import { FindButtonContainerTooltipWrapper } from '../FindButton/FindButtonContainerTooltipWrapper';
import { GeoSuggestContainer } from '../GeoSuggest/container';
import { SaveSearchButtonContainer } from '../SaveSearchButton';

import styles from './FilterWrapper.css';

export const FilterWrapper: FC = () => {
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  useEffect(() => {
    const handleHideTargetOffersCountTooltip = (): void => {
      setTargetOffersCountTooltip(null);
    };

    document.addEventListener('scroll', handleHideTargetOffersCountTooltip, false);

    return (): void => document.removeEventListener('scroll', handleHideTargetOffersCountTooltip, false);
  }, [setTargetOffersCountTooltip]);

  return (
    <div className={styles['container']}>
      <div className={styles['column']}>
        <AvailableFiltersContainer>
          <AdvancedFiltersModalContainer>
            {(_, onClick): JSX.Element => <AdvancedFiltersButton onClick={onClick} />}
          </AdvancedFiltersModalContainer>
        </AvailableFiltersContainer>
      </div>
      <div className={styles['column']}>
        <div className={styles['geosuggest']}>
          <GeoSuggestContainer />
        </div>
        <div>
          <FindButtonContainerTooltipWrapper />
        </div>
        <div>
          <SaveSearchButtonContainer />
        </div>
      </div>
    </div>
  );
};

FilterWrapper.displayName = 'FilterWrapper';
