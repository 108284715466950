import {
  ICianApiSiteV1GetRegionCitiesRequest,
  ICianApiSiteV1GetRegionCitiesResponse,
  ICianApiSiteV1GetRegionCitiesResponse200,
  ICianApiSiteV1GetRegionCitiesResponse400,
  createCianApiSiteV1GetRegionCitiesModel,
  fetchCianApiSiteV1GetRegionCities,
} from 'shared/repositories/monolith-python/cian-api/site/v1/get-region-cities';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export function fetchGetRegionCitiesService(
  dependencies: IApplicationContext,
  parameters: ICianApiSiteV1GetRegionCitiesRequest,
): Promise<ICianApiSiteV1GetRegionCitiesResponse> {
  const {
    httpApi,
    custom: { subdomain },
    logger,
  } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createCianApiSiteV1GetRegionCitiesModel(parameters),
    'src/shared/store/serverSide/monolith-python/cian-api/site/v1/get-region-cities/internal/fetchService.ts',
    logger,
  );

  return fetchCianApiSiteV1GetRegionCities<
    ICianApiSiteV1GetRegionCitiesResponse200,
    ICianApiSiteV1GetRegionCitiesResponse400
  >({
    config: { subdomain },
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        case 400:
          throw fetchAssertsService.assert400StatusCodeError({ response: result.response.data });
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
