import { ArticleParagraph2, Input, InputAdornment, RadioButtonGroup } from '@cian/ui-kit';
import { useCallback } from 'react';

import { EUndergroundTimeType } from 'shared/types/filters/advanced/undergroundTime';

import { RadioButtonGroupClick, UndergroundTimeFilterProps } from './types';

import styles from './styles.css';

export const UndergroundTimeFilter: React.FC<UndergroundTimeFilterProps> = props => {
  const { type, time, onUndergroundTimeChange, onUndergroundTimeTypeChange, options } = props;

  const handleRadioButtonGroupClick = useCallback<RadioButtonGroupClick>(
    (_, value) => {
      onUndergroundTimeTypeChange(value);
    },
    [onUndergroundTimeTypeChange],
  );

  return (
    <div className={styles['root']}>
      <RadioButtonGroup spliced options={options} value={type} onChange={handleRadioButtonGroupClick} />
      {type !== EUndergroundTimeType.none && (
        <div className={styles['underground-time']}>
          <div className={styles['input-with-label']}>
            <ArticleParagraph2>Не более</ArticleParagraph2>
            <div className={styles['input']}>
              <Input
                inline
                defaultValue={time}
                placeholder="10"
                rightAdornment={<InputAdornment>мин.</InputAdornment>}
                type="number"
                onChange={onUndergroundTimeChange}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

UndergroundTimeFilter.displayName = 'UndergroundTimeFilter';
