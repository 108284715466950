import { HttpBadStatusCodeError, NotFoundError } from '@cian/peperrors/shared';
import { QueryClient } from '@tanstack/react-query';

import { EObjectType } from 'shared/repositories/monolith-cian-realty/entities/Models/GeoLocationCatalogLink';
import { FetchGeocodeCachedLoadService } from 'shared/store/serverSide/monolith-cian-realty/api/geo/geocode-cached/load';
import { FetchGeocodedForSearchLoadService } from 'shared/store/serverSide/monolith-cian-realty/api/geo/geocoded-for-search/load';
import { IApplicationContext } from 'shared/types/applicationContext';

import { geoObjectDetailsToGeoValue } from './helpers';
import { GeoValue, GeocodeParams, GeocodeResult } from './types';

export async function geocode(
  dependencies: IApplicationContext,
  parameters: GeocodeParams,
  queryClient: QueryClient,
): Promise<GeocodeResult> {
  const { logger } = dependencies;

  const fetchGeocodeCachedLoadService = new FetchGeocodeCachedLoadService(queryClient);

  try {
    const result = await fetchGeocodeCachedLoadService.fetchQuery(dependencies, {
      request: parameters.query,
    });

    const geocodeCachedItem = result.items?.[0];

    if (!geocodeCachedItem?.coordinates) {
      throw new NotFoundError({
        domain: 'commercial-filters-frontend.geocode',
        message: 'не найден geocodeCached элемент',
      });
    }

    const fetchGeocodedForSearchLoadService = new FetchGeocodedForSearchLoadService(queryClient);

    const coordinates = geocodeCachedItem.coordinates;

    const geocodeForSearch = await fetchGeocodedForSearchLoadService.fetchQuery(dependencies, {
      address: geocodeCachedItem.text,
      kind: geocodeCachedItem.kind,
      lat: coordinates[1],
      lng: coordinates[0],
    });

    if (geocodeForSearch.isParsed) {
      const regionId: number | null = geocodeForSearch.regionId || null;
      let geoValue: GeoValue | null = null;

      if (geocodeForSearch.geoLocationCatalogLink && geocodeForSearch.geoLocationCatalogLink.id) {
        const { objectType, id } = geocodeForSearch.geoLocationCatalogLink;

        switch (objectType) {
          case EObjectType.NewObject:
            geoValue = {
              id,
              type: 'newobject',
            };

            break;
          default:
            throw new Error(`Unexpected geo location catalog objectType '${objectType}'`);
        }
      } else {
        if (
          geocodeForSearch.microDistricts &&
          geocodeForSearch.microDistricts.length > 0 &&
          geocodeForSearch.microDistricts[0].id
        ) {
          geoValue = {
            id: geocodeForSearch.microDistricts[0].id,
            type: 'district',
          };
        } else {
          if (geocodeForSearch.details?.length) {
            const geoObjectDetails = geocodeForSearch.details[geocodeForSearch.details.length - 1];

            const isNotRegionOrCity = geocodeForSearch.details.length > 1 && geoObjectDetails.geoType !== 'Location';
            const isCountryside = geocodeForSearch.details.length > 2;

            geoValue = geoObjectDetailsToGeoValue(geoObjectDetails.geoType, geoObjectDetails.id);

            if (isNotRegionOrCity) {
              const locationRegionId = geocodeForSearch.details.find(
                detailsItem => detailsItem.geoType === 'Location',
              )?.id;

              return { geoValue, regionId: locationRegionId || null };
            } else if (!isCountryside) {
              return { geoValue, regionId: geoObjectDetails.id || null };
            }
          }
        }
      }

      if (geoValue) {
        return {
          geoValue,
          regionId,
        };
      }
    }

    if (geocodeCachedItem.boundedBy) {
      const bounds = geocodeCachedItem.boundedBy.map(b => b.map(String) as [string, string]);

      const polygon = Array.of<[string, string]>(
        [bounds[0][0], bounds[0][1]],
        [bounds[0][0], bounds[1][1]],
        [bounds[1][0], bounds[1][1]],
        [bounds[1][0], bounds[0][1]],
        [bounds[0][0], bounds[0][1]],
      );

      if (geocodeCachedItem.name) {
        const geoValue: GeoValue = {
          coordinates: polygon,
          name: geocodeCachedItem.name,
          type: 'polygon',
        };

        return {
          geoValue,
          regionId: geocodeForSearch.regionId || null,
        };
      }
    }

    throw new NotFoundError({
      domain: 'commercial-filters-frontend.geocode',
      message: 'Ошибка при получении GeocodeCached',
    });
  } catch (error) {
    if (error instanceof HttpBadStatusCodeError) {
      logger.error(error, {
        degradation: 'Ошибка при получении GeocodeCached',
        description: 'monolith-cian-realty /api/geo/geocode-cached/ rejection error',
        domain: 'commercial-filters-frontend.geocode',
        parameters: JSON.stringify(parameters),
      });
    }

    if (error instanceof NotFoundError) {
      logger.error(error, {
        degradation: 'не найден geocodeCached элемент',
        description: 'monolith-cian-realty /api/geo/geocode-cached/',
        domain: 'commercial-filters-frontend.geocode',
        parameters: JSON.stringify(parameters),
      });
    }
    throw error;
  }
}
