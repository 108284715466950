import type { GeoValue } from 'shared/types/filters/geo';

import type { IJsonQueryGeo } from '../IJsonQueryElement';

export class JsonQueryGeo implements IJsonQueryGeo {
  public readonly type = 'geo';
  public value: GeoValue[];

  public constructor(value: GeoValue[]) {
    this.value = value;
  }
}
