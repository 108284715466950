import { EDealType } from 'shared/types/filters';
import { JsonQueryType } from 'shared/types/jsonQuery';

export function getJsonQueryTypeFromDealType(dealType: EDealType): JsonQueryType {
  if (dealType === EDealType.Sale) {
    return 'commercialsale';
  }

  return 'commercialrent';
}
