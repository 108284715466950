import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IGetPossibleAppointmentsResponse } from 'shared/repositories/announcements/v1/get-possible-appointments';
import { fetchGetSpecialtiesService } from 'shared/store/serverSide/announcements/v1/get-possible-appointments/internal/fetchService';
import { useApplicationContext } from 'shared/utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';

export function useGetPossibleAppointmentsQuery<TData = IGetPossibleAppointmentsResponse>(
  options?: UseQueryOptions<IGetPossibleAppointmentsResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();

  return useQuery<IGetPossibleAppointmentsResponse, QueryError, TData, QueryKey>({
    queryFn: () => fetchGetSpecialtiesService(context),
    queryKey: getQueryKey(),
    ...options,
  });
}
