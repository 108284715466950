import { builidingClassTypeMap } from 'shared/components/filters/advanced/BuildingClassTypeFilter/constants';
import { contractTypeMap } from 'shared/components/filters/advanced/ContractTypeFilter/constants';
import { mapInputTypeLabelByValue } from 'shared/components/filters/advanced/InputTypeFilter/constants';
import { mapAccessSystemLabel } from 'shared/constants/filters/advanced/accessSystemLabel';
import { mapBuildingTypeLabel } from 'shared/constants/filters/advanced/buildingType';
import { commissionTypeLabel } from 'shared/constants/filters/advanced/commissionTypeLabel';
import { coworkingAccessLabelMap } from 'shared/constants/filters/advanced/coworkingAccess';
import { estateTypeMapLabel } from 'shared/constants/filters/advanced/estateType';
import { mapFloorTypeLabel } from 'shared/constants/filters/advanced/floorTypes';
import { infrastructureLabelMap } from 'shared/constants/filters/advanced/infractructure';
import { mapFurnitureLabel } from 'shared/constants/filters/advanced/mapFurnitureLabel';
import { placementTypeMapLabel } from 'shared/constants/filters/advanced/placementType';
import { amenityMapLabel } from 'shared/constants/filters/amenity';
import { mapBuildingCranageTypesTypeLabel } from 'shared/constants/filters/mapBuildingCranageTypesTypeLabel';
import { mapBuildingGatesTypeLabel } from 'shared/constants/filters/mapBuildingGatesTypeLabel';
import { mapBuildingHeatingTypeLabel } from 'shared/constants/filters/mapBuildingHeatingTypeLabel';
import { mapBuildingHouseType } from 'shared/constants/filters/mapBuildingHouseType';
import { mapBuildingLiftTypesTypeLabel } from 'shared/constants/filters/mapBuildingLiftTypesTypeLabel';
import { mapConditionTypeIndustry } from 'shared/constants/filters/mapConditionTypeIndustry';
import { mapParkingTypeLabel } from 'shared/constants/filters/mapParkingTypeLabel';
import { PUBLISH_PERIOD_OPTIONS } from 'shared/constants/filters/publishPeriods';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { EAreaMeasure } from 'shared/enums/EAreaMeasure';
import { FlattenType } from 'shared/types/FlattenType';
import { Filters } from 'shared/types/filters';
import { EAccessSystem } from 'shared/types/filters/advanced/accessSystem';
import { EAmenityType } from 'shared/types/filters/advanced/amenities';
import { EBuildingHeatingType } from 'shared/types/filters/advanced/buildingHeatingType';
import { EBuildingLiftType } from 'shared/types/filters/advanced/buildingLiftTypesType';
import { EBuildingType } from 'shared/types/filters/advanced/buildingType';
import { EBuidlingHouseLine } from 'shared/types/filters/advanced/buildlingHouseLine';
import { ECommissionType } from 'shared/types/filters/advanced/commissionType';
import { EConditionType } from 'shared/types/filters/advanced/conditionType';
import { ECoworkingAccess } from 'shared/types/filters/advanced/coworkingAccess';
import { EElectronicTrading } from 'shared/types/filters/advanced/electronicTrading';
import { EEstateType } from 'shared/types/filters/advanced/estateType';
import { EFloorTypes } from 'shared/types/filters/advanced/floorTypes';
import { EFurnitureType } from 'shared/types/filters/advanced/furnitureType';
import { EInfrastructureItem } from 'shared/types/filters/advanced/infrastructure';
import { EParkingType } from 'shared/types/filters/advanced/parkingType';
import { EPlacementType } from 'shared/types/filters/advanced/placementType';
import { EUndergroundTimeType, UndergroundTime } from 'shared/types/filters/advanced/undergroundTime';
import { EBuildingClassType } from 'shared/types/filters/buildingClassType';
import { EContractType } from 'shared/types/filters/contractType';
import { BsCenter, Coworking, GeoValue } from 'shared/types/filters/geo';
import { EInputType } from 'shared/types/filters/inputType';
import { Specialty } from 'shared/types/filters/specialtyTypes';
import { RangeValue } from 'shared/types/rangeValue';
import { filterTypeToFilterLabelMapper } from 'shared/utils/filterTypeToFilterLabelMapper';

export const UNKNOWN_TAG = 'Неизвестный тэг';

export function getTagNameByFilter<F extends keyof Filters>(
  filterName: F,
  filterValue: FlattenType<Filters[F]>,
): string {
  switch (filterName) {
    case EAdvancedFilterType.publishPeriod: {
      const { label = 'неизвестно' } = PUBLISH_PERIOD_OPTIONS.find(period => period.value === filterValue) || {};

      return `Опубликовано: ${label.toLowerCase()}`;
    }

    case EAdvancedFilterType.withPhoto:
      return `С фото`;

    case EAdvancedFilterType.undergroundTime: {
      const { type, time } = filterValue as UndergroundTime;
      const typeLabel = type === EUndergroundTimeType.foot ? 'пешком' : 'транспортом';

      return ['До метро', time && `${time} мин.`, String(typeLabel)].filter(Boolean).join(' ');
    }

    case EAdvancedFilterType.coworking:
    case EAdvancedFilterType.bsCenter: {
      const { title } = filterValue as Coworking | BsCenter;

      return title || 'Неизвестное гео';
    }

    case EAdvancedFilterType.geo: {
      const { title, type } = filterValue as GeoValue;
      const typeLabel = type === 'highway' ? 'шоссе' : '';

      if (title) {
        return `${title} ${typeLabel}`;
      }

      return 'Неизвестное гео';
    }

    case EAdvancedFilterType.contract:
      return contractTypeMap[filterValue as EContractType] || UNKNOWN_TAG;

    case EAdvancedFilterType.electronicTrading: {
      if (filterValue === EElectronicTrading.WithTrading) {
        return 'Только торги и залог. недвижимость';
      }

      if (filterValue === EElectronicTrading.WithoutTrading) {
        return 'Не показывать торги и залог. недвижимость';
      }

      return UNKNOWN_TAG;
    }

    case EAdvancedFilterType.buildingClassType:
      return builidingClassTypeMap[filterValue as EBuildingClassType]
        ? `Класс ${builidingClassTypeMap[filterValue as EBuildingClassType]}`
        : UNKNOWN_TAG;

    case EAdvancedFilterType.floor:
    case EAdvancedFilterType.floorn:
    case EAdvancedFilterType.totalArea:
    case EAdvancedFilterType.houseYear: {
      const { min, max } = filterValue as RangeValue;

      if (min == null && max == null) {
        return UNKNOWN_TAG;
      }

      const label = filterTypeToFilterLabelMapper[filterName as EAdvancedFilterType];
      /**
       * @todo подумать над тем, чтобы вынести определение единиц измерения в отдельный сервис
       * @link https://jira.cian.tech/browse/CD-132896
       */
      const units = filterName === EAdvancedFilterType.totalArea ? EAreaMeasure.SquareMeters : '';

      return [
        label,
        min != null && !Number.isNaN(min) && `от ${min}`,
        max != null && !Number.isNaN(max) && `до ${max}`,
        units,
      ]
        .filter(Boolean)
        .join(' ');
    }

    case EAdvancedFilterType.specialtyTypes: {
      const { title } = filterValue as Specialty;

      return title || UNKNOWN_TAG;
    }

    case EAdvancedFilterType.inputType:
      return `Вход ${mapInputTypeLabelByValue[
        filterValue as EInputType.separatedFromStreet | EInputType.separatedFromYard
      ].toLowerCase()}`;

    case EAdvancedFilterType.isSeparatedInputType:
      return 'Отдельный вход';

    case EAdvancedFilterType.buildingCranageTypesType:
      return mapBuildingCranageTypesTypeLabel[filterValue as EBuildingLiftType] || UNKNOWN_TAG;

    case EAdvancedFilterType.buildingGatesType:
      return mapBuildingGatesTypeLabel[filterValue as EBuildingLiftType] || UNKNOWN_TAG;

    case EAdvancedFilterType.buildingHeatingType:
      return filterValue === EBuildingHeatingType.Without
        ? 'Без отопления'
        : mapBuildingHeatingTypeLabel[filterValue as EBuildingHeatingType] || UNKNOWN_TAG;

    case EAdvancedFilterType.buildingLiftTypesType:
      return mapBuildingLiftTypesTypeLabel[filterValue as EBuildingLiftType] || UNKNOWN_TAG;

    case EAdvancedFilterType.conditionType:
      return mapConditionTypeIndustry[filterValue as EConditionType] || UNKNOWN_TAG;

    case EAdvancedFilterType.amenity:
      return amenityMapLabel[filterValue as EAmenityType] || UNKNOWN_TAG;

    case EAdvancedFilterType.coworkingAccess: {
      const coworkingAccessLabel = coworkingAccessLabelMap[filterValue as ECoworkingAccess];
      if (coworkingAccessLabel) {
        return `Доступ ${coworkingAccessLabel.toLowerCase()}`;
      }

      return UNKNOWN_TAG;
    }

    case EAdvancedFilterType.buildingHouseLine: {
      const houseTypeLabel = mapBuildingHouseType[filterValue as EBuidlingHouseLine];

      if (houseTypeLabel) {
        return `${houseTypeLabel} линия домов`;
      }

      return UNKNOWN_TAG;
    }

    case EAdvancedFilterType.floorTypes:
      return mapFloorTypeLabel[filterValue as EFloorTypes] || UNKNOWN_TAG;

    case EAdvancedFilterType.placementType:
      return placementTypeMapLabel[filterValue as EPlacementType] || UNKNOWN_TAG;

    case EAdvancedFilterType.furniture:
      return mapFurnitureLabel[filterValue as EFurnitureType] || UNKNOWN_TAG;

    case EAdvancedFilterType.parkingType: {
      const label = mapParkingTypeLabel[filterValue as EParkingType];

      if (!label) {
        return UNKNOWN_TAG;
      }

      return filterValue === EParkingType.Rooftop ? `Парковка ${label.toLowerCase()}` : `${label} парковка`;
    }

    case EAdvancedFilterType.infrastructure:
      return infrastructureLabelMap[filterValue as EInfrastructureItem] || UNKNOWN_TAG;

    case EAdvancedFilterType.idUser: {
      return filterValue ? `Агент №${filterValue}` : UNKNOWN_TAG;
    }

    case EAdvancedFilterType.containWords:
      return `Содержит: "${filterValue}"`;

    case EAdvancedFilterType.excludeWords:
      return `Не содержит: "${filterValue}"`;

    case EAdvancedFilterType.contact:
      return `Телефон ${filterValue}`;

    case EAdvancedFilterType.estateTypes:
      return estateTypeMapLabel[filterValue as EEstateType] || UNKNOWN_TAG;

    case EAdvancedFilterType.id:
      return `Объявление №${filterValue}`;

    case EAdvancedFilterType.accessSystem:
      return `Вход ${mapAccessSystemLabel[filterValue as EAccessSystem].toLowerCase()}`;

    case EAdvancedFilterType.commissionType:
      return (filterValue as ECommissionType) === ECommissionType.Exclusive
        ? `${commissionTypeLabel[ECommissionType.Exclusive]} комиссия`
        : commissionTypeLabel[ECommissionType.WithoutDeposit];

    case EAdvancedFilterType.withoutAgentCall:
      return 'Без «Агентам не звонить»';

    case EAdvancedFilterType.buildingType:
      return mapBuildingTypeLabel[filterValue as EBuildingType] || UNKNOWN_TAG;

    default:
      return UNKNOWN_TAG;
  }
}
