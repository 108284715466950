import { deunit } from './deunit';
import { getUnit } from './getUnit';
import { round } from './round';

const UNITS: string[] = ['тыс', 'млн', 'млрд'];

export function commarize(value: number, withoutUnitName?: boolean): string {
  if (value >= 1e3) {
    const unit = getUnit(value);
    const unitName = UNITS[unit - 1];

    const result = deunit(value, unit);

    return `${result.toString().replace(/\./g, ',')}${(!withoutUnitName && unitName && ` ${unitName}`) || ''}`;
  }

  return round(value, 1).toString().replace(/\./g, ',');
}
