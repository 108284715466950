import { useCallback } from 'react';

import { useSaveSearchJsonQuery } from 'shared/utils/hooks/useSaveSearchJsonQuery';

import { trackSubscribed } from '../../tracking';
import { TrackSubscription } from '../../types';

interface IUseTrackSubscriptionResult {
  trackSubscription: TrackSubscription;
}

export function useTrackSubscription(
  isSubscribed: boolean,
  pushPermission: NotificationPermission,
): IUseTrackSubscriptionResult {
  const jsonQuery = useSaveSearchJsonQuery();

  const trackSubscription = useCallback<TrackSubscription>(
    params => {
      trackSubscribed({
        jsonQuery,
        subscribeToPushAvailable: !isSubscribed && pushPermission !== 'denied',
        ...params,
      });
    },
    [jsonQuery, isSubscribed, pushPermission],
  );

  return {
    trackSubscription,
  };
}
