import { SchemaBlock } from 'shared/components/AvailableAdvancedFilters/types';
import { AdvancedFilter, IFilter } from 'shared/components/AvailableFilters';
import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { filterTypeToAdvancedFilterComponentMapper } from 'shared/utils/filterTypeToAdvancedFilterComponentMapper';

import { IBlock } from '../../../models/Block';
import { IAdvancedFiltersBlocksSchema } from '../IAdvancedFiltersBlocksSchema';

export class AdvancedFiltersBlocksSchema implements IAdvancedFiltersBlocksSchema {
  private readonly blocks: IBlock[];

  public constructor(...blocks: IBlock[]) {
    this.blocks = blocks;
  }

  public getSchema(availableFilters: EAdvancedFilterType[]): SchemaBlock[] {
    return this.blocks.reduce((acc, group) => {
      const fields = this.mapAvailableFiltersWithComponents(availableFilters, group.fields);

      if (fields.length) {
        acc.push([group.title, fields]);
      }

      return acc;
    }, Array.of<SchemaBlock>());
  }

  private mapAvailableFiltersWithComponents(
    availableFilters: EAdvancedFilterType[],
    fields: EAdvancedFilterType[],
  ): IFilter<EAdvancedFilterType>[] {
    return fields.reduce((acc, field) => {
      const component = filterTypeToAdvancedFilterComponentMapper[field]();

      if (availableFilters.includes(field) && component) {
        acc.push(new AdvancedFilter(field, component));
      }

      return acc;
    }, Array.of<IFilter<EAdvancedFilterType>>());
  }
}
