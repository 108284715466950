import { IUndergroundMapDataV1, IUndergroundMapDataV2 } from '@cian/underground-map-widget';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { fetchGetUndergroundMapDataV1 } from 'shared/store/serverSide/map/v1/get-underground-map-data/load';
import { fetchGetUndergroundMapDataV2 } from 'shared/store/serverSide/map/v2/get-underground-map-data/load';
import { useApplicationContext } from 'shared/utils/applicationContext';

type FetchGetUndergroundMapData = (locationId: number) => Promise<IUndergroundMapDataV1 | IUndergroundMapDataV2>;

export function useFetchGetUndergroundMapData(): FetchGetUndergroundMapData {
  const context = useApplicationContext();
  const queryClient = useQueryClient();

  return useCallback<FetchGetUndergroundMapData>(
    locationId => {
      const { config } = context;

      const newUndergroundMapEnabled = !!config.get<boolean>('newUndergroundMapEnabled');

      if (newUndergroundMapEnabled) {
        return fetchGetUndergroundMapDataV2(queryClient, context, locationId).catch(() => {
          return fetchGetUndergroundMapDataV1(queryClient, context, locationId);
        });
      }

      return fetchGetUndergroundMapDataV1(queryClient, context, locationId);
    },
    [context, queryClient],
  );
}
