import { EOfficeType, Filters } from 'shared/types/filters';

import { toggleFilterValue } from './toggleFilterValue';

export const changeOfficeType = (officeTypeValue: EOfficeType, officeTypes: Filters['officeType']): EOfficeType[] => {
  switch (true) {
    case officeTypeValue === EOfficeType.CommercialLand:
      return [officeTypeValue];

    case [EOfficeType.Coworking, EOfficeType.Workplace].includes(officeTypeValue):
      return toggleFilterValue(
        officeTypeValue,
        officeTypes.filter(type => [EOfficeType.Coworking, EOfficeType.Workplace].includes(type)),
      );

    default: {
      const officeTypeWithoutSeparatedValues = officeTypes.filter(
        type => ![EOfficeType.CommercialLand, EOfficeType.Coworking, EOfficeType.Workplace].includes(type),
      );

      return toggleFilterValue(officeTypeValue, officeTypeWithoutSeparatedValues);
    }
  }
};
