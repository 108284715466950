import {
  PRICE_TYPES_COMMERCIAL_LAND_RENT,
  PRICE_TYPES_COMMERCIAL_LAND_SALE,
  PRICE_TYPES_COWORKING,
  PRICE_TYPES_RENT,
  PRICE_TYPES_SALE,
} from 'shared/constants/filters/priceTypes';
import { Filters } from 'shared/types/filters';
import { PriceType } from 'shared/types/filters/priceType';
import { hasCoworkingOfficeType } from 'shared/utils/filters/hasCoworkingOfficeType';
import { hasWorkplaceOfficeType } from 'shared/utils/filters/hasWorkplaceOfficeType';
import { isCommercialLand } from 'shared/utils/filters/isCommercialLand';
import { isSale } from 'shared/utils/filters/isSale';

export function getAvailablePriceTypes(filters: Filters): PriceType[] {
  if (hasCoworkingOfficeType(filters) || hasWorkplaceOfficeType(filters)) {
    return PRICE_TYPES_COWORKING;
  }

  const isSaleDealType = isSale(filters);

  if (isCommercialLand(filters)) {
    return isSaleDealType ? PRICE_TYPES_COMMERCIAL_LAND_SALE : PRICE_TYPES_COMMERCIAL_LAND_RENT;
  }

  if (isSaleDealType) {
    return PRICE_TYPES_SALE;
  }

  return PRICE_TYPES_RENT;
}
