// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IApiGeoGeocodeCachedRequest,
  TApiGeoGeocodeCachedModel,
  IMappers,
  TApiGeoGeocodeCachedResponse,
  IApiGeoGeocodeCachedResponse,
} from './types';

export const defaultConfig: TApiGeoGeocodeCachedModel = {
  apiType: 'legacy',
  assertStatusCodes: [200],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'monolith-cian-realty',
  pathApi: '/api/geo/geocode-cached/',
} as TApiGeoGeocodeCachedModel;

export function createApiGeoGeocodeCachedModel(parameters: IApiGeoGeocodeCachedRequest): TApiGeoGeocodeCachedModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IApiGeoGeocodeCachedOptions<TResponse200> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
  parameters: IApiGeoGeocodeCachedRequest;
}

export async function fetchApiGeoGeocodeCached<TResponse200>({
  httpApi,
  config,
  mappers,
  parameters,
}: IApiGeoGeocodeCachedOptions<TResponse200>): Promise<TApiGeoGeocodeCachedResponse | TResponse200> {
  const { statusCode, response, headers } = await httpApi.fetch(createApiGeoGeocodeCachedModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IApiGeoGeocodeCachedResponse);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TApiGeoGeocodeCachedResponse;
}
