import { initDependencies, setupDependencies } from './app';
import { runApp } from './services/runApp';

/**
 * @todo удалить после обновления @cian/polyfills
 * @description так как в полифилы не был добавлен данный метод, временный костыль
 * @link https://jira.cian.tech/browse/CD-134402
 */
if (!Object.fromEntries) {
  Object.fromEntries = (input: [string, any][]): object => {
    return input.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  };
}

setupDependencies();

initDependencies();

runApp();

module.hot?.accept();
