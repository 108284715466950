import { IOption } from '../../Option';
import { IOptions } from '../IOptions';

export class Options<Value = number> implements IOptions<Value> {
  public label: string;
  public readonly list: Array<IOption<Value>>;
  private readonly allSelectedLabel: string;

  public constructor(list: Array<IOption<Value>>, label: string, allSelectedLabel: string = 'Все типы') {
    this.list = list;
    this.label = label;
    this.allSelectedLabel = allSelectedLabel;
  }

  public getLabel(values: Set<Value>): string {
    if (!this.list.length || !values.size) {
      return this.label;
    }

    const isEveryValuesIncluded = this.getIsEveryValuesIncluded(values);

    if (isEveryValuesIncluded) {
      return this.allSelectedLabel;
    }

    const labelsList = this.list.reduce((accumulator, item) => {
      if (item.checkIsActive(values)) {
        const itemLabel = item.shortLabel || item.label;

        accumulator.push(itemLabel);
      }

      return accumulator;
    }, Array.of<string>());

    if (labelsList.length) {
      return labelsList.join(', ');
    }

    return this.label;
  }

  public filter(conditionFn: (item: IOption<Value>) => boolean): IOptions<Value> {
    const list = this.list.filter(item => conditionFn(item));

    return new Options(list, this.label, this.allSelectedLabel);
  }

  public getIsEveryValuesIncluded(values: Set<Value>): boolean {
    return this.list.every(item => item.checkIsActive(values));
  }
}
