import { RenderNestedCheckboxProps } from '@cian/nested-checkbox-groups';
import { ReactNode, RefObject } from 'react';

import { OffersCountTooltip } from 'shared/components/OffersCountTooltip';

type RenderNestedCheckboxFactory = (
  lastChangedElement: [null | string, null | number],
  ref: RefObject<HTMLDivElement>,
) => (props: RenderNestedCheckboxProps) => ReactNode;

// eslint-disable-next-line react/display-name
export const renderNestedCheckboxFactory: RenderNestedCheckboxFactory = (lastChangedElement, ref) => props => {
  const { group, item, children } = props;

  const isLastChangedElement = group.value === lastChangedElement[0] && item.value === lastChangedElement[1];

  return (
    <OffersCountTooltip portal opened={isLastChangedElement} placement="right">
      <div ref={isLastChangedElement ? ref : undefined}>{children}</div>
    </OffersCountTooltip>
  );
};
