import { Checkbox, CheckboxButtonGroup } from '@cian/ui-kit';

import { ParkingTypeFilterProps } from './types';

import styles from './ParkingTypeFilter.css';

export const ParkingTypeFilter: React.FC<ParkingTypeFilterProps> = props => {
  const { options, values, onFilterChange, onCheckboxChange, checked } = props;

  return (
    <div>
      <Checkbox checked={checked} label={`Есть\u00A0парковка`} onChange={onCheckboxChange} />
      {checked && (
        <div className={styles['options-container']}>
          <CheckboxButtonGroup options={options} value={values} onChange={onFilterChange} />
        </div>
      )}
    </div>
  );
};
