/* eslint-disable @typescript-eslint/no-explicit-any */

/* TODO: отрефакторить компонент, скопирован с серпа */
import { Component } from 'react';

import { Button } from 'shared/components/GeoSuggest/DistrictsModal/button';

import styles from './index.css';

export interface IAreasProps {
  areas: any | undefined;
  selectedCity?: any | undefined;
  selectedRegion?: any | 'moscow_mo' | 'spb_lo' | undefined;
  onSelected(city: any): void;
  onRegionSelected(region: any | 'moscow_mo' | 'spb_lo'): void;
}

export interface IRegionGroup {
  letter: string;
  regions: any[];
}

const LETTER_TITLE_HEIGHT = 4;

export class Areas extends Component<IAreasProps> {
  private regionGroups: IRegionGroup[] = [];

  public render(): any {
    this.parseRegions();

    const result = this.altSortRegions([[], [], [], this.regionGroups]);

    return (
      <div className={styles['column-wrapper']}>
        {result.map((column, id) => {
          return (
            <div className={styles['column']} key={id}>
              {column.map((group, index) => {
                return (
                  <div key={index}>
                    <div className={styles['letter-title']}>{group.letter}</div>
                    {group.regions.map((region, key) => {
                      return this.getButton(region, key);
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  private parseRegions(): void {
    this.regionGroups = [];

    if (!this.props.areas) {
      return;
    }

    this.props.areas.forEach((region: any) => {
      if (region.id !== 1 && region.id !== 2) {
        this.addRegion(region);
      }
    });

    this.regionGroups.sort((a, b) => {
      const nameA = a.letter.toUpperCase();
      const nameB = b.letter.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  private addRegion(region: any): void {
    const letter: string = region.name[0];
    let isAdded = false;

    this.regionGroups.forEach((value: IRegionGroup) => {
      if (value.letter === letter) {
        value.regions.push(region);
        isAdded = true;
      }
    });

    if (!isAdded) {
      this.createGroup(letter, region);
    }
  }

  private createGroup(letter: string, region: any): void {
    this.regionGroups.push({
      letter,
      regions: [region],
    });
  }

  private altSortRegions(initialColumns: IRegionGroup[][]): IRegionGroup[][] {
    const columns: IRegionGroup[][] = initialColumns;

    let isMoved = false;

    isMoved = this.moveColumns(columns[2], columns[3]) ? true : isMoved;
    isMoved = this.moveColumns(columns[1], columns[2]) ? true : isMoved;
    isMoved = this.moveColumns(columns[0], columns[1]) ? true : isMoved;

    if (isMoved) {
      return this.altSortRegions(columns);
    }

    return columns;
  }

  private moveColumns(left: IRegionGroup[], right: IRegionGroup[]): any {
    const leftCount = this.getColumnLength(left);
    const rightCount = this.getColumnLength(right);

    if (leftCount <= rightCount && rightCount - leftCount > LETTER_TITLE_HEIGHT) {
      const group = right.shift();
      if (group) {
        left.push(group);
      }

      return true;
    }
  }

  private getColumnLength = (arr: IRegionGroup[]): any => {
    return arr.reduce((acc, value) => {
      return acc + value.regions.length;
    }, arr.length * LETTER_TITLE_HEIGHT);
  };

  private getButton(region: any, index: number): any {
    if (this.props.selectedCity && this.props.selectedCity === region.id) {
      return (
        <div className={styles['breadcrumb-wrapper']} key={index}>
          <div className={styles['arrow']} />
          <Button
            className={styles['end-button']}
            theme="primary"
            onClick={(): void => {
              if (this.props.selectedRegion) {
                this.props.onRegionSelected(region);
              }
              this.props.onSelected(region);
            }}
          >
            {region.displayName}
          </Button>
        </div>
      );
    }

    return (
      <div
        className={styles['column-item']}
        key={index}
        onClick={(): void => {
          this.props.onSelected(region);
        }}
      >
        {region.displayName}
      </div>
    );
  }
}
