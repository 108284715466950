import { ArticleParagraph2, IconChevronDownSmall16, UIText2 } from '@cian/ui-kit';
import clsx from 'clsx';
import { Ref, forwardRef } from 'react';

import { FilterButtonProps } from '../FilterButton/types';

import styles from './FilterSelectButton.css';

export const FilterSelectButton = forwardRef(function FilterSelectButton(
  props: FilterButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const { isActive, onClick, count, children } = props;

  return (
    <button className={clsx(styles['button'], { [styles['button--active']]: isActive })} ref={ref} onClick={onClick}>
      <div className={styles['label-wrapper']}>
        <UIText2 color={count ? 'black_100' : 'gray40_100'} display="inline" whiteSpace="nowrap">
          {children}
        </UIText2>
      </div>
      <div className={styles['info-block']}>
        {Boolean(count) && (
          <div className={styles['count']}>
            <ArticleParagraph2 color="gray60_100">{count}</ArticleParagraph2>
          </div>
        )}
        <div className={styles['arrow-wrapper']} data-testid="arrow-down">
          <IconChevronDownSmall16 color="primary_100" />
        </div>
      </div>
    </button>
  );
});
