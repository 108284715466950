/**
 * @todo удалить файл
 * @description данная функциональность была добавлена в задаче CD-135759, будет удалена в CD-136055
 * @link https://jira.cian.tech/browse/CD-136055
 */
let coworkingTotalAreaFilterEnabled: boolean | null = null;

export const getCoworkingTotalAreaFilterEnabled = (): boolean => !!coworkingTotalAreaFilterEnabled;

export const setCoworkingTotalAreaFilterEnabled = (value: boolean): void => {
  coworkingTotalAreaFilterEnabled = value;
};
