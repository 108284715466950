import * as config from '@cian/config/browser';
import * as httpApi from '@cian/http-api/browser';
import * as telemetry from '@cian/telemetry/browser';

import { UNIQUE_CONFIG_KEY } from 'shared/constants/config';

export function setupDependencies(): void {
  config.setup({ settingsKey: UNIQUE_CONFIG_KEY });
  telemetry.setup();
  httpApi.setup({});
}
