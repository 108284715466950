import { Checkbox } from '@cian/ui-kit';
import { useCallback } from 'react';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

export const WithoutAgentCall = memoWithDisplayName(() => {
  const value = useFilter(EAdvancedFilterType.withoutAgentCall);
  const { setTermFilterValue } = useFiltersActions();

  const handleOnChange = useCallback(() => {
    setTermFilterValue({ name: EAdvancedFilterType.withoutAgentCall, value: !value });
  }, [setTermFilterValue, value]);

  return <Checkbox checked={value} label="Без «Агентам не звонить»" onChange={handleOnChange} />;
}, 'WithoutAgentCall');

WithoutAgentCall.displayName = 'WithoutAgentCall';
