import { FC } from 'react';

export interface IUndergroundIconProps {
  width?: number | string;
  height?: number | string;
  color: string;
  regionId?: number;
}

/* Старый компонент кружочка метро, взятый из серпа. Требуется рефакторинг */

export const UndergroundIcon: FC<IUndergroundIconProps> = ({ width, height, color, regionId }) => {
  let icon: React.ReactNode;

  switch (regionId) {
    case -1: // Москва и область
    case 1: // Москва
    case 4593: // Московская область
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M11.154 4L8 9.53 4.845 4 1.1 13.466H0v1.428h5.657v-1.428H4.81l.824-2.36L8 15l2.365-3.893.824 2.36h-.85v1.427H16v-1.428h-1.1z"
          fill={color}
          fillRule="evenodd"
        />
      );
      break;
    case -2: // СПб и область
    case 2: // СПб
    case 4588: // Ленинградская область
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M8 11.667L10.5 3s5 1.556 5 6.89c0 3.777-1.818 5.11-1.818 5.11h-3.41s3.523-1.556 3.637-5.11c.112-3.557-2.616-5.334-2.616-5.334L8.227 14.778H8v-3.11zm0 0v3.11h-.227L4.705 4.557S1.977 6.333 2.09 9.89C2.205 13.443 5.727 15 5.727 15h-3.41S.5 13.667.5 9.89C.5 4.555 5.5 3 5.5 3L8 11.667z"
          fill={color}
          fillRule="evenodd"
        />
      );
      break;
    case 4743: // Екатеринбург
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M2 3l5.895 6.316h.21L14 3v5.474L8.105 14.58h-.21L2 8.473V3zm0 6.737l4.21 4.42V15H2V9.737zm12 0V15H9.79v-.842L14 9.738z"
          fill={color}
          fillRule="evenodd"
        />
      );
      break;
    case 4777: // Казань
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M5.3 4l2.2 3.463L4.5 15h-4L5.3 4zm5.4 0l4.8 11h-4l-3-7.537L10.7 4zM7.9 8.89h.2l1 2.647-1 .407h-.2l-1-.407 1-2.648z"
          fill={color}
          fillRule="evenodd"
        />
      );
      break;
    case 4897: // Новосибирск
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M8 8.808h-.114L4.92 2.54 1.5 15.076h2.737L5.605 9.97l2.28 5.57h.23l2.28-5.57 1.37 5.106H14.5L11.08 2.54 8.113 8.808H8z"
          fill={color}
          fillRule="evenodd"
        />
      );
      break;
    case 4966: // Самара
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M11.75 4l3.75 10.983s-4.583.203-4.583-4.27C10.917 9.897 8 16 8 16v-5.898L11.75 4zm-7.5 0L8 10.102V16s-2.917-6.102-2.917-5.288c0 4.474-4.583 4.27-4.583 4.27L4.25 4z"
          fill={color}
          fillRule="evenodd"
        />
      );
      break;
    case 4704: // Волгоград
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M8.236 6a3 3 0 1 0 0 4H8V6h.236zM8 16A7 7 0 1 1 8 2a7 7 0 0 1 0 14zm-2-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7-2H8v1h5V8zm-3 1v4h1V9h-1z"
          fill={color}
          fillRule="evenodd"
        />
      );
      break;
    case 4885: // Нижний Новгород
      // tslint:disable-next-line:max-line-length
      icon = (
        <path
          d="M10.8 15.003H15l-1.997-3.995c.82-1.026 1.314-2.31 1.314-3.705 0-3.362-2.83-6.083-6.317-6.083S1.683 3.94 1.683 7.303c0 1.396.494 2.68 1.315 3.705L1 15.003h4.2l1.212-1.818c.13.032.258.065.39.09L8 15.47l1.197-2.195c.132-.024.262-.057.39-.09l1.213 1.818zm-8.617-7.7C2.183 4.223 4.785 1.72 8 1.72s5.817 2.502 5.817 5.583c0 1.196-.395 2.3-1.062 3.21L10.8 6.603l-2.8 3.5-2.8-3.5-1.955 3.91c-.667-.91-1.062-2.014-1.062-3.21z"
          fill={color}
        />
      );
      break;
    default:
      icon = <circle cx="8" cy="8" fill={color} r="8" />;
      break;
  }

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg height={height} viewBox="0 0 16 16" width={width} xmlns="http://www.w3.org/2000/svg">
      {icon}
    </svg>
  );
};
