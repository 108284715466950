import { Option } from 'shared/components/Select/models/Option';
import { EInputType } from 'shared/types/filters/inputType';

export const mapInputTypeLabelByValue: Record<EInputType.separatedFromStreet | EInputType.separatedFromYard, string> = {
  [EInputType.separatedFromStreet]: 'С улицы',
  [EInputType.separatedFromYard]: 'Со двора',
};

export const INPUT_TYPE_OPTIONS = [
  new Option(EInputType.separatedFromStreet, mapInputTypeLabelByValue[EInputType.separatedFromStreet]),
  new Option(EInputType.separatedFromYard, mapInputTypeLabelByValue[EInputType.separatedFromYard]),
];
