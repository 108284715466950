import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

export class AnyAnyAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return true;
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.floor,
        EAdvancedFilterType.floorTypes,
        EAdvancedFilterType.floorn,
        EAdvancedFilterType.buildingClassType,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.inputType,
        EAdvancedFilterType.isSeparatedInputType,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.infrastructure,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
