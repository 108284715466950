import { createSelector } from 'reselect';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { FormattedTag } from 'shared/models/FormattedTag';
import { filtersSelector } from 'shared/store/data/filters/selectors/root/filtersSelector';
import { FlattenType } from 'shared/types/FlattenType';
import { Filters } from 'shared/types/filters';
import { getAvailableAdvancedFilters } from 'shared/utils/filters/getAvailableAdvancedFilters';
import { getTagNameByFilter } from 'shared/utils/filters/getTagNameByFilter';

const getTag = <F extends keyof Filters>(
  filterName: F,
  filterValue: FlattenType<Filters[F]>,
  customName?: string,
): FormattedTag =>
  new FormattedTag(
    `tag_${filterName}_${filterValue}`,
    customName || getTagNameByFilter(filterName, filterValue),
    filterName,
    filterValue,
  );

const IGNORE_FILTER_LIST: EAdvancedFilterType[] = [];

export const tagsListSelector = createSelector(filtersSelector, filters => {
  const advancedFilters = getAvailableAdvancedFilters(filters);

  return advancedFilters.reduce((acc, filterName) => {
    const filterValue = filters[filterName];

    if (!filterValue || IGNORE_FILTER_LIST.includes(filterName)) {
      return acc;
    }

    const tags = ((): FormattedTag[] => {
      if (Array.isArray(filterValue)) {
        return filterValue.map(value => getTag(filterName, value));
      }

      return [getTag(filterName, filterValue)];
    })();

    acc.push(...tags);

    return acc;
  }, Array.of<FormattedTag>());
});
