import { useSelector } from 'react-redux';

import { geoSelector } from 'shared/store/data/filters/selectors/dump/geoSelector';
import { District } from 'shared/types/filters/geo';

export function useSelectedDistrictsIds(): number[] | null {
  const geo = useSelector(geoSelector);

  const districts: District[] | undefined = geo?.filter(geoItem => geoItem.type === 'district') as
    | District[]
    | undefined;

  return districts?.map(geoItem => geoItem.id) ?? null;
}
