import { IFastFiltersUserResponseSchema83 } from 'shared/repositories/monolith-python/entities/schemas/FastFiltersUserResponseSchema83';
import { IFastFiltersUserSchema } from 'shared/repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { extractUndefined } from 'shared/utils/extractUndefined';

export function dataFromCianApiSiteV1GetUserDataByRequestSelector(
  state: IFastFiltersUserResponseSchema83,
): IFastFiltersUserResponseSchema83['data'] {
  return state.data;
}

export function userCianApiSiteV1GetUserDataByRequestSelector(
  state: IFastFiltersUserResponseSchema83,
): IFastFiltersUserSchema | null {
  return extractUndefined(dataFromCianApiSiteV1GetUserDataByRequestSelector(state).user);
}

export function userIsAuthenticatedSelector(state: IFastFiltersUserResponseSchema83): boolean {
  return !!userCianApiSiteV1GetUserDataByRequestSelector(state)?.isAuthenticated;
}
