import { createReducer } from 'typesafe-actions';

import { UNIQUE_CONFIG_KEY } from 'shared/constants/config';

import { patchRS, putRS } from './actions';
import { RSState } from './types';

const initialState: RSState = {
  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-163541, будет удалён в задаче CD-163575
   */
  isFiltersSaveSearchEnabled: false,

  projectName: UNIQUE_CONFIG_KEY,
  requestTimeout: 1,
};

export const rsReducer = createReducer(initialState)
  .handleAction(putRS, (_state, action) => action.payload)
  .handleAction(patchRS, (state, action) => ({ ...state, ...action.payload }));
