import {
  IGetMetaRequest,
  IGetMetaResponse,
  IGetMetaResponse200,
  IGetMetaResponse400,
  createGetMetaModel,
  fetchGetMeta,
} from 'shared/repositories/search-offers-index/v2/get-meta';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export async function fetchPublicDesktopV1FiltersGetCountService(
  dependencies: IApplicationContext,
  parameters: IGetMetaRequest,
): Promise<IGetMetaResponse> {
  const { httpApi, logger } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createGetMetaModel(parameters),
    'src/shared/store/serverSide/search-offers-index/v2/get-count/internal/fetchService.ts',
    logger,
  );

  return fetchGetMeta<IGetMetaResponse200, IGetMetaResponse400>({
    httpApi,
    parameters,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        case 400:
          throw fetchAssertsService.assert400StatusCodeError(result);
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
