import { rootReducer } from 'shared/store/data/rootReducer';
import { ReduxStore, RootInitialState } from 'shared/types/RootState';

import { getReduxStore } from './internal/utils/getReduxStore';

let storeReminder: ReduxStore;

export function createReduxStore(initialState: RootInitialState | undefined, debugEnabled: boolean): ReduxStore {
  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = getReduxStore(initialState, debugEnabled);
    }

    storeReminder.replaceReducer(rootReducer);

    return storeReminder;
  }

  return getReduxStore(initialState, debugEnabled);
}
