export enum EPriceType {
  // тут так же цена за рабочее место в коворкингах, чтобы не разделять на 3 типа
  SM = 1,
  Total = 2,
}

export type PriceType = {
  label: string;
  value: EPriceType;
};
