import { useToggleState } from '@cian/react-utils';
import { FC, useCallback } from 'react';

import { RANGE_FILTERS } from 'shared/constants/filters/rangeFilters';
import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { useValidateRangeFiltersActions } from 'shared/utils/hooks/useValidateRangeFiltersActions';

import { PopupFilter } from '../PopupFilter';

import { IPopupFilterControlledProps } from './types';

export const PopupFilterControlled: FC<IPopupFilterControlledProps> = props => {
  const { filterType, closeAfterClick } = props;

  const { state: isPopupOpened, toggle: togglePopupOpened } = useToggleState();

  const { openedAtCurrentTarget } = useOffersCountTooltip(filterType);
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const { validateRangeFilterAction } = useValidateRangeFiltersActions();

  const handlePopupToggle = useCallback<VoidFunction>(() => {
    const isRangeFilter = !!filterType && RANGE_FILTERS.includes(filterType);

    if (!openedAtCurrentTarget) {
      setTargetOffersCountTooltip(null);
    }

    if (isRangeFilter && isPopupOpened) {
      validateRangeFilterAction(filterType);
    }

    togglePopupOpened();
  }, [
    openedAtCurrentTarget,
    isPopupOpened,
    filterType,
    togglePopupOpened,
    validateRangeFilterAction,
    setTargetOffersCountTooltip,
  ]);

  const handleClick = useCallback<VoidFunction>(() => {
    if (closeAfterClick) {
      togglePopupOpened();
    }
  }, [closeAfterClick, togglePopupOpened]);

  return (
    <PopupFilter
      isTooltipOpened={!isPopupOpened && openedAtCurrentTarget}
      opened={isPopupOpened}
      onContentClick={handleClick}
      onPopupToggle={handlePopupToggle}
      {...props}
    />
  );
};

PopupFilterControlled.displayName = 'PopupFilterControlled';
