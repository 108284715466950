import {
  IGetPossibleAppointmentsResponse,
  IGetPossibleAppointmentsResponse200,
  createGetPossibleAppointmentsModel,
  fetchGetPossibleAppointments,
} from 'shared/repositories/announcements/v1/get-possible-appointments';
import { FetchAssertsService } from 'shared/services/FetchAssertsService';
import { IApplicationContext } from 'shared/types/applicationContext';

export async function fetchGetSpecialtiesService(
  dependencies: IApplicationContext,
): Promise<IGetPossibleAppointmentsResponse> {
  const { httpApi, logger } = dependencies;

  const fetchAssertsService = new FetchAssertsService(
    createGetPossibleAppointmentsModel(),
    'src/shared/store/serverSide/announcements/v1/get-possible-appointments/internal/fetchService.ts',
    logger,
  );

  return fetchGetPossibleAppointments<IGetPossibleAppointmentsResponse200>({
    httpApi,
  }).then(
    result => result.response,
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
