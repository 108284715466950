import { ModalWindow } from '@cian/ui-kit';
import { FC } from 'react';

import { AdvancedFiltersModalFooterContainer } from './components/AdvancedFiltersModalFooter';
import { AdvancedFiltersModalHeader } from './components/AdvancedFiltersModalHeader';
import { AdvancedFiltersModalProps } from './types';

import styles from './AdvancedFiltersModal.css';

export const AdvancedFiltersModal: FC<AdvancedFiltersModalProps> = props => {
  const { children, open, onClose, onShowClick } = props;

  return (
    <ModalWindow
      fixed
      footer={<AdvancedFiltersModalFooterContainer onShowClick={onShowClick} />}
      header={<AdvancedFiltersModalHeader />}
      open={open}
      size="M"
      width={952}
      onClose={onClose}
    >
      <div className={styles['container']}>
        <div className={styles['wrapper']}>{children}</div>
      </div>
    </ModalWindow>
  );
};

AdvancedFiltersModal.displayName = 'AdvancedFiltersModal';
