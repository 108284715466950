import { EGeoType } from 'shared/repositories/monolith-cian-realty/entities/Models/GeoCodedSearchDetails';

import { GeoValue } from './types';

const geoTypesMap: { [key: string]: GeoValue['type'] } = {
  District: 'district',
  House: 'house',
  Location: 'location',
  Road: 'highway',
  Street: 'street',
  Underground: 'underground',
};

export function geoObjectDetailsToGeoValue(geoType: EGeoType, id: number): GeoValue {
  return {
    id,
    type: (geoTypesMap as any)[geoType],
  };
}
