import { FC } from 'react';

import { EButtonTarget } from 'shared/types/offersCountTooltip/offersCountTooltipTarget';
import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';

import { OffersCountTooltip } from '../OffersCountTooltip';

import { FindButtonContainer } from './FindButtonContainer';
import { EButtonType } from './types';

export const FindButtonContainerTooltipWrapper: FC = () => {
  const { openedAtCurrentTarget } = useOffersCountTooltip(EButtonTarget.findButton);

  return (
    <OffersCountTooltip opened={openedAtCurrentTarget} portal={true}>
      <div>
        <FindButtonContainer buttonType={EButtonType.Main} label="Найти" />
      </div>
    </OffersCountTooltip>
  );
};

FindButtonContainerTooltipWrapper.displayName = 'FindButtonContainerTooltipWrapper';
