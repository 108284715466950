import { EInfrastructureItem } from 'shared/types/filters/advanced/infrastructure';

export const infrastructureLabelMap: Record<EInfrastructureItem, string> = {
  [EInfrastructureItem.CarWash]: 'Автомойка',
  [EInfrastructureItem.CarService]: 'Автосервис',
  [EInfrastructureItem.Pharmacy]: 'Аптека',
  [EInfrastructureItem.ClothesStudio]: 'Ателье одежды',
  [EInfrastructureItem.ATM]: 'Банкомат',
  [EInfrastructureItem.Pool]: 'Бассейн',
  [EInfrastructureItem.Buffet]: 'Буфет',
  [EInfrastructureItem.WarehouseComplex]: 'Выставочно-складской комплекс',
  [EInfrastructureItem.Hotel]: 'Гостиница',
  [EInfrastructureItem.Cafe]: 'Кафе',
  [EInfrastructureItem.Cinema]: 'Кинотеатр',
  [EInfrastructureItem.ConferenceRoom]: 'Конференц-зал',
  [EInfrastructureItem.MedicalCenter]: 'Медицинский центр',
  [EInfrastructureItem.Minimarket]: 'Минимаркет',
  [EInfrastructureItem.NotaryOffice]: 'Нотариальная контора',
  [EInfrastructureItem.Department]: 'Отделение банка',
  [EInfrastructureItem.Park]: 'Парк',
  [EInfrastructureItem.Restaurant]: 'Ресторан',
  [EInfrastructureItem.BeautyShop]: 'Салон красоты',
  [EInfrastructureItem.Warehouse]: 'Складские помещения',
  [EInfrastructureItem.Canteen]: 'Столовая',
  [EInfrastructureItem.Supermarket]: 'Супермаркет',
  [EInfrastructureItem.ShoppingArea]: 'Торговая зона',
  [EInfrastructureItem.FitnessCentre]: 'Фитнес-центр',
  [EInfrastructureItem.Studio]: 'Фотосалон',
  [EInfrastructureItem.CentralReception]: 'Центральная рецепция',
};

export const infrastructureItemToJsonQueryFieldMap: Record<EInfrastructureItem, string> = {
  [EInfrastructureItem.CarWash]: 'infrastructure_has_car_wash',
  [EInfrastructureItem.CarService]: 'infrastructure_has_car_service',
  [EInfrastructureItem.Pharmacy]: 'infrastructure_has_pharmacy',
  [EInfrastructureItem.ClothesStudio]: 'infrastructure_has_clothes_studio',
  [EInfrastructureItem.ATM]: 'infrastructure_has_atm',
  [EInfrastructureItem.Pool]: 'infrastructure_has_pool',
  [EInfrastructureItem.Buffet]: 'infrastructure_has_buffet',
  [EInfrastructureItem.WarehouseComplex]: 'infrastructure_has_exhibition_and_warehouse_complex',
  [EInfrastructureItem.Hotel]: 'infrastructure_has_hotel',
  [EInfrastructureItem.Cafe]: 'infrastructure_has_cafe',
  [EInfrastructureItem.Cinema]: 'infrastructure_has_cinema',
  [EInfrastructureItem.ConferenceRoom]: 'infrastructure_has_conference_room',
  [EInfrastructureItem.MedicalCenter]: 'infrastructure_has_medical_center',
  [EInfrastructureItem.Minimarket]: 'infrastructure_has_minimarket',
  [EInfrastructureItem.NotaryOffice]: 'infrastructure_has_notary_office',
  [EInfrastructureItem.Department]: 'infrastructure_has_bank_department',
  [EInfrastructureItem.Park]: 'infrastructure_has_park',
  [EInfrastructureItem.Restaurant]: 'infrastructure_has_restaurant',
  [EInfrastructureItem.BeautyShop]: 'infrastructure_has_beauty_shop',
  [EInfrastructureItem.Warehouse]: 'infrastructure_has_warehouse',
  [EInfrastructureItem.Canteen]: 'infrastructure_has_canteen',
  [EInfrastructureItem.Supermarket]: 'infrastructure_has_supermarket',
  [EInfrastructureItem.ShoppingArea]: 'infrastructure_has_shopping_area',
  [EInfrastructureItem.FitnessCentre]: 'infrastructure_has_fitness_centre',
  [EInfrastructureItem.Studio]: 'infrastructure_has_studio',
  [EInfrastructureItem.CentralReception]: 'infrastructure_has_central_reception',
};

export const queryStringFieldToInfrastructureItemMap: Record<string, EInfrastructureItem> = {
  ['hasAtm']: EInfrastructureItem.ATM,
  ['hasBankDepartment']: EInfrastructureItem.Department,
  ['hasBeautyShop']: EInfrastructureItem.BeautyShop,
  ['hasBuffet']: EInfrastructureItem.Buffet,
  ['hasCafe']: EInfrastructureItem.Cafe,
  ['hasCanteen']: EInfrastructureItem.Canteen,
  ['hasCarService']: EInfrastructureItem.CarService,
  ['hasCarWash']: EInfrastructureItem.CarWash,
  ['hasCentralReception']: EInfrastructureItem.CentralReception,
  ['hasCinema']: EInfrastructureItem.Cinema,
  ['hasClothesStudio']: EInfrastructureItem.ClothesStudio,
  ['hasConferenceRoom']: EInfrastructureItem.ConferenceRoom,
  ['hasExhibitionAndWarehouseComplex']: EInfrastructureItem.WarehouseComplex,
  ['hasFitnessCentre']: EInfrastructureItem.FitnessCentre,
  ['hasHotel']: EInfrastructureItem.Hotel,
  ['hasMedicalCenter']: EInfrastructureItem.MedicalCenter,
  ['hasMinimarket']: EInfrastructureItem.Minimarket,
  ['hasNotaryOffice']: EInfrastructureItem.NotaryOffice,
  ['hasPark']: EInfrastructureItem.Park,
  ['hasPharmacy']: EInfrastructureItem.Pharmacy,
  ['hasPool']: EInfrastructureItem.Pool,
  ['hasRestaurant']: EInfrastructureItem.Restaurant,
  ['hasShoppingArea']: EInfrastructureItem.ShoppingArea,
  ['hasStudio']: EInfrastructureItem.Studio,
  ['hasSupermarket']: EInfrastructureItem.Supermarket,
  ['hasWarehouse']: EInfrastructureItem.Warehouse,
};
