import { useToggleState } from '@cian/react-utils';
import { IconChevronDownSmall16, UIText2 } from '@cian/ui-kit';
import cn from 'clsx';

import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { MultipleCheckboxFilter } from '../MultipleCheckboxFilter';

import styles from './InfrastructureFilter.css';

export const InfrastructureFilter = memoWithDisplayName(() => {
  const { state: isFilterExpanded, toggle: toggleIsFiltersExpanded } = useToggleState();

  const expandText = isFilterExpanded ? 'Свернуть' : 'Вся инфраструктура';

  return (
    <div className={styles['container']}>
      <div className={cn(styles['options-container'], { [styles['open']]: isFilterExpanded })}>
        <MultipleCheckboxFilter filterName={EAdvancedFilterType.infrastructure} />
      </div>
      <div className={styles['expand-button']} onClick={toggleIsFiltersExpanded}>
        <UIText2 color="primary_100">{expandText}</UIText2>
        <div className={cn(styles['arrow'], { [styles['rotated']]: isFilterExpanded })}>
          <IconChevronDownSmall16 color="primary_100" />
        </div>
      </div>
    </div>
  );
}, 'InfrastructureFilter');
