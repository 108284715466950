import { QueryClient } from '@tanstack/react-query';

import { IJsonQuery } from 'shared/models/JsonQuery';
import { Filters } from 'shared/types/filters';

import { JsonQueryStrategy } from './JsonQueryStrategy';
import { QueryStringStrategy } from './QueryStringStrategy';
import { EStrategyType, IStrategy } from './types';

export class MetaStrategyContext {
  private strategy: IStrategy;
  private readonly queryClient: QueryClient;

  public constructor(strategyType: EStrategyType, queryClient: QueryClient) {
    this.queryClient = queryClient;

    switch (strategyType) {
      case EStrategyType.toQueryString: {
        this.strategy = new QueryStringStrategy();
        break;
      }
      case EStrategyType.toJsonQuery: {
        this.strategy = new JsonQueryStrategy(this.queryClient);
        break;
      }
    }
  }

  public formatFilters(filters: Filters): IJsonQuery | string {
    return this.strategy.formatFilters(filters);
  }
}
