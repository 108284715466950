import { useForkRef } from '@cian/react-utils';
import { CustomTooltip } from '@cian/ui-kit';
import { numberToPrettyString, plural } from '@cian/utils';
import { FC, cloneElement, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useGetCountQuery } from 'shared/store/serverSide/search-offers-index/v2/get-count/query';

import { FindButtonContainer } from '../FindButton/FindButtonContainer';
import { EButtonType } from '../FindButton/types';

import { OffersCountTooltipProps } from './types';

import styles from './OffersCountTooltip.css';

export const OffersCountTooltip: FC<OffersCountTooltipProps> = props => {
  const { placement, children, opened, hostElement, portal = false } = props;
  const { data, isRefetching } = useGetCountQuery();

  const childrenRef = useRef<HTMLElement>(null);
  const handleChildrenRef = useForkRef((children as any).ref, childrenRef);

  const dataOrZero = data ?? 0;
  const prettyData = numberToPrettyString(dataOrZero);
  const text = `Найдено ${prettyData} ${plural(dataOrZero, ['объявление', 'объявления', 'объявлений'])}`;

  const tooltip = (
    <CustomTooltip
      arrow
      flip
      preventOverflow
      anchorRef={childrenRef}
      content={
        <div>
          <div className={styles['text']}>{text}</div>
          <FindButtonContainer
            buttonType={EButtonType.Popup}
            label={'Показать'}
            loading={isRefetching}
            theme="fill_white_secondary"
          />
        </div>
      }
      maxWidth={320}
      minHeight={56}
      open={opened}
      placement={placement}
      portal={portal}
      theme="black"
    />
  );

  return (
    <>
      {cloneElement(children, { ...children.props, ref: handleChildrenRef })}
      {hostElement ? createPortal(tooltip, hostElement) : tooltip}
    </>
  );
};

OffersCountTooltip.displayName = 'OffersCountTooltip';
