import { EOfficeType } from 'shared/types/filters';

export const TRADE_AREA_GROUP = [
  EOfficeType.TradeArea,
  EOfficeType.FreeAppointmentObject,
  EOfficeType.PublicCatering,
  EOfficeType.AutoService,
  EOfficeType.DomesticServices,
  EOfficeType.Building,
  EOfficeType.RentalBusiness,
  EOfficeType.ReadyBusiness,
];

export const WAREHOUSE_GROUP = [EOfficeType.Warehouse, EOfficeType.Manufacture];
