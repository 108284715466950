import { CheckboxButtonGroup } from '@cian/ui-kit';
import { useCallback } from 'react';

import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { useOptions } from './internal/hooks/useOptions';
import { CommonTermsFilterProps } from './types';

export const CommonTermsFilter = memoWithDisplayName<CommonTermsFilterProps>(props => {
  const { filterName } = props;

  const filterValue = useFilter(filterName);
  const options = useOptions(filterName);

  const { toggleTermsFilterValue } = useFiltersActions();

  const onFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-expect-error, у функции isNaN кривая типизация
      const value = isNaN(event.target.value) ? event.target.value : Number(event.target.value);

      toggleTermsFilterValue({ name: filterName, value });
    },
    [filterName, toggleTermsFilterValue],
  );

  return <CheckboxButtonGroup options={options} value={filterValue} onChange={onFilterChange} />;
}, 'CommonTermsFilter');
