import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorsMapObject, bindActionCreators } from 'redux';

export function useBoundActions<M extends ActionCreatorsMapObject, N extends ActionCreatorsMapObject>(
  actionCreators: M,
): N;

export function useBoundActions<A, M extends ActionCreatorsMapObject<A>>(actionCreators: M): M;

export function useBoundActions<A, M extends ActionCreatorsMapObject<A>>(actionCreators: M): M {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionCreators, dispatch), [actionCreators, dispatch]);
}
