import { Filters } from 'shared/types/filters';

export function getJsonQueryBsCenter(filters: Filters): number[] | undefined {
  const { bsCenter } = filters;

  if (!bsCenter) {
    return undefined;
  }

  return bsCenter.map(bsCenterItem => bsCenterItem.id);
}
