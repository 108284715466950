/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */

/* Лейаут старой модалки. Взят с серпа, необходим редизайн */
import { Component } from 'react';

import { IApiGeoGetDistrictsTreeResponse } from 'shared/repositories/monolith-cian-realty/api/geo/get-districts-tree';

import { Button } from '../button';

import styles from './index.css';

export interface IAreasProps {
  areas: IApiGeoGetDistrictsTreeResponse | undefined;
  selectedDistricts: number[];
  selectedLocation: any;
  onSelected(id: number, name: string): void;
  onRemoved(id: number): void;
}

export interface IRegionGroup {
  direction?: string | null;
  districts?: IApiGeoGetDistrictsTreeResponse;
  letter?: string;
  id: number;
}

const LETTER_TITLE_HEIGHT = 4;
const DistrictDirectionName: { [key: string]: string } = {
  ['ВАО']: 'восток',
  ['ЗАО']: 'запад',
  ['САО']: 'север',
  ['СВАО']: 'северо-восток',
  ['СЗАО']: 'северо-запад',
  ['ЦАО']: 'центр',
  ['ЮАО']: 'юг',
  ['ЮВАО']: 'юго-восток',
  ['ЮЗАО']: 'юго-запад',
};

export function isMoscowLocation(selectedLocation: any): boolean {
  if (!selectedLocation) {
    return false;
  }

  return selectedLocation === 1;
}

export function isStartFromNumber(value: string, divider: string): boolean {
  const firstChunk = value.split(divider)[0];

  return !isNaN(parseInt(firstChunk, 10));
}

export class DistrictsLayout extends Component<IAreasProps> {
  private districtGroups: IRegionGroup[] = [];

  public render(): JSX.Element {
    const showFlat = this.parseDistricts();
    const isMoscow = isMoscowLocation(this.props.selectedLocation);

    let result;
    if (isMoscow) {
      result = this.sortMoscowRegions(this.districtGroups);
    } else {
      result = this.sortRegions([[], [], [], this.districtGroups]);
    }

    return (
      <div className={isMoscow ? styles['column-wrapper-moskow'] : styles['column-wrapper']}>
        {result.map((column, id) => {
          return this.getColumn(column, id, showFlat);
        })}
      </div>
    );
  }

  private getColumn(column: IRegionGroup[], id: number, showFlat: boolean): any {
    return (
      <div className={styles['column']} key={id}>
        {column.map(group => {
          return this.getDistrict(group, showFlat);
        })}
      </div>
    );
  }

  private getDistrict(group: IRegionGroup, showFlat: boolean): any {
    if (!group.districts) {
      return;
    }

    return (
      <div key={group.id}>
        {this.getDistrictName(group, showFlat)}
        {group.districts.map(region => {
          return region.childs && this.getChilds(region.childs, region.id ?? 1);
        })}
      </div>
    );
  }

  private parseDistricts(): any {
    this.districtGroups = [];

    if (!this.props.areas) {
      return false;
    }

    let isFlatStructure = true;
    this.props.areas.forEach(district => {
      if (district.childs && district.childs.length > 0) {
        isFlatStructure = false;
      }

      this.addDistrict(district);
    });

    this.districtGroups.sort((a, b) => {
      const letterA = a.letter;
      const letterB = b.letter;

      const valueForSortA = isStartFromNumber(letterA ?? '', '-')
        ? parseInt(letterA ?? '', 10)
        : (letterA ?? '').toUpperCase();
      const valueForSortB = isStartFromNumber(letterB ?? '', '-')
        ? parseInt(letterB ?? '', 10)
        : (letterB ?? '').toUpperCase();
      if (valueForSortA < valueForSortB) {
        return -1;
      }
      if (valueForSortA > valueForSortB) {
        return 1;
      }

      return 0;
    });

    return isFlatStructure;
  }

  private addDistrict(district: IApiGeoGetDistrictsTreeResponse[number]): any {
    const letter: string | null = district.name as string | null;
    let isAdded = false;

    this.districtGroups.forEach((value: IRegionGroup) => {
      if (!value.districts) {
        return;
      }
      if (value.letter === letter) {
        value.districts.push(district);
        isAdded = true;
      }
    });

    if (!isAdded) {
      this.createGroup(letter ?? '', district);
    }
  }

  private createGroup(letter: string, district: IApiGeoGetDistrictsTreeResponse[number]): any {
    this.districtGroups.push({
      direction: district?.direction?.name,
      districts: [district],
      id: district.id ?? 1,
      letter,
    });
  }

  private sortRegions(initialColumns: IRegionGroup[][]): IRegionGroup[][] {
    const columns: IRegionGroup[][] = initialColumns;

    let isMoved = false;

    if (columns[2].length === 0 || columns[3].length > 1) {
      isMoved = this.moveColumns(columns[2], columns[3]) ? true : isMoved;
    }

    if (columns[1].length === 0 || columns[3].length > 0) {
      isMoved = this.moveColumns(columns[1], columns[2]) ? true : isMoved;
    }

    isMoved = this.moveColumns(columns[0], columns[1]) ? true : isMoved;

    if (isMoved) {
      return this.sortRegions(columns);
    }

    return columns;
  }

  private moveColumns(left: IRegionGroup[], right: IRegionGroup[]): any {
    const leftCount = this.getColumnLength(left);
    const rightCount = this.getColumnLength(right);

    if (leftCount <= rightCount && rightCount - leftCount > LETTER_TITLE_HEIGHT) {
      const group = right.shift();
      if (group) {
        left.push(group);
      }

      return true;
    }

    return false;
  }

  private getColumnLength = (arr: IRegionGroup[]): any => {
    return arr.reduce((acc, value): any => {
      if (!value || !value.districts || !value.districts[0].childs) {
        return;
      }
      const length = value.districts[0].childs.length;
      const increment = length === 0 ? 1 : length;

      return acc + increment;
    }, arr.length * LETTER_TITLE_HEIGHT);
  };

  private sortMoscowRegions(groups: IRegionGroup[]): IRegionGroup[][] {
    const columns: IRegionGroup[][] = [[], [], [], []];
    this.setDistrictGroupByName(0, 'НАО (Новомосковский)', groups, columns);
    this.setDistrictGroupByName(0, 'ТАО (Троицкий)', groups, columns);
    this.setDistrictGroupByName(0, 'ЗелАО', groups, columns);

    this.setDistrictGroupByName(1, 'СЗАО', groups, columns);
    this.setDistrictGroupByName(1, 'ЗАО', groups, columns);
    this.setDistrictGroupByName(1, 'ЮЗАО', groups, columns);

    this.setDistrictGroupByName(2, 'САО', groups, columns);
    this.setDistrictGroupByName(2, 'ЦАО', groups, columns);
    this.setDistrictGroupByName(2, 'ЮАО', groups, columns);

    this.setDistrictGroupByName(3, 'СВАО', groups, columns);
    this.setDistrictGroupByName(3, 'ВАО', groups, columns);
    this.setDistrictGroupByName(3, 'ЮВАО', groups, columns);

    return columns;
  }

  private setDistrictGroupByName = (
    columnId: number,
    name: string,
    groups: IRegionGroup[],
    columns: IRegionGroup[][],
  ): any => {
    groups.forEach((value: IRegionGroup) => {
      if (value.letter === name) {
        columns[columnId].push(value);
      }
    });
  };

  private getChilds(childs: IApiGeoGetDistrictsTreeResponse, index: number): any {
    return (
      <ul key={index}>
        {childs.map((item: IApiGeoGetDistrictsTreeResponse[number]) => {
          return this.isSelected(item.id ?? 1) ? (
            <li key={item.id}>
              <Button
                className={styles['end-button']}
                theme="primary"
                onClick={(): void => {
                  this.props.onRemoved(item.id ?? 1);
                }}
              >
                {item.name ?? ''}
              </Button>
            </li>
          ) : (
            <li key={item.id}>
              <div
                className={styles['column-item']}
                onClick={(): void => {
                  this.props.onSelected(item.id ?? 1, item.name ?? '');
                }}
              >
                {item.name}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  private getDistrictName(group: IRegionGroup, isFlat: boolean): any {
    return (
      <div>
        {this.isSelected(group.id) ? (
          <Button
            className={isFlat ? styles['end-button'] : styles['end-title-button']}
            theme="primary"
            onClick={(): void => {
              this.props.onRemoved(group.id);
            }}
          >
            {group.letter}
          </Button>
        ) : (
          <div
            className={isFlat ? styles['column-item'] : styles['letter-title']}
            onClick={(): void => {
              this.props.onSelected(group.id, group.letter ?? '');
            }}
          >
            {group.letter}
          </div>
        )}

        <div className={styles['direction-title']}>
          {group.direction ? group.direction : DistrictDirectionName[group.letter ?? '']}
        </div>
      </div>
    );
  }

  private isSelected(id: number): any {
    return this.props.selectedDistricts.reduce((acc, value) => {
      if (acc) {
        return acc;
      }

      return value === id;
    }, false);
  }
}
