import { stringify } from 'qs';

import { Filters } from 'shared/types/filters';

import { IStrategy } from './types';

export class QueryStringStrategy implements IStrategy {
  public formatFilters(filters: Filters): string {
    return stringify(filters);
  }
}
