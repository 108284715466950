import { Children, FC, ReactNode } from 'react';

import styles from './styles.css';

type CommonFiltersWrapper = {
  children: ReactNode;
};

export const CommonFiltersWrapper: FC<CommonFiltersWrapper> = props => {
  const { children } = props;

  return (
    <div className={styles['container']}>
      {Children.map(children, child => (
        <div className={styles['child']}>{child}</div>
      ))}
    </div>
  );
};

CommonFiltersWrapper.displayName = 'CommonFiltersWrapper';
