import { plural } from '@cian/utils';
import { FC, useMemo } from 'react';

import { useGetCountQuery } from 'shared/store/serverSide/search-offers-index/v2/get-count/query';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';

import { AdvancedFiltersModalFooter } from './AdvancedFiltersModalFooter';
import { AdvancedFiltersModalFooterPropsContainer } from './types';

export const AdvancedFiltersModalFooterContainer: FC<AdvancedFiltersModalFooterPropsContainer> = props => {
  const { data, isFetching } = useGetCountQuery();

  const offersCount = data ?? 0;
  const label = useMemo(
    () => `Показать ${offersCount} ${plural(offersCount, ['объект', 'объекта', 'объектов'])}`,
    [offersCount],
  );

  const { clearAdvancedFiltersAction } = useFiltersActions();

  return (
    <AdvancedFiltersModalFooter {...props} label={label} loading={isFetching} onClear={clearAdvancedFiltersAction} />
  );
};

AdvancedFiltersModalFooterContainer.displayName = 'AdvancedFiltersModalFooterContainer';
