import { Checkbox, InformationTooltip } from '@cian/ui-kit';
import { FC, useCallback } from 'react';

import { OffersCountTooltip } from 'shared/components/OffersCountTooltip';
import { EFilterType } from 'shared/enums/EFilterType';
import { useFromOwnerTooltipExperimentActive } from 'shared/utils/hooks/experiments/useFromOwnerTooltipExperimentActive';
import { useFilter } from 'shared/utils/hooks/useFilter';
import { useFiltersActions } from 'shared/utils/hooks/useFiltersActions';
import { useOffersCountTooltip } from 'shared/utils/hooks/useOffersCountTooltip';
import { useOffersCountTooltipActions } from 'shared/utils/hooks/useOffersCountTooltipActions';
import { memoWithDisplayName } from 'shared/utils/memoWithDisplayName';

import { useFormOwnerMotivationTooltipState } from './internal/hooks/useFormOwnerMotivationTooltipState';

/**
 * @todo Удалить компонент с экспериментом
 * @description Данный функционал появился в задаче CD-161150, будет удалён в задаче CD-161151
 * Оставить, если будет удачным, переименовать в IsByCommercialOwnerFilter
 * Удалить если эксперимент будет не удачным
 */
const IsByCommercialOwnerFilterTest = memoWithDisplayName(() => {
  const isByCommercialOwner = useFilter(EFilterType.isByCommercialOwner);
  const { toggleIsByCommercialOwnerAction } = useFiltersActions();

  const [motivationTooltipVisible, hideMotivationTooltip] = useFormOwnerMotivationTooltipState();

  const { openedAtCurrentTarget } = useOffersCountTooltip(EFilterType.isByCommercialOwner);
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const handleChange = useCallback(() => {
    setTargetOffersCountTooltip(EFilterType.isByCommercialOwner);
    toggleIsByCommercialOwnerAction();
  }, [setTargetOffersCountTooltip, toggleIsByCommercialOwnerAction]);

  return (
    <InformationTooltip
      arrow
      content="Если хотите обсуждать подробности напрямую"
      header="Смотрите объявления от собственников"
      open={motivationTooltipVisible}
      placement="bottom"
      theme="black"
      onClose={hideMotivationTooltip}
    >
      <div>
        <OffersCountTooltip opened={openedAtCurrentTarget}>
          <div data-testid="filter-isByCommercialOwner">
            <Checkbox checked={!!isByCommercialOwner} label={`От\u00A0собственника`} onChange={handleChange} />
          </div>
        </OffersCountTooltip>
      </div>
    </InformationTooltip>
  );
}, 'IsByCommercialOwnerFilter');

/**
 * @todo Удалить компонент с экспериментом
 * @description Данный функционал появился в задаче CD-161150, будет удалён в задаче CD-161151
 * Оставить, если будет не удачным, переименовать в IsByCommercialOwnerFilter
 * Удалить если эксперимент будет удачным
 */
const IsByCommercialOwnerFilterCurrent = memoWithDisplayName(() => {
  const isByCommercialOwner = useFilter(EFilterType.isByCommercialOwner);
  const { toggleIsByCommercialOwnerAction } = useFiltersActions();

  const { openedAtCurrentTarget } = useOffersCountTooltip(EFilterType.isByCommercialOwner);
  const { setTargetOffersCountTooltip } = useOffersCountTooltipActions();

  const handleChange = useCallback(() => {
    setTargetOffersCountTooltip(EFilterType.isByCommercialOwner);
    toggleIsByCommercialOwnerAction();
  }, [setTargetOffersCountTooltip, toggleIsByCommercialOwnerAction]);

  return (
    <OffersCountTooltip opened={openedAtCurrentTarget} portal={true}>
      <div data-testid="filter-isByCommercialOwner">
        <Checkbox checked={!!isByCommercialOwner} label={`От\u00A0собственника`} onChange={handleChange} />
      </div>
    </OffersCountTooltip>
  );
}, 'IsByCommercialOwnerFilter');

/**
 * @todo Удалить компонент с экспериментом
 * @description Данный функционал появился в задаче CD-161150, будет удалён в задаче CD-161151
 * Удалить в любом случае
 */
export const IsByCommercialOwnerFilter: FC = () => {
  const fromOwnerTooltipExperimentActive = useFromOwnerTooltipExperimentActive();

  return fromOwnerTooltipExperimentActive ? <IsByCommercialOwnerFilterTest /> : <IsByCommercialOwnerFilterCurrent />;
};
