import { Checkbox, CheckboxButtonGroup } from '@cian/ui-kit';

import { InputTypeProps } from './types';

import styles from './styles.css';

export const InputTypeFilter: React.FC<InputTypeProps> = props => {
  const { options, inputType, isSeparatedInputType, onInputTypeChange, onIsSeparatedInputTypeChange } = props;

  return (
    <div className={styles['root']}>
      <CheckboxButtonGroup options={options} value={inputType} onChange={onInputTypeChange} />
      <Checkbox checked={isSeparatedInputType} label="Отдельный вход" onChange={onIsSeparatedInputTypeChange} />
    </div>
  );
};

InputTypeFilter.displayName = 'InputTypeFilter';
