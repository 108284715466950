import { EAdvancedFilterType } from 'shared/enums/EAdvancedFilterType';
import { hasBusinessOfficeType } from 'shared/utils/filters/hasBusinessOfficeType';
import { hasTwoOfficeTypes } from 'shared/utils/filters/hasTwoOfficeTypes';
import { includesBuildingType } from 'shared/utils/filters/includesBuildingType';

import { IFiltersService } from '../../IFiltersService';

import { AbstractAdvancedFiltersService } from './AbstractAdvancedFiltersService';

/**
 * @description Сервис для категории "Здание + ГБ". Не имеет значение аренда или продажа
 */
export class AnyBuildingAndBusinessAdvancedFiltersService
  extends AbstractAdvancedFiltersService
  implements IFiltersService<EAdvancedFilterType>
{
  public isAvailable(): boolean {
    return [hasTwoOfficeTypes, includesBuildingType, hasBusinessOfficeType].every(fn => fn(this.filters));
  }

  public getFields(): EAdvancedFilterType[] {
    return super
      .getFields()
      .concat(
        EAdvancedFilterType.floor,
        EAdvancedFilterType.floorTypes,
        EAdvancedFilterType.floorn,
        EAdvancedFilterType.buildingClassType,
        EAdvancedFilterType.conditionType,
        EAdvancedFilterType.contract,
        EAdvancedFilterType.inputType,
        EAdvancedFilterType.isSeparatedInputType,
        EAdvancedFilterType.houseYear,
        EAdvancedFilterType.parkingType,
        EAdvancedFilterType.commissionType,
        EAdvancedFilterType.withoutAgentCall,
        EAdvancedFilterType.buildingType,
        EAdvancedFilterType.electronicTrading,
      );
  }
}
