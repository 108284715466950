import { IAddressSuggest } from '@cian/geosuggest-widget';
import { isEmpty } from 'ramda';

import { ISuggestResponse } from 'shared/repositories/geo-suggest/v2/suggest';

import { ResultSuggestions } from './types';

export function prepareSuggestions(
  suggestionsData: ISuggestResponse['data'] | null,
  yandexSuggestionsData: YMaps.ISuggestResult<'geo'>[] | null,
): { sortOrder: string[]; suggestions: ResultSuggestions } {
  let suggestions: ResultSuggestions = {};
  const sortOrder: string[] = [];

  if (suggestionsData?.suggestions) {
    const {
      businessCenters,
      cities,
      districts,
      newbuildings,
      shoppingCenters,
      undergrounds,
      railways,
      roads,
      villages,
      coworkings,
    } = suggestionsData.suggestions;

    suggestions = {
      ...(businessCenters?.items?.length && { businessCenters: businessCenters.items }),
      ...(cities?.items?.length && { cities: cities.items }),
      ...(coworkings?.items?.length && { coworkings: coworkings.items }),
      ...(districts?.items?.length && { districts: districts.items }),
      ...(newbuildings?.items?.length && { newbuildings: newbuildings.items }),
      ...(railways?.items?.length && { railways: railways.items }),
      ...(roads?.items?.length && { roads: roads.items }),
      ...(shoppingCenters?.items?.length && { shoppingCenters: shoppingCenters.items }),
      ...(undergrounds?.items?.length && { undergrounds: undergrounds.items }),
      ...(villages?.items?.length && { villages: villages.items }),
    };
  }

  if (suggestionsData && !isEmpty(suggestions)) {
    sortOrder.push(...suggestionsData.sortOrder);
  }

  const yaData = yandexSuggestionsData as IAddressSuggest[];
  if (yandexSuggestionsData && !isEmpty(yandexSuggestionsData)) {
    suggestions.addresses = Object.keys(suggestions).length ? yaData.slice(0, 5) : yaData;

    if (!sortOrder.includes('addresses')) {
      sortOrder.push('addresses');
    }
  }

  return {
    sortOrder,
    suggestions,
  };
}
