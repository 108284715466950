import { plural } from '@cian/utils';
import { FC, useMemo } from 'react';

import { FindButtonContainer } from 'shared/components/FindButton/FindButtonContainer';
import { EButtonType } from 'shared/components/FindButton/types';
import { useGetCountQuery } from 'shared/store/serverSide/search-offers-index/v2/get-count/query';

import { ModalFooterProps } from './types';

export const ModalFooter: FC<ModalFooterProps> = props => {
  const { data, isFetching } = useGetCountQuery();
  const label = useMemo(
    () => (data ? `Показать ${data} ${plural(data, ['объект', 'объекта', 'объектов'])}` : 'Показать объекты'),
    [data],
  );

  return (
    <FindButtonContainer
      buttonType={EButtonType.AdvancedFilters}
      label={label}
      loading={isFetching}
      onClick={props.onShowClick}
    />
  );
};

ModalFooter.displayName = 'ModalFooter';
