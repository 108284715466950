import { FC } from 'react';

import { useApplicationContext } from 'shared/utils/applicationContext';

import { ReactQueryDevtoolsLoadable } from '../ReactQueryDevtoolsLoadable';

import styles from './ReactQueryDevtools.css';

export const ReactQueryDevtools: FC = () => {
  const {
    custom: { isTestMode },
  } = useApplicationContext();

  if (isTestMode) {
    return (
      <ReactQueryDevtoolsLoadable>
        {(Lib): JSX.Element => (
          <Lib.ReactQueryDevtools position="bottom-left" toggleButtonProps={{ className: styles['toggle-button'] }} />
        )}
      </ReactQueryDevtoolsLoadable>
    );
  }

  return null;
};
