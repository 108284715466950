import { ReactNode } from 'react';

import { BusinessAppointmentsFilter } from 'shared/components/filters/BusinessAppointmentsFilter';
import { DealTypeFilter } from 'shared/components/filters/DealTypeFilter';
import { IsByCommercialOwnerFilter } from 'shared/components/filters/IsByCommercialOwnerFilter';
import { OfficeTypeFilter } from 'shared/components/filters/OfficeTypeFilter';
import { PriceFilter } from 'shared/components/filters/PriceFilter';
import { TotalAreaFilter } from 'shared/components/filters/TotalAreaFilter';
import { WorkplaceCountFilter } from 'shared/components/filters/WorkplaceCountFilter/WorkplaceCountFilter';
import { WorkplaceTypeFilter } from 'shared/components/filters/WorkplaceOfferTypeFilter';
import { EFilterType } from 'shared/enums/EFilterType';

export const filterTypeToFilterComponentMapper: Record<EFilterType, () => ReactNode> = {
  [EFilterType.dealType]: () => <DealTypeFilter key={EFilterType.dealType} />,
  [EFilterType.officeType]: () => <OfficeTypeFilter key={EFilterType.officeType} />,
  [EFilterType.price]: () => <PriceFilter key={EFilterType.price} />,
  [EFilterType.totalArea]: () => <TotalAreaFilter key={EFilterType.totalArea} />,
  [EFilterType.isByCommercialOwner]: () => <IsByCommercialOwnerFilter key={EFilterType.isByCommercialOwner} />,
  [EFilterType.BusinessAppointments]: () => <BusinessAppointmentsFilter key={EFilterType.BusinessAppointments} />,
  [EFilterType.SpecialtyTypes]: () => null,
  [EFilterType.workplaceCount]: () => <WorkplaceCountFilter key={EFilterType.workplaceCount} />,
  [EFilterType.workplaceType]: () => <WorkplaceTypeFilter key={EFilterType.workplaceType} />,
  [EFilterType.region]: () => null,
  [EFilterType.priceType]: () => null,
};
